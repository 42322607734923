import { Texture } from "@babylonjs/core";

const path = "./assets/textures/Graphic_Wood.webp";

let normal;
let rotate90;
const WoodGrainTexture = {
    get normal() {
        if (!normal) {
            normal = new Texture(path);
        }
        return normal;
    },

    get rotate90() {
        if (!rotate90) {
            rotate90 = new Texture(path);
            rotate90.wAng = Math.PI / 2;
        }
        return rotate90;
    },
};

export default WoodGrainTexture;
