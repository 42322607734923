import { PRICE_UNITS } from "./constants";

function priceFromGroupAndUnit(group, product) {
    const [height, width, length] = group.dimensions ? group.dimensions.map((dimension) => (dimension ? dimension / 1000 : 0)) : [0, 0, 0];

    if (product) {
        switch (product.product_price_unit) {
            case PRICE_UNITS.LINEAR_METER:
                return height * Number.parseFloat(product.product_price) * Number.parseInt(group.quantity, 10);
            case PRICE_UNITS.SQUARE_METER:
                return length * width * Number.parseFloat(product.product_price) * Number.parseInt(group.quantity, 10);
            case PRICE_UNITS.UNIT_PRICE:
                return Number.parseFloat(product.product_price) * Number.parseInt(group.quantity, 10);
            default:
                return 0;
        }
    }
    return 0;
}

function getProductGroupsPriceFromCatalog(groups, catalog) {
    return groups.reduce((total, group) => {
        const selectedProduct = catalog.find((product) => product.product_ref === group.ref);
        const price = priceFromGroupAndUnit(group, selectedProduct);
        return total + price;
    }, 0);
}

export default { getProductGroupsPriceFromCatalog, priceFromGroupAndUnit };
