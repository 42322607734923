<template>
    <div class="h-full w-100 flex flex-col">
        <div class="flex pl-6 bg-theme-gray-100 py-3 items-center">
            <SelectableButton
                :selected="activeTab === DIMENSIONS_TAB"
                @click="setActiveTab(DIMENSIONS_TAB)"
            >
                {{ t("Dimensions caisson") }}
            </SelectableButton>
            <SelectableButton
                :selected="activeTab === FOOT_TAB"
                @click="setActiveTab(FOOT_TAB)"
            >
                {{ t("Pieds") }}
            </SelectableButton>
            <SelectableButton
                :selected="activeTab === PLINTHS_TAB"
                @click="setActiveTab(PLINTHS_TAB)"
            >
                {{ t("Plinthes") }}
            </SelectableButton>
        </div>
        <div v-if="activeTab === DIMENSIONS_TAB" class="pl-6 h-full flex items-center flex-grow">
            <div class="mr-6 flex items-center">
                <label for="height">{{ t("Hauteur") }}</label>
                <div class="flex border-2 border-theme-gray-200 h-12 rounded-md overflow-hidden mx-2">
                    <button type="button" class="h-full w-8 font-bold bg-theme-gray-100" @click="decrement(FIELD_HEIGHT)">
                        <span class="sr-only">{{ t("Moins") }}</span>
                        <span aria-hidden="true">-</span>
                    </button>
                    <input
                        id="height"
                        :value="dimensions.height"
                        type="number"
                        class="border-x-2 border-gray-200 px-2 h-full w-16 number-as-text text-center"
                        @keypress="updateFieldValues($event, FIELD_HEIGHT)"
                        @blur="updateFieldValues($event, FIELD_HEIGHT)"
                    >
                    <button type="button" class="h-full w-8 font-bold bg-theme-gray-100" @click="increment(FIELD_HEIGHT)">
                        <span class="sr-only">{{ t("Plus") }}</span>
                        <span aria-hidden="true">+</span>
                    </button>
                </div>
                <span>mm</span>
            </div>
            <div class="mr-6 flex items-center">
                <label for="width">{{ t("Largeur") }}</label>
                <div class="flex border-2 border-theme-gray-200 h-12 rounded-md overflow-hidden mx-2">
                    <button type="button" class="h-full w-8 font-bold bg-theme-gray-100" @click="decrement(FIELD_WIDTH)">
                        <span class="sr-only">{{ t("Moins") }}</span>
                        <span aria-hidden="true">-</span>
                    </button>
                    <input
                        id="width"
                        :value="dimensions.width"
                        type="number"
                        class="border-x-2 border-gray-200 px-2 h-full w-16 number-as-text text-center"
                        @keypress="updateFieldValues($event, FIELD_WIDTH)"
                        @blur="updateFieldValues($event, FIELD_WIDTH)"
                    >
                    <button type="button" class="h-full w-8 font-bold bg-theme-gray-100" @click="increment(FIELD_WIDTH)">
                        <span class="sr-only">{{ t("Plus") }}</span>
                        <span aria-hidden="true">+</span>
                    </button>
                </div>
                <span>mm</span>
            </div>
            <div class="mr-6 flex items-center">
                <label for="depth">{{ t("Profondeur") }}</label>
                <div class="flex border-2 border-theme-gray-200 h-12 rounded-md overflow-hidden mx-2">
                    <button type="button" class="h-full w-8 font-bold bg-theme-gray-100" @click="decrement(FIELD_DEPTH)">
                        <span class="sr-only">{{ t("Moins") }}</span>
                        <span aria-hidden="true">-</span>
                    </button>
                    <input
                        id="depth"
                        :value="dimensions.depth"
                        type="number"
                        class="border-x-2 border-gray-200 px-2 h-full w-16 number-as-text text-center"
                        @keypress="updateFieldValues($event, FIELD_DEPTH)"
                        @blur="updateFieldValues($event, FIELD_DEPTH)"
                    >
                    <button type="button" class="h-full w-8 font-bold bg-theme-gray-100" @click="increment(FIELD_DEPTH)">
                        <span class="sr-only">{{ t("Plus") }}</span>
                        <span aria-hidden="true">+</span>
                    </button>
                </div>
                <span>mm</span>
            </div>
        </div>
        <div v-if="activeTab === FOOT_TAB" class="h-full overflow-auto flex items-center">
            <SelectableCard
                :selected="selectedFeet === null"
                @click="onNothing"
            >
                <template #svgIcon>
                    <WithoutPlinthIcon />
                </template>
                {{ t("Sans pieds") }}
            </SelectableCard>
            <SelectableCard
                v-for="feet in feetCatalog"
                :key="feet.product_id"
                :selected="selectedFeet === feet"
                :image-url="feet.product_img"
                @click="onFeetChanged(feet)"
            >
                {{ feet.product_name }}
            </SelectableCard>
        </div>
        <div v-if="activeTab === PLINTHS_TAB" class="pl-6 h-full flex items-center flex-grow">
            <SelectableCard
                :selected="baseMode !== BASE_MODE_BASEBOARD"
                @click="onNothing"
            >
                <template #svgIcon>
                    <WithoutPlinthIcon />
                </template>
                {{ t("Sans\nplinthe") }}
            </SelectableCard>
            <SelectableCard
                :selected="baseMode === BASE_MODE_BASEBOARD"
                @click="onBaseboard"
            >
                <template #svgIcon>
                    <WithPlinthIcon />
                </template>
                {{ t("Avec\nplinthe") }}
            </SelectableCard>
            <div
                v-show="baseMode === BASE_MODE_BASEBOARD"
                class="mr-6 ml-6 flex items-center"
            >
                <label for="plinthHeight">{{ t("Hauteur de plinthe") }}</label>
                <div class="flex border-2 border-theme-gray-200 h-12 rounded-md overflow-hidden mx-2">
                    <button type="button" class="h-full w-8 font-bold bg-theme-gray-100" @click="decrement(FIELD_PLINTH_HEIGHT)">
                        <span class="sr-only">{{ t("Moins") }}</span>
                        <span aria-hidden="true">-</span>
                    </button>
                    <input
                        id="plinthHeight"
                        :value="storePlinthHeight"
                        type="number"
                        class="border-x-2 border-gray-200 px-2 h-full w-16 number-as-text text-center"
                        @keypress="updateFieldValues($event, FIELD_PLINTH_HEIGHT)"
                        @blur="updateFieldValues($event, FIELD_PLINTH_HEIGHT)"
                    >
                    <button type="button" class="h-full w-8 font-bold bg-theme-gray-100" @click="increment(FIELD_PLINTH_HEIGHT)">
                        <span class="sr-only">{{ t("Plus") }}</span>
                        <span aria-hidden="true">+</span>
                    </button>
                </div>
                <span>mm</span>
            </div>
        </div>
    </div>
</template>

<script>
import self from "../../../index";

import useFurnitureStore from "../../stores/furniture";
import useGlobalStore from "../../stores/global";

import WithoutPlinthIcon from "../icons/WithoutPlinthIcon.vue";
import WithPlinthIcon from "../icons/WithPlinthIcon.vue";

import {
    FIELD_WIDTH,
    FIELD_HEIGHT,
    FIELD_DEPTH,
    FIELD_PLINTH_HEIGHT,
    BASE_MODE_BASEBOARD,
    BASE_MODE_FEET,
} from "../../services/constants";
import SelectableButton from "./SelectableButton.vue";
import SelectableCard from "./SelectableCard.vue";
import { debounce } from "../../../../../helpers/utils";

const { events, modules } = self.app;

const DIMENSIONS_TAB = "dimensions";
const FOOT_TAB = "pieds";
const PLINTHS_TAB = "plinthes";

const ENTER_KEY_CODE = 13;

export default {
    name: "external-structure",
    components: {
        WithoutPlinthIcon,
        WithPlinthIcon,
        SelectableButton,
        SelectableCard,
    },
    data() {
        return {
            activeTab: DIMENSIONS_TAB,
            DIMENSIONS_TAB,
            FOOT_TAB,
            PLINTHS_TAB,
            FIELD_WIDTH,
            FIELD_HEIGHT,
            FIELD_DEPTH,
            FIELD_PLINTH_HEIGHT,
            BASE_MODE_BASEBOARD,
            BASE_MODE_FEET,
            debounceSendEvent: debounce((event, field) => {
                this.sendEvent(event, field);
            }, 500),
        };
    },
    computed: {
        store() {
            return useFurnitureStore();
        },
        globalStore() {
            return useGlobalStore();
        },
        dimensions() {
            return this.store.dimensions;
        },
        storePlinthHeight() {
            return this.store.plinthHeight;
        },
        baseMode() {
            return this.store.baseMode;
        },
        baseHeight() {
            return this.store.plinthHeight;
        },
        feetCatalog() {
            return this.globalStore.catalogs.feet;
        },
        selectedFeet() {
            return this.globalStore.selectedProducts.feet;
        },
    },
    mounted() {
        events.on("@furnitures.furniture:tryToScaleX:rejected", () => {
            this.$forceUpdate();
        });
        events.on("@furnitures.furniture:tryToScaleY:rejected", () => {
            this.$forceUpdate();
        });
        events.on("@furnitures.furniture:tryToScaleZ:rejected", () => {
            this.$forceUpdate();
        });
        events.on("@furnitures.baseboard:height:rejected", () => {
            this.$forceUpdate();
        });
        events.on("validate:foot:delete", () => {
            events.emit("foot:delete");
            events.emit("baseboard:delete");
            this.globalStore.setSelectedFeet(null);
        });
        events.on("validate:foot:create", (feet) => {
            events.emit("foot:create", { height: feet.product_height, ref: feet.product_ref });
            this.globalStore.setSelectedFeet(feet);
        });
    },
    unmounted() {
        events.removeListener("@furnitures.furniture:tryToScaleX:rejected");
        events.removeListener("@furnitures.furniture:tryToScaleY:rejected");
        events.removeListener("@furnitures.furniture:tryToScaleZ:rejected");
        events.removeListener("@furnitures.baseboard:height:rejected");
    },
    methods: {
        t(...args) {
            return modules.stonejs.gettext(...args);
        },
        onNothing() {
            events.emit("show:modal", {
                text: "Pour poursuivre cette modification, la hauteur hors tout de votre meuble va être modifiée, et certains accessoires pourront être supprimés.\nConfirmez-vous l’opération ?",
                validateEvent: "validate:foot:delete",
            });
        },
        onFeetChanged(feet) {
            events.emit("show:modal", {
                text: "Pour poursuivre cette modification, la hauteur hors tout de votre meuble va être modifiée, et certains accessoires pourront être supprimés.\nConfirmez-vous l’opération ?",
                validateEvent: "validate:foot:create",
                validateEventParams: feet,
            });
        },
        onBaseboard() {
            events.emit("baseboard:create");
        },
        setActiveTab(tab) {
            this.activeTab = tab;
        },
        decrement(field) {
            const fieldValue = this.getFieldValue(field);
            const value = fieldValue - 1;
            this.store.updateDimension(field, value);
            this.debounceSendEvent(field, value);
        },
        increment(field) {
            const fieldValue = this.getFieldValue(field);
            const value = fieldValue + 1;
            this.store.updateDimension(field, value);
            this.debounceSendEvent(field, value);

        },
        updateFieldValues(event, field) {
            if ((event.type === "keypress" && event.keyCode === ENTER_KEY_CODE) || event.type === "blur") {
                const value = parseInt(event.target.value, 10);
                this.sendEvent(field, value);
            }
        },
        getFieldValue(field) {
            switch (field) {
                case FIELD_WIDTH:
                    return this.dimensions.width;
                case FIELD_HEIGHT:
                    return this.dimensions.height;
                case FIELD_DEPTH:
                    return this.dimensions.depth;
                case FIELD_PLINTH_HEIGHT:
                    return this.storePlinthHeight;
                default:
                    return 0;
            }
        },
        sendEvent(field, value) {
            switch (field) {
                case FIELD_WIDTH:
                    events.emit("furniture:tryToScaleX", value);
                    break;
                case FIELD_HEIGHT:
                    events.emit("furniture:tryToScaleY", value);
                    break;
                case FIELD_DEPTH:
                    events.emit("furniture:tryToScaleZ", value);
                    break;
                case FIELD_PLINTH_HEIGHT:
                    events.emit("baseboard:height", value);
                    break;
                default:
                    break;
            }
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
