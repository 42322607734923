import {
    Vector3,
} from "@babylonjs/core/Maths/math.vector";
import {
    RESIZE_FROM_BACK,
    RESIZE_FROM_BOTTOM,
    RESIZE_FROM_LEFT,
    RESIZE_FROM_RIGHT,
    RESIZE_FROM_TOP,
} from "./constants";
import TransformHelper from "./helpers/transformHelper";
import FurnitureController from "./furniture/furniture-controller";
import BaseboardController from "./baseboard/baseboard-controller";
import FootController from "./foot/foot-controller";

import self from "../index";
import DoorHelper from "./door/door-helper";
import OverlayHelper from "./overlay/overlay-helper";
import RodHelper from "./rod/rod-helper";
import DoorEntityHelper from "./door/door-entity-helper";
import AccessorydrawerHelper from "./accessorydrawer/accessorydrawer-helper";
import DrawerHelper from "./drawer/drawer-helper";
import PulloutComposition from "./pulloutshelf/pullout-composition";

const {
    config,
    modules,
} = self.app;

const TransformManager = {

    /**
     *
     * @param {FrameEntity} startFrameEntityId
     * @param {Vector3} scalingDiff
     * @param {String} direction : RESIZE_FROM_LEFT, RESIZE_FROM_RIGHT, RESIZE_FROM_BOTTOM, RESIZE_FROM_TOP, RESIZE_FROM_BACK
     */
    resizeFurniture(furnitureEntity, scalingDiff, direction) {
        furnitureEntity.getScaling().addInPlace(scalingDiff);
        TransformManager.resizeFrame(furnitureEntity.getIdFrame(), scalingDiff, direction);
        FurnitureController.updateBlockMesh(furnitureEntity);
        if (furnitureEntity.getIdBaseboard()) BaseboardController.updateSize(furnitureEntity.getIdBaseboard());
        if (furnitureEntity.getIdsFeet()) {
            FootController.transformFeet(furnitureEntity);
        }
    },

    /**
     * Calling 2 times for board movement.
     *     1st time : for left or bottom frame compared to current board
     *     2nd time : for right or top frame compared to current board
     * Calling 1 time when furniture resizing, because there is no frame outside the furniture
     * @param {FrameEntity} startFrameEntityId
     * @param {Vector3} scalingDiff
     * @param {String} resizeOrigin : RESIZE_FROM_LEFT, RESIZE_FROM_RIGHT, RESIZE_FROM_BOTTOM, RESIZE_FROM_TOP, RESIZE_FROM_BACK
     */
    resizeFrame(startFrameEntityId, scalingDiff, resizeOrigin) {
        const startFrameEntity = modules.dataStore.getEntity(startFrameEntityId);

        const traverse = (frameEntityId) => {

            const frameEntity = modules.dataStore.getEntity(frameEntityId);
            if (!frameEntity) return;
            traverse(frameEntity.getIdFrameLeftOrBottom());
            traverse(frameEntity.getIdFrameRightOrTop());

            switch (resizeOrigin) {
                case RESIZE_FROM_RIGHT:
                    if (TransformHelper.isFramesAreAlignedToRightSide(frameEntity, startFrameEntity)) {
                        frameEntity.setScalingX(frameEntity.getScaling().x + scalingDiff.x);
                    }
                    break;

                case RESIZE_FROM_LEFT:
                    if (TransformHelper.isFramesAreAlignedToLeftSide(frameEntity, startFrameEntity)) {
                        frameEntity.setPositionX(frameEntity.getPosition().x - scalingDiff.x);
                        frameEntity.setScalingX(frameEntity.getScaling().x + scalingDiff.x);
                    }
                    break;

                case RESIZE_FROM_TOP:
                    if (TransformHelper.isFramesAreAlignedToTopSide(frameEntity, startFrameEntity)) {
                        frameEntity.setScalingY(frameEntity.getScaling().y + scalingDiff.y);
                    }
                    break;

                case RESIZE_FROM_BOTTOM:
                    if (TransformHelper.isFramesAreAlignedToBottomSide(frameEntity, startFrameEntity)) {
                        frameEntity.setPositionY(frameEntity.getPosition().y - scalingDiff.y);
                        frameEntity.setScalingY(frameEntity.getScaling().y + scalingDiff.y);
                    }
                    break;

                case RESIZE_FROM_BACK:
                    frameEntity.setScalingZ(frameEntity.getScaling().z + scalingDiff.z);
                    break;

                default:
                    break;
            }

            if (frameEntity.getIsSplitted()) {
                const boardEntity = modules.dataStore.getEntity(frameEntity.getIdBoard());
                const side1 = modules.dataStore.getEntity(frameEntity.getIdFrameLeftOrBottom());
                const side2 = modules.dataStore.getEntity(frameEntity.getIdFrameRightOrTop());
                if (boardEntity.isHorizontal()) {
                    boardEntity.setPositionX(side1.getPosition().x);
                    boardEntity.setPositionY(side2.getPosition().y - side2.getThickness());
                    boardEntity.setScalingX(side1.getScaling().x);
                } else if (boardEntity.isVertical()) {
                    boardEntity.setPositionX(side2.getPosition().x - side2.getThickness());
                    boardEntity.setPositionY(side1.getPosition().y);
                    boardEntity.setScalingY(side1.getScaling().y);
                }
                if (resizeOrigin === RESIZE_FROM_BACK) {
                    boardEntity.setScalingZ(frameEntity.getScaling().z);
                }
            } else {
                TransformManager.resizeBackboard(frameEntity);
                TransformManager.resizeMovableshelf(frameEntity);
                TransformManager.resizePulloutshelf(frameEntity);
                TransformManager.resizeRod(frameEntity);
                TransformManager.resizeAccessorydrawer(frameEntity);
            }
        };

        traverse(startFrameEntityId);

        const furnitureEntity = modules.dataStore.getEntity(startFrameEntity.getFurnitureId());
        if (furnitureEntity.isFrontModeOutside()) {
            OverlayHelper.detectOverlaysNeighbors();
        }
    },

    resizeDoors() {
        const doorsEntities = modules.dataStore.listEntities("door");
        for (let i = 0; i < doorsEntities.length; i++) {
            const doorEntity = doorsEntities[i];
            const doorBBox = DoorHelper.getDoorFramesBBox2Union(doorEntity);
            if (doorEntity.isFrontModeInside()) {
                doorEntity.scaling = new Vector3(doorBBox.width, doorBBox.height, doorEntity.getScaling().z);
                doorEntity.position = new Vector3(doorBBox.p1.x, doorBBox.p1.y, doorEntity.getPosition().z);
            } else {
                doorEntity.scaling = new Vector3(doorBBox.width, doorBBox.height, doorEntity.scaling.z);
                doorEntity.position = new Vector3(doorBBox.p1.x, doorBBox.p1.y, 0);
                DoorEntityHelper.updateDoorOverlayPosition(doorEntity);
                DoorEntityHelper.updateDoorOverlayScaling(doorEntity);
            }
        }
    },

    resizeOverlaydrawer() {
        const overlaydrawerEntities = modules.dataStore.listEntities("overlaydrawer");
        for (let i = 0, il = overlaydrawerEntities.length; i < il; i++) {
            const overlaydrawerEntity = overlaydrawerEntities[i];
            const frameEntity = modules.dataStore.getEntity(overlaydrawerEntity.getIdParent());
            overlaydrawerEntity.scaling = frameEntity.scaling.clone();
            overlaydrawerEntity.position = frameEntity.position.clone();
            OverlayHelper.updatePosition(overlaydrawerEntity);
            OverlayHelper.updateScaling(overlaydrawerEntity);
            DrawerHelper.updateMeshScaleAndPosition(overlaydrawerEntity);
        }
    },

    resizeInsetdrawer() {
        const insetdrawerEntities = modules.dataStore.listEntities("insetdrawer");
        for (let i = 0, il = insetdrawerEntities.length; i < il; i++) {
            const insetdrawerEntity = insetdrawerEntities[i];
            const frameEntity = modules.dataStore.getEntity(insetdrawerEntity.getIdParent());
            insetdrawerEntity.scaling = frameEntity.scaling.clone();
            insetdrawerEntity.position = frameEntity.position.clone();
            DrawerHelper.updateMeshScaleAndPosition(insetdrawerEntity);
        }
    },

    resizeAccessorydrawer(frameEntity) {
        if (!frameEntity.hasAccessorydrawer()) return;

        const accessorydrawerEntity = modules.dataStore.getEntity(frameEntity.idAccessorydrawer);
        accessorydrawerEntity.scaling = frameEntity.scaling.clone();
        accessorydrawerEntity.position = frameEntity.position.clone();
        AccessorydrawerHelper.updateMeshScaleAndPosition(accessorydrawerEntity);
        if (accessorydrawerEntity.isVisible) {
            AccessorydrawerHelper.applyConstructionMaterial(accessorydrawerEntity);
        }
    },

    resizeBackboard(frameEntity) {
        if (!frameEntity) return;
        const backboardEntity = modules.dataStore.getEntity(frameEntity.getIdBackboard());
        if (!backboardEntity) return;
        const furnitureEntity = modules.dataStore.getEntity(frameEntity.getFurnitureId());
        backboardEntity.positionX = frameEntity.getPosition().x - config.get("backboard_groove");
        backboardEntity.positionY = frameEntity.getPosition().y - config.get("backboard_groove");
        backboardEntity.positionZ = furnitureEntity.getScaling().z - config.get("backboard_z_shift") * 2;
        backboardEntity.scalingX = frameEntity.getScaling().x + config.get("backboard_groove") * 2;
        backboardEntity.scalingY = frameEntity.getScaling().y + +config.get("backboard_groove") * 2;
    },

    resizeMovableshelf(frameEntity) {
        if (!frameEntity || !frameEntity.hasMovableshelf()) return;
        for (let i = 0, il = frameEntity.idMovableshelfs.length; i < il; i++) {
            const movableshelfEntity = modules.dataStore.getEntity(frameEntity.idMovableshelfs[i]);
            movableshelfEntity.positionX = frameEntity.position.x;
            movableshelfEntity.scalingX = frameEntity.scaling.x;
            movableshelfEntity.scalingZ = frameEntity.scaling.z;
        }
    },

    resizePulloutshelf(frameEntity) {
        if (!frameEntity || !frameEntity.hasPulloutshelf()) return;
        for (let i = 0, il = frameEntity.idPulloutshelfs.length; i < il; i++) {
            const pulloutshelfEntity = modules.dataStore.getEntity(frameEntity.idPulloutshelfs[i]);
            pulloutshelfEntity.positionX = frameEntity.position.x;
            pulloutshelfEntity.scalingX = frameEntity.scaling.x;
            pulloutshelfEntity.scalingZ = frameEntity.scaling.z;
            PulloutComposition.updatePulloutComposition(pulloutshelfEntity);
        }
    },

    resizeRod(frameEntity) {
        if (!frameEntity || !frameEntity.hasRod()) return;
        RodHelper.transformFromParentEntity(frameEntity);
    },

};

export default TransformManager;
