import BBox2Helper from "../../../../helpers/BBox2Helper.js";
import self from "../../index.js";
import BoardInfo from "../board/board-info";
import {
    BOARD_THICKNESS, DOOR_DOUBLE, DOOR_SIMPLE_LEFT, DOOR_SIMPLE_RIGHT,
    DRAWER_BACKSPACE, DRAWER_RUNNER_PULLOUTSHEF_FRONT_LATERAL_SPACE,
    DRAWER_RUNNER_PULLOUTSHEF_LATERAL_SPACE, DRAWER_RUNNER_PULLOUTSHELF_VOID_SPACE, FRONT_MODE_INSIDE, PULLOUTSHELF, PULLOUTSHELF_BACK_HEIGHT, PULLOUTSHELF_FRONT_HEIGHT,
    PULLOUTSHELF_SPACE_ABOVE_BACK_BOARD,
    PULLOUTSHELF_SPACE_BELOW_BACK_BOARD,
} from "../constants";
import DrawerRunnerHelper from "../drawer/drawer-runner-helper.js";

const {
    modules,
} = self.app;

function createHingeSpacerBoard(hingeBoardComposition, frameEntity, box) {

    box.x -= BOARD_THICKNESS * 2;

    const spacerBoard = new BoardInfo();
    spacerBoard.scaling.x = BOARD_THICKNESS;
    spacerBoard.scaling.y = frameEntity.scaling.y;
    spacerBoard.scaling.z = box.z + BOARD_THICKNESS * 2 + DRAWER_BACKSPACE;

    hingeBoardComposition.push(spacerBoard);

    const spacerBoardPlus = spacerBoard.clone();
    spacerBoardPlus.scaling.z = 68;

    hingeBoardComposition.push(spacerBoardPlus);
    hingeBoardComposition.push(spacerBoardPlus);
}


const PulloutComposition = {
    updatePulloutComposition(pulloutshelfEntity) {
        const frameEntity = modules.dataStore.getEntity(pulloutshelfEntity.idParent);
        const drawerRunner = DrawerRunnerHelper.getNearestDrawerRunnerForFrame(frameEntity, PULLOUTSHELF, pulloutshelfEntity.drawerRunnerType);

        const {
            unitPulloutshelfBoardComposition: {
                front,
                back,
                bottom,
            },
            hingeBoardComposition,
        } = pulloutshelfEntity;

        const box = {
            x: frameEntity.scaling.x,
            y: PULLOUTSHELF_SPACE_ABOVE_BACK_BOARD + PULLOUTSHELF_BACK_HEIGHT + PULLOUTSHELF_SPACE_BELOW_BACK_BOARD,
            z: frameEntity.scaling.z,
        };

        hingeBoardComposition.length = 0;

        const isBehindDoor = frameEntity.hasDoor();

        if (isBehindDoor) {
            const doorEntity = modules.dataStore.getEntity(frameEntity.idDoor);
            const bBox2 = BBox2Helper.getBBox2FromObject3dEntity(frameEntity);
            const frameHasHingeTopLeft = bBox2.containVector2(doorEntity.hingeTopLeft);
            const frameHasHingeBottomLeft = bBox2.containVector2(doorEntity.hingeBottomLeft);
            const frameHasHingeTopRight = bBox2.containVector2(doorEntity.hingeTopRight);
            const frameHasHingeBottomRight = bBox2.containVector2(doorEntity.hingeBottomRight);
            const hasHingeAtLeft = frameHasHingeTopLeft || frameHasHingeBottomLeft;
            const hasHingeAtRight = frameHasHingeTopRight || frameHasHingeBottomRight;

            if (doorEntity.mode === FRONT_MODE_INSIDE) {
                box.z -= BOARD_THICKNESS;
            }

            switch (doorEntity.type) {
                case DOOR_DOUBLE:
                    if (hasHingeAtLeft) {
                        createHingeSpacerBoard(hingeBoardComposition, frameEntity, box);
                    }
                    if (hasHingeAtRight) {
                        createHingeSpacerBoard(hingeBoardComposition, frameEntity, box);
                    }
                    break;

                case DOOR_SIMPLE_RIGHT:
                    if (hasHingeAtLeft) {
                        createHingeSpacerBoard(hingeBoardComposition, frameEntity, box);
                    }
                    break;
                case DOOR_SIMPLE_LEFT:
                    if (hasHingeAtRight) {
                        createHingeSpacerBoard(hingeBoardComposition, frameEntity, box);
                    }
                    break;
                default:
                    break;
            }
        }

        front.scaling.x = box.x - DRAWER_RUNNER_PULLOUTSHEF_FRONT_LATERAL_SPACE * 2;
        front.scaling.y = PULLOUTSHELF_FRONT_HEIGHT;
        front.scaling.z = BOARD_THICKNESS;

        back.scaling = front.scaling.clone();
        back.scaling.x -= DRAWER_RUNNER_PULLOUTSHEF_LATERAL_SPACE * 2;

        bottom.scaling.x = box.x - DRAWER_RUNNER_PULLOUTSHEF_LATERAL_SPACE * 2;
        bottom.scaling.y = BOARD_THICKNESS;
        bottom.scaling.z = drawerRunner.length - DRAWER_RUNNER_PULLOUTSHELF_VOID_SPACE - BOARD_THICKNESS;
    },
};
export default PulloutComposition;
