<template>
    <svg v-if="isColored"
         xmlns="http://www.w3.org/2000/svg"
         width="29"
         height="29"
         viewBox="0 0 29 29"
    >
        <title>Enregistrer mon projet</title>
        <desc>Une disquette</desc>
        <g id="icon-save-color" transform="translate(-1 -1)">
            <path id="Tracé_140"
                  data-name="Tracé 140"
                  d="M28.674,8.767,22.109,2.2a2.384,2.384,0,0,0-1.7-.7H3.9A2.4,2.4,0,0,0,1.5,3.9V26.972a2.4,2.4,0,0,0,2.4,2.4H26.972a2.4,2.4,0,0,0,2.4-2.4v-16.5A2.393,2.393,0,0,0,28.674,8.767ZM7.267,2.461H17.841V8.709a.481.481,0,0,1-.481.481H7.748a.481.481,0,0,1-.481-.481ZM28.414,26.972a1.442,1.442,0,0,1-1.442,1.442H3.9a1.442,1.442,0,0,1-1.442-1.442V3.9A1.442,1.442,0,0,1,3.9,2.461h2.4V8.709a1.442,1.442,0,0,0,1.442,1.442H17.36A1.442,1.442,0,0,0,18.8,8.709V2.461h1.605a1.451,1.451,0,0,1,1.019.423l6.565,6.565a1.432,1.432,0,0,1,.423,1.019Z"
                  transform="translate(0 0)"
                  stroke="#000"
                  stroke-width="1"
            />
            <rect id="Rectangle_349"
                  data-name="Rectangle 349"
                  width="2"
                  height="4"
                  rx="0.5"
                  transform="translate(13.938 2.938)"
                  fill="#ec910a"
            />
            <path id="Tracé_141"
                  data-name="Tracé 141"
                  d="M19.419,13.5H6.571A1.142,1.142,0,0,0,5.5,14.7v8.82a1.142,1.142,0,0,0,1.071,1.2H19.419a1.142,1.142,0,0,0,1.071-1.2V14.7a1.142,1.142,0,0,0-1.071-1.2Zm.357,10.023a.381.381,0,0,1-.357.4H6.571a.381.381,0,0,1-.357-.4V14.7a.381.381,0,0,1,.357-.4H19.419a.381.381,0,0,1,.357.4Z"
                  transform="translate(2.443 -0.737)"
                  fill="#e83e8c"
                  stroke="#e83e8c"
                  stroke-width="1"
            />
        </g>
    </svg>
    <svg v-else
         xmlns="http://www.w3.org/2000/svg"
         width="29"
         height="29"
         viewBox="0 0 29 29"
    >
        <title>Enregistrer mon projet</title>
        <desc>Une disquette</desc>
        <g id="icon-save-white" transform="translate(-1 -1)">
            <path id="Tracé_140"
                  data-name="Tracé 140"
                  d="M28.674,8.767,22.109,2.2a2.384,2.384,0,0,0-1.7-.7H3.9A2.4,2.4,0,0,0,1.5,3.9V26.972a2.4,2.4,0,0,0,2.4,2.4H26.972a2.4,2.4,0,0,0,2.4-2.4v-16.5A2.393,2.393,0,0,0,28.674,8.767ZM7.267,2.461H17.841V8.709a.481.481,0,0,1-.481.481H7.748a.481.481,0,0,1-.481-.481ZM28.414,26.972a1.442,1.442,0,0,1-1.442,1.442H3.9a1.442,1.442,0,0,1-1.442-1.442V3.9A1.442,1.442,0,0,1,3.9,2.461h2.4V8.709a1.442,1.442,0,0,0,1.442,1.442H17.36A1.442,1.442,0,0,0,18.8,8.709V2.461h1.605a1.451,1.451,0,0,1,1.019.423l6.565,6.565a1.432,1.432,0,0,1,.423,1.019Z"
                  transform="translate(0 0)"
                  fill="#fff"
                  stroke="#fff"
                  stroke-width="1"
            />
            <rect id="Rectangle_349"
                  data-name="Rectangle 349"
                  width="2"
                  height="4"
                  rx="0.5"
                  transform="translate(13.938 2.938)"
                  fill="#fff"
            />
            <path id="Tracé_141"
                  data-name="Tracé 141"
                  d="M19.419,13.5H6.571A1.142,1.142,0,0,0,5.5,14.7v8.82a1.142,1.142,0,0,0,1.071,1.2H19.419a1.142,1.142,0,0,0,1.071-1.2V14.7a1.142,1.142,0,0,0-1.071-1.2Zm.357,10.023a.381.381,0,0,1-.357.4H6.571a.381.381,0,0,1-.357-.4V14.7a.381.381,0,0,1,.357-.4H19.419a.381.381,0,0,1,.357.4Z"
                  transform="translate(2.443 -0.737)"
                  fill="#fff"
                  stroke="#fff"
                  stroke-width="1"
            />
        </g>
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "save-icon",
    props: {
        isColored: {
            type: Boolean,
            default: true,
        },
    },
});
</script>
