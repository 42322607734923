<template>
    <svg v-if="isColored"
         xmlns="http://www.w3.org/2000/svg"
         width="33.487"
         height="33.657"
         viewBox="0 0 33.487 33.657"
    >
        <title>{{ title }}</title>
        <desc>Flèche rotative sur plusieurs axes</desc>
        <g id="icon-3d-color" transform="translate(-667.054 -712.847)">
            <path id="Tracé_319"
                  data-name="Tracé 319"
                  d="M-.185,15.154S-.574.615,3.6-.024s7.232,9.7,7.992,15.1S11.069,29.436,7.7,29.921s-5.672-5.86-5.672-5.86"
                  transform="translate(681.107 714.061) rotate(8)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-width="2"
            />
            <path id="Tracé_320"
                  data-name="Tracé 320"
                  d="M.244,14.9S-.141,29.435,4.035,30.076s7.23-9.7,7.989-15.1S11.5.619,8.129.131,2.458,5.989,2.458,5.989"
                  transform="translate(698.026 721.889) rotate(83)"
                  fill="none"
                  stroke="#e83e8c"
                  stroke-linecap="round"
                  stroke-width="2"
            />
            <circle id="Ellipse_38"
                    data-name="Ellipse 38"
                    cx="2"
                    cy="2"
                    r="2"
                    transform="translate(682.564 728.299)"
                    fill="#ec910a"
            />
            <line id="Ligne_141"
                  data-name="Ligne 141"
                  x2="1"
                  y2="2"
                  transform="translate(677.742 729.847)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-width="2"
            />
            <line id="Ligne_142"
                  data-name="Ligne 142"
                  x1="1"
                  y2="2"
                  transform="translate(678.63 729.847)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-width="2"
            />
            <line id="Ligne_143"
                  data-name="Ligne 143"
                  x2="1"
                  y2="2"
                  transform="translate(684.309 724.926) rotate(-93)"
                  fill="none"
                  stroke="#e83e8c"
                  stroke-linecap="round"
                  stroke-width="2"
            />
            <line id="Ligne_144"
                  data-name="Ligne 144"
                  x1="1"
                  y2="2"
                  transform="translate(684.263 724.024) rotate(-93)"
                  fill="none"
                  stroke="#e83e8c"
                  stroke-linecap="round"
                  stroke-width="2"
            />
        </g>
    </svg>

    <svg v-else
         xmlns="http://www.w3.org/2000/svg"
         width="33.487"
         height="33.657"
         viewBox="0 0 33.487 33.657"
    >
        <title>{{ title }}</title>
        <desc>Flèche rotative sur plusieurs axes</desc>
        <g id="icon-3d-white" transform="translate(-667.054 -712.847)">
            <path id="Tracé_319"
                  data-name="Tracé 319"
                  d="M-.185,15.154S-.574.615,3.6-.024s7.232,9.7,7.992,15.1S11.069,29.436,7.7,29.921s-5.672-5.86-5.672-5.86"
                  transform="translate(681.107 714.061) rotate(8)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="2"
            />
            <path id="Tracé_320"
                  data-name="Tracé 320"
                  d="M.244,14.9S-.141,29.435,4.035,30.076s7.23-9.7,7.989-15.1S11.5.619,8.129.131,2.458,5.989,2.458,5.989"
                  transform="translate(698.026 721.889) rotate(83)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="2"
            />
            <circle id="Ellipse_38"
                    data-name="Ellipse 38"
                    cx="2"
                    cy="2"
                    r="2"
                    transform="translate(682.564 728.299)"
                    fill="#fff"
            />
            <line id="Ligne_141"
                  data-name="Ligne 141"
                  x2="1"
                  y2="2"
                  transform="translate(677.742 729.847)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="2"
            />
            <line id="Ligne_142"
                  data-name="Ligne 142"
                  x1="1"
                  y2="2"
                  transform="translate(678.63 729.847)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="2"
            />
            <line id="Ligne_143"
                  data-name="Ligne 143"
                  x2="1"
                  y2="2"
                  transform="translate(684.309 724.926) rotate(-93)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="2"
            />
            <line id="Ligne_144"
                  data-name="Ligne 144"
                  x1="1"
                  y2="2"
                  transform="translate(684.263 724.024) rotate(-93)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="2"
            />
        </g>
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "three-dimension-icon",
    props: {
        isColored: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: "Icone 3D",
        },
    },
});
</script>
