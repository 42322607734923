import { debounce } from "../../../../helpers/utils";
import self from "../../index";
import MovableshelfController from "../movableshelf/movableshelf-controller";
import PulloutshelfController from "../pulloutshelf/pulloutshelf-controller";
import FurnitureCompositionBuilder from "./furniture-composition-builder";
import FurnitureController from "./furniture-controller";
import FurnitureHelper from "./furniture-helper";

const {
    events,
    modules,
} = self.app;

const data = {
    listenersAdded: false,
};

const FurnitureEventsManager = {
    addEventListeners() {
        if (data.listenersAdded) return;

        events.on("furniture:tryToScaleX", FurnitureHelper.tryToScaleX);
        events.on("furniture:tryToScaleY", FurnitureHelper.tryToScaleY);
        events.on("furniture:tryToScaleZ", FurnitureHelper.tryToScaleZ);

        events.on("baseboard:height", FurnitureHelper.updateBaseboardHeight);
        events.on("tool:deactivateTools", FurnitureHelper.deactivateAllTools);
        events.on("accessories:selectable", FurnitureEventsManager.onAccessoriesSelectable);


        events.on("@vuejs.furniture:tryToScaleX", FurnitureHelper.tryToScaleX);
        events.on("@vuejs.furniture:tryToScaleY", FurnitureHelper.tryToScaleY);
        events.on("@vuejs.furniture:tryToScaleZ", FurnitureHelper.tryToScaleZ);

        events.on("@vuejs.furniture:deleteContentForScaleXorY", FurnitureHelper.deleteContent);
        events.on("@vuejs.furniture:deleteContentForScaleXorY", FurnitureHelper.scaleXorY);

        events.on("@vuejs.furniture:deleteContentForScaleZ", FurnitureHelper.deleteContent);
        events.on("@vuejs.furniture:deleteContentForScaleZ", FurnitureHelper.scaleZ);

        events.on("@vuejs.baseboard:create", FurnitureHelper.createBaseboard);
        events.on("@vuejs.baseboard:delete", FurnitureHelper.deleteBaseboard);
        events.on("@vuejs.baseboard:height", FurnitureHelper.updateBaseboardHeight);


        events.on("@vuejs.tool:deactivateTools", FurnitureHelper.deactivateAllTools);

        events.on("@vuejs.foot:create", FurnitureHelper.createFoot);
        events.on("@vuejs.foot:delete", FurnitureHelper.deleteFoot);

        events.on("@vuejs.history:back", FurnitureEventsManager.onHistoryBack);
        events.on("@vuejs.history:forward", FurnitureEventsManager.onHistoryForward);

        events.on("@vuejs.accessories:selectable", FurnitureEventsManager.onAccessoriesSelectable);

        events.on("@vuejs.furniture:knob", FurnitureHelper.updateKnobs);

        events.on("furniture:edited", FurnitureEventsManager.onEdited);

        events.on("@vuejs.accessories:showAll", FurnitureController.showAllAccessories);
        events.on("@vuejs.accessories:hideAll", FurnitureController.hideAllAccessories);

        data.listenersAdded = true;
    },

    emitFurnitureUpdated: debounce(() => {
        events.emit("furniture:updated", FurnitureHelper.getCurrentFurnitureEntityCopyForUi());
    }, 400),

    emitFurnitureCompositionUpdated: debounce((params) => {
        events.emit("furniture:composition:updated", FurnitureCompositionBuilder.instance.getComposition(params));
    }, 400),

    onHistoryBack() {
        FurnitureEventsManager.emitFurnitureCompositionUpdated();
    },

    onHistoryForward() {
        FurnitureEventsManager.emitFurnitureCompositionUpdated();
    },

    onEdited(params) {
        FurnitureEventsManager.snapshot();
        FurnitureEventsManager.emitFurnitureCompositionUpdated(params);
    },

    snapshot: debounce(() => {
        modules.history.snapshot();
    }, 400),

    onAccessoriesSelectable() {
        events.emit("tool:frame:deactivate");
        modules.toolManager.controller.activateToolList([
            MovableshelfController.atool.tool_name,
            PulloutshelfController.atool.tool_name,
        ]);
    },
};

export default FurnitureEventsManager;
