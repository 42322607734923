import {
    Matrix,
    Vector3,
} from "@babylonjs/core";
import self from "../../index";
import {
    BOARD_THICKNESS,
    ROD_DISTANCE_FROM_BOARD,
    ROD_LATERIAL_DISTANCE,
    ROD_ORIENTED_IN_DEPTH_UP_TO,
} from "../constants";
import FrameController from "../frame/frame-controller";
import FrameEntity from "../frame/frame-entity";
import FrameMesh from "../frame/frame-mesh";
import RodController from "./rod-controller";

const {
    events,
    modules,
} = self.app;

const RodHelper = {
    transformFromParentEntity: (parentEntity) => {
        const rodEntity = modules.dataStore.getEntity(parentEntity.idRod);
        if (!rodEntity) return;

        let freeDepth = parentEntity.scaling.z;
        if (parentEntity instanceof FrameEntity) {
            if (parentEntity.hasBackboard()) {
                freeDepth -= BOARD_THICKNESS * 2;
            }
        }
        rodEntity.isOrientedInDepth = freeDepth < ROD_ORIENTED_IN_DEPTH_UP_TO;

        const parentIsFrame = parentEntity instanceof FrameEntity;
        if (rodEntity.isOrientedInDepth) {

            rodEntity.scalingX = parentEntity.scaling.z - ROD_LATERIAL_DISTANCE * 2;
            if (parentIsFrame && parentEntity.hasBackboard()) {
                rodEntity.scalingX = rodEntity.scaling.x - BOARD_THICKNESS;
            }
            rodEntity.position = new Vector3(
                parentEntity.position.x + parentEntity.scaling.x / 2,
                parentEntity.position.y + (parentIsFrame ? parentEntity.scaling.y : 0) - ROD_DISTANCE_FROM_BOARD,
                parentEntity.position.z + ROD_LATERIAL_DISTANCE,
            );

        } else {

            rodEntity.scalingX = parentEntity.scaling.x - ROD_LATERIAL_DISTANCE * 2;
            rodEntity.position = new Vector3(
                parentEntity.position.x + ROD_LATERIAL_DISTANCE,
                parentEntity.position.y + (parentIsFrame ? parentEntity.scaling.y : 0) - ROD_DISTANCE_FROM_BOARD,
                parentEntity.position.z + parentEntity.scaling.z / 2,
            );
        }

    },

    positionZFromParentEntity: (parentEntity) => {
        const rodEntity = modules.dataStore.getEntity(parentEntity.idRod);
        if (!rodEntity) return;
        if (rodEntity.isOrientedInDepth) {
            rodEntity.position.z = parentEntity.position.z + ROD_LATERIAL_DISTANCE;
        } else {
            rodEntity.position.z = parentEntity.scaling.z - parentEntity.scaling.z / 2;
        }
    },

    actionToRod(event) {
        if (!FrameController.isActiveTool()) return;
        const {
            scene,
        } = modules.obsidianBabylonEngine;
        const ray = scene.createPickingRay(event.layerX, event.clientY, Matrix.Identity(), scene.activeCamera);
        const hit = scene.pickWithRay(ray);
        if (!(hit.pickedMesh instanceof FrameMesh)) return;
        const frameEntity = modules.dataStore.getEntity(hit.pickedMesh.metadata.frameId);
        if (!frameEntity) return;
        if (!RodHelper.canAddToFrame(frameEntity)) return;

        RodController.createRod(frameEntity);

        events.emit("furniture:edited");
    },

    canAddToFrame(frameEntity) {
        return !frameEntity.idDrawer &&
            !frameEntity.idAccessorydrawer &&
            !frameEntity.idRod;
    },
};

export default RodHelper;
