import self from "../../index";
import furnitureUiButtons from "../furniture/furniture-ui-buttons";

import RodEntityBuilder from "./rod-entity-builder";
import RodHelper from "./rod-helper";
import RodMeshBuilder from "./rod-mesh-builder";

const {
    modules,
    events,
} = self.app;

const RodController = {
    /**
     *
     * @param {BoardEntity || MovableshelfEntity} parentEntity
     */
    createRod: (parentEntity) => {
        const rodEntity = RodEntityBuilder.build(parentEntity);
        parentEntity.idRod = rodEntity.id;
        modules.dataStore.addEntity(rodEntity, "rod");
        furnitureUiButtons.updateButtons();
    },

    deleteRod: (rodEntityId) => {
        const rodEntity = modules.dataStore.getEntity(rodEntityId);
        if (!rodEntity) return;
        modules.dataStore.removeEntity(rodEntity.id);
        const parentEntity = modules.dataStore.getEntity(rodEntity.getIdParent());
        parentEntity.idRod = null;
        events.emit("furniture:edited");
        furnitureUiButtons.updateButtons();
    },

    generateRodMeshAndMaterial(rodEntity) {
        const furnitureEntity = modules.dataStore.getEntity(rodEntity.getFurnitureId());
        const parentEntity = modules.dataStore.getEntity(rodEntity.getIdParent());
        RodMeshBuilder.buildDefault(rodEntity, furnitureEntity.container);
        if (!parentEntity) return;
        RodHelper.transformFromParentEntity(parentEntity);
    },

    hideAll() {
        const rodEntities = modules.dataStore.listEntities("rod");
        rodEntities.forEach((rodEntity) => {
            rodEntity.hide();
        });
    },

    showAll() {
        const rodEntities = modules.dataStore.listEntities("rod");
        rodEntities.forEach((rodEntity) => {
            rodEntity.show();
        });
    },
};
export default RodController;
