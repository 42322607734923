<template>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg"
             width="29.569"
             height="30.362"
             viewBox="0 0 29.569 30.362"
        >
            <title>{{ title }}</title>
            <desc>Une icône représentant des accessoires de meuble</desc>
            <g id="icon-accessories-color" transform="translate(0.5 0.5)">
                <path id="Tracé_126"
                      data-name="Tracé 126"
                      d="M38.694,39.93H35.408a.458.458,0,0,0,0,.916h3.286a.458.458,0,1,0,0-.916Z"
                      transform="translate(-29.766 -17.736)"
                      class="transition duration-300"
                      :stroke="isActive || isHovered ? '#ec910a' : 'currentColor'"
                      stroke-width="1"
                />
                <line id="Ligne_9"
                      data-name="Ligne 9"
                      x2="26"
                      transform="translate(1.285 7.5)"
                      fill="none"
                      class="transition duration-300"
                      :stroke="isActive || isHovered ? '#e83e8c' : 'currentColor'"
                      stroke-linecap="round"
                      stroke-width="2"
                />
                <path id="Tracé_127"
                      data-name="Tracé 127"
                      d="M28.057,0H.512A.512.512,0,0,0,0,.512V28.849a.512.512,0,0,0,1.024,0v-2.1h26.52v2.1a.512.512,0,0,0,1.024,0V.512A.512.512,0,0,0,28.057,0ZM1.024,18.362V4.486h26.52V18.362ZM27.545,1.024V3.462H1.024V1.024ZM1.024,25.182V19.387H13.772v6.335H1.024Zm13.772.54V19.387H27.545v6.335Z"
                      fill="#bdc9d5"
                      stroke="#bdc9d5"
                      stroke-width="1"
                />
                <path id="Tracé_128"
                      data-name="Tracé 128"
                      d="M35.408,13.916h3.286a.458.458,0,1,0,0-.916H35.408a.458.458,0,1,0,0,.916Z"
                      transform="translate(-15.766 9.194)"
                      :fill="isActive || isHovered ? '#e83e8c' : 'currentColor'"
                      class="transition duration-300"
                      :stroke="isActive || isHovered ? '#ec910a' : 'currentColor'"
                      stroke-width="1"
                />
            </g>
        </svg>
    </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "accessories-icon",
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        isHovered: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "Icône d'accessoires de meuble",
        },
    },
});
</script>
