import { MeshBuilder, Vector3 } from "@babylonjs/core";
import self from "../../index";

const MESH_PART_LINE = "MESH_PART_LINE";


const DrawerSeparateLine = {
    getRef() {
        const lines = [];
        lines.push([
            new Vector3(0, 0, 1),
            new Vector3(0, 0, 0),
            new Vector3(1, 0, 0),
            new Vector3(1, 0, 1),
            new Vector3(0, 0, 1),
        ]);
        const lineSystem = MeshBuilder.CreateLineSystem(MESH_PART_LINE, {
            lines,
        });
        lineSystem.isPickable = false;
        lineSystem.color = self.config.doorLinesColor;
        return lineSystem;
    },
};

export default DrawerSeparateLine;
export { MESH_PART_LINE };
