import DoorMeshBuilder from "./door-mesh-builder";

import self from "../../index";
import DoorEntityBuilder from "./door-entity-builder";
import ConstraintsManager from "../constraints-manager";
import DoorHelper from "./door-helper";
import FrameHelper from "../frame/frame-helper";
import BBox2Helper from "../../../../helpers/BBox2Helper";
import OverlayHelper from "../overlay/overlay-helper";
import FurnitureHelper from "../furniture/furniture-helper";
import DoorEntityHelper from "./door-entity-helper";
import furnitureUiButtons from "../furniture/furniture-ui-buttons";
import { DOOR_CONSTRAINTS, DOOR_DOUBLE_CONSTRAINTS } from "../constraints";
import { DOOR_DOUBLE } from "../constants";

const {
    modules,
    events,
} = self.app;

const data = {
    currentDoorEntity: null,
    currentFrameAncestor: null,
    framesForExtendDoor: [],
};

const DoorController = {

    get currentDoorEntity() {
        return data.currentDoorEntity;
    },

    set currentDoorEntity(value) {
        data.currentDoorEntity = value;
    },

    get currentFrameAncestor() {
        return data.currentFrameAncestor;
    },

    set currentFrameAncestor(value) {
        data.currentFrameAncestor = value;
        if (!value) return;
        data.currentFrameAncestor.bbox = BBox2Helper.getBBox2FromObject3dEntity(data.currentFrameAncestor);
    },

    get framesForExtendDoor() {
        return data.framesForExtendDoor;
    },

    set framesForExtendDoor(value) {
        data.framesForExtendDoor = value;
    },

    createDoor(frameEntity, type, mode) {
        const furnitureEntity = modules.dataStore.getEntity(frameEntity.getFurnitureId());
        if (!FurnitureHelper.isValidFurnitureFrontMode(furnitureEntity, mode) || frameEntity.hasDoor()) {
            return;
        }

        DoorHelper.validCurrentDoorInEdition();

        const canAddDoor = ConstraintsManager.canAddDoor(frameEntity, type, mode);
        if (!canAddDoor.isAdapted) {
            const message = type === DOOR_DOUBLE ? DOOR_DOUBLE_CONSTRAINTS.message : DOOR_CONSTRAINTS.message;

            events.emit("show:modal", {
                text: message,
                isInfo: true,
            });
            return;
        }

        if (frameEntity.idDrawer) return;

        const doorEntity = DoorEntityBuilder.build(frameEntity, type, mode);
        doorEntity.isInEdition = true;
        doorEntity.addFrameToBuildOrder(frameEntity.id);
        doorEntity.addFrameEntityId(frameEntity.id);

        frameEntity.setIdDoor(doorEntity.id);
        furnitureEntity.frontMode = mode;
        modules.dataStore.addEntity(doorEntity, "door");
        DoorHelper.addAllFramesIdBehindTheDoorToTheDoor(doorEntity);
        events.emit("furniture:edited");

        data.currentDoorEntity = doorEntity;
        DoorController.currentFrameAncestor = FrameHelper.getAncestorWithSameOrientation(frameEntity);
        DoorHelper.updateItemsCoveredByDoor(doorEntity);
        DoorHelper.showExtendableZones(doorEntity);
        furnitureUiButtons.updateButtons();
    },

    deleteDoor(frameEntity) {
        const doorEntity = modules.dataStore.getEntity(frameEntity.getIdDoor());
        if (!doorEntity) return;

        DoorController.currentDoorEntity = doorEntity;
        DoorController.currentFrameAncestor = undefined;

        DoorHelper.unlinkFramesOfDoor(doorEntity);

        modules.dataStore.removeEntity(doorEntity);
        if (doorEntity.isFrontModeOutside()) {
            OverlayHelper.detectOverlaysNeighbors();
        }
        frameEntity.setIdDoor(null);
        OverlayHelper.updateFurnitureFrontMode(frameEntity);

        DoorController.resetValues();
        DoorHelper.updateItemsCoveredByDoor(doorEntity);
        events.emit("tool:frame:deactivate");
        events.emit("furniture:edited");
        furnitureUiButtons.updateButtons();
    },

    generateDoorMeshAndMaterial(doorEntity) {
        const furnitureEntity = modules.dataStore.getEntity(doorEntity.getFurnitureId());
        DoorMeshBuilder.buildDefault(doorEntity, furnitureEntity.container);

        DoorEntityHelper.updateDoorPosition(doorEntity);
        DoorEntityHelper.updateDoorScaling(doorEntity);
        DoorHelper.updateHinges(doorEntity);

        DoorHelper.applyConstructionMaterial(doorEntity);

        if (doorEntity.isFrontModeOutside()) {
            OverlayHelper.detectOverlaysNeighbors();
        }
    },

    resetValues() {
        DoorController.currentFrameAncestor = undefined;
        DoorController.currentDoorEntity = undefined;
        DoorController.framesForExtendDoor = [];
    },

    switchMaterialToConstruction() {
        modules.dataStore.listEntities("door").forEach((doorEntity) => {
            DoorHelper.applyConstructionMaterial(doorEntity);
        });
    },

    switchMaterialsToFinition() {
        modules.dataStore.listEntities("door").forEach((doorEntity) => {
            DoorHelper.applyFinishMaterial(doorEntity);
        });
    },

    clearAllFromFurnitureId(furnitureId) {
        const doorEntities = modules.dataStore.listEntities("door");
        doorEntities.forEach((doorEntity) => {
            if (doorEntity.furnitureId === furnitureId) {
                const frameEntity = modules.dataStore.getEntity(doorEntity.idParent);
                frameEntity.idDoor = null;
                frameEntity.isCoveredByDoor = false;
                frameEntity.isPartialyCoveredByADoor = false;
                modules.dataStore.removeEntity(doorEntity);
            }
        });
    },
};

export default DoorController;
