import self from "../../index";
import {
    TOOL_MODE_SELECT,
} from "../constants";
import furnitureUiButtons from "../furniture/furniture-ui-buttons";
import MovableshelfController from "./movableshelf-controller";
import MovableshelfHelper from "./movableshelf-helper";

const {
    modules,
    events,
} = self.app;
const data = {
    listenersAdded: false,
};

function onHistoryGo() {
    if (!MovableshelfController.isActiveTool()) return;
    MovableshelfController.currentMovableshelfEntity = null;
}

function onToolMovableshelfDeactivate() {
    modules.toolManager.controller.deactivateTool(MovableshelfController.atool.tool_name);
    MovableshelfController.atool.activateModeAlone("");
}

function onToolBoardUpdate(payload) {
    switch (payload.toolModeName) {
        case TOOL_MODE_SELECT:
            modules.toolManager.controller.activateToolAlone(MovableshelfController.atool.tool_name);
            MovableshelfController.atool.activateModeAlone(payload.toolModeName);
            break;
        default:
            onToolMovableshelfDeactivate();
            break;
    }
}

function onMovableshelfSelect(movableshelfEntity) {
    events.emit("accesorry:current:unselect");
    MovableshelfController.currentMovableshelfEntity = movableshelfEntity;
    window.currentMovableshelfEntity = movableshelfEntity;
    window.frameParent = modules.dataStore.getEntity(movableshelfEntity.idParent);
    events.emit("movableshelf:selected", movableshelfEntity);
    MovableshelfHelper.showMeasures(movableshelfEntity);
    furnitureUiButtons.updateButtons();
}

function onMovableshelfUnselect() {
    if (MovableshelfController.currentMovableshelfEntity) {
        MovableshelfController.currentMovableshelfEntity = null;
    }
}

function onMovableshelfDelete() {
    MovableshelfHelper.deleteCurrentMovableshelf();
}

function editDistanceFrom(distance, from) {
    MovableshelfHelper.setDistanceFrom(distance, from);
}

function onCameraChanged() {
    if (!MovableshelfController.currentMovableshelfEntity) return;
    MovableshelfHelper.showMeasures(MovableshelfController.currentMovableshelfEntity);
}

function onDeleteMovableshelf() {
    MovableshelfHelper.deleteCurrentMovableshelf();
}

const MovableshelfEventsManager = {
    addEventListeners() {
        if (data.listenersAdded) return;

        events.on("@history.history-go", onHistoryGo);
        events.on("@vuejs.tool:movableshelf:update", onToolBoardUpdate);
        events.on("@vuejs.movableshelf:delete", onMovableshelfDelete);
        events.on("tool:movableshelf:update", onToolBoardUpdate);
        events.on("accesorry:current:unselect", onMovableshelfUnselect);
        events.on("movableshelf:select", onMovableshelfSelect);
        events.on("@vuejs.movableshelf:edit:distance", editDistanceFrom);
        events.on("@vuejs.movableshelf:delete", onDeleteMovableshelf);

        events.on("@cameras-manager.camera:move:end", onCameraChanged);
        events.on("@cameras-manager.camera:moved", onCameraChanged);
        events.on("@cameras-manager.camera:move:start", onCameraChanged);

        data.listenersAdded = true;
    },
};

export default MovableshelfEventsManager;
