import { jsPDF as JsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import ObsidianApi from "obsidian-api";

const FOOTER_Y = 600;
const START_Y = 10;
const FOOTER_FONT_SIZE = 9;
const CONTENT_FONT_SIZE = 10;
const TITLE_FONT_SIZE = 15;
const TITLE_MARGIN = 20;
const CONTENT_MARGIN = 30;
const LOGO_HEIGHT = 30;
const HEADER_HEIGHT = 50;

class PdfGenerator {

    constructor() {
        const obsidianConfig = ObsidianApi.$getConfig();
        if (obsidianConfig) {
            this.furnitureId = obsidianConfig.furniture_id;
        }
        this.currentY = START_Y;
        this.doc = new JsPDF({ unit: "px" });
        this.currentFont = this.doc.getFont();
        this.lineWidth = this.doc.internal.pageSize.width - CONTENT_MARGIN * 2;
        this.logoUrl = new URL("../../../../assets/images/logo.png", import.meta.url).href;
        this.doc.setFontSize(CONTENT_FONT_SIZE);
        this.doc.autoTableSetDefaults({});
        this.doc.setFillColor(0, 0, 0);
        this.currentPage = 1;
        this.setHeader();
        this.setFooter();
    }

    getPdfBlob() {
        return this.doc.output("blob");
    }

    addTextSection(title, content) {
        this.doc.setFontSize(CONTENT_FONT_SIZE);
        const lineHeight = this.doc.getLineHeight();
        let nextY = this.currentY + lineHeight;
        if (title) {
            if (nextY >= FOOTER_Y - lineHeight * 2) {
                this.newPage();
            }
            this.doc.setFont(this.currentFont.fontName, "bold");
            this.doc.text(title, TITLE_MARGIN, this.currentY);
            this.doc.setFont(this.currentFont.fontName, "normal");
            this.currentY += lineHeight * 1.5;
        }

        const splittedContent = this.doc.splitTextToSize(content, this.lineWidth);
        splittedContent.forEach((line) => {
            nextY = this.currentY + lineHeight;
            if (nextY >= FOOTER_Y - lineHeight) {
                this.newPage();
            }
            this.doc.text(line, CONTENT_MARGIN, this.currentY, { align: "justify" });
            this.currentY += lineHeight;
        });
        this.currentY += lineHeight * 1.5;
    }

    addImageSection(title, canvas, width = 0, height = 0) {
        let imgWidth = width;
        let imgHeigt = height;
        if (title) {
            this.addTitle(title);
        }
        if (!width && height) {
            const imageInfos = this.doc.getImageProperties(canvas);
            imgWidth = imageInfos.width * (height / imageInfos.height);
        }
        if (!height && width) {
            const imageInfos = this.doc.getImageProperties(canvas);
            imgHeigt = imageInfos.height * (width / imageInfos.width);
        }
        this.doc.addImage(canvas, this.getXoffset() - imgWidth / 2, this.currentY, imgWidth, imgHeigt);
        this.currentY += imgHeigt + TITLE_MARGIN;
    }

    addTable(title, header, content) {
        if (this.currentY > FOOTER_Y - 100) {
            this.newPage();
        }
        this.addTitle(title);
        const lineHeight = this.doc.getLineHeight();
        autoTable(this.doc, {
            startY: this.currentY,
            head: header,
            body: content,
            didDrawCell: (hookData) => {
                if (hookData.column.dataKey === hookData.row.raw.length - 1 && hookData.cursor.y + hookData.cell.height >= FOOTER_Y - CONTENT_FONT_SIZE * 2) {
                    this.newPage();
                    hookData.cursor.y = this.currentY;
                }
            },
        });

        this.currentY = this.doc.autoTable.previous.finalY + lineHeight;
    }

    addTitle(title) {
        this.doc.setFontSize(TITLE_FONT_SIZE);
        this.doc.setFont(this.currentFont.fontName, "bold");
        const lineHeight = this.doc.getLineHeight();
        this.doc.text(title, this.getXoffset(), this.currentY, { align: "center" });
        this.doc.setFont(this.currentFont.fontName, "normal");
        this.currentY += lineHeight;
    }

    setHeader() {
        this.doc.rect(0, 0, this.doc.internal.pageSize.width, HEADER_HEIGHT, "F");
        const lineHeight = this.doc.getLineHeight();
        this.currentY = START_Y;
        this.doc.addImage(this.logoUrl, "png", TITLE_MARGIN, this.currentY, 0, LOGO_HEIGHT);
        if (this.furnitureId) {
            this.doc.setTextColor(255, 255, 255);
            this.doc.setFontSize(FOOTER_FONT_SIZE);
            const furnitureIdLabel = `Meuble n° ${this.furnitureId}`;
            const furnitureIdLabelXOffset = this.doc.internal.pageSize.width - this.doc.getTextWidth(furnitureIdLabel) - 20;
            this.doc.text(furnitureIdLabel, furnitureIdLabelXOffset, 27);
            this.doc.setTextColor(0, 0, 0);
            this.doc.setFontSize(CONTENT_FONT_SIZE);
        }
        this.currentY += HEADER_HEIGHT + lineHeight;
    }

    setFooter() {
        this.doc.setFillColor(0, 0, 0);
        this.doc.rect(0, FOOTER_Y, this.doc.internal.pageSize.width, this.doc.internal.pageSize.height - FOOTER_Y, "F");
        this.doc.setFontSize(FOOTER_FONT_SIZE);
        const lineHeight = this.doc.getLineHeight();
        let footerY = FOOTER_Y + 12;
        this.doc.setTextColor(255, 255, 255);
        this.doc.text("clickandmeubles.fr", this.getXoffset(), footerY, { align: "center" });
        footerY += lineHeight;
        this.doc.text("contact@clickandmeubles.fr", this.getXoffset(), footerY, { align: "center" });
        this.doc.setTextColor(0, 0, 0);
    }

    setWatermark() {
        this.doc.saveGraphicsState();
        this.doc.setGState(new this.doc.GState({ opacity: 0.2 }));
        this.doc.addImage(this.logoUrl, "png", 5, 10, 600, 0, null, null, -50);
        this.doc.restoreGraphicsState();
    }

    getXoffset() {
        return this.doc.internal.pageSize.width / 2;
    }

    newPage() {
        this.setWatermark();
        this.doc.addPage();
        this.currentPage++;
        this.setHeader();
        this.setFooter();
    }

    generatePageNumbers() {
        this.doc.setTextColor(255, 255, 255);
        this.doc.setFontSize(FOOTER_FONT_SIZE);
        const pageNumberY = FOOTER_Y + 20;
        for (let page = 1; page <= this.currentPage; page++) {
            this.doc.setPage(page);
            const pageLabel = `${page}/${this.currentPage}`;
            const pageNumberXOffset = this.doc.internal.pageSize.width - this.doc.getTextWidth(pageLabel) - 20;
            this.doc.text(pageLabel, pageNumberXOffset, pageNumberY);
        }
        this.doc.setTextColor(0, 0, 0);
        this.doc.setFontSize(CONTENT_FONT_SIZE);
    }

}

export default PdfGenerator;
