import { CAMERA_ARC_ROTATE, CAMERA_ORTHO } from "./constants";

export default class CameraTweaks {

    static addTweaks(cameraManagerController, pane) {
        const tweakpane = pane;
        const camFolder = tweakpane.addFolder({ title: "Cameras", expanded: false });
        const camList = camFolder.addBlade({
            view: "list",
            label: "cameras",
            options: [
                { text: "3D", value: "3d" },
                { text: "Ortho", value: "ortho" },
                { text: "Top (t)", value: "top" },
                { text: "Side (s)", value: "side" },
                { text: "Front (f)", value: "front" },
            ],
            value: "3d",
        }).on("change", (event) => {
            switch (event.value) {
                case "3d":
                    cameraManagerController.switchCamera(CAMERA_ARC_ROTATE);
                    break;
                case "ortho":
                    cameraManagerController.switchCamera(CAMERA_ORTHO);
                    break;
                case "top":
                    cameraManagerController.switchCamera(CAMERA_ARC_ROTATE);
                    cameraManagerController.setViewByName(event.value);
                    camList.value = "3d";
                    break;
                case "side":
                    cameraManagerController.switchCamera(CAMERA_ARC_ROTATE);
                    cameraManagerController.setViewByName(event.value);
                    camList.value = "3d";
                    break;
                case "front":
                    cameraManagerController.switchCamera(CAMERA_ARC_ROTATE);
                    cameraManagerController.setViewByName(event.value);
                    camList.value = "3d";
                    break;
                default:
                    break;
            }
        });

        camFolder.addButton({ title: "Center Camera" }).on("click", () => cameraManagerController.focusOnMeshes());
    }

}
