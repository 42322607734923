import {
    Color4,
    MeshBuilder,
    TransformNode,
    Vector3,
} from "@babylonjs/core";

class Axis extends TransformNode {

    constructor(name, scene) {
        super(name, scene);
        if (Axis.instance) {
            throw new Error("Axis is a singleton. Use Axis.getInstance()");
        } else {
            Axis.instance = this;
        }
    }

    initialize(size = 10) {
        this.createAxis();
        this.size = size;
    }

    createAxis() {
        this.createXAxis();
        this.createYAxis();
        this.createZAxis();
    }

    set size(value) {
        this._size = value;
        this.scaling.scaleInPlace(this._size);
    }

    get size() {
        return this._size;
    }

    createXAxis() {
        const color = new Color4(1, 0, 0, 1);
        this.xAxis = MeshBuilder.CreateLines("xAxis", {
            points: [
                new Vector3(0, 0, 0),
                new Vector3(1, 0, 0),
            ],
            colors: [
                color,
                color,
            ],
        });
        this.xAxis.isPickable = false;
        this.xAxis.parent = this;
    }

    createYAxis() {
        const color = new Color4(0, 1, 0, 1);
        this.yAxis = MeshBuilder.CreateLines("yAxis", {
            points: [
                new Vector3(0, 0, 0),
                new Vector3(0, 1, 0),
            ],
            colors: [
                color,
                color,
            ],
        });
        this.yAxis.isPickable = false;
        this.yAxis.parent = this;
    }

    createZAxis() {
        const color = new Color4(0, 0, 1, 1);
        this.zAxis = MeshBuilder.CreateLines("zAxis", {
            points: [
                new Vector3(0, 0, 0),
                new Vector3(0, 0, -1),
            ],
            colors: [
                color,
                color,
            ],
        });
        this.zAxis.isPickable = false;
        this.zAxis.parent = this;
    }

    hide() {
        this.xAxis.isVisible = false;
        this.yAxis.isVisible = false;
        this.zAxis.isVisible = false;
    }

    show() {
        this.xAxis.isVisible = true;
        this.yAxis.isVisible = true;
        this.zAxis.isVisible = true;
    }

    static adjustDepthPosition(positionZ) {
        this.getInstance().position.z = positionZ;
    }

    static getInstance() {
        if (!Axis.instance) {
            new Axis("axes");
        }
        return Axis.instance;
    }

}

export default Axis;
