const TAU = Math.PI * 2;

const commonFrontAndBackCameraArcRotateSettings = (cameraArcRotate) => {
    cameraArcRotate.wheelPrecision = 0.01;
    cameraArcRotate.zoomOnFactor = 0.1;
    cameraArcRotate.minZ = 8000;
    cameraArcRotate.maxZ = 45000;
    cameraArcRotate.fov = 0.1;

    cameraArcRotate.alpha = Math.PI * 2 * 0.75;
    cameraArcRotate.beta = Math.PI * 2 * 0.25;

    cameraArcRotate.lowerRadiusLimit = 11000;
    cameraArcRotate.upperRadiusLimit = 42000;

    cameraArcRotate.lowerBetaLimit = TAU * 0.25;
    cameraArcRotate.upperBetaLimit = TAU * 0.25;
};

const CameraArcRotateSettings = {
    default: (cameraArcRotate) => {
        cameraArcRotate.wheelPrecision = 0.03;
        cameraArcRotate.panningSensibility = 1;
        cameraArcRotate.panningInertia = 0;
        cameraArcRotate.inertia = 0;
        cameraArcRotate.angularSensibilityX = 200;
        cameraArcRotate.angularSensibilityY = 200;
        cameraArcRotate.zoomOnFactor = 1.5;
        cameraArcRotate.minZ = 1;
        cameraArcRotate.maxZ = 10000;
        cameraArcRotate.fov = 0.8;
        cameraArcRotate.lowerRadiusLimit = 500;
        cameraArcRotate.upperRadiusLimit = 5000;
        cameraArcRotate.lowerAlphaLimit = null;
        cameraArcRotate.upperAlphaLimit = null;
        cameraArcRotate.lowerBetaLimit = null;
        cameraArcRotate.upperBetaLimit = null;
    },

    lockToFront: (cameraArcRotate) => {
        commonFrontAndBackCameraArcRotateSettings(cameraArcRotate);
        cameraArcRotate.lowerAlphaLimit = -(TAU * 0.25);
        cameraArcRotate.upperAlphaLimit = -(TAU * 0.25);
    },

    lockToBack: (cameraArcRotate) => {
        commonFrontAndBackCameraArcRotateSettings(cameraArcRotate);
        cameraArcRotate.upperRadiusLimit = 44000;
        cameraArcRotate.lowerAlphaLimit = (TAU * 0.25);
        cameraArcRotate.upperAlphaLimit = (TAU * 0.25);
    },

    lockToRight: (cameraArcRotate) => {
        commonFrontAndBackCameraArcRotateSettings(cameraArcRotate);
        cameraArcRotate.lowerAlphaLimit = 0;
        cameraArcRotate.upperAlphaLimit = 0;
    },
};

const CameraOrthoSettings = {

};

export {
    CameraArcRotateSettings,
    CameraOrthoSettings,
};
