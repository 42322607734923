import {
    MeshBuilder,
} from "@babylonjs/core/Meshes/meshBuilder";
import {
    Mesh,
} from "@babylonjs/core/Meshes/mesh";
import {
    Geometry,
} from "@babylonjs/core/Meshes/geometry";

class FootMesh extends Mesh {

    constructor(footEntity) {
        super("baseboardMesh", undefined);
        this.metadata = {
            footEntity,
        };
        FootMesh.getBoxGeometry().copy().applyToMesh(this);
    }

    static getBoxGeometry() {
        // Generate geometries once
        if (!FootMesh.BoxGeometry) {
            const box = MeshBuilder.CreateBox("hitBox", {});
            box.position.set(0, 0.5, 0);
            box.bakeCurrentTransformIntoVertices();
            FootMesh.BoxGeometry = Geometry.ExtractFromMesh(box, "hitBox");
            box.dispose();
        }
        return FootMesh.BoxGeometry;
    }



}

export default FootMesh;
