<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g id="icon-back-fix" transform="translate(-760 -324)">
            <rect id="Rectangle_569"
                  data-name="Rectangle 569"
                  width="24"
                  height="24.001"
                  rx="1"
                  transform="translate(764 328)"
                  fill="#fff"
            />
            <g id="Rectangle_617"
               data-name="Rectangle 617"
               transform="translate(760 324)"
               fill="none"
               stroke="#fff"
               stroke-width="2"
            >
                <rect width="32" height="32" rx="2" stroke="none" />
                <rect x="1"
                      y="1"
                      width="30"
                      height="30"
                      rx="1"
                      fill="none"
                />
            </g>
        </g>
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "back-fixed-icon",
});
</script>
