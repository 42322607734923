<template>
    <div
        class="absolute w-full flex justify-center top-2 pointer-events-none"
    >
        <button
            type="button"
            :class="[
                'rounded bg-white px-3 py-2 font-bold text-sm shadow-lg mx-2 flex items-center pointer-events-auto',
                (isLast) && 'opacity-60 shadow-none cursor-not-allowed']"
            @click="undo()"
        >
            <BackwardArrowIcon />
            <span class="pl-4">
                {{ t('Annuler (Ctrl+Z)') }}
            </span>
        </button>
        <button
            type="button"
            :class="[
                'rounded bg-white px-3 py-2 font-bold text-sm shadow-lg mx-2 flex items-center pointer-events-auto',
                (isFirst) && 'opacity-60 shadow-none cursor-not-allowed']"
            @click="redo()"
        >
            <ForwardArrowIcon />
            <span class="pl-4">
                {{ t('Rétablir (Ctrl+Y)') }}
            </span>
        </button>
    </div>
</template>

<script>
import MouseTrap from "mousetrap";
import self from "../..";

import BackwardArrowIcon from "./icons/BackwardArrowIcon.vue";
import ForwardArrowIcon from "./icons/ForwardArrowIcon.vue";

const { events, modules } = self.app;

export default {
    name: "history",
    components: {
        BackwardArrowIcon,
        ForwardArrowIcon,
    },
    data() {
        return {
            historyController: modules.history,
            isFirst: true,
            isLast: true,
        };
    },
    mounted() {
        MouseTrap.bind("ctrl+z", () => {
            this.undo();
        });
        MouseTrap.bind("ctrl+y", () => {
            this.redo();
        });
        events.on("@history.history-go", () => {
            this.isFirst = modules.history.isFirst();
            this.isLast = modules.history.isLast();
        });

        events.on("@history.history-snapshot", () => {
            this.isFirst = modules.history.isFirst();
            this.isLast = modules.history.isLast();
        });
        // Initial snapshot
        // this.historyController.snapshot();
    },
    methods: {
        t(...args) {
            return modules.stonejs.gettext(...args);
        },
        undo() {
            this.historyController.back();
            events.emit("history:back");
        },
        redo() {
            this.historyController.forward();
            events.emit("history:forward");
        },
    },
};

</script>
