import {
    MeshBuilder,
} from "@babylonjs/core/Meshes/meshBuilder";
import {
    Mesh,
} from "@babylonjs/core/Meshes/mesh";

import {
    ROD_DISTANCE_FROM_BOARD,
    ROD_SUPPORT_RING_DIAMETER,
    ROD_SUPPORT_RING_WIDTH,
} from "../constants";

class RodSupportMesh extends Mesh {

    constructor() {
        super("rodSupportMesh", undefined);

        this.ring = RodSupportMesh.getRingInstance();
        this.addChild(this.ring);

        this.support = RodSupportMesh.getSupportInstance();
        this.addChild(this.support);
    }

    // eslint-disable-next-line class-methods-use-this
    set material(material) {
        RodSupportMesh.ring.material = material;
        RodSupportMesh.support.material = material;
    }

    static getRingInstance() {
        if (!RodSupportMesh.ring) {

            const ring = MeshBuilder.CreateCylinder("hitBox", {
                height: 1,
            });

            ring.rotation.z = 6.283 * 0.25;
            ring.position.set(0, 0, 0);
            ring.bakeCurrentTransformIntoVertices();

            ring.scaling.x = ROD_SUPPORT_RING_WIDTH;
            ring.scaling.y = ROD_SUPPORT_RING_DIAMETER;
            ring.scaling.z = ROD_SUPPORT_RING_DIAMETER;
            ring.isVisible = false;
            RodSupportMesh.ring = ring;
        }
        return RodSupportMesh.ring.createInstance();
    }

    static getSupportInstance() {
        if (!RodSupportMesh.support) {
            const support = MeshBuilder.CreateBox("support", {});
            support.position.set(0, 0.5, 0);
            support.bakeCurrentTransformIntoVertices();

            support.scaling.x = 4;
            support.scaling.y = ROD_DISTANCE_FROM_BOARD;
            support.scaling.z = 4;
            support.isVisible = false;
            RodSupportMesh.support = support;
        }
        return RodSupportMesh.support.createInstance();
    }

}
RodSupportMesh.matcapMaterials = {};
export default RodSupportMesh;
