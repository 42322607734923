<template>
    <button
        :class="[
            'w-6 h-6 bg-white border border-black rounded-full flex justify-center items-center pointer-events-auto z-20',
            'group hover:bg-theme-red-600 transition-colors duration-300'
        ]"
        title="Supprimer l'élément"
        @click="$emit('click')"
    >
        <TrashIcon class="w-3 h-3 text-black group-hover:text-white transition-colors duration-300" />
    </button>
</template>
<script>
import TrashIcon from "../icons/TrashIcon.vue";

export default {
    components: { TrashIcon },
    emits: ["click"],
};
</script>
