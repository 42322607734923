<template>
    <svg id="icon-back-none"
         xmlns="http://www.w3.org/2000/svg"
         width="32"
         height="32"
         viewBox="0 0 32 32"
    >
        <g id="Rectangle_617"
           data-name="Rectangle 617"
           fill="none"
           stroke="#fff"
           stroke-width="2"
        >
            <rect width="32" height="32" rx="2" stroke="none" />
            <rect x="1"
                  y="1"
                  width="30"
                  height="30"
                  rx="1"
                  fill="none"
            />
        </g>
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "back-none-icon",
});
</script>
