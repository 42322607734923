import {
    Vector3,
} from "@babylonjs/core/Maths/math.vector";
import {
    BOARD_THICKNESS,
    DOOR_DOUBLE,
    ORIENTATION_DEPTH,
    ORIENTATION_HORIZONTAL,
    ORIENTATION_VERTICAL,
    OVERLAY_DRAWER,
    RESIZE_FROM_BACK,
    RESIZE_FROM_BOTTOM,
    RESIZE_FROM_LEFT,
    RESIZE_FROM_RIGHT,
    RESIZE_FROM_TOP,
} from "./constants";
import constraints, {
    DOOR_CONSTRAINTS,
    DOOR_DOUBLE_CONSTRAINTS,
    DRAWER_CONSTRAINTS,
    FRAME_CONSTRAINTS,
    RESISTANCE_HARD,
    RESISTANCE_SOFT,
} from "./constraints";
import TransformHelper from "./helpers/transformHelper";
import ContraintsHelper from "./helpers/constrainsHelper";

import self from "../index";
import FrameHelper from "./frame/frame-helper";
import BBox2Helper from "../../../helpers/BBox2Helper";
import DoorHelper from "./door/door-helper";
import BoardHelper from "./board/board-helper";
import DrawerRunnerHelper from "./drawer/drawer-runner-helper";
import OverlaydrawerEntity from "./overlaydrawer/overlaydrawer-entity";
import BoardEventsManager from "./board/board-events-manager";

const {
    modules,
} = self.app;

const ConstraintsManager = {

    /**
     *
     * @param {FurnitureEntity} furnitureEntity
     * @param {Vector3} nextScaling
     * @param {String} direction RESIZE_FROM_LEFT, RESIZE_FROM_RIGHT, RESIZE_FROM_BOTTOM, RESIZE_FROM_TOP, RESIZE_FROM_BACK
     * @returns Object : { Boolean isResizable, Object constraintsReactivityByFrameId, Vector3 scalingDiff }
     */
    canResizeFurniture(furnitureEntity, nextScaling, direction) {
        nextScaling.x -= furnitureEntity.getThickness() * 2;
        nextScaling.y -= furnitureEntity.getThickness() * 2;

        return ConstraintsManager.canResizeFrame(furnitureEntity.getIdFrame(), nextScaling, direction);
    },

    /**
     * Traverse tree of frames from particular frame, apply constraints at smallers frames(leafs), check constraints and return if is it resizable.
     * @param {FrameEntity} startFrameEntityId
     * @param {Vector3} nextScaling
     * @param {String} resizeOrigin RESIZE_FROM_LEFT, RESIZE_FROM_RIGHT, RESIZE_FROM_BOTTOM, RESIZE_FROM_TOP, RESIZE_FROM_BACK
     * @returns Object : { Boolean isResizable, Object constraintsReactivityByFrameId, Vector3 scalingDiff }
     */
    canResizeFrame(startFrameEntityId, nextScaling, resizeOrigin) {

        let isResizable = true;

        const startFrameEntity = modules.dataStore.getEntity(startFrameEntityId);
        const scalingDiff = nextScaling.subtract(startFrameEntity.getScaling());
        const scalingOrientation = TransformHelper.getScalingOrientation(scalingDiff);

        const constraintsReactivityByFrameId = {};
        const startLevel = 0;

        const traverse = (frameEntityId, level) => {
            const frameEntity = modules.dataStore.getEntity(frameEntityId);

            if (frameEntity.getIsSplitted()) {
                traverse(frameEntity.getIdFrameLeftOrBottom(), level + 1);
                traverse(frameEntity.getIdFrameRightOrTop(), level + 1);
            }

            // Leaf node
            if (!frameEntity.getIsSplitted()) {
                const currentConstraints = [];
                if (scalingOrientation === ORIENTATION_HORIZONTAL) {
                    if (TransformHelper.isDecrease(scalingDiff.x)) {
                        switch (resizeOrigin) {
                            case RESIZE_FROM_RIGHT:
                                if (TransformHelper.isFramesAreAlignedToRightSide(frameEntity, startFrameEntity)) {
                                    ContraintsHelper.addConstraint(currentConstraints, constraints.MIN_FRAME_WIDTH);
                                }
                                break;
                            case RESIZE_FROM_LEFT:
                                if (TransformHelper.isFramesAreAlignedToLeftSide(frameEntity, startFrameEntity)) {
                                    ContraintsHelper.addConstraint(currentConstraints, constraints.MIN_FRAME_WIDTH);
                                }
                                break;
                            default:
                                break;
                        }
                    } else if (FrameHelper.hasBottomFrameSplittedVerticaly(frameEntity)) {
                        ContraintsHelper.addConstraint(currentConstraints, constraints.MAX_FRAME_BOTTOM_VERTICAL_SPLITTED_WIDTH);
                    } else {
                        ContraintsHelper.addConstraint(currentConstraints, constraints.MAX_FRAME_WIDTH);
                    }
                }
                if (scalingOrientation === ORIENTATION_VERTICAL) {
                    if (TransformHelper.isDecrease(scalingDiff.y)) {
                        switch (resizeOrigin) {
                            case RESIZE_FROM_BOTTOM:
                                if (TransformHelper.isFramesAreAlignedToBottomSide(frameEntity, startFrameEntity)) {
                                    ContraintsHelper.addConstraint(currentConstraints, constraints.MIN_FRAME_HEIGHT);
                                }
                                break;
                            case RESIZE_FROM_TOP:
                                if (TransformHelper.isFramesAreAlignedToTopSide(frameEntity, startFrameEntity)) {
                                    ContraintsHelper.addConstraint(currentConstraints, constraints.MIN_FRAME_HEIGHT);
                                }
                                break;
                            default:
                                break;
                        }
                    } else {
                        ContraintsHelper.addConstraint(currentConstraints, constraints.MAX_FRAME_HEIGHT);
                    }
                }

                if (scalingOrientation === ORIENTATION_DEPTH) {
                    if (TransformHelper.isDecrease(scalingDiff.z)) {
                        if (resizeOrigin === RESIZE_FROM_BACK) {
                            ContraintsHelper.addConstraint(currentConstraints, constraints.MIN_FURNITURE_DEPTH);
                        }
                    } else {
                        ContraintsHelper.addConstraint(currentConstraints, constraints.MAX_FURNITURE_DEPTH);
                    }
                }

                if (frameEntity.getIdDoor()) {
                    const doorEntity = modules.dataStore.getEntity(frameEntity.getIdDoor());
                    ContraintsHelper.addDoorConstraints(currentConstraints, doorEntity.getType());
                }

                if (frameEntity.getIdDrawers().length) {
                    ConstraintsManager.addDrawerConstraints(currentConstraints);
                }

                if (frameEntity.getIdRod().length) {
                    ConstraintsManager.addRodConstraints(currentConstraints);
                }

                const currentConstraintsReactivity = ConstraintsManager.checkConstraints(
                    currentConstraints,
                    frameEntity,
                    scalingDiff,
                );

                if (currentConstraintsReactivity.filtered.length) {
                    constraintsReactivityByFrameId[frameEntityId] = currentConstraintsReactivity;
                }

                if (currentConstraintsReactivity.hasResistanceHard) {
                    isResizable = false;
                }
            }

        };

        traverse(startFrameEntityId, startLevel);

        return {
            isResizable,
            constraintsReactivityByFrameId,
            scalingDiff,
        };

    },

    checkConstraints(activedConstraints, frameEntity, scalingDiff, _nextScaling) {
        let hasResistanceHard = false;
        let hasResistanceSoft = false;
        const filtered = [];
        activedConstraints.forEach((constraint) => {
            const doorEntity = modules.dataStore.getEntity(frameEntity.getIdDoor());
            let nextScaling = scalingDiff ? frameEntity.getScaling().clone().add(scalingDiff) : _nextScaling;
            let result;
            if (constraint.door && doorEntity && doorEntity.isMultiFrames()) {
                nextScaling = scalingDiff ? doorEntity.getScaling().clone().add(scalingDiff) : _nextScaling;
                result = constraint.check(nextScaling);
            } else {
                result = constraint.check(nextScaling);
            }
            if (!hasResistanceHard) hasResistanceHard = result && constraint.resistance === RESISTANCE_HARD;
            if (!hasResistanceSoft) hasResistanceSoft = result && constraint.resistance === RESISTANCE_SOFT;
            if (result) filtered.push(constraint);
        });

        return {
            filtered,
            hasResistanceHard,
            hasResistanceSoft,
        };
    },

    detectBoardWeakness(board) {
        if (board.orientation === ORIENTATION_HORIZONTAL && !board.isBlockPart) {
            const frameEntity = modules.dataStore.getEntity(board.idParent);
            const bottomFrame = modules.dataStore.getEntity(frameEntity.idFrameLeftOrBottom);
            if (bottomFrame.scaling.x > FRAME_CONSTRAINTS.max.x) {
                if (bottomFrame.isSplitted) {
                    const bottomBoardEntity = modules.dataStore.getEntity(bottomFrame.idBoard);
                    if (bottomBoardEntity.orientation === ORIENTATION_HORIZONTAL) {
                        BoardEventsManager.emitBoardWeakness();
                    }
                } else {
                    BoardEventsManager.emitBoardWeakness();
                }
            }
        }
    },

    canAddDoor(frameEntity, type) {
        const currentConstraints = [];
        let isAdapted = true;
        ContraintsHelper.addDoorConstraints(currentConstraints, type);
        const constraintsReactivity = ConstraintsManager.checkConstraints(
            currentConstraints,
            frameEntity,
            Vector3.Zero(),
        );

        if (constraintsReactivity.hasResistanceHard) {
            isAdapted = false;
        }
        return {
            isAdapted,
            constraintsReactivity,
        };
    },

    canAddDrawer(frameEntity) {
        const currentConstraints = [];
        let isAdapted = true;
        ContraintsHelper.addDrawerConstraints(currentConstraints);
        const constraintsReactivity = ConstraintsManager.checkConstraints(
            currentConstraints,
            frameEntity,
            Vector3.Zero(),
        );

        if (constraintsReactivity.hasResistanceHard) {
            isAdapted = false;
        }
        return {
            isAdapted,
            constraintsReactivity,
        };
    },

    canAddPulloutshelf(frameEntity) {
        const currentConstraints = [];
        let isAdapted = true;
        ContraintsHelper.addPulloutshelfConstraints(currentConstraints);
        const constraintsReactivity = ConstraintsManager.checkConstraints(
            currentConstraints,
            frameEntity,
            Vector3.Zero(),
        );

        if (constraintsReactivity.hasResistanceHard) {
            isAdapted = false;
        }
        return {
            isAdapted,
            constraintsReactivity,
        };
    },

    getBoardDisplacementLimits(boardEntity) {
        const frameEntityParent = modules.dataStore.getEntity(boardEntity.getIdParent());
        const frameLeftOrBottom = modules.dataStore.getEntity(frameEntityParent.getIdFrameLeftOrBottom());
        const frameRightOrTop = modules.dataStore.getEntity(frameEntityParent.getIdFrameRightOrTop());

        const positionLimitOfFrameLeftOrBottom = ConstraintsManager.getBoardDisplacementLimitsFromFrame(
            frameLeftOrBottom.id,
            boardEntity
        );
        const positionLimitOfFrameRightOrTop = ConstraintsManager.getBoardDisplacementLimitsFromFrame(
            frameRightOrTop.id,
            boardEntity
        );

        return {
            min: Math.max(positionLimitOfFrameLeftOrBottom.min, positionLimitOfFrameRightOrTop.min),
            max: Math.min(positionLimitOfFrameLeftOrBottom.max, positionLimitOfFrameRightOrTop.max),
        };
    },

    getBoardDisplacementLimitsFromFrame(startFrameEntityId, boardEntity) {

        const limits = {
            min: 0,
            max: 100000,
        };


        const traverse = (frameEntityId) => {
            const frameEntity = modules.dataStore.getEntity(frameEntityId);

            if (frameEntity.getIsSplitted()) {
                traverse(frameEntity.getIdFrameLeftOrBottom());
                traverse(frameEntity.getIdFrameRightOrTop());
            }

            let doorEntity;
            let bbDoorFrames;
            let doorConstraints;
            let bbDivided;
            let hasDoorControlledByBoard;
            const hasMovableShelf = frameEntity.hasMovableshelf();
            const hasDrawer = frameEntity.hasDrawer() || frameEntity.hasAccessorydrawer();
            const hasPulloutshelf = frameEntity.hasPulloutshelf();

            const bbBoard = BBox2Helper.getBBox2FromObject3dEntity(boardEntity);

            if (frameEntity.getIdDoor()) {
                doorEntity = modules.dataStore.getEntity(frameEntity.getIdDoor());
                doorConstraints = doorEntity.getType() === DOOR_DOUBLE ? DOOR_DOUBLE_CONSTRAINTS : DOOR_CONSTRAINTS;
                bbDoorFrames = DoorHelper.getDoorFramesBBox2Union(doorEntity);
                bbDivided = BBox2Helper.BBox2divideWithContainedBBox2(bbDoorFrames, bbBoard);
                hasDoorControlledByBoard = bbDivided.length === 0;
            }

            if (boardEntity.isHorizontal()) {
                if (BoardHelper.boardIsAboveFrame(boardEntity, frameEntity)) {
                    const min = frameEntity.getPosition().y + FRAME_CONSTRAINTS.min.y;
                    if (min > limits.min) limits.min = min;
                    if (hasDoorControlledByBoard) {
                        const doorMin = doorEntity.getPosition().y + doorConstraints.min.y;
                        limits.min = Math.max(limits.min, doorMin);
                        const doorMax = doorEntity.getPosition().y + doorConstraints.max.y - BOARD_THICKNESS;
                        limits.max = Math.min(limits.max, doorMax);
                    }
                    if (hasMovableShelf) {
                        const upperMovableShelf = modules.dataStore.getEntity(frameEntity.getIdUpperMovableShelf());
                        upperMovableShelf.updateBbox2();
                        limits.min = Math.max(limits.min, upperMovableShelf.bbox2.p2.y);
                    }

                    if (hasPulloutshelf) {
                        const pulloutShelf = modules.dataStore.getEntity(frameEntity.getIdUpperPulloutShelf());
                        pulloutShelf.updateBbox2();
                        limits.min = Math.max(limits.min, pulloutShelf.bbox2.p2.y);
                    }

                    if (hasDrawer) {
                        limits.min = Math.max(limits.min, frameEntity.position.y + DRAWER_CONSTRAINTS.min.y);
                    }
                }
                if (BoardHelper.boardIsBelowFrame(boardEntity, frameEntity)) {
                    const max = frameEntity.getPosition().y + frameEntity.getScaling().y - FRAME_CONSTRAINTS.min.y - BOARD_THICKNESS;
                    if (max < limits.max) limits.max = max;
                    if (hasDoorControlledByBoard) {
                        const doorMax = doorEntity.getPosition().y + doorEntity.getScaling().y - doorConstraints.min.y - BOARD_THICKNESS;
                        limits.max = Math.min(limits.max, doorMax);
                    }
                    if (hasDrawer) {
                        const drawerMin = frameEntity.getPosition().y + frameEntity.getScaling().y - constraints.MIN_DRAWER_HEIGHT.min;
                        limits.max = Math.min(limits.max, drawerMin);
                    }
                    if (hasMovableShelf) {
                        const upperMovableShelf = modules.dataStore.getEntity(frameEntity.getIdLowerMovableShelf());
                        upperMovableShelf.updateBbox2();
                        limits.max = Math.min(limits.max, upperMovableShelf.bbox2.p1.y);
                    }
                    if (hasPulloutshelf) {
                        const pulloutShelf = modules.dataStore.getEntity(frameEntity.getIdLowerPulloutShelf());
                        pulloutShelf.updateBbox2();
                        limits.max = Math.min(limits.max, pulloutShelf.bbox2.p1.y);
                    }

                    if (hasDrawer) {
                        limits.max = Math.max(limits.min, frameEntity.position.y + frameEntity.scaling.y - DRAWER_CONSTRAINTS.min.y - BOARD_THICKNESS);
                    }
                }
            } else if (boardEntity.isVertical()) {
                if (BoardHelper.boardIsRightOfFrame(boardEntity, frameEntity)) {
                    const min = frameEntity.getPosition().x + FRAME_CONSTRAINTS.min.x;
                    if (min > limits.min) limits.min = min;
                    if (hasDoorControlledByBoard) {
                        const doorMin = frameEntity.getPosition().x + doorConstraints.min.x;
                        limits.min = Math.max(limits.min, doorMin);

                        const doorMax = frameEntity.getPosition().x + doorConstraints.max.x;
                        limits.max = Math.min(limits.max, doorMax);
                    }
                    if (hasDrawer) {
                        const bbFrame = BBox2Helper.getBBox2FromObject3dEntity(frameEntity);
                        const intersection = BBox2Helper.getBBox2Intersection(bbFrame, bbBoard);
                        // If intersection in not null, it means that the board and the frame are neighbors
                        if (intersection) {
                            const drawerMin = frameEntity.getPosition().x + constraints.MIN_DRAWER_WIDTH.min;
                            limits.min = Math.max(limits.min, drawerMin);

                            const drawerMax = frameEntity.getPosition().x + constraints.MAX_DRAWER_WIDTH.max;
                            limits.max = Math.min(limits.max, drawerMax);
                        }
                    }
                    if (hasPulloutshelf) {
                        limits.min = Math.max(limits.min, frameEntity.position.x + constraints.MIN_PULLOUTSHELF_WIDTH.min);
                        limits.max = Math.min(limits.max, frameEntity.position.x + constraints.MAX_PULLOUTSHELF_WIDTH.max);
                    }
                }
                if (BoardHelper.boardIsLeftOfFrame(boardEntity, frameEntity)) {
                    const max = frameEntity.getPosition().x + frameEntity.getScaling().x - FRAME_CONSTRAINTS.min.x - BOARD_THICKNESS;
                    if (max < limits.max) limits.max = max;
                    if (hasDoorControlledByBoard) {
                        const doorMin = frameEntity.position.x + frameEntity.scaling.x - doorConstraints.min.x - BOARD_THICKNESS;
                        limits.max = Math.min(limits.max, doorMin);

                        const doorMax = frameEntity.getPosition().x + frameEntity.scaling.x - doorConstraints.max.x - BOARD_THICKNESS;
                        limits.min = Math.max(limits.min, doorMax);
                    }
                    if (hasDrawer) {
                        const bbFrame = BBox2Helper.getBBox2FromObject3dEntity(frameEntity);
                        const intersection = BBox2Helper.getBBox2Intersection(bbFrame, bbBoard);
                        // If intersection in not null, it means that the board and the frame are neighbors
                        if (intersection) {
                            const drawerMin = frameEntity.position.x + frameEntity.scaling.x - constraints.MIN_DRAWER_WIDTH.min - BOARD_THICKNESS;
                            limits.max = Math.min(limits.max, drawerMin);

                            const drawerMax = frameEntity.getPosition().x + frameEntity.scaling.x - constraints.MAX_DRAWER_WIDTH.max - BOARD_THICKNESS;
                            limits.min = Math.max(limits.min, drawerMax);
                        }
                    }
                    if (hasPulloutshelf) {
                        limits.max = Math.min(limits.max, frameEntity.position.x + frameEntity.scaling.x - constraints.MIN_PULLOUTSHELF_WIDTH.min - BOARD_THICKNESS);
                        limits.min = Math.max(limits.min, frameEntity.position.x + frameEntity.scaling.x - constraints.MAX_PULLOUTSHELF_WIDTH.max - BOARD_THICKNESS);
                    }
                }
            }
        };

        traverse(startFrameEntityId);
        if (limits.max === 100000) {
            if (boardEntity.isHorizontal()) limits.max = constraints.MAX_FURNITURE_HEIGHT.max - BOARD_THICKNESS;
            else if (boardEntity.isVertical()) limits.max = constraints.MAX_FURNITURE_WIDTH.max;
        }

        return limits;
    },

    getFurnitureMinDepthForDrawerRunner(furnitureEntity) {
        const mainFrameEntity = modules.dataStore.getEntity(furnitureEntity.getIdFrame());

        let minDepthAccepted = constraints.MIN_FURNITURE_DEPTH.min;

        const traverse = (frameEntityId) => {
            const frameEntity = modules.dataStore.getEntity(frameEntityId);

            if (frameEntity.getIsSplitted()) {
                traverse(frameEntity.getIdFrameLeftOrBottom());
                traverse(frameEntity.getIdFrameRightOrTop());
            }

            const drawerRunnerParentId = frameEntity.idDrawer || frameEntity.idAccessorydrawer || frameEntity.idPulloutshelfs;
            if (drawerRunnerParentId) {
                const drawerRunnerParentEntity = modules.dataStore.getEntity(drawerRunnerParentId);
                if (drawerRunnerParentEntity instanceof OverlaydrawerEntity) {
                    const frameMinDepth = DrawerRunnerHelper.getMinDepthOfFrame(frameEntity, OVERLAY_DRAWER, drawerRunnerParentEntity.drawerRunnerType);
                    if (frameMinDepth > minDepthAccepted) minDepthAccepted = frameMinDepth;
                }
            }
        };

        traverse(mainFrameEntity.id);
        return minDepthAccepted;
    },
};

export default ConstraintsManager;
