import {
    Vector3,
} from "@babylonjs/core";
import self from "../../index";
import {
    BOARD_THICKNESS,
} from "../constants";
import MovableshelfEntity from "./movableshelf-entity";

const {
    modules,
} = self.app;

const MovableshelfEntityBuilder = {
    build(frameEntity) {
        const furnitureEntity = modules.dataStore.getEntity(frameEntity.getFurnitureId());
        return new MovableshelfEntity({
            furnitureId: furnitureEntity.id,
            idParent: frameEntity.id,
            scaling: new Vector3(
                frameEntity.scaling.x,
                BOARD_THICKNESS,
                frameEntity.scaling.z,
            ),
            position: new Vector3(frameEntity.position.x, frameEntity.position.y, frameEntity.position.z),
        });
    },
};
export default MovableshelfEntityBuilder;
