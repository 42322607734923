import defaultConfig from "defaultConfig";
import packageJson from "packageJson";
import self from "../../index";
import constraints from "../constraints";
import FurnitureCompositionBuilder from "../furniture/furniture-composition-builder";


const {
    modules,
    events,
} = self.app;

class FurnituresTweaks {

    constructor() {
        if (!FurnituresTweaks.instance) FurnituresTweaks.instance = this;
        else throw new Error("FurnituresTweaks is a singleton. Use FurnituresTweaks.getInstance()");

        events.on("furniture:tryToScaleX:rejected", () => {
            const {
                currentFurnitureEntity,
            } = self.furnituresListController;
            this.furnitureParams.scaling.x = currentFurnitureEntity.getScaling().x;
            this.tweakpane.refresh();
        });
        events.on("furniture:tryToScaleY:rejected", () => {
            const {
                currentFurnitureEntity,
            } = self.furnituresListController;
            this.furnitureParams.scaling.y = currentFurnitureEntity.getScaling().y;
            this.tweakpane.refresh();
        });

    }

    addTweaks(pane) {

        this.tweakpane = pane;

        this.tweakpane.addButton({
            title: "Save",
        }).on("click", () => {
            events.emit("request-project-saving");
            const fileName = "save";
            const appVersion = packageJson.version;
            const { fileExtension } = defaultConfig;
            modules.projectManager.helper.saveProjectAsBlob(fileName, fileExtension, appVersion);
        });

        this.tweakpane.addButton({
            title: "Debug scene, dataStore and history",
        }).on("click", () => {
            window.scene = modules.obsidianBabylonEngine.scene;
            console.log("window.scene", window.scene);
            window.dataStore = modules.dataStore;
            console.log("window.dataStore", window.dataStore);
            window.hist = modules.history;
            console.log("window.hist", window.hist);
        });
        // Furniture
        this.folder = this.tweakpane.addFolder({
            title: "Furniture",
            expanded: false,
        });
        this.furnitureParams = {
            scaling: self.furnituresListController.currentFurnitureEntity.getScaling().clone(),
            position: self.furnituresListController.currentFurnitureEntity.getPosition().clone(),
            baseHeight: self.furnituresListController.currentFurnitureEntity.getBaseHeight(),
        };
        this.folder.addInput(
            this.furnitureParams.scaling, "x", {
                label: "width",
                step: 1,
                min: constraints.MIN_FURNITURE_WIDTH.min,
                max: constraints.MAX_FURNITURE_WIDTH.max,
            }
        ).on("change", FurnituresTweaks.onScaleXChanged.bind(this));

        this.folder.addInput(
            this.furnitureParams.scaling, "y", {
                label: "height",
                step: 1,
                min: constraints.MIN_FURNITURE_HEIGHT.min,
                max: constraints.MAX_FURNITURE_HEIGHT.max,
            }
        ).on("change", FurnituresTweaks.onScaleYChanged.bind(this));

        this.folder.addInput(
            this.furnitureParams.scaling, "z", {
                label: "depth",
                step: 1,
                min: constraints.MIN_FURNITURE_DEPTH.min,
                max: constraints.MAX_FURNITURE_DEPTH.max,
            }
        ).on("change", FurnituresTweaks.onScaleZChanged.bind(this));

        this.folder.addInput(
            this.furnitureParams, "baseHeight", {
                label: "baseHeight",
                step: 1,
                min: constraints.MIN_BASEBOARD_HEIGHT.min,
                max: constraints.MAX_BASEBOARD_HEIGHT.max,
            }
        ).on("change", this.onBaseHeightChanged.bind(this));

        this.folder.addButton({
            title: "Export composition",
        }).on("click", () => {
            console.log(FurnitureCompositionBuilder.instance.getComposition());
        });
    }

    static onScaleXChanged(event) {
        events.emit("furniture:tryToScaleX", event.value);
    }

    static onScaleYChanged(event) {
        events.emit("furniture:tryToScaleY", event.value);
    }

    static onScaleZChanged(event) {
        events.emit("furniture:tryToScaleZ", event.value);
    }

    onBaseHeightChanged(event) {
        const {
            currentFurnitureEntity,
        } = self.furnituresListController;
        if (currentFurnitureEntity.getIdBaseboard()) {
            events.emit("baseboard:height", event.value);
        } else {
            this.furnitureParams.baseHeight = self.furnituresListController.currentFurnitureEntity.getBaseHeight();
            this.tweakpane.refresh();
        }
    }

    static getInstance() {
        if (!FurnituresTweaks.instance) {
            return new FurnituresTweaks();
        }
        return FurnituresTweaks.instance;
    }

}

export default FurnituresTweaks;
