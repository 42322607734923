import KnobMixin from "../entity-mixins/knob-mixin";
import DrawerAbstractEntity from "../drawer/drawer-abstract-entity";
import BoardInfo from "../board/board-info";

const Entity = require("@obsidianjs/data-store/src/entity");

require("helpers/serializers/babylon-vector3");

const InsetdrawerEntity = DrawerAbstractEntity.$extend({

    __name__: "insetdrawer",

    __init__(params = {}) {
        this.$super(params);
        this.unitDrawerBoardComposition.overlay = new BoardInfo();
    },

    __include__: [KnobMixin.prototype],

});

Entity.$register(InsetdrawerEntity);
export default InsetdrawerEntity;
