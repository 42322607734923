import {
    Color3,
    MeshBuilder,
    StandardMaterial,
    Vector2,
} from "@babylonjs/core";

// Simple and light Bounding Box 2D
class BBox2 {

    constructor(p1 = {
        x: 0,
        y: 0,
    }, p2 = {
        x: 0,
        y: 0,
    }) {
        this.p1 = p1;
        this.p2 = p2;
        this.width = p2.x - p1.x;
        this.height = p2.y - p1.y;
        this.isArea = this.width > 0 && this.height > 0;
    }

    updateFromMesh(mesh, parentPosition = {
        x: 0,
        y: 0,
        z: 0,
    }) {
        this.p1.x = parentPosition.x + mesh.position.x;
        this.p2.y = parentPosition.y + mesh.position.y;
        this.p2.x = this.p1.x + mesh.scaling.x;
        this.p2.y = this.p1.y + mesh.scaling.y;
        this.width = this.p2.x - this.p1.x;
        this.height = this.p2.y - this.p1.y;

        this.isArea = this.width > 0 && this.height > 0;
    }

    containVector2(vec) {
        if (!vec) return null;
        return this.p1.x <= vec.x && vec.x <= this.p2.x && this.p1.y <= vec.y && vec.y <= this.p2.y;
    }

    toString() {
        return `BBox2(${this.p1.x}, ${this.p1.y}, ${this.p2.x}, ${this.p2.y})`;
    }

}

const BBox2Helper = {

    getBBox2FromMesh(mesh, parentPosition = {
        x: 0,
        y: 0,
        z: 0,
    }) {
        const x1 = parentPosition.x + mesh.position.x;
        const y1 = parentPosition.y + mesh.position.y;
        const x2 = x1 + mesh.scaling.x;
        const y2 = y1 + mesh.scaling.y;
        const width = x2 - x1;
        const height = y2 - y1;
        return new BBox2(new Vector2(x1, y1), new Vector2(x2, y2), width, height);
    },

    getBBox2FromObject3dEntity(object3dEntity) {
        const x1 = object3dEntity.position.x;
        const y1 = object3dEntity.position.y;
        const x2 = x1 + object3dEntity.scaling.x;
        const y2 = y1 + object3dEntity.scaling.y;
        const width = x2 - x1;
        const height = y2 - y1;
        return new BBox2(new Vector2(x1, y1), new Vector2(x2, y2), width, height);
    },

    getBBox2Equals(bb1, bb2) {
        return (
            bb1.p1.x === bb2.p1.x &&
            bb1.p1.y === bb2.p1.y &&
            bb1.p2.x === bb2.p2.x &&
            bb1.p2.y === bb2.p2.y
        );
    },

    getBBox2Intersection(bb1, bb2) {
        const x1 = Math.max(bb1.p1.x, bb2.p1.x);
        const y1 = Math.max(bb1.p1.y, bb2.p1.y);
        const x2 = Math.min(bb1.p2.x, bb2.p2.x);
        const y2 = Math.min(bb1.p2.y, bb2.p2.y);
        const width = x2 - x1;
        const height = y2 - y1;
        if (width < 0 || height < 0) {
            return null;
        }
        return new BBox2(new Vector2(x1, y1), new Vector2(x2, y2), width, height);
    },

    getBBox2Union(bb1, bb2) {
        const x1 = Math.min(bb1.p1.x, bb2.p1.x);
        const y1 = Math.min(bb1.p1.y, bb2.p1.y);
        const x2 = Math.max(bb1.p2.x, bb2.p2.x);
        const y2 = Math.max(bb1.p2.y, bb2.p2.y);
        const width = x2 - x1;
        const height = y2 - y1;
        if (width < 0 || height < 0) {
            return null;
        }

        return new BBox2(new Vector2(x1, y1), new Vector2(x2, y2), width, height);
    },

    getDistanceBetweenBBox2(bb1, bb2) {
        const dx = Math.max(bb1.p1.x - bb2.p2.x, 0, bb2.p1.x - bb1.p2.x);
        const dy = Math.max(bb1.p1.y - bb2.p2.y, 0, bb2.p1.y - bb1.p2.y);
        return Math.sqrt(dx * dx + dy * dy);
    },

    increaseBBox2FromCenter(bb, value) {
        const half = value * 0.5;

        const width = bb.width + value;
        const height = bb.height + value;
        const p1 = new Vector2(bb.p1.x - half, bb.p1.y - half);
        const p2 = new Vector2(bb.p2.x + half, bb.p2.y + half);

        return new BBox2(p1, p2, width, height);
    },

    decreaseBBox2FromCenter(bb, value) {
        const half = value * 0.5;
        const width = bb.width - value;
        const height = bb.height - value;
        const p1 = new Vector2(bb.p1.x + half, bb.p1.y + half);
        const p2 = new Vector2(bb.p2.x - half, bb.p2.y - half);

        return new BBox2(p1, p2, width, height);
    },

    BBox2containBBox2(bb1, bb2) {
        return bb1.p1.x <= bb2.p1.x && bb2.p2.x <= bb1.p2.x && bb1.p1.y <= bb2.p1.y && bb2.p2.y <= bb1.p2.y;
    },

    /**
     * if bb1 contains bb2, return an array of BBox2.
     * @param {BBox2} bb1
     * @param {BBox2} bb2
     * @returns {BBox2[4] || {BBox2[6]} || BBox2[9]}
     * 0 : bb1 does not contain bb2
     * 1 : bb1 is equal to bb2
     * 2 : bb2 has width or height equal to bb1 width or height and bb2 is in at a side of bb1
     * 3 : bb2 has width or height equal to bb1 width or height
     * 4 : bb2 is in a corner of bb1
     * 6 : bb2 is in a side of bb1
     * 9 : bb2 is in bb1
     * Array is ordered by the following order : bottom left to top right
     * image : https://git.wanadev.org/clickandmeubles/clickandmeubles-3d/-/wikis/BBox2Helper
     */
    BBox2divideWithContainedBBox2(bb1, bb2) {

        if (BBox2Helper.BBox2containBBox2(bb1, bb2)) {
            const col = [bb1.p1.x, bb2.p1.x, bb2.p2.x, bb1.p2.x];
            const row = [bb1.p1.y, bb2.p1.y, bb2.p2.y, bb1.p2.y];
            const result = [];
            for (let i = 0; i < 3; i++) {
                for (let j = 0; j < 3; j++) {
                    const bb = new BBox2(new Vector2(col[i], row[j]), new Vector2(col[i + 1], row[j + 1]));
                    if (bb.width > 0 && bb.height > 0) result.push(bb);
                }
            }
            return result;
        }
        return [];
    },

    BBox2intersectBBox2(bb1, bb2) {
        return (
            bb1.p1.x < bb2.p2.x &&
            bb2.p1.x < bb1.p2.x &&
            bb1.p1.y < bb2.p2.y &&
            bb2.p1.y < bb1.p2.y
        );
    },

    showBBoxDebug(bboxes, vparams = {}) {
        const params = { color: "#00ff00", decaleZ: 0, duration: 200, delay: 200, ...vparams };
        for (let i = 0, il = bboxes.length; i < il; i++) {
            const bbox = bboxes[i];

            const mesh = MeshBuilder.CreatePlane("bboxDebugMesh");
            mesh.material = new StandardMaterial("debugMesh");
            mesh.material.emissiveColor = params.color ? Color3.FromHexString(params.color) : Color3.Random();
            mesh.isVisible = true;
            mesh.showBoundingBox = true;
            mesh.isPickable = false;
            mesh.position.set(0.5, 0.5, 0);
            mesh.bakeCurrentTransformIntoVertices();

            mesh.scaling.x = bbox.width || 2;
            mesh.scaling.y = bbox.height || 2;
            mesh.position.x = bbox.p1.x;
            mesh.position.y = bbox.p1.y;
            mesh.position.z = -30 - params.decaleZ;

            setTimeout(() => {
                mesh.dispose();
            }, params.duration + i * params.delay);
        }
    },
};

export default BBox2Helper;
export {
    BBox2,
};

if (process.env.NODE_ENV === "development") {
    window.BBox2Helper = BBox2Helper;
}
