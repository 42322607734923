<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="31.271" viewBox="0 0 32 31.271">
        <g id="icon-drawer-ext" transform="translate(0 0.5)">
            <path id="Soustraction_27"
                  data-name="Soustraction 27"
                  d="M-815,1623.139h-30a1,1,0,0,1-1-1v-11a1,1,0,0,1,1-1h30a1,1,0,0,1,1,1v11A1,1,0,0,1-815,1623.139Zm-17.656-9.8a.752.752,0,0,0-.751.751.751.751,0,0,0,.751.75h5.312a.751.751,0,0,0,.751-.75.752.752,0,0,0-.751-.751Z"
                  transform="translate(846.001 -1592.368)"
                  fill="#fff"
            />
            <path id="Tracé_180"
                  data-name="Tracé 180"
                  d="M1430.15-1698.635s2.586-1.877,2.609-1.872a4.2,4.2,0,0,1,2.1-.34h18.858c1.524,0,1.759.121,2.1.34l2.643,1.872Z"
                  transform="translate(-1428.145 1700.847)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="1"
            />
            <path id="Soustraction_63"
                  data-name="Soustraction 63"
                  d="M-815,1623.139h-30a1,1,0,0,1-1-1v-11a1,1,0,0,1,1-1h30a1,1,0,0,1,1,1v11A1,1,0,0,1-815,1623.139Zm-17.656-9.8a.752.752,0,0,0-.751.751.751.751,0,0,0,.751.75h5.312a.751.751,0,0,0,.751-.75.752.752,0,0,0-.751-.751Z"
                  transform="translate(846.001 -1606.469)"
                  fill="#fff"
            />
        </g>
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "drawers-outside-icon",
});
</script>
