<template>
    <div class="h-full w-100 flex flex-col">
        <div class="flex pl-6 bg-theme-gray-100 py-3 items-center">
            <SelectableButton
                :selected="activeTab === EMBEDDED_FRONT_TAB"
                :disabled="frontMode === FRONT_MODE_OUTSIDE"
                @click="activeTab = EMBEDDED_FRONT_TAB"
            >
                {{ t("Porte encastrée") }}
            </SelectableButton>
            <SelectableButton
                :selected="activeTab === APPLIED_FRONT_TAB"
                :disabled="frontMode === FRONT_MODE_INSIDE"
                @click="activeTab = APPLIED_FRONT_TAB"
            >
                {{ t("Porte en applique") }}
            </SelectableButton>
            <SelectableButton
                :selected="activeTab === OVERLAY_DRAWERS_TAB"
                :disabled="frontMode === FRONT_MODE_INSIDE"
                @click="activeTab = OVERLAY_DRAWERS_TAB"
            >
                {{ t("Tiroir(s) en applique") }}
            </SelectableButton>
            <SelectableButton
                :selected="activeTab === INSET_DRAWERS_TAB"
                :disabled="frontMode === FRONT_MODE_OUTSIDE"
                @click="activeTab = INSET_DRAWERS_TAB"
            >
                {{ t("Tiroir(s) encastré") }}
            </SelectableButton>
            <SelectableButton
                :selected="activeTab === DOOR_BUTTONS_TAB"
                @click="activeTab = DOOR_BUTTONS_TAB"
            >
                {{ t("Boutons de porte") }}
            </SelectableButton>
            <InfoLabel v-if="activeTab === DOOR_BUTTONS_TAB" class="ml-auto mr-10">
                {{ t("L'emplacement des boutons affiché dans la 3D est indicatif et ne represente pas leur emplacement definitif") }}
            </InfoLabel>
        </div>
        <div v-if="activeTab === EMBEDDED_FRONT_TAB" class="pl-6 h-full flex items-center flex-grow">
            <DraggableCard
                :start-drag-events="[{eventName:'tool:frame:update', params:{toolModeName:TOOL_MODE_DOOR}}]"
                :drop-events="[{eventName:'frame:action:door', params:{type:DOOR_DOUBLE, mode:FRONT_MODE_INSIDE}}]"
            >
                <DoorCenterInsideIcon />
                <template #text>{{ t("Ouverture\ndouble") }}</template>
            </DraggableCard>

            <DraggableCard
                :start-drag-events="[{eventName:'tool:frame:update', params:{toolModeName:TOOL_MODE_DOOR}}]"
                :drop-events="[{eventName:'frame:action:door', params:{type:DOOR_SIMPLE_RIGHT, mode:FRONT_MODE_INSIDE}}]"
            >
                <DoorRightInsideIcon />
                <template #text>{{ t("Ouverture\ngauche") }}</template>
            </DraggableCard>

            <DraggableCard
                :start-drag-events="[{eventName:'tool:frame:update', params:{toolModeName:TOOL_MODE_DOOR}}]"
                :drop-events="[{eventName:'frame:action:door', params:{type:DOOR_SIMPLE_LEFT, mode: FRONT_MODE_INSIDE}}]"
            >
                <DoorLeftInsideIcon />
                <template #text>{{ t("Ouverture\ndroite") }}</template>
            </DraggableCard>
        </div>
        <div v-if="activeTab === APPLIED_FRONT_TAB" class="pl-6 h-full flex items-center flex-grow">
            <DraggableCard
                :start-drag-events="[{eventName:'tool:frame:update', params:{toolModeName:TOOL_MODE_DOOR}}]"
                :drop-events="[{eventName:'frame:action:door', params:{type:DOOR_DOUBLE, mode:FRONT_MODE_OUTSIDE}}]"
            >
                <DoorCenterOutsideIcon />
                <template #text>{{ t("Ouverture\ndouble") }}</template>
            </DraggableCard>

            <DraggableCard
                :start-drag-events="[{eventName:'tool:frame:update', params:{toolModeName:TOOL_MODE_DOOR}}]"
                :drop-events="[{eventName:'frame:action:door', params:{type:DOOR_SIMPLE_RIGHT, mode:FRONT_MODE_OUTSIDE}}]"
            >
                <DoorRightOutsideIcon />
                <template #text>{{ t("Ouverture\ndroite") }}</template>
            </DraggableCard>

            <DraggableCard
                :start-drag-events="[{eventName:'tool:frame:update', params:{toolModeName:TOOL_MODE_DOOR}}]"
                :drop-events="[{eventName:'frame:action:door', params:{type:DOOR_SIMPLE_LEFT, mode: FRONT_MODE_OUTSIDE}}]"
            >
                <DoorLeftOutsideIcon />
                <template #text>{{ t("Ouverture\ngauche") }}</template>
            </DraggableCard>
        </div>
        <div v-if="activeTab === OVERLAY_DRAWERS_TAB" class="pl-6 h-full flex items-center flex-grow">
            <DraggableCard
                :start-drag-events="[{eventName:'tool:frame:update', params:{toolModeName:TOOL_MODE_DRAWER, mode:OVERLAY_DRAWER}}]"
                :drop-events="[{eventName:'frame:action:overlaydrawer', params:{type:DRAWER_RUNNER_WEIGHT_1}}]"
            >
                <DrawersOutsideIcon />
                <template #text>{{ t("Tiroir(s)\n40kg") }}</template>
            </DraggableCard>

            <DraggableCard
                :start-drag-events="[{eventName:'tool:frame:update', params:{toolModeName:TOOL_MODE_DRAWER, mode:OVERLAY_DRAWER}}]"
                :drop-events="[{eventName:'frame:action:overlaydrawer', params:{type:DRAWER_RUNNER_WEIGHT_2}}]"
            >
                <DrawersOutsideIcon />
                <template #text>{{ t("Tiroir(s)\n60kg") }}</template>
            </DraggableCard>
            <div
                v-if="isDrawerSelected"
                class="mr-6 flex items-center border-theme-pink-400 rounded-md border-2 p-2"
            >
                <label for="drawers-amount">{{ t("Nombre de tiroir(s)") }}</label>
                <div class="flex border-2 border-theme-gray-200 h-12 rounded-md overflow-hidden mx-2">
                    <button type="button" class="h-full w-8 font-bold bg-theme-gray-100" @click="decrement()">
                        <span class="sr-only">{{ t("Moins") }}</span>
                        <span aria-hidden="true">-</span>
                    </button>
                    <input
                        id="drawers-amount"
                        v-model="currentDrawerQuantity"
                        type="number"
                        class="border-x-2 border-gray-200 px-2 h-full w-16 number-as-text text-center"
                        @keypress="updateDrawersAmount($event, FIELD_DRAWERS_AMOUNT)"
                        @blur="updateDrawersAmount($event, FIELD_DRAWERS_AMOUNT)"
                    >
                    <button type="button" class="h-full w-8 font-bold bg-theme-gray-100" @click="increment()">
                        <span class="sr-only">{{ t("Plus") }}</span>
                        <span aria-hidden="true">+</span>
                    </button>
                </div>
                <div class="ml-4">
                    <span class="m-2 font-bold">{{ t("Dimensions utiles:") }}</span>
                    <span class="m-2">{{ `Largeur: ${currentDrawer.unitUtilSize.x}mm` }}</span>
                    <span class="m-2">{{ `Hauteur: ${currentDrawer.unitUtilSize.y}mm` }}</span>
                    <span class="m-2">{{ `Profondeur: ${currentDrawer.unitUtilSize.z}mm` }}</span>
                </div>
            </div>
        </div>
        <div v-if="activeTab === INSET_DRAWERS_TAB" class="pl-6 h-full flex items-center flex-grow">
            <DraggableCard
                :start-drag-events="[{eventName:'tool:frame:update', params:{toolModeName:TOOL_MODE_DRAWER, mode:INSET_DRAWER}}]"
                :drop-events="[{eventName:'frame:action:insetdrawer', params:{type:DRAWER_RUNNER_WEIGHT_1}}]"
            >
                <DrawersOutsideIcon />
                <template #text>{{ t("Tiroir(s)\n40kg") }}</template>
            </DraggableCard>

            <DraggableCard
                :start-drag-events="[{eventName:'tool:frame:update', params:{toolModeName:TOOL_MODE_DRAWER, mode:INSET_DRAWER}}]"
                :drop-events="[{eventName:'frame:action:insetdrawer', params:{type:DRAWER_RUNNER_WEIGHT_2}}]"
            >
                <DrawersOutsideIcon />
                <template #text>{{ t("Tiroir(s)\n60kg") }}</template>
            </DraggableCard>
            <div
                v-if="isDrawerSelected"
                class="mr-6 flex items-center border-theme-pink-400 rounded-md border-2 p-2"
            >
                <label for="drawers-amount">{{ t("Nombre de tiroir(s)") }}</label>
                <div class="flex border-2 border-theme-gray-200 h-12 rounded-md overflow-hidden mx-2">
                    <button type="button" class="h-full w-8 font-bold bg-theme-gray-100" @click="decrement()">
                        <span class="sr-only">{{ t("Moins") }}</span>
                        <span aria-hidden="true">-</span>
                    </button>
                    <input
                        id="drawers-amount"
                        v-model="currentDrawerQuantity"
                        type="number"
                        class="border-x-2 border-gray-200 px-2 h-full w-16 number-as-text text-center"
                        @keypress="updateDrawersAmount($event, FIELD_DRAWERS_AMOUNT)"
                        @blur="updateDrawersAmount($event, FIELD_DRAWERS_AMOUNT)"
                    >
                    <button type="button" class="h-full w-8 font-bold bg-theme-gray-100" @click="increment()">
                        <span class="sr-only">{{ t("Plus") }}</span>
                        <span aria-hidden="true">+</span>
                    </button>
                </div>
                <div class="ml-4">
                    <span class="m-2 font-bold">{{ t("Dimensions utiles:") }}</span>
                    <span class="m-2">{{ `Largeur: ${currentDrawer.unitUtilSize.x}mm` }}</span>
                    <span class="m-2">{{ `Hauteur: ${currentDrawer.unitUtilSize.y}mm` }}</span>
                    <span class="m-2">{{ `Profondeur: ${currentDrawer.unitUtilSize.z}mm` }}</span>
                </div>
            </div>
        </div>
        <div v-if="activeTab === DOOR_BUTTONS_TAB" class="h-full overflow-auto flex items-center">
            <SelectableCard
                :selected="isButtonSelected(null)"
                @click="setSelectedButton(null)"
            >
                Sans bouton
            </SelectableCard>
            <SelectableCard
                v-for="button in buttonsCatalog"
                :key="button.product_id"
                :selected="isButtonSelected(button)"
                :image-url="button.product_img"
                @click="setSelectedButton(button)"
            >
                {{ button.product_name }}
            </SelectableCard>
        </div>
    </div>
</template>

<script>
import {
    TOOL_MODE_DOOR,
    DOOR_SIMPLE_LEFT, DOOR_SIMPLE_RIGHT, DOOR_DOUBLE, DOOR_NONE,
    FRONT_MODE_INSIDE, FRONT_MODE_OUTSIDE, TOOL_MODE_DRAWER, DRAWER_RUNNER_WEIGHT_1, DRAWER_RUNNER_WEIGHT_2, DRAWER_NONE,
} from "../../../../furnitures/src/constants";
import { PUSH_BUTTON_DRAWER_REF } from "../../../../../constants/pushButtonsRef";
import useFurnitureStore from "../../stores/furniture";
import useGlobalStore from "../../stores/global";

import Draggable from "./Draggable.vue";
import DraggableCard from "./DraggableCard.vue";
import DoorCenterInsideIcon from "../icons/DoorCenterInsideIcon.vue";
import DoorCenterOutsideIcon from "../icons/DoorCenterOutsideIcon.vue";
import DoorLeftInsideIcon from "../icons/DoorLeftInsideIcon.vue";
import DoorLeftOutsideIcon from "../icons/DoorLeftOutsideIcon.vue";
import DoorRightInsideIcon from "../icons/DoorRightInsideIcon.vue";
import DoorRightOutsideIcon from "../icons/DoorRightOutsideIcon.vue";
import DrawersInsideIcon from "../icons/DrawersInsideIcon.vue";
import DrawersOutsideIcon from "../icons/DrawersOutsideIcon.vue";
import SelectableButton from "./SelectableButton.vue";
import SelectableCard from "./SelectableCard.vue";

import {
    FIELD_DRAWERS_AMOUNT, TYPE_DRAWER_INSET, TYPE_DRAWER_OVERLAY,
} from "../../services/constants";

import self from "../../../index";
import InfoLabel from "../common/InfoLabel.vue";


const EMBEDDED_FRONT_TAB = "façade_encastrée";
const APPLIED_FRONT_TAB = "façade_en_applique";
const OVERLAY_DRAWERS_TAB = "tiroirs_en_applique";
const INSET_DRAWERS_TAB = "tiroirs_encastré";
const DOOR_BUTTONS_TAB = "door_buttons";

const PUSH_BUTTON = "push";
const CLASSIC_BUTTON = "classic";

const ENTER_KEY_CODE = 13;

const { modules, events } = self.app;

export default {
    components: {
        Draggable,
        DoorCenterInsideIcon,
        DoorCenterOutsideIcon,
        DoorLeftInsideIcon,
        DoorLeftOutsideIcon,
        DoorRightInsideIcon,
        DoorRightOutsideIcon,
        DrawersInsideIcon,
        DrawersOutsideIcon,
        SelectableButton,
        DraggableCard,
        SelectableCard,
        InfoLabel,
    },
    data() {
        return {
            TOOL_MODE_DOOR,
            DOOR_SIMPLE_LEFT,
            DOOR_SIMPLE_RIGHT,
            DOOR_DOUBLE,
            DOOR_NONE,
            FRONT_MODE_INSIDE,
            FRONT_MODE_OUTSIDE,
            TOOL_MODE_DRAWER,
            DRAWER_RUNNER_WEIGHT_1,
            DRAWER_RUNNER_WEIGHT_2,
            DRAWER_NONE,

            activeTab: APPLIED_FRONT_TAB,
            EMBEDDED_FRONT_TAB,
            APPLIED_FRONT_TAB,
            OVERLAY_DRAWERS_TAB,
            INSET_DRAWERS_TAB,
            DOOR_BUTTONS_TAB,

            PUSH_BUTTON,
            CLASSIC_BUTTON,

            FIELD_DRAWERS_AMOUNT,
            drawersAmount: 0,
        };
    },
    computed: {
        store() {
            return useFurnitureStore();
        },
        globalStore() {
            return useGlobalStore();
        },
        frontMode() {
            return this.store.frontMode;
        },
        currentDrawerQuantity() {
            return this.store.currentDrawer?.quantity;
        },
        currentDrawer() {
            return this.store.currentDrawer;
        },
        isDrawerSelected() {
            return !!this.store.currentDrawerId;
        },
        buttonsCatalog() {
            return this.globalStore.catalogs.buttons.filter((button) => button.product_ref !== PUSH_BUTTON_DRAWER_REF);
        },
        selectedButton() {
            return this.globalStore.selectedProducts.doorButton;
        },
    },
    mounted() {
        if (this.frontMode === FRONT_MODE_INSIDE) {
            this.activeTab = EMBEDDED_FRONT_TAB;
        }
        events.on("@furnitures.overlaydrawer:amount:rejected", () => {
            this.$forceUpdate();
        });
        events.on("@furnitures.insetdrawer:amount:rejected", () => {
            this.$forceUpdate();
        });
        events.on("front:activetab", (tab) => {
            this.activeTab = tab;
        });
    },
    unmounted() {
        events.removeListener("@furnitures.overlaydrawer:amount:rejected");
        events.removeListener("@furnitures.insetdrawer:amount:rejected");
    },
    methods: {
        t(...args) {
            return modules.stonejs.gettext(...args);
        },
        decrement() {
            const amount = this.currentDrawerQuantity - 1;
            this.emitUpdateQuantity(amount);
        },
        increment() {
            const amount = this.currentDrawerQuantity + 1;
            this.emitUpdateQuantity(amount);
        },
        updateDrawersAmount(event) {
            if (event.type === "keypress" && event.keyCode === ENTER_KEY_CODE) {
                const amount = parseInt(event.target.value, 10);
                this.emitUpdateQuantity(amount);
                return;
            }
            if (event.type === "blur") {
                const amount = parseInt(event.target.value, 10);
                this.emitUpdateQuantity(amount);
            }
        },
        emitUpdateQuantity(amount) {
            if (this.currentDrawer && this.currentDrawer.type === TYPE_DRAWER_OVERLAY) {
                events.emit("overlaydrawer:update:quantity", amount);
            }
            if (this.currentDrawer && this.currentDrawer.type === TYPE_DRAWER_INSET) {
                events.emit("insetdrawer:update:quantity", amount);
            }
        },
        setSelectedButton(button) {
            this.globalStore.setSelectedButton(button);
            events.emit("furniture:knob", button ? { ref: button.product_ref } : null);
        },
        isButtonSelected(button) {
            return button === this.selectedButton;
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
