import DoorUiButtons from "../door/door-ui-buttons";
import self from "../../index";
import FurnituresListController from "../furnitures-list-controller";
import BackboardUIButtons from "../backboard/backboard-ui-buttons";
import DrawerUIButtons from "../drawer/drawer-ui-buttons";
import AccessoryDrawerUiButtons from "../accessorydrawer/accessorydrawer-ui-buttons";
import MovableshelfUiButtons from "../movableshelf/movableshelf-ui-buttons";
import RodUiButtons from "../rod/rod-ui-buttons";
import PulloutshelfUiButtons from "../pulloutshelf/pulloutshelf-ui-buttons";

const {
    events,
} = self.app;

const data = {
    currentUiMainTab: "external",
};

const furnitureUiButtons = {

    set currentUiMainTab(tab) {
        data.currentUiMainTab = tab;
    },

    get currentUiMainTab() {
        return data.currentUiMainTab;
    },

    get isUiMainTabFinishing() {
        return data.currentUiMainTab === "finishing";
    },

    updateButtons() {
        switch (data.currentUiMainTab) {
            case "front":
                furnitureUiButtons.emitFrontButtons();
                break;
            case "back":
                furnitureUiButtons.emitBackButtons();
                break;
            case "accessories":
                furnitureUiButtons.emitAccessoriesButtons();
                break;
            case "exterior":
            case "interior":
            case "finishing":
                this.emitWithoutButtons();
                break;
            default:
                break;
        }
    },

    emitWithoutButtons() {
        events.emit("3D:buttons:updated", []);
    },

    emitFrontButtons() {
        const doorsButtons = DoorUiButtons.getAllButtons();
        const { currentFurnitureEntity } = FurnituresListController.getInstance();
        let drawerButtons;
        if (currentFurnitureEntity.isFrontModeOutside()) {
            drawerButtons = DrawerUIButtons.getAllButtons("overlaydrawer");
        } else {
            drawerButtons = DrawerUIButtons.getAllButtons("insetdrawer");
        }

        const buttons = [...doorsButtons, ...drawerButtons];
        events.emit("3D:buttons:updated", buttons);
    },

    emitBackButtons() {
        const backboardUIButtons = BackboardUIButtons.getAllButtons();
        events.emit("3D:buttons:updated", backboardUIButtons);
    },

    emitAccessoriesButtons() {
        const accessoriesButtons = AccessoryDrawerUiButtons.getAllButtons();
        const movableshelfButtons = MovableshelfUiButtons.getAllButtons();
        const pulloutshelfButtons = PulloutshelfUiButtons.getAllButtons();
        const rodButtons = RodUiButtons.getAllButtons();
        const buttons = [...accessoriesButtons, ...movableshelfButtons, ...pulloutshelfButtons, ...rodButtons];
        events.emit("3D:buttons:updated", buttons);
    },
};

export default furnitureUiButtons;
