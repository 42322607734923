export default {

    name: "api",
    requires: ["iframe-api", "project-manager", "wanadev-loader", "data-exporter", "vuejs"],

    load() {
        const ApiController = require("./src/api-controller").default;
        this.apiController = new ApiController();
        return {
            controller: this.apiController,
        };
    },

    unload() {},

};
