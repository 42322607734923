import Hitbox from "../common/hitbox-mesh";
import PulloutshelfMesh from "./pulloutshelf-mesh";
import PulloutshelfMeshDepth from "./pulloutshelf-mesh-depth";
import PulloutshelfMeshFront from "./pulloutshelf-mesh-front";

const PulloutshelfMeshBuilder = {

    /**
     *
     * @param {BoardEntity} pulloutshelfEntity
     * @param {TransformNode} parent : furnitureEntity.container
     */
    buildDefault(pulloutshelfEntity, parent) {

        pulloutshelfEntity.container.parent = parent;

        const pulloutshelfMesh = new PulloutshelfMesh(pulloutshelfEntity);
        pulloutshelfMesh.isPickable = false;
        pulloutshelfMesh.parent = pulloutshelfEntity.container;
        pulloutshelfMesh.metadata = Object.assign(pulloutshelfMesh.metadata || {}, {
            pulloutshelfEntity: pulloutshelfEntity,
        });
        pulloutshelfEntity.mesh = pulloutshelfMesh;

        pulloutshelfMesh.linesSystem.parent = pulloutshelfEntity.container;
        pulloutshelfMesh.updateLinesSystem();

        const meshDepth = new PulloutshelfMeshDepth(pulloutshelfEntity);
        meshDepth.isPickable = false;
        meshDepth.parent = pulloutshelfEntity.container;
        meshDepth.linesSystem.parent = pulloutshelfEntity.container;
        meshDepth.updateLinesSystem();
        pulloutshelfEntity.meshDepth = meshDepth;

        const meshFront = new PulloutshelfMeshFront(pulloutshelfEntity);
        meshFront.isPickable = false;
        meshFront.parent = pulloutshelfEntity.container;
        meshFront.linesSystem.parent = pulloutshelfEntity.container;
        meshFront.updateLinesSystem();
        pulloutshelfEntity.meshFront = meshFront;

        const hitbox = new Hitbox();
        pulloutshelfEntity.hitbox = hitbox;
        hitbox.parent = pulloutshelfEntity.container;

        return pulloutshelfMesh;
    },

};

export default PulloutshelfMeshBuilder;
