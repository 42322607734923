<template>
    <div class="w-[calc(100vw-12rem)]">
        <div v-show="activeTab === TAB_STRUCTURE_EXT">
            <ExternalStructure />
        </div>
        <div v-show="activeTab === TAB_STRUCTURE_INT">
            <InternalStructure />
        </div>
        <div v-if="activeTab === TAB_FRONT">
            <Front />
        </div>
        <div v-show="activeTab === TAB_BACK">
            <Backboard />
        </div>
        <div v-show="activeTab === TAB_ACCESSORIES">
            <Accessories />
        </div>
        <div v-show="activeTab === TAB_FINISHING_TOUCHES">
            <FinishingTouches />
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";
import useGlobalStore from "../../stores/global";

import ExternalStructure from "../menu-tools/ExternalStructure.vue";
import InternalStructure from "../menu-tools/InternalStructure.vue";
import Front from "../menu-tools/Front.vue";
import Backboard from "../menu-tools/Backboard.vue";
import Accessories from "../menu-tools/Accessories.vue";
import FinishingTouches from "../menu-tools/FinishingTouches.vue";

import {
    TAB_STRUCTURE_EXT,
    TAB_STRUCTURE_INT,
    TAB_FRONT,
    TAB_BACK,
    TAB_ACCESSORIES,
    TAB_FINISHING_TOUCHES,
} from "../../services/constants";

export default defineComponent({
    name: "tab-container",
    components: {
        ExternalStructure,
        InternalStructure,
        Front,
        Backboard,
        Accessories,
        FinishingTouches,
    },
    data() {
        return {
            TAB_STRUCTURE_EXT,
            TAB_STRUCTURE_INT,
            TAB_FRONT,
            TAB_BACK,
            TAB_ACCESSORIES,
            TAB_FINISHING_TOUCHES,
        };
    },
    computed: {
        store() {
            return useGlobalStore();
        },
        activeTab() {
            return this.store.activeTab;
        },
    },
});
</script>
