import cu from "vendors/class-utils";
import { Vector2 } from "@babylonjs/core";
import Object3DEntity from "../entity-mixins/object3D-entity";
import {
    BOARD_THICKNESS,
    DOOR_DOUBLE,
    DOOR_SIMPLE_LEFT,
    DOOR_SIMPLE_RIGHT,
    FRONT_MODE_INSIDE,
    FRONT_MODE_OUTSIDE,
} from "../constants";
import { KNOB_DIAMETER } from "../knob/knob-factory";
import KnobMixin from "../entity-mixins/knob-mixin";

const Entity = require("@obsidianjs/data-store/src/entity");

require("helpers/serializers/babylon-vector3");

const DoorEntity = Object3DEntity.$extend({

    __name__: "door",

    __init__(params = {}) {

        this.hingeCreated = false;
        this.type = params.type || "";
        this.$data.mode = params.mode || FRONT_MODE_INSIDE;
        this.$data.frameEntitiesIds = params.frameEntitiesIds || [];

        this.$data.hasNeighborTop = params.hasNeighborTop || false;
        this.$data.hasNeighborRight = params.hasNeighborRight || false;
        this.$data.hasNeighborBottom = params.hasNeighborBottom || false;
        this.$data.hasNeighborLeft = params.hasNeighborLeft || false;

        this.isInEdition = false;

        this.framesBuildOrder = [];

        this.mesh = null;


        this.$super(params);
        this.initKnobMixin();
    },

    __include__: [KnobMixin.prototype],

    setType(type) {
        this.$data.type = type;
        if (this.hingeCreated) return;
        switch (type) {
            case DOOR_DOUBLE:
                this.hingeTopLeft = new Vector2();
                this.hingeBottomLeft = new Vector2();
                this.hingeTopRight = new Vector2();
                this.hingeBottomRight = new Vector2();
                this.hingeCreated = true;
                break;
            case DOOR_SIMPLE_LEFT:
                this.hingeTopRight = new Vector2();
                this.hingeBottomRight = new Vector2();
                this.hingeCreated = true;
                break;
            case DOOR_SIMPLE_RIGHT:
                this.hingeTopLeft = new Vector2();
                this.hingeBottomLeft = new Vector2();
                this.hingeCreated = true;
                break;
            default:
        }
    },
    getType: cu.getter,

    setMode: cu.setter,
    getMode: cu.getter,

    setFrameEntitiesIds: cu.setter,
    getFrameEntitiesIds: cu.getter,

    addFrameToBuildOrder(frameEntityId) {
        if (this.framesBuildOrder.indexOf(frameEntityId) < 0) {
            this.framesBuildOrder.push(frameEntityId);
        }
    },

    addFrameEntityId(frameEntityId) {
        if (this.$data.frameEntitiesIds.indexOf(frameEntityId) < 0) {
            this.$data.frameEntitiesIds.unshift(frameEntityId);
        }
    },

    hasFrameEntityId(frameEntityId) {
        return this.$data.frameEntitiesIds.indexOf(frameEntityId) >= 0;
    },

    getLastFrameFromBuildOrder() {
        return this.framesBuildOrder[this.framesBuildOrder.length - 1];
    },

    setPositionZ(z) {
        this.$data.position.z = z - (this.isFrontModeOutside() ? BOARD_THICKNESS : 0);
        this.updateContainer();
    },

    setHasNeighborTop: cu.setter,
    getHasNeighborTop: cu.getter,

    setHasNeighborRight: cu.setter,
    getHasNeighborRight: cu.getter,

    setHasNeighborBottom: cu.setter,
    getHasNeighborBottom: cu.getter,

    setHasNeighborLeft: cu.setter,
    getHasNeighborLeft: cu.getter,

    setHasNeighborAll(value) {
        this.setHasNeighborTop(value);
        this.setHasNeighborRight(value);
        this.setHasNeighborBottom(value);
        this.setHasNeighborLeft(value);
    },

    isMonoFrame() {
        return this.$data.frameEntitiesIds.length === 1;
    },

    isMultiFrames() {
        return this.$data.frameEntitiesIds.length > 1;
    },

    isFrontModeInside() {
        return this.$data.mode === FRONT_MODE_INSIDE;
    },

    isFrontModeOutside() {
        return this.$data.mode === FRONT_MODE_OUTSIDE;
    },

    updateMeshes() {
        if (!this.mesh) return;
        this.mesh.scaling = this.$data.scaling.clone();
        this.mesh.updateLinesSystem();
        this.mesh.updateUVScaling();

        switch (this.$data.type) {
            case DOOR_DOUBLE:
                this.knobs[0].position.x = this.$data.scaling.x * 0.5 - KNOB_DIAMETER;
                this.knobs[0].position.y = this.$data.scaling.y * 0.5;

                this.knobs[1].position.x = this.$data.scaling.x * 0.5 + KNOB_DIAMETER;
                this.knobs[1].position.y = this.$data.scaling.y * 0.5;
                break;
            case DOOR_SIMPLE_LEFT:
                this.knobs[0].position.x = KNOB_DIAMETER;
                this.knobs[0].position.y = this.$data.scaling.y * 0.5;
                break;
            case DOOR_SIMPLE_RIGHT:
                this.knobs[0].position.x = this.$data.scaling.x - KNOB_DIAMETER;
                this.knobs[0].position.y = this.$data.scaling.y * 0.5;
                break;
            default:
                break;
        }
    },

    /**
     * override object entity because we can have a double door
     * @returns {Array}
     */
    getDimensions() {
        if (this.$data.type === DOOR_DOUBLE) {
            const dim = [
                this.$data.scaling.x / 2,
                this.$data.scaling.y,
                this.$data.scaling.z,
            ].sort((a, b) => a - b);
            return [dim, dim];
        }
        return [[this.$data.scaling.x, this.$data.scaling.y, this.$data.scaling.z].sort((a, b) => a - b)];
    },
});

Entity.$register(DoorEntity);
export default DoorEntity;
