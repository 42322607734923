import FootEntityBuilder from "./foot-entity-builder";
import FootMeshBuilder from "./foot-mesh-builder";
import self from "../../index";
import FootMaterialsBuilder from "./foot-materials-builder";

const {
    modules,
    config,
} = self.app;

const FootController = {
    createFeet(furnitureEntity) {
        const idsFeet = [];
        for (let i = 0; i < 4; i++) {
            const footEntity = FootEntityBuilder.build(furnitureEntity);
            modules.dataStore.addEntity(footEntity, "foot");
            idsFeet.push(footEntity.id);
        }
        furnitureEntity.idsFeet = idsFeet;

        FootController.transformFeet(furnitureEntity);

    },
    generateFootMeshAndMaterial(footEntity) {
        const furnitureEntity = modules.dataStore.getEntity(footEntity.getFurnitureId());
        const footMesh = FootMeshBuilder.buildDefault(footEntity, furnitureEntity.container);
        footMesh.material = FootMaterialsBuilder.getDefaultMaterial();
    },
    deleteFeet(furnitureEntity) {
        furnitureEntity.idsFeet.forEach((footEntityId) => {
            const footEntity = modules.dataStore.getEntity(footEntityId);
            modules.dataStore.removeEntity(footEntity);
        });
        furnitureEntity.idsFeet = [];

    },
    transformFeet(furnitureEntity) {

        const footSize = config.get("default_foot_size");

        furnitureEntity.idsFeet.forEach((footEntityId, index) => {
            const footEntity = modules.dataStore.getEntity(footEntityId);

            if (index === 0 || index === 1) {
                footEntity.setPositionX(footSize / 2);
            }
            if (index === 2 || index === 3) {
                footEntity.setPositionX(furnitureEntity.getScaling().x - footSize / 2);
            }

            if (index === 0 || index === 3) {
                footEntity.setPositionZ(footSize / 2);
            }

            if (index === 1 || index === 2) {
                footEntity.setPositionZ(furnitureEntity.getScaling().z - footSize / 2);
            }

            footEntity.setScalingY(furnitureEntity.getPosition().y);
            footEntity.setPositionY(-furnitureEntity.getPosition().y);
        });

    },
};

export default FootController;
