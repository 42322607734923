import {
    defineStore,
} from "pinia";
import { PUSH_BUTTON_DRAWER_REF, PUSH_BUTTON_DOOR_REF } from "../../../../constants/pushButtonsRef";
import {
    TAB_STRUCTURE_EXT,
    TAB_STRUCTURE_INT,
    TAB_FRONT,
    TAB_BACK,
    TAB_ACCESSORIES,
    TAB_FINISHING_TOUCHES,
    CAMERA_DEFAULT,
    CAMERA_SWITCH,
} from "../services/constants";
import {
    TOOL_MODE_SELECT,
} from "../../../furnitures/src/constants";

import self from "../../index";
import priceCalculator from "../services/priceCalculator";
import weightCalculator from "../services/weightCalculator";

import useFurnitureStore from "./furniture";

const {
    modules,
    events,
} = self.app;

export default defineStore("global", {
    state: () => ({
        activeTab: TAB_STRUCTURE_EXT,
        displayDoors: false,
        displayPrice: true,
        shouldDisplayMeasures: false,
        cameraMode: CAMERA_DEFAULT,
        furnitureComposition: null,
        selectedProducts: {
            board: null,
            border: null,
            front: null,
            doorButton: null,
            drawerButton: null,
            feet: null,
            hinge: null,
            rod: null,
            rodSupport: null,
        },
        catalogs: {
            buttons: [],
            boards: [],
            drawers: [],
            accessories: [],
            borders: [],
            feet: [],
        },
    }),
    getters: {
        getActiveTab: (state) => state.activeTab,
        price(state) {
            if (state.furnitureComposition) {
                let totalPrice = 0;
                totalPrice += priceCalculator.getProductGroupsPriceFromCatalog(state.furnitureComposition.boardGroupCollection, state.catalogs.boards);
                totalPrice += priceCalculator.getProductGroupsPriceFromCatalog(state.furnitureComposition.borderGroupCollection, state.catalogs.borders);
                const formatedDrawerRunnerGroup = state.furnitureComposition.drawerRunnersGroupCollection.map((drawerRunner) => {
                    drawerRunner.quantity /= 2;
                    return drawerRunner;
                });
                totalPrice += priceCalculator.getProductGroupsPriceFromCatalog(formatedDrawerRunnerGroup, state.catalogs.drawers);
                const formatedRodGroups = state.furnitureComposition.rodGroupCollection.map((rodGroup) => ({ ...rodGroup, ref: state.selectedProducts.rod.product_ref }));
                totalPrice += priceCalculator.getProductGroupsPriceFromCatalog(formatedRodGroups, state.catalogs.accessories);
                totalPrice += priceCalculator.priceFromGroupAndUnit(state.furnitureComposition.feet, state.selectedProducts.feet);
                totalPrice += priceCalculator.priceFromGroupAndUnit(state.furnitureComposition.doorKnobs, state.selectedProducts.doorButton);
                totalPrice += priceCalculator.priceFromGroupAndUnit(state.furnitureComposition.drawerKnobs, state.selectedProducts.drawerButton);
                totalPrice += priceCalculator.priceFromGroupAndUnit(state.furnitureComposition.hinges, state.selectedProducts.hinge);
                const rodSupportsQuantity = state.furnitureComposition.rodGroupCollection.reduce((acc, rodGroup) => acc + rodGroup.quantity, 0);
                totalPrice += priceCalculator.priceFromGroupAndUnit({ quantity: rodSupportsQuantity }, state.selectedProducts.rodSupport);
                return totalPrice;
            }
            return 0;
        },
        weight(state) {
            if (state.furnitureComposition) {
                let totalWeight = 0;
                totalWeight += weightCalculator.getProductGroupsWeightFromCatalog(state.furnitureComposition.boardGroupCollection, state.catalogs.boards);
                totalWeight += weightCalculator.getProductGroupsWeightFromCatalog(state.furnitureComposition.borderGroupCollection, state.catalogs.borders);
                totalWeight += weightCalculator.getProductGroupsWeightFromCatalog(state.furnitureComposition.drawerRunnersGroupCollection, state.catalogs.drawers);
                const formatedRodGroups = state.furnitureComposition.rodGroupCollection.map((rodGroup) => ({ ...rodGroup, ref: state.selectedProducts.rod.product_ref }));
                totalWeight += weightCalculator.getProductGroupsWeightFromCatalog(formatedRodGroups, state.catalogs.accessories);
                totalWeight += weightCalculator.weightFromGroupAndUnit(state.furnitureComposition.feet, state.selectedProducts.feet);
                totalWeight += weightCalculator.weightFromGroupAndUnit(state.furnitureComposition.doorKnobs, state.selectedProducts.doorButton);
                totalWeight += weightCalculator.weightFromGroupAndUnit(state.furnitureComposition.drawerKnobs, state.selectedProducts.drawerButton);
                totalWeight += weightCalculator.weightFromGroupAndUnit(state.furnitureComposition.hinges, state.selectedProducts.hinge);
                const rodSupportsQuantity = state.furnitureComposition.rodGroupCollection.reduce((acc, rodGroup) => acc + rodGroup.quantity, 0);
                totalWeight += weightCalculator.weightFromGroupAndUnit({ quantity: rodSupportsQuantity }, state.selectedProducts.rodSupport);
                return totalWeight;
            }
            return 0;
        },
    },
    actions: {
        switchCameraMode() {
            switch (this.activeTab) {
                case TAB_STRUCTURE_INT:
                case TAB_FRONT:
                case TAB_ACCESSORIES:
                    if (this.cameraMode === CAMERA_DEFAULT) {
                        modules.camerasManager.controller.freeCamera();
                        this.cameraMode = CAMERA_SWITCH;
                    } else {
                        modules.camerasManager.controller.lockToFrontWithPerspective();
                        this.cameraMode = CAMERA_DEFAULT;
                    }
                    break;

                case TAB_BACK:
                    if (this.cameraMode === CAMERA_DEFAULT) {
                        modules.camerasManager.controller.freeCamera();
                        this.cameraMode = CAMERA_SWITCH;
                    } else {
                        modules.camerasManager.controller.lockToBackWithPerspective();
                        this.cameraMode = CAMERA_DEFAULT;
                    }
                    break;

                default:
                    break;
            }
        },
        setActiveTab(tab) {
            const hideMeasures = () => {
                if (this.shouldDisplayMeasures) this.toggleMeasures();
                events.emit("camera:eventsDispatcher:off");
            };

            events.emit("tool:deactivateTools");
            this.cameraMode = CAMERA_DEFAULT;

            const furnitureStore = useFurnitureStore();

            switch (tab) {
                case TAB_STRUCTURE_EXT:
                    hideMeasures();
                    modules.camerasManager.controller.freeCamera();
                    events.emit("tool:deactivateTools");
                    events.emit("doors:showAll");
                    this.displayDoors = true;
                    events.emit("overlaydrawer:showAll");
                    events.emit("insetdrawer:showAll");
                    events.emit("accessorydrawer:showAll");
                    events.emit("ui:maintab:changed", "exterior");
                    break;

                case TAB_STRUCTURE_INT:
                    events.emit("tool:board:update", {
                        toolModeName: TOOL_MODE_SELECT,
                    });
                    this.displayDoors = false;
                    modules.camerasManager.controller.lockToFrontWithPerspective();
                    events.emit("camera:eventsDispatcher:on");
                    events.emit("doors:hideAll");
                    events.emit("overlaydrawer:hideAll");
                    events.emit("insetdrawer:hideAll");
                    events.emit("accessorydrawer:hideAll");
                    events.emit("ui:maintab:changed", "interior");
                    break;

                case TAB_FRONT:
                    furnitureStore.resetCurrentDrawer();
                    events.emit("tool:deactivateTools");
                    hideMeasures();
                    modules.camerasManager.controller.lockToFrontWithPerspective();
                    events.emit("doors:showAll");
                    this.displayDoors = true;
                    events.emit("overlaydrawer:showAll");
                    events.emit("insetdrawer:showAll");
                    events.emit("accessorydrawer:showAll");
                    events.emit("camera:eventsDispatcher:on");
                    events.emit("ui:maintab:changed", "front");
                    break;

                case TAB_BACK:
                    events.emit("tool:deactivateTools");
                    hideMeasures();
                    modules.camerasManager.controller.lockToFrontWithPerspective();
                    events.emit("doors:hideAll");
                    this.displayDoors = false;
                    events.emit("overlaydrawer:hideAll");
                    events.emit("insetdrawer:hideAll");
                    events.emit("accessorydrawer:hideAll");
                    events.emit("camera:eventsDispatcher:on");
                    events.emit("ui:maintab:changed", "back");
                    break;

                case TAB_ACCESSORIES:
                    furnitureStore.resetCurrentDrawer();
                    hideMeasures();
                    modules.camerasManager.controller.lockToFrontWithPerspective();
                    events.emit("doors:hideAll");
                    this.displayDoors = false;
                    events.emit("overlaydrawer:hideAll");
                    events.emit("insetdrawer:hideAll");
                    events.emit("accessories:selectable");
                    events.emit("accessorydrawer:showAll");
                    events.emit("camera:eventsDispatcher:on");
                    events.emit("ui:maintab:changed", "accessories");
                    break;

                case TAB_FINISHING_TOUCHES:
                    events.emit("tool:deactivateTools");
                    hideMeasures();
                    modules.camerasManager.controller.freeCamera();
                    events.emit("doors:showAll");
                    events.emit("overlaydrawer:showAll");
                    events.emit("accessorydrawer:showAll");
                    events.emit("insetdrawer:showAll");
                    events.emit("ui:maintab:changed", "finishing");
                    break;

                default:
                    hideMeasures();
                    modules.camerasManager.controller.unlockCamera();
                    break;
            }
            this.activeTab = tab;
        },
        toggleMeasures() {
            if (this.shouldDisplayMeasures) {
                events.emit("measures:hideAll");
                events.emit("accessories:showAll");
                this.shouldDisplayMeasures = false;
            } else {
                events.emit("measures:showAll");
                events.emit("accessories:hideAll");
                this.shouldDisplayMeasures = true;
            }
        },
        showMeasures() {
            events.emit("measures:showAll");
            events.emit("accessories:hideAll");
            this.shouldDisplayMeasures = true;
        },
        toggleDoors() {
            if (this.displayDoors) {
                events.emit("doors:hideAll");
                this.displayDoors = false;
            } else {
                events.emit("doors:showAll");
                this.displayDoors = true;
            }
        },
        hideDoors() {
            events.emit("doors:hideAll");
            this.displayDoors = false;
        },
        setFurnitureComposition(furnitureComposition) {
            this.furnitureComposition = furnitureComposition;
        },
        setSelectedDoor(board) {
            this.selectedProducts.door = board;
        },
        setBordersCatalog(borderCatalog) {
            this.catalogs.borders = borderCatalog;
        },
        setButtonsCatalog(buttonsCatalog) {
            this.catalogs.buttons = buttonsCatalog;
        },
        setFeetCatalog(feetCatalog) {
            this.catalogs.feet = feetCatalog;
        },
        setDrawersCatalog(drawersCatalog) {
            this.catalogs.drawers = drawersCatalog;
        },
        setSelectedButton(button) {
            if (button?.product_ref === PUSH_BUTTON_DOOR_REF) {
                this.selectedProducts.doorButton = button;
                this.selectedProducts.drawerButton = this.catalogs.buttons.find((catalogButton) => catalogButton.product_ref === PUSH_BUTTON_DRAWER_REF);
            } else {
                this.selectedProducts.doorButton = button;
                this.selectedProducts.drawerButton = button;
            }
        },
        setSelectedFeet(feet) {
            this.selectedProducts.feet = feet;
        },
        setOrderBoardGroups(boardGroups) {
            this.order.boardGroups = boardGroups;
        },
        setBoardsCatalog(boardCatalog) {
            this.catalogs.boards = boardCatalog;
        },
        setAccessoriesCatalog(accessoriesCatalog) {
            this.catalogs.accessories = accessoriesCatalog;
        },
        setSelectedBoard(board) {
            this.selectedProducts.board = board;
        },
        setSelectedFront(board) {
            this.selectedProducts.front = board;
        },
        setSelectedBorder(board) {
            this.selectedProducts.border = board;
        },
        setSelectedHinge(hinge) {
            this.selectedProducts.hinge = hinge;
        },
        setSelectedRod(rod) {
            this.selectedProducts.rod = rod;
        },
        setSelectedRodSupport(rodSupport) {
            this.selectedProducts.rodSupport = rodSupport;
        },
        setDisplayPrice(displayPrice) {
            this.displayPrice = displayPrice;
        },
        hideAxes() {
            events.emit("axes:hide");
        },
        showAxes() {
            events.emit("axes:show");
        },
    },
});
