import BackboardMesh from "./backboard-mesh";

const BackboardMeshBuilder = {

    /**
     *
     * @param {BackboardEntity} backboardEntity
     * @param {TransformNode} parent : furnitureEntity.container
     */
    buildDefault(backboardEntity, parent) {
        backboardEntity.container.parent = parent;
        const backboardMesh = new BackboardMesh(backboardEntity);
        backboardMesh.parent = backboardEntity.container;
        backboardMesh.metadata = Object.assign(backboardMesh.metadata || {}, {
            backboardEntity: backboardEntity,
        });
        backboardEntity.mesh = backboardMesh;

        backboardMesh.linesSystem.parent = backboardEntity.container;
        return backboardMesh;
    },

};

export default BackboardMeshBuilder;
