import CameraHelper from "../../../cameras-manager/src/camera-helper";
import self from "../../index";
import FrameEntity from "../frame/frame-entity";

const {
    modules: {
        dataStore,
    },
} = self.app;

const RodUiButtons = {
    getAllButtons() {
        const buttons = [];
        dataStore.listEntities("rod").forEach((rodEntity) => {
            const parentIsFrame = rodEntity.parentEntity instanceof FrameEntity;
            if (parentIsFrame) {
                const center = rodEntity.getCenterAtFront();
                const center2D = CameraHelper.convert3DPointTo2D(center);
                buttons.push({
                    id: rodEntity.id,
                    x: center2D.x,
                    y: center2D.y,
                    buttons: [
                        {
                            deletable: true,
                            event: "rod:delete",
                        },
                    ],
                });
            }
        });
        return buttons;
    },
};

export default RodUiButtons;
