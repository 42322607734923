import {
    MeshBuilder,
} from "@babylonjs/core/Meshes/meshBuilder";
import {
    Vector3,
} from "@babylonjs/core/Maths/math.vector";
import self from "../../index";
import CubeFacesMesh from "../common/cube-faces-mesh";

class BaseboardMesh extends CubeFacesMesh {

    constructor(backboardEntity) {
        super(backboardEntity);
        this.addCustomWireframe();
    }

    addCustomWireframe() {
        const lines = [];
        // Front bottom
        lines.push(
            [
                new Vector3(0, 0, 0),
                new Vector3(1, 0, 0),
            ],
        );
        // back bottom
        lines.push(
            [
                new Vector3(0, 0, 1),
                new Vector3(1, 0, 1),
            ]
        );
        // back top
        lines.push(
            [
                new Vector3(0, 1, 1),
                new Vector3(1, 1, 1),
            ],
        );
        // back vertical left
        lines.push(
            [
                new Vector3(0, 0, 1),
                new Vector3(0, 1, 1),
            ],
        );
        // back vertical right
        lines.push(
            [
                new Vector3(1, 0, 1),
                new Vector3(1, 1, 1),
            ],
        );

        this.linesSystem = MeshBuilder.CreateLineSystem("lines", {
            lines: lines,
        });
        this.linesSystem.isPickable = false;
        this.linesSystem.color = self.config.boardLinesColor;
    }

    /**
     * Lines scaling and position are independant for having a bigger size than the mesh.
     * Without flickering
     * @param {Number} enlargeValue in mm
     */
    updateLinesSystem(enlargeValue = 1) {
        this.linesSystem.scaling = this.scaling.clone();
        this.linesSystem.position = this.position.clone();
        this.linesSystem.scaling.z += enlargeValue;
        this.linesSystem.position.z -= enlargeValue * 0.5;
    }

}

export default BaseboardMesh;
