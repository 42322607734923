import {
    MeshBuilder,
} from "@babylonjs/core/Meshes/meshBuilder";
import {
    Geometry,
} from "@babylonjs/core/Meshes/geometry";
import {
    Mesh,
} from "@babylonjs/core/Meshes/mesh";

class RodMesh extends Mesh {

    constructor(rodEntity) {
        super("rodMesh", undefined);
        RodMesh.getCylinderGeometry().copy().applyToMesh(this);
        this.metadata = {
            rodEntity,
        };
    }

    static getCylinderGeometry() {
        // Generate geometries once
        if (!RodMesh.hitBoxGeometry) {
            const box = MeshBuilder.CreateCylinder("hitBox", {
                height: 1,
            });
            box.rotation.z = 6.283 * 0.25;
            box.position.set(0.5, 0, 0);
            box.bakeCurrentTransformIntoVertices();
            RodMesh.hitBoxGeometry = Geometry.ExtractFromMesh(box, "hitBox");
            box.dispose();
        }
        return RodMesh.hitBoxGeometry;
    }

    static getHitBoxGeometry() {
        // Generate geometries once
        if (!RodMesh.hitBoxGeometry) {
            const box = MeshBuilder.CreateBox("hitBox", {});
            box.position.set(0.5, 0.5, 0.5);
            box.bakeCurrentTransformIntoVertices();
            RodMesh.hitBoxGeometry = Geometry.ExtractFromMesh(box, "hitBox");
            box.dispose();
        }
        return RodMesh.hitBoxGeometry;
    }

}
RodMesh.matcapMaterials = {};
export default RodMesh;
