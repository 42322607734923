import {
    Vector3,
} from "@babylonjs/core";
import self from "../../index";
import FrameHelper from "../frame/frame-helper";
import { BOARD_THICKNESS, BOTTOM, LEFT, PULLOUTSHELF_MARGIN_BOTTOM, RIGHT, TOP } from "../constants";
import CameraHelper from "../../../cameras-manager/src/camera-helper";

const {
    modules,
} = self.app;

const MeasureHelper = {

    getFrameMeasure(frameEntity, boardEntity) {
        const furnitureEntity = modules.dataStore.getEntity(frameEntity.furnitureId);
        const frameScale = frameEntity.getScaling();
        const frameCenter = frameEntity.getPosition().add(frameScale.scale(0.5)).add(furnitureEntity.position);

        let from = "";
        if (boardEntity) {
            if (boardEntity.isVertical()) {
                if (frameEntity.position.x < boardEntity.position.x) {
                    from = LEFT;
                } else {
                    from = RIGHT;
                }
            } else if (frameEntity.position.y < boardEntity.position.y) {
                from = BOTTOM;
            } else {
                from = TOP;
            }
        }

        const measure = {
            ...CameraHelper.convert3DPointTo2D(frameCenter),
            values: { width: frameScale.x, height: frameScale.y },
            frameEntity,
            from,
            event: "board:edit:distance",
        };

        return measure;
    },

    getMovableshelfMeasures(movableshelfEntity) {
        const furnitureEntity = modules.dataStore.getEntity(movableshelfEntity.furnitureId);
        const frameParent = modules.dataStore.getEntity(movableshelfEntity.idParent);
        const distanceTop = frameParent.position.y + frameParent.scaling.y - movableshelfEntity.position.y - BOARD_THICKNESS;
        const distanceBottom = movableshelfEntity.position.y - frameParent.position.y;

        const centerX = frameParent.position.x + frameParent.scaling.x * 0.5;
        const topCenter = new Vector3(
            centerX,
            frameParent.position.y + frameParent.scaling.y - distanceTop * 0.5,
            frameParent.position.z,
        ).add(furnitureEntity.position);

        const bottomCenter = new Vector3(
            centerX,
            frameParent.position.y + distanceBottom * 0.5,
            frameParent.position.z,
        ).add(furnitureEntity.position);

        return [
            Object.assign(
                CameraHelper.convert3DPointTo2D(topCenter),
                { values: { length: distanceTop },
                    entity: movableshelfEntity,
                    from: TOP,
                    event: "movableshelf:edit:distance" }
            ),
            Object.assign(
                CameraHelper.convert3DPointTo2D(bottomCenter),
                { values: { length: distanceBottom },
                    entity: movableshelfEntity,
                    from: BOTTOM,
                    event: "movableshelf:edit:distance" },
            ),
        ];
    },

    getPulloutshelfMeasures(pulloutshelfEntity) {
        const furnitureEntity = modules.dataStore.getEntity(pulloutshelfEntity.furnitureId);
        const frameParent = modules.dataStore.getEntity(pulloutshelfEntity.idParent);
        const distanceTop = frameParent.position.y + frameParent.scaling.y - pulloutshelfEntity.position.y - pulloutshelfEntity.bbox2.height;
        const distanceBottom = pulloutshelfEntity.position.y - frameParent.position.y + PULLOUTSHELF_MARGIN_BOTTOM;

        const centerX = frameParent.position.x + frameParent.scaling.x * 0.5;
        const topCenter = new Vector3(
            centerX,
            frameParent.position.y + frameParent.scaling.y - distanceTop * 0.5,
            frameParent.position.z,
        ).add(furnitureEntity.position);

        const bottomCenter = new Vector3(
            centerX,
            frameParent.position.y + distanceBottom * 0.5,
            frameParent.position.z,
        ).add(furnitureEntity.position);

        return [
            Object.assign(
                CameraHelper.convert3DPointTo2D(topCenter),
                {
                    values: { length: distanceTop },
                    from: TOP,
                    event: "pulloutshelf:edit:distance",
                },
            ),
            Object.assign(
                CameraHelper.convert3DPointTo2D(bottomCenter),
                {
                    values: { length: distanceBottom },
                    from: BOTTOM,
                    event: "pulloutshelf:edit:distance",
                }
            ),
        ];
    },

    getAllMeasures() {
        const {
            currentFurnitureEntity,
        } = self.furnituresListController;
        const currentFrame = modules.dataStore.getEntity(currentFurnitureEntity.idFrame);
        const frames = FrameHelper.getDescendantsUnsplittedFrames(currentFrame);
        const measures = [];
        if (frames.length > 0) {
            frames.forEach((frameEntity) => {
                measures.push({ ...MeasureHelper.getFrameMeasure(frameEntity), allFrames: true });
            });
        } else { // Add base frame if the is no splitting
            measures.push({ ...MeasureHelper.getFrameMeasure(currentFrame), allFrames: true });
        }
        return measures;
    },
};

export default MeasureHelper;
