<template>
    <svg id="arrow-right"
         xmlns="http://www.w3.org/2000/svg"
         width="8"
         height="14.176"
         viewBox="0 0 8 14.176"
         stroke="currentColor"
         fill="currentColor"
    >
        <desc>Une flèche vers la droite</desc>
        <path id="Tracé_131" data-name="Tracé 131" d="M31.978,32.3A1.416,1.416,0,0,1,31,29.879l4.635-4.635L31,20.608A1.42,1.42,0,1,1,33.008,18.6l5.614,5.717a1.4,1.4,0,0,1,0,2.009l-5.614,5.614A1.574,1.574,0,0,1,31.978,32.3Z" transform="translate(-30.575 -18.175)" />
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "chevron-right-icon",
});
</script>
