import {
    Mesh,
    BoundingBox,
    Vector3,
} from "@babylonjs/core";

const MeshUtility = {

    /**
     * Returns the objectEntity of a given mesh (if the objectEntity exists)
     * @param {Mesh} pickedMesh
     * @return {objectEntity} or returns null if no objectEntity is found or if the mesh has too many parents
     */
    getObjectEntityFromMesh(pickedMesh) {
        const rootParent = this.getRootParent(pickedMesh);

        if (rootParent.metadata && rootParent.metadata.objectEntity) {
            return rootParent.metadata.objectEntity;
        }

        return null;
    },

    /**
     * Returns the root parent (of type Mesh) of a given node
     * @param {TransformNode} node the current node of the hierarchy
     * @param {Number} loopSecurity number used in case there is a parent loop
     */
    getRootParent(node, loopSecurity = 0) {
        const security = loopSecurity + 1;
        if (loopSecurity > 1000) {
            throw new Error("The object has too many parents, or is stuck in a parent loop!");
        }
        // in addition to getting parent we want to exclude customTransformNode.
        // Aim si to prevent selecting gizmo which are set as parent of Mesh
        // Those gizmos are TransformNode as exported meshes could have a transform node parent
        if (node.parent && !node.parent.customTransformNode) {
            const nextParent = this.getRootParent(node.parent, security);
            if (nextParent) {
                return nextParent;
            }
            return node.parent;
        }

        if (node instanceof Mesh) {
            return node;
        }
        return null;
    },

    /**
     * Helper function that assign a mesh to an object entity
     * @param {BABYLON.Mesh} mesh the mesh we want to assign to the objectEntity
     * @param {entity} objectEntity the objectEntity we want to assign to the mesh
     */
    assignMeshToObjectEntity(mesh, objectEntity) {
        objectEntity.mesh = mesh;
        // carefull, here metadata is taken from a clone mesh,
        // it means by default there is an objectEntity which is the one cloned from root object
        // It's why it's important to reset metadata
        mesh.metadata = {
            objectEntity,
        };
        if (objectEntity.position) {
            mesh.position = objectEntity.position.clone();
        }
        if (objectEntity.rotationQuaternion) {
            mesh.rotationQuaternion = objectEntity.rotationQuaternion.clone();
        }
        if (objectEntity.scaling) {
            mesh.scaling = objectEntity.scaling.clone();
        }
        if (typeof objectEntity.visible !== "undefined") {
            mesh.setEnabled(objectEntity.visible);
        }
        mesh.computeWorldMatrix(true);
    },

    /**
     * Compute a bounding box gathering all the selected mesh on the scene
     * @return {BoundingBox}
     */
    computeMeshListBB(objectList) {
        if (objectList.length > 0) {
            const meshList = this.getAllChildMeshList(objectList);
            const minPoint = meshList[0].getBoundingInfo().boundingBox.minimumWorld.clone();
            const maxPoint = meshList[0].getBoundingInfo().boundingBox.maximumWorld.clone();
            let bb = new BoundingBox(minPoint, maxPoint);
            for (let i = 1; i < meshList.length; i += 1) {
                const currentBb = meshList[i].getBoundingInfo().boundingBox;
                bb = this.computeNewBB(bb, currentBb);
            }
            return bb;
        }
        return null;
    },

    /**
     * Gather all child mesh from an initial mesh
     * @param {Array<ObjectEntity} objectList
     */
    getAllChildMeshList(objectList) {
        let finalMeshList = [];
        for (let i = 0; i < objectList.length; i++) {
            finalMeshList = finalMeshList.concat(this.getAllChildMesh(objectList[i].mesh));
        }
        return finalMeshList;
    },

    /**
     * Gather all child mesh from an initial mesh
     * @param {BABYLON.Mesh} mesh the mesh we want to get children
     */
    getAllChildMesh(mesh) {
        const finalMeshList = [];
        if (mesh instanceof Mesh) {
            finalMeshList.push(mesh);
        }
        mesh.getChildMeshes(false).forEach((childMesh) => {
            if (childMesh instanceof Mesh) {
                finalMeshList.push(childMesh);
            }
        });
        return finalMeshList;
    },

    /**
     * Compute a bounding box including a new given bounding box
     * @param {BoundingBox} newBB the bounding box we want to include to the existing bounding box
     * @param {BoundingBox} refBB the original bounding box
     */
    computeNewBB(newBB, refBB) {
        const minPoint = Vector3.Minimize(newBB.minimumWorld, refBB.minimumWorld);
        const maxPoint = Vector3.Maximize(newBB.maximumWorld, refBB.maximumWorld);
        return new BoundingBox(minPoint, maxPoint);
    },

};

const TransformNodeUtil = {
    childrenVisible(transformNode, value) {
        transformNode.getDescendants().forEach((child) => {
            child.isVisible = value;
        });
    },
};

export default MeshUtility;
export { TransformNodeUtil };
