export default {

    name: "main",
    requires: [
        "obsidian-babylon-engine",
        "obsidian-mesh-manager",
        // "obsidian-light-manager",
        // "library",
        "project-manager",
        // "interactive-point",
        // "object-manager",
        // "material-applier",
        "wanadev-loader",
        "furnitures",
        "data-store",
        "history",
        "cameras-manager",
    ],

    load() {
        const MainController = require("./src/main-controller").default;
        this.mainController = new MainController();
        return {
            controller: this.mainController,
        };
    },

    unload() {},

};
