import cu from "vendors/class-utils";
import Object3DEntity from "../entity-mixins/object3D-entity";

const Entity = require("@obsidianjs/data-store/src/entity");

require("helpers/serializers/babylon-vector3");

/**
 * Backboard Entity in Furnitures module
 */
const BackboardEntity = Object3DEntity.$extend({

    __name__: "backboard",

    __init__(params = {}) {

        this.$data.isRemovable = params.isRemovable || false;

        this.mesh = null;

        this.$super(params);
    },

    updateMeshes() {
        if (!this.mesh) return;
        this.mesh.scaling = this.$data.scaling.clone();
        this.mesh.updateLinesSystem();
        this.mesh.updateUVScaling();
    },

    setIsRemovable(value) {
        this.$data.isRemovable = value;
        if (this.mesh) {
            this.mesh.linesSystem.isVisible = value;
        }
    },
    getIsRemovable: cu.getter,

});

Entity.$register(BackboardEntity);
export default BackboardEntity;
