<template>
    <transition v-if="loaderController" name="fade">
        <div
            v-if="loaderController && loaderController.isVisible"
            id="wanadev-loader-container"
            :class="loaderController.type === 'small' ? 'small' : 'fullscreen'"
        >
            <div class="loader-container play">
                <svg version="1.1" viewBox="0 0 35 25" xmlns="http://www.w3.org/2000/svg">
                    <g
                        fill="none"
                        class="logo-color"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-width="2.15"
                    >
                        <path id="stroke-1" d="m3.8889 6.1871v13.935" />
                        <path id="stroke-2" d="m8.4882 4.907v4.0652" />
                        <path id="stroke-2" d="m8.4882 11.904v5.9853" />
                        <path id="stroke-3" d="m13.088 3.5858v7.0661" />
                        <path id="stroke-3" d="m13.088 13.523v2.9843" />
                        <path id="stroke-4" d="m17.687 6.1705v11.341" />
                        <path id="stroke-5" d="m22.286 9.3419v1.9106" />
                        <path id="stroke-5" d="m22.286 13.929v4.5865" />
                        <path id="stroke-6" d="m26.885 11.462v4.0652" />
                        <path id="stroke-7" d="m30.956 10.249v1.5243" />
                    </g>
                </svg>
            </div>
        </div>
    </transition>
</template>

<script>
import self from "../..";

const { modules, events } = self.app;

export default {
    name: "wanadev-loader",
    data() {
        return {
            loaderController: null,
        };
    },
    mounted() {
        this.loaderController = modules.wanadevLoader.controller;
        events.on("@wanadev-loader.controller:update", (payload) => {
            this.loaderController = payload;
        });
    },
};

</script>

<style lang="scss">
    $loader-bg-color: purple;

    .fade-leave-active {
        -webkit-transition: opacity 0.7s ease-in-out;
        -moz-transition: opacity 0.7s ease-in-out;
        transition: opacity 0.7s ease-in-out;
    }
    .fade-leave-to {
        opacity: 0;
    }

    .small{
        background: none;
        .logo-color {
            stroke: purple;
        }
    }
    .fullscreen{
        background: $loader-bg-color;
        .logo-color {
            stroke: white;
        }
    }

    #wanadev-loader-container {
        height: 100vh;
        width: 100vw;
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 1000;

        .loader-container {
            width: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .loader-container svg {
            height: 100px;
        }

        .play #stroke-1 {
            animation: 1.3s opacity 0.2s infinite, 1.3s move 0.09s infinite;
        }

        .play #stroke-2 {
            animation: 1.3s opacity 0.4s infinite, 1.3s move 0.18s infinite;
        }

        .play #stroke-3 {
            animation: 1.3s opacity 0.6s infinite, 1.3s move 0.27s infinite;
        }

        .play #stroke-4 {
            animation: 1.3s opacity 0.8s infinite, 1.3s move 0.36s infinite;
        }

        .play #stroke-5 {
            animation: 1.3s opacity 1s infinite, 1.3s move 0.45s infinite;
        }

        .play #stroke-6 {
            animation: 1.3s opacity 1.2s infinite, 1.3s move 0.54s infinite;
        }

        .play #stroke-7 {
            animation: 1.3s opacity 1.4s infinite, 1.3s move 0.63s infinite;
        }

        @keyframes opacity {

            0%,
            100% {
                opacity: 1;
            }

            60% {
                opacity: 0.2;
            }
        }

        @keyframes move {

            0%,
            100% {
                transform: translateY(0);
            }

            60% {
                transform: translateY(-3%);
            }
        }
    }

</style>
