import {
    ORIENTATION_DEPTH,
    ORIENTATION_HORIZONTAL,
    ORIENTATION_NONE,
    ORIENTATION_VERTICAL,
} from "../constants";

const TransformHelper = {

    isFramesAreAlignedToLeftSide(frameEntity1, frameEntity2) {
        return frameEntity1.getPosition().x === frameEntity2.getPosition().x;
    },

    isFramesAreAlignedToRightSide(frameEntity1, frameEntity2) {
        return frameEntity1.getPositionAtRight() === frameEntity2.getPositionAtRight();
    },

    isFramesAreAlignedToBottomSide(frameEntity1, frameEntity2) {
        return frameEntity1.getPosition().y === frameEntity2.getPosition().y;
    },

    isFramesAreAlignedToTopSide(frameEntity1, frameEntity2) {
        return frameEntity1.getPositionAtTop() === frameEntity2.getPositionAtTop();
    },

    getScalingOrientation(scaling) {
        if (scaling.x !== 0) return ORIENTATION_HORIZONTAL;
        if (scaling.y !== 0) return ORIENTATION_VERTICAL;
        if (scaling.z !== 0) return ORIENTATION_DEPTH;
        return ORIENTATION_NONE;
    },

    isDecrease(value) {
        return value < 0;
    },

};
export default TransformHelper;
