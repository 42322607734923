import {
    ActionManager,
    ExecuteCodeAction,
} from "@babylonjs/core";

import BoardMeshBuilder from "./board-mesh-builder";
import {
    ORIENTATION_VERTICAL,
    TOOL_MODE_SELECT,
} from "../constants";
import BoardATool from "./board-atool";

import self from "../../index";
import BoardHelper from "./board-helper";
import BoardDisplacement from "./board-displacement";
import furnitureUiButtons from "../furniture/furniture-ui-buttons";

const {
    modules,
    events,
    config,
} = self.app;

const data = {
    atool: new BoardATool(),
    currentBoardEntity: null,
    debugTextureUV: null,
};

function getCursorForBoard(mesh) {
    if (mesh.orientation === ORIENTATION_VERTICAL) {
        return "ew-resize";
    }
    return "ns-resize";

}

const BoardController = {

    get atool() {
        return data.atool;
    },

    get currentBoardEntity() {
        return data.currentBoardEntity;
    },

    set currentBoardEntity(boardEntity) {
        BoardHelper.deselectCurrentBoardEntity();
        data.currentBoardEntity = boardEntity;

        if (!boardEntity) return;
        boardEntity.mesh.material.emissiveColor = config.get("selectedBoardColor");
        boardEntity.mesh.material.alpha = 1;

        boardEntity.mesh.setFacesVisible(false);
    },

    get debugTextureUV() {
        return data.debugTextureUV;
    },

    set debugTextureUV(value) {
        data.debugTextureUV = value;
    },

    generateBoardMeshAndMaterial(boardEntity) {
        const furnitureEntity = modules.dataStore.getEntity(boardEntity.getFurnitureId());
        const boardMesh = BoardMeshBuilder.buildDefault(boardEntity, furnitureEntity.container);
        boardMesh.isPickable = BoardController.isSelectActiveToolMode();
        if (furnitureUiButtons.isUiMainTabFinishing) {
            setTimeout(() => {
                BoardHelper.applyFinishMaterial(boardEntity);
            });
        } else {
            BoardHelper.applyConstructionMaterial(boardEntity);
        }

        if (!boardEntity.getIsBlockPart()) {
            BoardController.addMouseActionsToFrameMesh(boardEntity);
        }
    },

    /**
     * Add Mouse actions : over, out, pick
     * @param {BoardEntity} boardEntity
     * @returns
     */
    addMouseActionsToFrameMesh(boardEntity) {
        const {
            mesh,
        } = boardEntity;

        // Actions
        mesh.actionManager = new ActionManager();

        const OnPickDownTrigger = new ExecuteCodeAction(ActionManager.OnPickDownTrigger, () => {
            if (!BoardController.isActiveTool()) return;
            BoardController.currentBoardEntity = boardEntity;
            events.emit("board:selected", boardEntity);
            BoardDisplacement.start();
            modules.obsidianBabylonEngine.scene.hoverCursor = getCursorForBoard(mesh);
        });

        const OnPickUpTrigger = new ExecuteCodeAction(ActionManager.OnPickUpTrigger, () => {
            modules.obsidianBabylonEngine.scene.hoverCursor = "default";
        });

        const OnPointerOutTrigger = new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, () => {
            mesh.actionManager.unregisterAction(OnPointerOutTrigger);
            mesh.actionManager.unregisterAction(OnPickDownTrigger);
            mesh.actionManager.unregisterAction(OnPickUpTrigger);
        });

        const OnPointerOverTrigger = new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, () => {
            mesh.actionManager.registerAction(OnPointerOutTrigger);
            mesh.actionManager.registerAction(OnPickDownTrigger);
            mesh.actionManager.registerAction(OnPickUpTrigger);
            modules.obsidianBabylonEngine.scene.hoverCursor = getCursorForBoard(mesh);
        });

        mesh.actionManager.registerAction(OnPointerOverTrigger);

        return mesh;
    },

    isSelectActiveToolMode() {
        return BoardController.atool.isModeActive(TOOL_MODE_SELECT);
    },

    isActiveTool() {
        return BoardController.atool.tool_isActive;
    },

    switchMaterialToConstruction() {
        modules.dataStore.listEntities("board").forEach((boardEntity) => {
            BoardHelper.applyConstructionMaterial(boardEntity);
        });
    },

    switchMaterialsToFinition() {
        modules.dataStore.listEntities("board").forEach((boardEntity) => {
            BoardHelper.applyFinishMaterial(boardEntity);
        });
    },

};

export default BoardController;
