import MaterialManager from "../materials/material-manager";

const BackboardHelper = {
    applyConstructionMaterial(backboardEntity) {
        backboardEntity.mesh.material = MaterialManager.mainConstructionMaterial;
        backboardEntity.mesh.isVisible = true;
        backboardEntity.mesh.setFacesVisible(false);
    },

    applyFinishMaterial(backboardEntity) {
        const { mesh } = backboardEntity;
        const faces = [mesh.faceFront, mesh.faceBack];
        faces.forEach((face) => {
            face.material = MaterialManager.mainFinishMaterial;
        });
        mesh.isVisible = false;
        mesh.setFacesVisible(true);
        mesh.updateUVScaling();
    },
};

export default BackboardHelper;
