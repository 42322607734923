import DoorHelper from "../door/door-helper";

const data = {
    tweakpane: null,
    doorsFolder: null,
};
const DoorsTweaks = {
    addTweaks(pane) {
        data.tweakpane = pane;

        data.doorsFolder = pane.addFolder({
            title: "Doors",
        });

        data.doorsFolder.addButton({
            title: "hide doors",
        }).on("click", DoorHelper.hideAllDoors);

        data.doorsFolder.addButton({
            title: "show doors",
        }).on("click", DoorHelper.showAllDoors);
    },
};

export default DoorsTweaks;
