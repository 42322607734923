/* eslint-disable indent */
import {
    Vector3,
} from "@babylonjs/core";


import FrameController from "../frame/frame-controller";

import self from "../../index";
import {
    TOOL_MODE_SELECT,
    TOOL_MODE_SPLIT,
} from "../constants";
import FrameHelper from "../frame/frame-helper";

const {
    modules,
    events,
} = self.app;

class FramesTweaks {

    constructor() {
        if (!FramesTweaks.instance) FramesTweaks.instance = this;
        else throw new Error("FramesTweaks is a singleton. Use FramesTweaks.getInstance()");

        this.toolManager = modules.toolManager.controller;
    }

    addTweaks(pane) {
        this.tweakpane = pane;
        // tweak frames folder
        this.framesFolder = this.tweakpane.addFolder({
            title: "Frames",
            expanded: false,
        });

        // tweak frames params
        this.params = {
            debugFrames: false,
            debugSpacing: 0.26,
            debugAlpha: 0.1,
            currentFrameEntityParams: {
                scaling: Vector3.Zero(),
                position: Vector3.Zero(),
                id: "",
                idParent: "",
            },
        };

        this.toolModes = this.framesFolder.addBlade({
            view: "list",
            label: "frame tools",
            options: [{
                    text: "",
                    value: "",
                },
                {
                    text: "split",
                    value: TOOL_MODE_SPLIT,
                },
                {
                    text: "select",
                    value: TOOL_MODE_SELECT,
                },
            ],
            value: "",
        });
        this.toolModes.on("change", this.onToolModeChanged.bind(this));

        this.framesFolder.addInput(this.params, "debugFrames", {
            label: "show all frames",
        }).on("change", (event) => {
            const {
                currentFurnitureEntity,
            } = self.furnituresListController;
            FrameHelper.refreshDebugFrames(currentFurnitureEntity, event.value, this.params.debugSpacing, this.params.debugAlpha);
        });

        this.framesFolder.addInput(this.params, "debugSpacing", {
            label: "debug spacing",
            min: -0.5,
            max: 2,
        }).on("change", (event) => {
            const {
                currentFurnitureEntity,
            } = self.furnituresListController;
            FrameHelper.refreshDebugFrames(currentFurnitureEntity, this.params.debugFrames, event.value, this.params.debugAlpha);
        });

        this.framesFolder.addInput(this.params, "debugAlpha", {
            label: "debug alpha",
            min: 0,
            max: 1,
        }).on("change", (event) => {
            const {
                currentFurnitureEntity,
            } = self.furnituresListController;
            FrameHelper.refreshDebugFrames(currentFurnitureEntity, this.params.debugFrames, this.params.debugSpacing, event.value);
        });

        this.currentFrameFolder = this.tweakpane.addFolder({
            title: "Current Frame",
            expanded: false,
        });
        this.currentFrameFolder.addInput(this.params.currentFrameEntityParams, "id", {
            disabled: true,
        });
        this.currentFrameFolder.addInput(this.params.currentFrameEntityParams, "idParent", {
            disabled: true,
        });
        this.currentFrameFolder.addInput(this.params.currentFrameEntityParams, "scaling", {
            disabled: true,
        });
        this.currentFrameFolder.addInput(this.params.currentFrameEntityParams, "position", {
            disabled: true,
        });

        events.on("frame:selected", this.onFrameSelected.bind(this));
        events.on("frame:tool:endActive", this.onFrameToolEndActive.bind(this));
    }

    onToolModeChanged(event) {
        if (event.value === "") {
            if (!FrameController.isActiveTool()) return;

            this.toolManager.deactivateTool(FrameController.atool.tool_name);
            FrameController.atool.activateModeAlone(event.value);

        } else {
            this.toolManager.activateToolAlone(FrameController.atool.tool_name);
            FrameController.atool.activateModeAlone(event.value);
        }
    }

    onFrameToolEndActive() {
        this.toolModes.value = "";
    }

    onFrameSelected(frameEntityId) {
        this.frameEntity = modules.dataStore.getEntity(frameEntityId);
        this.params.currentFrameEntityParams.id = this.frameEntity.id;
        this.params.currentFrameEntityParams.idParent = this.frameEntity.getIdParent();
        this.params.currentFrameEntityParams.scaling = this.frameEntity.getScaling();
        this.params.currentFrameEntityParams.position = this.frameEntity.getPosition();
        FrameHelper.deselectCurrentFrame();
        FrameController.currentFrameEntity = this.frameEntity;
        FrameHelper.updateSelectedFrameDebug(this.frameEntity);

        if (this.parentBtn) this.parentBtn.dispose();
        if (this.side1Btn) this.side1Btn.dispose();
        if (this.side2Btn) this.side2Btn.dispose();
        if (this.logBtn) this.logBtn.dispose();

        if (this.frameEntity.getIdParent() !== "") {
            this.parentBtn = this.currentFrameFolder.addButton({
                    label: "parent",
                    title: "select",
                })
                .on("click", () => {
                    this.onFrameSelected(this.frameEntity.getIdParent());
                });
        }

        if (this.frameEntity.getIsSplitted()) {
            this.side1Btn = this.currentFrameFolder.addButton({
                    label: "side1",
                    title: "select",
                })
                .on("click", () => {
                    this.onFrameSelected(this.frameEntity.getIdFrameLeftOrBottom());
                });

            this.side2Btn = this.currentFrameFolder.addButton({
                    label: "side2",
                    title: "select",
                })
                .on("click", () => {
                    this.onFrameSelected(this.frameEntity.getIdFrameRightOrTop());
                });
        }
        this.logBtn = this.currentFrameFolder.addButton({
                title: "log entity",
            })
            .on("click", () => {
                window.selectedFrameEntity = this.frameEntity;
                console.log("window.selectedFrameEntity = ", this.frameEntity);
            });
        this.tweakpane.refresh();
    }




    static getInstance() {
        if (!FramesTweaks.instance) {
            return new FramesTweaks();
        }
        return FramesTweaks.instance;
    }

}

export default FramesTweaks;
