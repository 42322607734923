import Object3DEntity from "../entity-mixins/object3D-entity";

const Entity = require("@obsidianjs/data-store/src/entity");

require("helpers/serializers/babylon-vector3");

/**
 * Backboard Entity in Furnitures module
 */
const FootEntity = Object3DEntity.$extend({

    __name__: "foot",

    __init__(params = {}) {

        this.$data.isVisible = params.isVisible ? params.isVisible : true;

        this.mesh = null;

        this.$super(params);
    },

    updateMeshes() {
        if (!this.mesh) return;
        this.mesh.scaling = this.$data.scaling.clone();
    },

    getIsVisible() {
        if (this.mesh) {
            return this.mesh.isVisible;
        }

        return false;
    },

    setIsVisible(isVisible) {
        this.$data.isVisible = isVisible;
        if (this.mesh) {
            this.mesh.isVisible = isVisible;
        }
    },
});

Entity.$register(FootEntity);
export default FootEntity;
