import self from "../../index";
import RodController from "./rod-controller";

const {
    events,
} = self.app;

function onDeleteRod(params) {
    RodController.deleteRod(params.id);
}
const RodEventsManager = {
    addEventListeners() {
        events.on("@vuejs.rod:delete", onDeleteRod);
    },
};

export default RodEventsManager;
