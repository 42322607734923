import {
    MeshBuilder,
} from "@babylonjs/core/Meshes/meshBuilder";
import {
    Vector3,
} from "@babylonjs/core/Maths/math.vector";

import { StandardMaterial } from "@babylonjs/core";
import self from "../../index";
import CubeFacesMesh from "../common/cube-faces-mesh";

class PulloutshelfMesh extends CubeFacesMesh {

    constructor(pulloutshelfEntity) {
        super(pulloutshelfEntity, undefined);

        this.material = new StandardMaterial("hitBoxMat");
        this.material.alpha = 0;

        this.addCustomWireframe();
    }

    dispose() {
        super.dispose();
        this.linesSystem.dispose();
    }

    addCustomWireframe() {
        const lines = [];
        lines.push(
            // Represent bottom face
            [
                new Vector3(0, 0, 0),
                new Vector3(1, 0, 0),
                new Vector3(1, 0, 1),
                new Vector3(0, 0, 1),
                new Vector3(0, 0, 0),
            ],
            // Represent top face
            [
                new Vector3(0, 1, 0),
                new Vector3(1, 1, 0),
                new Vector3(1, 1, 1),
                new Vector3(0, 1, 1),
                new Vector3(0, 1, 0),
            ],
        );

        this.linesSystem = MeshBuilder.CreateLineSystem("lines", {
            lines: lines,
        });

        this.linesSystem.isVisible = self.config.boardLinesVisible;
        this.linesSystem.isPickable = false;
        this.linesSystem.color = self.config.pulloutshelfLinesColor;
    }

    updateLinesSystem(enlargeValue = 1) {
        this.linesSystem.scaling = this.scaling.clone();
        this.linesSystem.position = this.position.clone();
        this.linesSystem.scaling.y += enlargeValue;
        this.linesSystem.scaling.z -= enlargeValue * 2;
        this.linesSystem.position.z += enlargeValue;
    }

}

export default PulloutshelfMesh;
