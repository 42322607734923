import cu from "vendors/class-utils";
import defaultMaterialData from "../../../constants/defaultMaterialData";
import FurnituresListController from "./furnitures-list-controller";

const Entity = require("@obsidianjs/data-store/src/entity");

require("helpers/serializers/babylon-vector3");


/**
 * Furnitures list Entity module
 */
const FurnituresListEntity = Entity.$extend({

    __name__: "furnituresList",

    __init__(params) {

        this.$data.thickness = params && params.thickness ? params.thickness : 19;

        if (params && params.mainMaterialDatas) {
            this.$data.mainMaterialDatas = params.mainMaterialDatas;
        } else {
            this.$data.mainMaterialDatas = { ...defaultMaterialData };
        }

        if (params && params.borderMaterialDatas) {
            this.$data.borderMaterialDatas = params.borderMaterialDatas;
        } else {
            this.$data.borderMaterialDatas = { ...defaultMaterialData };
        }

        if (params && params.frontMaterialDatas) {
            this.$data.frontMaterialDatas = params.frontMaterialDatas;
        } else {
            this.$data.frontMaterialDatas = { ...defaultMaterialData };
        }

        this.$data.list = [];

        const furnituresListController = FurnituresListController.getInstance();
        furnituresListController.furnituresListEntity = this;
        this.$super(params);
    },

    setList(list) {
        this.$data.list = list;
    },

    getList() {
        return this.$data.list;
    },

    addFurnitureId(id) {
        this.$data.list.push(id);
    },

    getBorderMaterialDatas: cu.getter,
    setBorderMaterialDatas(params) {
        this.$data.borderMaterialDatas = params;
    },

    getMainMaterialDatas: cu.getter,
    setMainMaterialDatas(params) {
        this.$data.mainMaterialDatas = params;
    },

    getFrontMaterialDatas: cu.getter,
    setFrontMaterialDatas(params) {
        this.$data.frontMaterialDatas = params;
    },

});

Entity.$register(FurnituresListEntity);
export default FurnituresListEntity;
