<template>
    <svg id="icon-door-int-left"
         xmlns="http://www.w3.org/2000/svg"
         width="32"
         height="33.358"
         viewBox="0 0 32 33.358"
    >
        <path id="Soustraction_65"
              data-name="Soustraction 65"
              d="M29,32H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0h8.856L4.047,2H4A2,2,0,0,0,2,4V28a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2H25.477V0H29a3,3,0,0,1,3,3V29A3,3,0,0,1,29,32Zm-2-3.5H9.632l14.935-4.184a1.315,1.315,0,0,0,.91-1.219V3.5H27A1.5,1.5,0,0,1,28.5,5V27A1.5,1.5,0,0,1,27,28.5Z"
              transform="translate(32 32.001) rotate(180)"
              fill="#fff"
        />
        <path id="Tracé_293"
              data-name="Tracé 293"
              d="M188.346,458.589c-.065-.039,18.957-5.313,18.957-5.313l.014,24.013v.595l-18.971,5.166a.633.633,0,0,1-.819-.658V459.671A1.171,1.171,0,0,1,188.346,458.589Zm2.223,13.549a.633.633,0,1,0,1.266,0v-4.451a.633.633,0,1,0-1.266,0Z"
              transform="translate(-178.817 -449.718)"
              fill="#fff"
        />
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "door-left-inside-icon",
});
</script>
