export default {

    name: "project-manager",
    requires: ["data-store", "data-exporter", "history", "http-request"],

    load() {
        this.projectHelper = require("./src/project-helper").default;
        return {
            helper: this.projectHelper,
        };
    },

    unload() {},

};
