import cu from "vendors/class-utils";
import Object3DEntity from "../entity-mixins/object3D-entity";
import {
    ROD_LATERIAL_DISTANCE,
} from "../constants";


const Entity = require("@obsidianjs/data-store/src/entity");

require("helpers/serializers/babylon-vector3");

/**
 * Board Entity in Furnitures module
 */
const RodEntity = Object3DEntity.$extend({

    __name__: "rod",

    __init__(params = {}) {

        this.$data.isOrientedInDepth = Boolean(params.isOrientedInDepth);

        this.mesh = null;
        this.mesh2 = null;
        this.mesh3 = null;

        this.$super(params);
    },

    setFurnitureId: cu.setter,
    getFurnitureId: cu.getter,

    setIdParent: cu.setter,
    getIdParent: cu.getter,

    setIsOrientedInDepth(isOrientedInDepth) {
        this.$data.isOrientedInDepth = isOrientedInDepth;
        if (!this.container) return;
        if (isOrientedInDepth) {
            this.container.rotation.y = -(Math.PI / 2);
        } else {
            this.container.rotation.y = 0;
        }
    },
    getIsOrientedInDepth: cu.getter,

    updateMeshes() {
        if (!this.mesh) return;
        this.mesh.scaling = this.$data.scaling.clone();
        this.mesh2.position.x = ROD_LATERIAL_DISTANCE;
        this.mesh3.position.x = this.mesh.position.x + this.scaling.x - ROD_LATERIAL_DISTANCE;
    },

    dispose() {
        if (this.mesh) {
            this.mesh.dispose();
            this.mesh2.dispose();
            this.mesh3.dispose();
        }
    },

    getDimensions() {
        return [this.scaling.x];
    },

});

Entity.$register(RodEntity);
export default RodEntity;
