import {
    MeshBuilder,
} from "@babylonjs/core/Meshes/meshBuilder";
import {
    Geometry,
} from "@babylonjs/core/Meshes/geometry";
import {
    Mesh,
} from "@babylonjs/core/Meshes/mesh";
import { VertexBuffer, VertexData } from "@babylonjs/core";

let squareFaceMesh;

/**
 * Extends by all furnitures boards
 * CubeFacesMesh is a mesh with 6 children faces mesh (front, back, left, right, top, bottom)
 * It's more easy to manipulate each face uv and material
 * The pivot is at left front bottom corner of the cube
 * https://www.notion.so/wanadev/CubeFacesMesh-f427bee1143f4a2aa5b78483b7ded942
 * @class CubeFacesMesh
 */
class CubeFacesMesh extends Mesh {

    constructor(entity, params = { addFaces: true }) {
        super("boxMesh", undefined);

        this.applyGeometry();

        this.metadata = {
            entity,
        };
        if (params.addFaces) {
            this.addFaces();
        }
    }

    dispose() {
        super.dispose();
        this.linesSystem?.dispose();
    }

    setFacesVisible(value) {
        this.faceFront.isVisible = value;
        this.faceBack.isVisible = value;
        this.faceLeft.isVisible = value;
        this.faceRight.isVisible = value;
        this.faceTop.isVisible = value;
        this.faceBottom.isVisible = value;
    }

    setVisible(value) {
        this.isVisible = value;
        this.setFacesVisible(value);
        if (this.linesSystem) this.linesSystem.isVisible = value;
    }

    applyGeometry() {
        CubeFacesMesh.getBoxGeometry().copy().applyToMesh(this);
    }

    addFaces() {
        const faces = CubeFacesMesh.generateCubesFaces();
        this.faceFront = faces.front;
        this.faceBack = faces.back;
        this.faceLeft = faces.left;
        this.faceRight = faces.right;
        this.faceTop = faces.top;
        this.faceBottom = faces.bottom;

        this.addChild(this.faceFront);
        this.addChild(this.faceBack);
        this.addChild(this.faceLeft);
        this.addChild(this.faceRight);
        this.addChild(this.faceTop);
        this.addChild(this.faceBottom);
    }

    /**
     * Adjust homegeneous UV scaling and ratio for all submeshes
     */
    updateUVScaling() {
        const worldScale = 1.0;

        // left and right
        if (this.faceLeft && this.right) {
            const leftAndRightUV = this.faceLeft.getVerticesData(VertexBuffer.UVKind);
            if (this.scaling.y > this.scaling.z) {
                const scaleRatio = this.scaling.y / this.scaling.z;
                const worldRatio = (1000 / this.scaling.z) * worldScale;
                leftAndRightUV[2] = 1 / worldRatio;
                leftAndRightUV[6] = 1 / worldRatio;

                leftAndRightUV[3] = (1 * scaleRatio) / worldRatio;
                leftAndRightUV[5] = (1 * scaleRatio) / worldRatio;
            } else {
                const scaleRatio = this.scaling.z / this.scaling.y;
                const worldRatio = (1000 / this.scaling.y) * worldScale;
                leftAndRightUV[2] = (1 * scaleRatio) / worldRatio;
                leftAndRightUV[6] = (1 * scaleRatio) / worldRatio;

                leftAndRightUV[3] = 1 / worldRatio;
                leftAndRightUV[5] = 1 / worldRatio;
            }

            this.faceLeft.setVerticesData(VertexBuffer.UVKind, leftAndRightUV, true);
            this.faceRight.setVerticesData(VertexBuffer.UVKind, leftAndRightUV, true);
        }

        // top and bottom
        if (this.faceTop && this.faceBottom) {
            const topAndBottomUV = this.faceTop.getVerticesData(VertexBuffer.UVKind);
            if (this.scaling.x > this.scaling.z) {
                const scaleRatio = this.scaling.x / this.scaling.z;
                const worldRatio = (1000 / this.scaling.z) * worldScale;

                topAndBottomUV[2] = (1 * scaleRatio) / worldRatio;
                topAndBottomUV[6] = (1 * scaleRatio) / worldRatio;

                topAndBottomUV[3] = 1 / worldRatio;
                topAndBottomUV[5] = 1 / worldRatio;

            } else {
                const scaleRatio = this.scaling.z / this.scaling.x;
                const worldRatio = (1000 / this.scaling.x) * worldScale;

                topAndBottomUV[2] = 1 / worldRatio;
                topAndBottomUV[6] = 1 / worldRatio;

                topAndBottomUV[3] = (1 * scaleRatio) / worldRatio;
                topAndBottomUV[5] = (1 * scaleRatio) / worldRatio;
            }

            this.faceTop.setVerticesData(VertexBuffer.UVKind, topAndBottomUV, true);
            this.faceBottom.setVerticesData(VertexBuffer.UVKind, topAndBottomUV, true);
        }



        // front and back
        if (this.faceFront && this.faceBack) {
            const frontAndBackUV = this.faceFront.getVerticesData(VertexBuffer.UVKind);
            if (this.scaling.x > this.scaling.y) {
                const scaleRatio = this.scaling.y / this.scaling.x;
                const worldRatio = (1000 / this.scaling.x) * worldScale;
                frontAndBackUV[2] = 1 / worldRatio;
                frontAndBackUV[6] = 1 / worldRatio;

                frontAndBackUV[3] = (1 * scaleRatio) / worldRatio;
                frontAndBackUV[5] = (1 * scaleRatio) / worldRatio;
            } else {
                const scaleRatio = this.scaling.x / this.scaling.y;
                const worldRatio = (1000 / this.scaling.y) * worldScale;
                frontAndBackUV[2] = (1 * scaleRatio) / worldRatio;
                frontAndBackUV[6] = (1 * scaleRatio) / worldRatio;

                frontAndBackUV[3] = 1 / worldRatio;
                frontAndBackUV[5] = 1 / worldRatio;
            }
            this.faceFront.setVerticesData(VertexBuffer.UVKind, frontAndBackUV, true);
            this.faceBack.setVerticesData(VertexBuffer.UVKind, frontAndBackUV, true);
        }
    }

    updateUVAngle(frontUvScale, backUvScale, leftRightUvScale, bottomTopUvScale) {
        if (this.scaling.y < this.scaling.x) {
            const rotate90 = Math.PI / 2;
            leftRightUvScale.wAng = rotate90;
            bottomTopUvScale.wAng = rotate90;
            frontUvScale.wAng = rotate90;
            backUvScale.wAng = rotate90;
        }
    }

    /**
     * Lines scaling and position are independant for having a bigger size than the mesh.
     * Without flickering
     * @param {Number} enlargeValue in mm
     */
    updateLinesSystem(enlargeValue = 1) {
        if (!this.linesSystem) return;
        this.linesSystem.scaling = this.scaling.clone();
        this.linesSystem.position = this.position.clone();
        this.linesSystem.scaling.z += enlargeValue;
        this.linesSystem.position.z -= enlargeValue * 0.5;
    }

    static generateCubesFaces() {
        const front = this.getSquareFace();

        const back = this.getSquareFace();
        back.rotation.y = Math.PI;
        back.position.set(1, 0, 1);

        const left = this.getSquareFace();
        left.rotation.y = Math.PI / 2;
        left.position.set(0, 0, 1);

        const right = this.getSquareFace();
        right.rotation.y = -Math.PI / 2;
        right.position.set(1, 0, 0);

        const top = this.getSquareFace();
        top.rotation.x = Math.PI / 2;
        top.position.set(0, 1, 0);

        const bottom = this.getSquareFace();
        bottom.rotation.x = -Math.PI / 2;
        bottom.position.set(0, 0, 1);

        return {
            front,
            back,
            left,
            right,
            top,
            bottom,
        };
    }

    static getSquareFace() {
        // if (!squareFaceMesh) {
        squareFaceMesh = new Mesh("custom");

        // square position and indices
        const positions = [
            0, 0, 0,
            1, 1, 0,
            0, 1, 0,
            1, 0, 0,
        ];
        const indices = [0, 1, 2, 0, 3, 1];
        const uvs = [
            0, 0, // 0
            1, 1, // 1
            0, 1, // 2
            1, 0, // 3
        ];

        const normals = [];
        VertexData.ComputeNormals(positions, indices, normals);

        const vertexData = new VertexData();

        vertexData.positions = positions;
        vertexData.indices = indices;
        vertexData.normals = normals;
        vertexData.uvs = uvs;

        vertexData.applyToMesh(squareFaceMesh, true);
        // squareFaceMesh.setVerticesData(VertexBuffer.UVKind, uvs, true);
        squareFaceMesh.isPickable = false;
        return squareFaceMesh;
        // }
        // return squareFaceMesh.clone("squareFace");
    }

    static getBoxGeometry() {
        // Generate geometries once
        if (!CubeFacesMesh.hitBoxGeometry) {
            const box = MeshBuilder.CreateBox("hitBox", {});
            box.position.set(0.5, 0.5, 0.5);
            box.bakeCurrentTransformIntoVertices();
            CubeFacesMesh.hitBoxGeometry = Geometry.ExtractFromMesh(box, "hitBox");
            box.dispose();
        }
        return CubeFacesMesh.hitBoxGeometry;
    }

}

export default CubeFacesMesh;
