import self from "../index";
import Axis from "./axis";
import FurnitureEventsManager from "./furniture/furniture-event-managers";
import FurnituresListController from "./furnitures-list-controller";

const {
    events,
    modules,
} = self.app;

const data = {
    listenersAdded: false,
};

function onProjectOpening() {
    modules.toolManager.controller.deactivateAllTools();
    FurnitureEventsManager.emitFurnitureCompositionUpdated();
}

function onUpdateMainMaterialDatas(payload) {
    FurnituresListController.getInstance().updateMainMaterialDatas(payload);
    FurnitureEventsManager.emitFurnitureCompositionUpdated();
}

function onUpdateBorderMaterialDatas(payload) {
    FurnituresListController.getInstance().updateBorderMaterialDatas(payload);
    FurnitureEventsManager.emitFurnitureCompositionUpdated();
}

function onUpdateFrontMaterialDatas(payload) {
    FurnituresListController.getInstance().updateFrontMaterialDatas(payload);
    FurnitureEventsManager.emitFurnitureCompositionUpdated();
}

function onUiMaintabChanged(tabName) {
    FurnituresListController.onUiMaintabChanged(tabName);
}

function OnHideAxes() {
    Axis.getInstance().hide();
}

function OnShowAxes() {
    Axis.getInstance().show();
}

function onProjectOpened() {
    FurnituresListController.getInstance().updateAllMaterialsFromFurnituresListEntity();
    modules.obsidianBabylonEngine.scene.onAfterRenderObservable.addOnce(() => {
        modules.camerasManager.controller.focusOnMeshes();
    });
}

const FurnituresEventsManager = {
    addEventListeners() {
        if (data.listenersAdded) return;
        events.on("@vuejs.project:opening", onProjectOpening);
        events.on("@project-manager.opened", onProjectOpened);
        events.on("@vuejs.materialDatas:main:update", onUpdateMainMaterialDatas);
        events.on("@vuejs.materialDatas:border:update", onUpdateBorderMaterialDatas);
        events.on("@vuejs.materialDatas:front:update", onUpdateFrontMaterialDatas);

        events.on("@vuejs.axes:hide", OnHideAxes);
        events.on("@vuejs.axes:show", OnShowAxes);

        events.on("@vuejs.ui:maintab:changed", onUiMaintabChanged);
        data.listenersAdded = true;
    },
};

export default FurnituresEventsManager;
