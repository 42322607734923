import {
    Mesh,
} from "@babylonjs/core/Meshes/mesh";
import {
    MeshBuilder,
} from "@babylonjs/core/Meshes/meshBuilder";
import {
    Geometry,
} from "@babylonjs/core/Meshes/geometry";
import {
    Color3,
    StandardMaterial,
} from "@babylonjs/core";

import self from "../../index";

// config
const {
    config,
} = self.app;

/**
 * 2 sides geometries : front and back
 */
class FrameMesh extends Mesh {

    constructor(name, parent) {
        super(name, parent);

        FrameMesh.getGeometry().copy().applyToMesh(this);

        if (config.get("debugFrames")) {
            this.debugMesh = MeshBuilder.CreatePlane("frameMesh", {});
            this.debugMesh.material = new StandardMaterial("debugMesh");
            this.debugMesh.material.alpha = 0;
            this.debugMesh.material.diffuseColor = Color3.Random();
            this.debugMesh.metadata = {
                defaultDiffuseColor: this.debugMesh.material.diffuseColor.clone(),
                highlightDiffuseColor: Color3.Red(),
                lastAlpha: this.debugMesh.material.alpha,
            };
            this.debugMesh.isVisible = false;
            this.debugMesh.showBoundingBox = true;
            this.debugMesh.isPickable = false;
            this.addChild(this.debugMesh);
        }

        this.position.set(0.5, 0.5, 0);
        this.bakeCurrentTransformIntoVertices();
        this.metadata = {};
    }

    /**
     * Generate geometries once
     * @returns
     */
    static getGeometry() {
        if (!FrameMesh.frameFeometry) {
            const frontMesh = MeshBuilder.CreatePlane("frameMesh", {});
            const backMesh = MeshBuilder.CreatePlane("frameMesh", {});
            backMesh.scaling.z = -1;
            backMesh.position.z = 1;

            const mergedMesh = Mesh.MergeMeshes([frontMesh, backMesh]);
            FrameMesh.frameFeometry = Geometry.ExtractFromMesh(mergedMesh, "frameGeometry");
            mergedMesh.dispose();
        }
        return FrameMesh.frameFeometry;
    }

}

export default FrameMesh;
