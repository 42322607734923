import { WEIGHT_UNITS } from "./constants";

function getWeightPerSquareMeter(product) {
    return product.product_weight / ((product.product_width / 1000) * (product.product_length / 1000));
}

function getWeightPerMeter(product) {
    return product.product_weight / (product.product_length / 1000);
}

function weightFromGroupAndUnit(group, product) {
    const [height, width, length] = group.dimensions ? group.dimensions.map((dimension) => (dimension ? dimension / 1000 : 0)) : [0, 0, 0];
    if (product) {
        switch (product.product_weight_unit) {
            case WEIGHT_UNITS.LINEAR_METER:
                return height * getWeightPerMeter(product) * group.quantity;
            case WEIGHT_UNITS.KILOGRAM_PER_SQUARE_METER:
                return length * width * getWeightPerSquareMeter(product) * group.quantity;
            case WEIGHT_UNITS.UNIT_WEIGHT:
                return product.product_weight * group.quantity;
            default:
                return 0;
        }
    }
    return 0;
}

function getProductGroupsWeightFromCatalog(groups, catalog) {
    return groups.reduce((total, group) => {
        const selectedProduct = catalog.find((product) => product.product_ref === group.ref);
        const weight = weightFromGroupAndUnit(group, selectedProduct);
        return total + weight;
    }, 0);
}

export default { getProductGroupsWeightFromCatalog, weightFromGroupAndUnit };
