import ATool from "../../../tool-manager/src/atool";
import {
    TOOL_MODE_SELECT,
} from "../constants";

import self from "../../index";
import FrameHelper from "../frame/frame-helper";
import BoardController from "./board-controller";

const {
    modules,
    events,
} = self.app;

class BoardATool extends ATool {

    constructor() {
        super({
            tool_name: "board",
        });
        this.tool_modes = [TOOL_MODE_SELECT];
        modules.toolManager.controller.registerTool(this);
    }

    // Atool method
    // eslint-disable-next-line class-methods-use-this
    onActiveCallback() {
        const allBoards = modules.dataStore.listEntities("board");
        allBoards.forEach((boardEntity) => {
            if (!boardEntity.isBlockPart) {
                boardEntity.mesh.isPickable = true;
            }
        });
    }

    // Atool method
    // eslint-disable-next-line class-methods-use-this
    onEndActiveCallback() {
        const allBoards = modules.dataStore.listEntities("board");
        allBoards.forEach((boardEntity) => {
            boardEntity.mesh.isPickable = false;
        });
        BoardController.currentBoardEntity = null;
        FrameHelper.hideAllFrames();
        events.emit("board:tool:endActive");
    }

}

export default BoardATool;
