import ConstraintsManager from "../constraints-manager";
import OverlaydrawerEntityBuilder from "./overlaydrawer-entity-builder";

import self from "../../index";
import OverlaydrawerEventsManager from "./overlaydrawer-events-manager";
import OverlaydrawerATool from "./overlaydrawer-atool";
import constraints, { DRAWER_CONSTRAINTS } from "../constraints";
import OverlayHelper from "../overlay/overlay-helper";
import OverlaydrawerHelper from "./overlaydrawer-helper";
import {
    FRONT_MODE_OUTSIDE,
    OVERLAY_DRAWER,
} from "../constants";
import FurnitureHelper from "../furniture/furniture-helper";
import { KNOB_INSTANCE_NAME } from "../knob/knob-factory";
import DrawerMeshBuilder from "../drawer/drawer-mesh-builder";
import DrawerHelper from "../drawer/drawer-helper";
import furnitureUiButtons from "../furniture/furniture-ui-buttons";
import MaterialManager from "../materials/material-manager";
import DrawerRunnerHelper from "../drawer/drawer-runner-helper";

const {
    events,
    modules,
} = self.app;

const data = {
    atool: new OverlaydrawerATool(),
    currentOverlaydrawerEntity: null,
};

const OverlaydrawerController = {

    get atool() {
        return data.atool;
    },

    get currentOverlaydrawerEntity() {
        return data.currentOverlaydrawerEntity;
    },

    set currentOverlaydrawerEntity(overlaydrawerEntity) {
        data.currentOverlaydrawerEntity = overlaydrawerEntity;
    },

    createDrawer(frameEntity, drawerRunnerType) {
        const furnitureEntity = modules.dataStore.getEntity(frameEntity.getFurnitureId());
        if (!FurnitureHelper.isValidFurnitureFrontMode(furnitureEntity, FRONT_MODE_OUTSIDE)) {
            return;
        }

        const canAddDrawer = ConstraintsManager.canAddDrawer(frameEntity);
        if (!canAddDrawer.isAdapted) {
            events.emit("show:modal", {
                text: DRAWER_CONSTRAINTS.message,
                isInfo: true,
            });
            return;
        }

        if (!DrawerRunnerHelper.canAddToFrame(frameEntity, OVERLAY_DRAWER, drawerRunnerType)) {
            return;
        }

        if (!DrawerHelper.canAddToFrame(frameEntity)) return;

        furnitureEntity.frontMode = FRONT_MODE_OUTSIDE;

        const overlaydrawerEntity = OverlaydrawerEntityBuilder.build(frameEntity, drawerRunnerType);
        overlaydrawerEntity.quantity = DrawerHelper.getMinimumQuantity(overlaydrawerEntity, DRAWER_CONSTRAINTS);

        frameEntity.idDrawer = overlaydrawerEntity.id;

        modules.dataStore.addEntity(overlaydrawerEntity, "overlaydrawer");
        events.emit("furniture:edited");

        OverlaydrawerController.selectOverlaydrawer(overlaydrawerEntity);
        furnitureUiButtons.updateButtons();
    },

    generateDrawerMeshAndMaterial(overlaydrawerEntity) {
        const furnitureEntity = modules.dataStore.getEntity(overlaydrawerEntity.getFurnitureId());
        DrawerMeshBuilder.buildDefault(overlaydrawerEntity, furnitureEntity.container);

        OverlaydrawerHelper.updatePosition(overlaydrawerEntity);
        OverlaydrawerHelper.updateScaling(overlaydrawerEntity);

        OverlayHelper.detectOverlaysNeighbors();
        DrawerHelper.updateMeshScaleAndPosition(overlaydrawerEntity);

        if (furnitureUiButtons.isUiMainTabFinishing) {
            DrawerHelper.applyFinishMaterial(overlaydrawerEntity);
        } else {
            DrawerHelper.applyConstructionMaterial(overlaydrawerEntity);
        }

    },

    deleteDrawer(frameEntity) {
        const overlaydrawerEntity = modules.dataStore.getEntity(frameEntity.idDrawer);
        if (!overlaydrawerEntity) return;

        frameEntity.idDrawer = null;

        modules.dataStore.removeEntity(overlaydrawerEntity);
        OverlayHelper.detectOverlaysNeighbors();
        OverlayHelper.updateFurnitureFrontMode(frameEntity);

        events.emit("furniture:edited");
        OverlaydrawerEventsManager.emitUpdated();
        furnitureUiButtons.updateButtons();
        events.emit("drawer:deleted");
    },

    selectOverlaydrawerById(id) {
        const overlaydrawerEntity = modules.dataStore.getEntity(id);
        if (!overlaydrawerEntity) return;

        OverlaydrawerController.selectOverlaydrawer(overlaydrawerEntity);
    },


    selectOverlaydrawer(overlaydrawerEntity) {
        if (data.currentOverlaydrawerEntity) {
            DrawerHelper.applyConstructionMaterial(data.currentOverlaydrawerEntity);
        }
        data.currentOverlaydrawerEntity = overlaydrawerEntity;
        if (!overlaydrawerEntity) return;
        overlaydrawerEntity.mesh.material = MaterialManager.selectedMaterial;
        modules.toolManager.controller.activateToolAlone(data.atool.tool_name);
        OverlaydrawerEventsManager.emitUpdated();
    },

    updateQuantity(quantity) {
        if (!data.currentOverlaydrawerEntity) return;
        const frameEntity = modules.dataStore.getEntity(data.currentOverlaydrawerEntity.idParent);
        const height = Math.ceil(frameEntity.scaling.y / quantity);
        if (height >= constraints.MIN_DRAWER_HEIGHT.min && height <= constraints.MAX_DRAWER_HEIGHT.max) {
            data.currentOverlaydrawerEntity.quantity = quantity;
            DrawerHelper.updateMeshScaleAndPosition(data.currentOverlaydrawerEntity);
            events.emit("furniture:edited");
            OverlaydrawerEventsManager.emitUpdated();
        } else {
            OverlaydrawerEventsManager.emitRejected(height <= constraints.MIN_DRAWER_HEIGHT.min);
        }
    },

    getCurrentOverlaydrawerEntityCopyForUi() {
        if (!data.currentOverlaydrawerEntity) {
            return {
                id: null,
                quantity: null,
                unitUtilSize: null,
            };
        }
        return {
            id: data.currentOverlaydrawerEntity.id,
            quantity: data.currentOverlaydrawerEntity.quantity,
            unitUtilSize: data.currentOverlaydrawerEntity.unitUtilSize,
        };
    },

    hideAll() {
        const overlaydrawerEntities = modules.dataStore.listEntities("overlaydrawer");
        for (let i = 0; i < overlaydrawerEntities.length; i++) {
            const overlaydrawerEntity = overlaydrawerEntities[i];
            overlaydrawerEntity.isVisible = false;
            overlaydrawerEntity.mesh.setVisible(false);
            overlaydrawerEntity.groupInstances.forEach((group) => {
                group.getChildMeshes().forEach((mesh) => {
                    mesh.isVisible = false;
                });
            });
        }
    },

    showAll() {
        const overlaydrawerEntities = modules.dataStore.listEntities("overlaydrawer");
        for (let i = 0; i < overlaydrawerEntities.length; i++) {
            const overlaydrawerEntity = overlaydrawerEntities[i];
            overlaydrawerEntity.isVisible = true;
            overlaydrawerEntity.mesh.setVisible(true);
            overlaydrawerEntity.groupInstances.forEach((group, index) => {
                group.getChildMeshes().forEach((mesh) => {
                    const isVisibleByQuantity = index < overlaydrawerEntity.quantity;
                    if (mesh.name === KNOB_INSTANCE_NAME) {
                        mesh.isVisible = isVisibleByQuantity && overlaydrawerEntity.furnitureEntity.knobVisible;
                    } else {
                        mesh.isVisible = isVisibleByQuantity;
                    }
                });
            });
        }
    },

    clearAllFromFurnitureId(furnitureId) {
        const overlaydrawerEntities = modules.dataStore.listEntities("overlaydrawer");
        overlaydrawerEntities.forEach((overlaydrawerEntity) => {
            if (overlaydrawerEntity.getFurnitureId() === furnitureId) {
                const frameEntity = modules.dataStore.getEntity(overlaydrawerEntity.idParent);
                frameEntity.idDrawer = null;
                modules.dataStore.removeEntity(overlaydrawerEntity);
            }
        });
    },

    switchMaterialToConstruction() {
        modules.dataStore.listEntities("overlaydrawer").forEach((overlaydrawerEntity) => {
            DrawerHelper.applyConstructionMaterial(overlaydrawerEntity);
        });
    },

    switchMaterialsToFinition() {
        modules.dataStore.listEntities("overlaydrawer").forEach((overlaydrawerEntity) => {
            DrawerHelper.applyFinishMaterial(overlaydrawerEntity);
        });
    },

    updateFromHistoryChange() {
        modules.dataStore.listEntities("overlaydrawer").forEach((overlaydrawerEntity) => {
            OverlaydrawerHelper.updatePosition(overlaydrawerEntity);
            OverlaydrawerHelper.updateScaling(overlaydrawerEntity);
            DrawerHelper.updateMeshScaleAndPosition(overlaydrawerEntity);
        });
    },
};

export default OverlaydrawerController;
