<template>
    <svg v-if="isColored"
         xmlns="http://www.w3.org/2000/svg"
         width="19"
         height="29"
         viewBox="0 0 19 29"
    >
        <g id="icon-door-color" transform="translate(-220.158 -769.47)">
            <rect id="Rectangle_684"
                  data-name="Rectangle 684"
                  width="4"
                  height="2"
                  rx="1"
                  transform="translate(232.158 784.47)"
                  fill="#e83e8c"
            />
            <path fill="currentColor" id="Soustraction_82" data-name="Soustraction 82" d="M664-4107H651a3,3,0,0,1-3-3v-23a3,3,0,0,1,3-3h13a3,3,0,0,1,3,3v23A3,3,0,0,1,664-4107Zm-13.1-27.128a1,1,0,0,0-1,1v23.258a1,1,0,0,0,1,1h13.2a1,1,0,0,0,1-1v-23.258a1,1,0,0,0-1-1Z" transform="translate(-427.842 4905.47)" />
        </g>
    </svg>
    <svg v-else
         xmlns="http://www.w3.org/2000/svg"
         width="23.221"
         height="33.517"
         viewBox="0 0 23.221 33.517"
    >
        <g id="icon-door-white" transform="translate(-257.389 -766.711)">
            <rect id="Rectangle_670"
                  data-name="Rectangle 670"
                  width="4"
                  height="2"
                  rx="1"
                  transform="translate(272.158 784.47)"
                  fill="#fff"
            />
            <path id="Soustraction_81"
                  data-name="Soustraction 81"
                  d="M669.768-4104.242h-13a3,3,0,0,1-3-3v-.25l1.9-2.925v3.3a1,1,0,0,0,1,1h13.2a1,1,0,0,0,1-1v-23.258a1,1,0,0,0-1-1h-.593l1.129-1.739c.012-.019.024-.038.035-.057a3.023,3.023,0,0,1,2.328,2.924v23A3,3,0,0,1,669.768-4104.242Zm-16-8.757v-17.242a3,3,0,0,1,3-3h10.146l-1.215,1.872h-9.03a1,1,0,0,0-1,1v14.446l-1.9,2.922Z"
                  transform="translate(-393.611 4902.711)"
                  fill="#fff"
            />
            <rect id="Rectangle_677"
                  data-name="Rectangle 677"
                  width="3"
                  height="38.016"
                  rx="1.5"
                  transform="translate(278.095 766.711) rotate(33)"
                  fill="#fff"
            />
        </g>
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "door-icon",
    props: {
        isColored: {
            type: Boolean,
            default: false,
        },
    },
});
</script>
