<template>
    <svg
        id="arrow-right"
        width="8"
        height="14.176"
        viewBox="0 0 8 14.176"
        stroke="currentColor"
        fill="currentColor"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:dc="http://purl.org/dc/elements/1.1/"
    >
        <defs
            id="defs10"
        />
        <title
            id="title2"
        >Chevron vers la droite</title>
        <desc
            id="desc4"
        >Une flèche vers la droite</desc>
        <path
            id="Tracé_131"
            data-name="Tracé 131"
            d="m -2.8310317,4.2297008 a 1.416,1.416 0 0 1 2.42099899,-0.978 l 4.63500011,4.635 4.6359997,-4.635 a 1.42,1.42 0 1 1 2.0080009,2.008 L 5.1519674,10.8737 a 1.4,1.4 0 0 1 -2.009,0 L -2.4710317,5.2597008 a 1.574,1.574 0 0 1 -0.36,-1.03 z"
        />
        <metadata
            id="metadata160"
        >
            <rdf:RDF>
                <cc:Work
                    rdf:about=""
                >
                    <dc:title>Chevron vers la droite</dc:title>
                </cc:Work>
            </rdf:RDF>
        </metadata>
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "chevron-down-icon",
});
</script>
