import { TransformNode } from "@babylonjs/core";
import constraints from "../constraints";
import KnobFactory from "../knob/knob-factory";
import DrawerMesh from "./drawer-mesh";
import DrawerSeparateLine from "./drawer-separate-line";

const DrawerMeshBuilder = {

    /**
     *
     * @param {InsetdrawerEntity | OverlayDrawerEntity} drawerEntity
     * @param {TransformNode} parent : furnitureEntity.container
     */
    buildDefault(drawerEntity, parent) {
        drawerEntity.container.parent = parent;
        const drawerMesh = new DrawerMesh(drawerEntity);
        drawerMesh.parent = drawerEntity.container;
        drawerMesh.metadata = Object.assign(drawerMesh.metadata || {}, {
            drawerEntity,
        });
        drawerMesh.isPickable = false;
        drawerEntity.mesh = drawerMesh;

        drawerMesh.linesSystem.parent = drawerEntity.container;

        drawerEntity.addMeshRef(DrawerSeparateLine.getRef());
        DrawerMeshBuilder.createGroupWithInstances(drawerEntity);

        return drawerMesh;
    },

    /**
     *
     * @param {InsetdrawerEntity | OverlayDrawerEntity} drawerEntity
     */
    createGroupWithInstances(drawerEntity) {
        const max = Math.ceil(constraints.MAX_FRAME_HEIGHT.max / constraints.MIN_DRAWER_HEIGHT.min);
        for (let i = 0; i < max; i++) {
            const group = new TransformNode("drawer_group_" + i);
            group.parent = drawerEntity.container;

            for (let j = 0; j < drawerEntity.meshesRef.length; j++) {
                const meshRef = drawerEntity.meshesRef[j];
                const instantanceMesh = meshRef.createInstance(`drawer_${meshRef.name}_instance_${i}`);
                instantanceMesh.scaling = meshRef.scaling;
                instantanceMesh.position = meshRef.position;
                instantanceMesh.parent = group;
                meshRef.isVisible = false;
            }

            drawerEntity.addKnob(KnobFactory.createInstance(), group);
            drawerEntity.groupInstances.push(group);
        }
    },
};

export default DrawerMeshBuilder;
