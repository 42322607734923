<template>
    <div class="pl-6">
        <InfoLabel>
            {{ t("Faites glisser un élément sur la scène") }}
        </InfoLabel>
        <div class="flex mt-2">
            <DraggableCard
                :start-drag-events="[{eventName:'tool:frame:update', params:{toolModeName:TOOL_MODE_BACKBOARD}}]"
                :drop-events="[{eventName:'frame:action:backboard', params:{type:BACKBOARD_NORMAL}}]"
                :end-drag-events="[{eventName:'tool:frame:update', params:{toolModeName:''}}]"
            >
                <BackFixedIcon />
                <template #text>{{ t("Dos\nfixe") }}</template>
            </DraggableCard>

            <DraggableCard
                :start-drag-events="[{eventName:'tool:frame:update', params:{toolModeName:TOOL_MODE_BACKBOARD}}]"
                :drop-events="[{eventName:'frame:action:backboard', params:{type:BACKBOARD_REMOVABLE}}]"
                :end-drag-events="[{eventName:'tool:frame:update', params:{toolModeName:''}}]"
            >
                <BackMoveIcon />
                <template #text>{{ t("Dos\namovible") }}</template>
            </DraggableCard>
        </div>
    </div>
</template>

<script>
import DraggableCard from "./DraggableCard.vue";
import { BACKBOARD_REMOVABLE, TOOL_MODE_BACKBOARD, BACKBOARD_NORMAL, BACKBOARD_NONE, BACKBOARD_ALL_NORMAL, BACKBOARD_ALL_NONE } from "../../../../furnitures/src/constants";
import self from "../../../index";

import BackFixedIcon from "../icons/BackFixedIcon.vue";
import BackMoveIcon from "../icons/BackMoveIcon.vue";
import BackNoneIcon from "../icons/BackNoneIcon.vue";
import InfoLabel from "../common/InfoLabel.vue";

const { events, modules } = self.app;

export default {
    components: {
        DraggableCard,
        BackFixedIcon,
        BackMoveIcon,
        BackNoneIcon,
        InfoLabel,
    },
    data() {
        return {
            TOOL_MODE_BACKBOARD,
            BACKBOARD_REMOVABLE,
            BACKBOARD_NORMAL,
            BACKBOARD_NONE,
            BACKBOARD_ALL_NORMAL,
        };
    },
    methods: {
        t(...args) {
            return modules.stonejs.gettext(...args);
        },
        onAddBackboards() {
            events.emit("frame:action:backboard", { type: BACKBOARD_ALL_NORMAL });
        },
        onRemoveBackboards() {
            events.emit("frame:action:backboard", { type: BACKBOARD_ALL_NONE });
        },
    },
};
</script>
