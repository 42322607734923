/**
 * Rules for the drawer runner
 * Types: overlay, inset, accessory, pullout
 * with backboard or  without backboard
 */

import drawerRunnerJSON from "../../../../assets/data/drawer-runner.json";
import { ACCESSORYDRAWER, ACCESSORYDRAWER_SPACE_DEPTH, BOARD_THICKNESS, DRAWER_RUNNER_BACKSPACE, INSET_DRAWER, PULLOUTSHELF, PULLOUTSHELF_SPACE_DEPTH } from "../constants";
import self from "../../index";

const {
    modules,
    events,
} = self.app;

const drawerRunnerData = {
    minLenghtPerWeight: {},
};

function findDrawerRunner(weight, lengthMax) {
    // find the nearest drawer runner but not bigger than the max length
    let result = null;
    drawerRunnerJSON.forEach((drawerRunner) => {
        if (drawerRunner.weight === weight && drawerRunner.length <= lengthMax) {
            if (!result) {
                result = drawerRunner;
            } else if (drawerRunner.length > result.length) {
                result = drawerRunner;
            }
        }
    });

    return result;
}

function getMinLengthForWeight(weight) {
    if (!drawerRunnerData.minLenghtPerWeight[weight]) {
        drawerRunnerJSON.forEach((drawerRunner) => {
            if (!drawerRunnerData.minLenghtPerWeight[drawerRunner.weight]) {
                drawerRunnerData.minLenghtPerWeight[drawerRunner.weight] = drawerRunner.length;
            } else if (drawerRunner.length < drawerRunnerData.minLenghtPerWeight[drawerRunner.weight]) {
                drawerRunnerData.minLenghtPerWeight[drawerRunner.weight] = drawerRunner.length;
            }
        });
    }
    return drawerRunnerData.minLenghtPerWeight[weight];
}

const DrawerRunnerHelper = {

    getMinDepthOfFrame(frameEntity, drawerType, drawerRunnerWeight) {
        const minLength = getMinLengthForWeight(drawerRunnerWeight);

        let frameMinDepth = minLength + DRAWER_RUNNER_BACKSPACE + BOARD_THICKNESS + 1;

        if (frameEntity.hasBackboard()) {
            frameMinDepth += BOARD_THICKNESS;
        }

        switch (drawerType) {
            case INSET_DRAWER:
                frameMinDepth += BOARD_THICKNESS + 1;
                break;
            case ACCESSORYDRAWER:
                frameMinDepth += ACCESSORYDRAWER_SPACE_DEPTH;
                break;
            case PULLOUTSHELF:
                frameMinDepth += PULLOUTSHELF_SPACE_DEPTH;
                break;
            default:
                break;
        }

        return frameMinDepth;
    },

    getFrameDepthFreeSpace(frameEntity, drawerType) {
        const furnitureEntity = modules.dataStore.getEntity(frameEntity.getFurnitureId());

        let frameDepthFreeSpace = furnitureEntity.scaling.z - DRAWER_RUNNER_BACKSPACE - 1;

        if (frameEntity.hasBackboard()) {
            frameDepthFreeSpace -= (BOARD_THICKNESS * 2);
        }

        switch (drawerType) {
            case INSET_DRAWER:
                frameDepthFreeSpace -= BOARD_THICKNESS;
                break;
            case ACCESSORYDRAWER:
                frameDepthFreeSpace = frameDepthFreeSpace - ACCESSORYDRAWER_SPACE_DEPTH - BOARD_THICKNESS;
                break;
            case PULLOUTSHELF:
                frameDepthFreeSpace -= PULLOUTSHELF_SPACE_DEPTH;
                break;
            default:
                break;
        }

        return frameDepthFreeSpace;
    },

    getNearestDrawerRunnerForFrame(frameEntity, drawerType, drawerRunnerWeight) {
        const frameDepthFreeSpace = this.getFrameDepthFreeSpace(frameEntity, drawerType);
        const result = findDrawerRunner(drawerRunnerWeight, frameDepthFreeSpace);
        if (result) {
            return result;
        }
        return null;
    },

    canAddToFrame(frameEntity, drawerType, drawerRunnerWeight) {
        const furnitureEntity = modules.dataStore.getEntity(frameEntity.getFurnitureId());

        const frameDepthFreeSpace = this.getFrameDepthFreeSpace(frameEntity, drawerType);
        const result = findDrawerRunner(drawerRunnerWeight, frameDepthFreeSpace);

        if (result) {
            return true;
        }

        const minLength = getMinLengthForWeight(drawerRunnerWeight);
        const furnitureMinDepth = furnitureEntity.scaling.z + minLength - frameDepthFreeSpace;

        events.emit("notification:message", {
            title: "Profondeur insuffisante",
            text: `La profondeur du meuble doit être de ${furnitureMinDepth} mm minimum`,
            status: "error",
        });
        return false;
    },
};

export default DrawerRunnerHelper;
