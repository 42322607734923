import { MeshBuilder, Vector3 } from "@babylonjs/core";
import self from "../../index";

let knobRef = null;

const KNOB_HOLE_DIAMETER = 4;
const KNOB_HOLE_HALFDIAMETER = KNOB_HOLE_DIAMETER / 2;
const KNOB_DIAMETER = 40;
const KNOB_HALFDIAMETER = KNOB_DIAMETER / 2;
const TAU = Math.PI * 2;
const KNOB_INSTANCE_NAME = "KNOB_INSTANCE_NAME";
const KNOB_SUBDIVISIONS = 32;

function createRef() {
    const lines = [];

    // Cross for hole
    lines.push(
        [
            new Vector3(-KNOB_HOLE_HALFDIAMETER, -KNOB_HOLE_HALFDIAMETER, 0),
            new Vector3(KNOB_HOLE_HALFDIAMETER, KNOB_HOLE_HALFDIAMETER, 0),
        ],
        [
            new Vector3(KNOB_HOLE_HALFDIAMETER, -KNOB_HOLE_HALFDIAMETER, 0),
            new Vector3(-KNOB_HOLE_HALFDIAMETER, KNOB_HOLE_HALFDIAMETER, 0),
        ]
    );

    // Circle for knob
    const angleStep = TAU / KNOB_SUBDIVISIONS;
    let angle = 0;
    const circlePoints = [];
    for (let i = 0; i <= KNOB_SUBDIVISIONS; i++) {
        circlePoints.push(
            new Vector3(
                Math.cos(angle) * KNOB_HALFDIAMETER,
                Math.sin(angle) * KNOB_HALFDIAMETER,
                0
            )
        );
        angle += angleStep;
    }
    lines.push(circlePoints);

    knobRef = MeshBuilder.CreateLineSystem("lines", {
        lines: lines,
    });

    knobRef.isVisible = false;
    knobRef.isPickable = false;
    knobRef.color = self.config.doorLinesColor;
}

const KnobFactory = {
    createInstance: () => {
        if (!knobRef) { createRef(); }
        return knobRef.createInstance(KNOB_INSTANCE_NAME);
    },
};



export default KnobFactory;
export { KNOB_DIAMETER, KNOB_INSTANCE_NAME };
