<template>
    <div class="absolute right-4 top-2 flex flex-col gap-2">
        <div class="flex justify-end">
            <SaveProjectButton />
        </div>
        <div v-if="isUnlockCameraDisplayed" class="flex justify-end">
            <UnlockCameraButton />
        </div>
        <div class="flex justify-end">
            <DisplayMeasuresButton />
        </div>
        <div v-if="displayDoorsButton" class="flex justify-end">
            <ToggleDoorButton />
        </div>
        <div class="flex justify-end">
            <OpenProjectButton />
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

import SaveProjectButton from "./SaveProjectButton.vue";
import UnlockCameraButton from "./UnlockCameraButton.vue";
import DisplayMeasuresButton from "./DisplayMeasuresButton.vue";
import ToggleDoorButton from "./ToggleDoorButton.vue";
import OpenProjectButton from "./OpenProjectButton.vue";

import self from "../../../index";
import useGlobalStore from "../../stores/global";
import { TAB_ACCESSORIES, TAB_FRONT, TAB_FINISHING_TOUCHES, TAB_STRUCTURE_EXT } from "../../services/constants";

const {
    modules,
} = self.app;

export default defineComponent({
    name: "right-menu",
    components: {
        SaveProjectButton,
        UnlockCameraButton,
        DisplayMeasuresButton: DisplayMeasuresButton,
        ToggleDoorButton,
        OpenProjectButton,
    },
    computed: {
        store() {
            return useGlobalStore();
        },
        activeTab() {
            return this.store.activeTab;
        },
        displayDoorsButton() {
            return this.activeTab === TAB_FRONT || this.activeTab === TAB_ACCESSORIES || this.activeTab === TAB_FINISHING_TOUCHES;
        },
        isUnlockCameraDisplayed() {
            return this.activeTab !== TAB_STRUCTURE_EXT && this.activeTab !== TAB_FINISHING_TOUCHES;
        },
    },
    methods: {
        t(...args) {
            return modules.stonejs.gettext(...args);
        },
    },
});
</script>
