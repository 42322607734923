import {
    Vector3,
} from "@babylonjs/core/Maths/math.vector";
import FrameEntity from "./frame-entity";

const FrameEntityBuilder = {

    buildRootFrameEntity(furnitureEntity) {
        return new FrameEntity({
            furnitureId: furnitureEntity.id,
            scaling: new Vector3(
                furnitureEntity.getScaling().x - furnitureEntity.getThickness() * 2,
                furnitureEntity.getScaling().y - furnitureEntity.getThickness() * 2,
                furnitureEntity.getScaling().z,
            ),
            position: new Vector3(
                furnitureEntity.getThickness(),
                furnitureEntity.getThickness(),
                0,
            ),
        });
    },

    buildLeftFrameEntity(parentFrameEntity, splitPoint) {
        return new FrameEntity({
            furnitureId: parentFrameEntity.getFurnitureId(),
            idParent: parentFrameEntity.id,
            scaling: new Vector3(
                splitPoint.x - parentFrameEntity.getPosition().x - parentFrameEntity.halfThickness,
                parentFrameEntity.getScaling().y,
                parentFrameEntity.getScaling().z,
            ),
            position: parentFrameEntity.getPosition().clone(),
        });
    },

    buildRightFrameEntity(parentFrameEntity, splitPoint) {
        const leftFrameScalingX = splitPoint.x - parentFrameEntity.getPosition().x - parentFrameEntity.halfThickness;
        return new FrameEntity({
            furnitureId: parentFrameEntity.getFurnitureId(),
            idParent: parentFrameEntity.id,
            scaling: new Vector3(
                parentFrameEntity.getScaling().x - leftFrameScalingX - parentFrameEntity.getThickness(),
                parentFrameEntity.getScaling().y,
                parentFrameEntity.getScaling().z
            ),
            position: new Vector3(
                splitPoint.x + parentFrameEntity.halfThickness,
                parentFrameEntity.getPosition().y,
                parentFrameEntity.getPosition().z,
            ),
        });
    },

    buildBottomFrameEntity(parentFrameEntity, splitPoint) {
        return new FrameEntity({
            furnitureId: parentFrameEntity.getFurnitureId(),
            idParent: parentFrameEntity.id,
            scaling: new Vector3(
                parentFrameEntity.getScaling().x,
                splitPoint.y - parentFrameEntity.getPosition().y - parentFrameEntity.halfThickness,
                parentFrameEntity.getScaling().z,
            ),
            position: parentFrameEntity.getPosition().clone(),
        });

    },

    buildTopFrameEntity(parentFrameEntity, splitPoint) {
        const bottomFrameScalingY = splitPoint.y - parentFrameEntity.getPosition().y - parentFrameEntity.halfThickness;
        return new FrameEntity({
            furnitureId: parentFrameEntity.getFurnitureId(),
            idParent: parentFrameEntity.id,
            scaling: new Vector3(
                parentFrameEntity.getScaling().x,
                parentFrameEntity.getScaling().y - bottomFrameScalingY - parentFrameEntity.getThickness(),
                parentFrameEntity.getScaling().z
            ),
            position: new Vector3(
                parentFrameEntity.getPosition().x,
                splitPoint.y + parentFrameEntity.halfThickness,
                parentFrameEntity.getPosition().z,
            ),
        });
    },

};

export default FrameEntityBuilder;
