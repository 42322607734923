import { ArcRotateCamera, Matrix, Vector3 } from "@babylonjs/core";

import self from "../index";

const {
    app: { modules },
} = self;

const CameraHelper = {
    isFront(camera) {
        if (camera instanceof ArcRotateCamera) {
            return camera.alpha < 0;
        }
        return camera.position.z > 0;
    },

    isBack(camera) {
        if (camera instanceof ArcRotateCamera) {
            return camera.alpha > 0;
        }
        return camera.position.z < 0;
    },

    convert3DPointTo2D(point) {
        const {
            scene, engine,
        } = modules.obsidianBabylonEngine;
        scene.activeCamera.update();
        scene.updateTransformMatrix();
        const transPoint = Vector3.Project(point, Matrix.Identity(),
            scene.getTransformMatrix(), scene.activeCamera.viewport.toGlobal(engine.getRenderWidth(), engine.getRenderHeight()));
        return { x: transPoint.x, y: transPoint.y };
    },
};

export default CameraHelper;
