<script>
import { defineComponent } from "vue";
import CheckIcon from "../icons/CheckIcon.vue";
import WarningIcon from "../icons/WarningIcon.vue";
import ErrorIcon from "../icons/CrossIcon.vue";

import self from "../../../index";

const {
    events,
    modules,
} = self.app;

export default defineComponent({
    name: "notification",
    components: {
        CheckIcon,
        WarningIcon,
        ErrorIcon,
    },
    data() {
        return {
            messages: [],
        };
    },
    mounted() {
        /* message {
            title: string;
            text: string;
            status: string;  success, warning, error
        } */
        events.on("@furnitures.notification:message", (message) => {
            message.autoClose = true;
            this.addMessage(message);
        });
        events.on("@api.show:alert", (message) => {
            this.addMessage(message);
        });
        events.on("@api.hide:alert", () => {
            this.messages = [];
        });
    },
    methods: {
        t(...args) {
            return modules.stonejs.gettext(...args);
        },
        addMessage(message) {
            const TOASTER_TIME = message.closeAfter ?? 5 * 1000; // 5 secondes in ms

            this.messages.push(message);
            if (message.autoClose) {
                setTimeout(() => {
                    const index = this.messages.findIndex((m) => m === message);
                    this.hideMessage(index);
                }, TOASTER_TIME);
            }
        },
        hideMessage(messageIndex) {
            this.messages.splice(messageIndex, 1);
        },
    },
});
</script>
<template>
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div
        aria-live="assertive"
        class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
        <div
            v-if="messages.length"
            class="flex w-full flex-col items-center space-y-4 sm:items-end"
        >
            <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
            <transition
                v-for="(message, index) in messages"
                :key="index"
                enter-active-class="transform ease-out duration-300 transition"
                enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div
                    class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                >
                    <div class="p-4">
                        <div class="flex items-center">
                            <div v-if="message.status === 'success'" class="flex-shrink-0">
                                <CheckIcon class="h-6 w-6 text-green-500" />
                            </div>
                            <div v-if="message.status === 'error'" class="flex-shrink-0">
                                <ErrorIcon class="h-6 w-6 text-red-500" />
                            </div>
                            <div v-if="message.status === 'warning'" class="flex-shrink-0">
                                <WarningIcon class="h-6 w-6 text-orange-500" />
                            </div>
                            <div class="ml-3 w-0 flex-1 pt-0.5">
                                <p class="text-sm font-medium text-gray-900">
                                    {{ message.title }}
                                </p>
                                <p class="mt-1 text-sm text-gray-500">
                                    {{ message.text }}
                                </p>
                            </div>
                            <div class="ml-4 flex flex-shrink-0">
                                <button
                                    type="button"
                                    class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    @click="hideMessage(index)"
                                >
                                    <span class="sr-only">{{ t("Fermer") }}</span>
                                    X
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>
