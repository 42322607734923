import Axis from "./axis";
import FrameController from "./frame/frame-controller";
import BoardController from "./board/board-controller";
import BoardEntity from "./board/board-entity";
import FrameEntity from "./frame/frame-entity";
import FurnitureEntity from "./furniture/furniture-entity";
import BackboardEntity from "./backboard/backboard-entity";
import BackboardController from "./backboard/backboard-controller";
import BaseboardEntity from "./baseboard/baseboard-entity";
import BaseboardController from "./baseboard/baseboard-controller";
import FootEntity from "./foot/foot-entity";
import FootController from "./foot/foot-controller";
import self from "../index";
import DoorEntity from "./door/door-entity";
import DoorController from "./door/door-controller";
import MovableshelfEntity from "./movableshelf/movableshelf-entity";
import MovableshelfController from "./movableshelf/movableshelf-controller";
import RodEntity from "./rod/rod-entity";
import RodController from "./rod/rod-controller";
import OverlaydrawerEntity from "./overlaydrawer/overlaydrawer-entity";
import OverlaydrawerController from "./overlaydrawer/overlaydrawer-controller";
import InsetdrawerEntity from "./insetdrawer/insetdrawer-entity";
import InsetdrawerController from "./insetdrawer/insetdrawer-controller";
import AccessorydrawerEntity from "./accessorydrawer/accessorydrawer-entity";
import AccessorydrawerController from "./accessorydrawer/accessorydrawer-controller";
import PulloutshelfEntity from "./pulloutshelf/pulloutshelf-entity";
import PulloutshelfController from "./pulloutshelf/pulloutshelf-controller";
import FurnitureEventsManager from "./furniture/furniture-event-managers";

const {
    events,
    modules,
} = self.app;

let projectOpening = false;
/**
 * Listen entity added en removed for generate meshes from dataStore events
 * Only frameEntity and BoardEntity
 */
const DataStoreMeshGenerator = {

    start() {
        events.on("@data-store.entity-added", DataStoreMeshGenerator.onEntityAdded);
        events.on("@data-store.entity-removed", DataStoreMeshGenerator.onEntityRemoved);

        events.on("@project-manager.opening", DataStoreMeshGenerator.onProjectOpening);
        events.on("@project-manager.opened", DataStoreMeshGenerator.onProjectOpened);
    },

    stop() {
        events.removeListener("@data-store.entity-added", DataStoreMeshGenerator.onEntityAdded);
        events.removeListener("@data-store.entity-removed", DataStoreMeshGenerator.onEntityRemoved);
    },

    onEntityAdded(entity) {
        DataStoreMeshGenerator.generateForEntity(entity);
    },

    onEntityRemoved(entity) {
        if (entity.dispose) {
            entity.dispose();
        } else if (entity.mesh) {
            entity.mesh.dispose();
        }
        FurnitureEventsManager.emitFurnitureCompositionUpdated();
    },

    onProjectOpening() {
        projectOpening = true;
    },

    onProjectOpened() {
        projectOpening = false;
        DataStoreMeshGenerator.generateForEntities("furniture");
        DataStoreMeshGenerator.generateForEntities("frame");
        DataStoreMeshGenerator.generateForEntities("board");
        DataStoreMeshGenerator.generateForEntities("backboard");
        DataStoreMeshGenerator.generateForEntities("baseboard");
        DataStoreMeshGenerator.generateForEntities("door");
        DataStoreMeshGenerator.generateForEntities("movableshelf");
        DataStoreMeshGenerator.generateForEntities("pulloutshelf");
        DataStoreMeshGenerator.generateForEntities("foot");
        DataStoreMeshGenerator.generateForEntities("rod");
        DataStoreMeshGenerator.generateForEntities("overlaydrawer");
        DataStoreMeshGenerator.generateForEntities("insetdrawer");
        DataStoreMeshGenerator.generateForEntities("accessorydrawer");
    },

    generateForEntities(entitiesByPath) {
        modules.dataStore.listEntities(entitiesByPath).forEach((entity) => {
            DataStoreMeshGenerator.generateForEntity(entity);
        });
    },

    generateForEntity(entity) {
        if (!entity.mesh && !projectOpening) {
            if (entity instanceof FurnitureEntity) {
                self.furnituresListController.currentFurnitureEntity = entity;
                Axis.adjustDepthPosition(entity.scaling.z);
            }

            if (entity instanceof FrameEntity) {
                FrameController.generateFrameMeshAndMaterial(entity);
            }

            if (entity instanceof BoardEntity) {
                BoardController.generateBoardMeshAndMaterial(entity);
            }

            if (entity instanceof MovableshelfEntity) {
                MovableshelfController.generateMovableshelfMeshAndMaterial(entity);
            }

            if (entity instanceof PulloutshelfEntity) {
                PulloutshelfController.generatePulloutshelfMeshAndMaterial(entity);
            }

            if (entity instanceof BackboardEntity) {
                BackboardController.generateBackboardMeshAndMaterial(entity);
            }

            if (entity instanceof BaseboardEntity) {
                BaseboardController.generateBaseboardMeshAndMaterial(entity);
            }

            if (entity instanceof FootEntity) {
                FootController.generateFootMeshAndMaterial(entity);
            }

            if (entity instanceof DoorEntity) {
                DoorController.generateDoorMeshAndMaterial(entity);
            }

            if (entity instanceof RodEntity) {
                RodController.generateRodMeshAndMaterial(entity);
            }

            if (entity instanceof OverlaydrawerEntity) {
                OverlaydrawerController.generateDrawerMeshAndMaterial(entity);
            }

            if (entity instanceof InsetdrawerEntity) {
                InsetdrawerController.generateDrawerMeshAndMaterial(entity);
            }

            if (entity instanceof AccessorydrawerEntity) {
                AccessorydrawerController.generateDrawerMeshAndMaterial(entity);
            }
            FurnitureEventsManager.emitFurnitureCompositionUpdated();
        }
    },

};

export default DataStoreMeshGenerator;
