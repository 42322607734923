import cu from "vendors/class-utils";
import {
    BOARD_THICKNESS,
    DISTANCE_BEHIND_DOOR,
} from "../constants";

import self from "../../index";
import Object3DEntity from "../entity-mixins/object3D-entity";

const {
    modules,
} = self.app;

const Entity = require("@obsidianjs/data-store/src/entity");

require("helpers/serializers/babylon-vector3");

/**
 * Frame Entity in Furnitures module
 *   side1 is left or bottom
 *   side2 is right or top
 */
const FrameEntity = Object3DEntity.$extend({

    __name__: "frame",

    __init__(params = {}) {

        this.$data.isSplitted = Boolean(params.isSplitted);
        this.$data.idFrameLeftOrBottom = params.idFrameLeftOrBottom || ""; // left or bottom
        this.$data.idFrameRightOrTop = params.idFrameRightOrTop || ""; // right or top
        this.$data.idBoard = params.idBoard || "";
        this.$data.idBackboard = params.idBackboard || "";

        this.$data.thickness = params.thickness ? params.thickness : BOARD_THICKNESS;
        this.halfThickness = this.$data.thickness * 0.5;

        this.$data.idMovableshelfs = params.idMovableshelfs || [];
        this.$data.idPulloutshelfs = params.idPulloutshelfs || [];
        this.$data.idDrawer = params.idDrawer || "";
        this.$data.idAccessorydrawer = params.idAccessorydrawer || "";
        this.$data.idDoor = params.idDoor || "";
        this.$data.idRod = "";

        this.$data.isCoveredByDoor = params.isCoveredByDoor || false;
        this.$data.isPartialyCoveredByADoor = params.isPartialyCoveredByADoor || false;

        this.mesh = null;
        this.bbox = null;

        this.isPickable = false;
        this.isVisible = true;
        this.isEnabled = true;

        this.contraints = [];

        this.$super(params);
    },

    setIsSplitted(isSplitted) {
        this.$data.isSplitted = isSplitted;
        if (this.mesh) {
            this.mesh.isVisible = !isSplitted;
        }
    },
    getIsSplitted: cu.getter,

    setIdBoard: cu.setter,
    getIdBoard: cu.getter,

    setIdBackboard: cu.setter,
    getIdBackboard: cu.getter,

    setIdFrameLeftOrBottom: cu.setter,
    getIdFrameLeftOrBottom: cu.getter,

    setIdFrameRightOrTop: cu.setter,
    getIdFrameRightOrTop: cu.getter,

    setThickness: cu.setter,
    getThickness: cu.getter,

    setIdDrawer: cu.setter,
    getIdDrawer: cu.getter,

    setIdAccessorydrawer: cu.setter,
    getIdAccessorydrawer: cu.getter,

    setIsPartialyCoveredByADoor: cu.setter,
    getIsPartialyCoveredByADoor: cu.getter,

    setIdMovableshelfs: cu.setter,
    getIdMovableshelfs: cu.getter,

    setIdPulloutshelfs: cu.setter,
    getIdPulloutshelfs: cu.getter,


    setIsCoveredByDoor(isCoveredByDoor) {
        this.$data.isCoveredByDoor = isCoveredByDoor;
        const furnitureEntity = modules.dataStore.getEntity(this.getFurnitureId());
        if (furnitureEntity) {
            this.setPositionZ(furnitureEntity.getPosition().z);
            this.setScalingZ(furnitureEntity.getScaling().z);
        }
    },
    getIsCoveredByDoor: cu.getter,

    setIdDoor(idDoor) {
        this.$data.idDoor = idDoor || "";
    },
    getIdDoor: cu.getter,

    setIdRod: cu.setter,
    getIdRod: cu.getter,

    setPositionZ(z) {
        if (this.getIsCoveredByDoor()) {
            const furnitureEntity = modules.dataStore.getEntity(this.getFurnitureId());
            this.$data.position.z = furnitureEntity.getPosition().z + DISTANCE_BEHIND_DOOR;
        } else {
            this.$data.position.z = z;
        }
        // if (this.mesh) this.mesh.position.z = this.$data.position.z;
        this.updateMeshes();
    },

    setScalingZ(scaleZ) {
        if (this.getIsCoveredByDoor()) {
            const furnitureEntity = modules.dataStore.getEntity(this.getFurnitureId());
            this.$data.scaling.z = furnitureEntity.getScaling().z - DISTANCE_BEHIND_DOOR;
        } else {
            this.$data.scaling.z = scaleZ;
        }
        this.updateMeshes();
    },

    updateMeshes() {
        if (!this.mesh) return;
        this.mesh.scaling = this.$data.scaling.clone();
    },

    getPositionAtRight() {
        return this.$data.position.x + this.$data.scaling.x;
    },

    getPositionAtTop() {
        return this.$data.position.y + this.$data.scaling.y;
    },

    hasDoor() {
        return Boolean(this.$data.idDoor);
    },

    hasMovableshelf() {
        return Boolean(this.$data.idMovableshelfs.length);
    },

    hasPulloutshelf() {
        return Boolean(this.$data.idPulloutshelfs.length);
    },

    hasAccessorydrawer() {
        return Boolean(this.$data.idAccessorydrawer);
    },

    hasDrawer() {
        return Boolean(this.$data.idDrawer);
    },

    hasBackboard() {
        return Boolean(this.$data.idBackboard);
    },

    hasRod() {
        return Boolean(this.$data.idRod);
    },

    getIdUpperMovableShelf() {
        return this.$data.idMovableshelfs[this.$data.idMovableshelfs.length - 1];
    },

    getIdLowerMovableShelf() {
        return this.$data.idMovableshelfs[0];
    },

    getIdUpperPulloutShelf() {
        return this.$data.idPulloutshelfs[this.$data.idPulloutshelfs.length - 1];
    },

    getIdLowerPulloutShelf() {
        return this.$data.idPulloutshelfs[0];
    },

    getCenter() {
        return this.$data.position.add(this.$data.scaling.scale(0.5)).scale(10).floor().scale(0.1);
    },
});

Entity.$register(FrameEntity);
export default FrameEntity;
