import BackboardMeshBuilder from "./backboard-mesh-builder";

import self from "../../index";
import BackboardEntityBuilder from "./backboard-entity-builder";
import MovableshelfHelper from "../movableshelf/movableshelf-helper";
import AccessorydrawerHelper from "../accessorydrawer/accessorydrawer-helper";
import RodHelper from "../rod/rod-helper";
import furnitureUiButtons from "../furniture/furniture-ui-buttons";
import BackboardHelper from "./backboard-helper";

const {
    modules,
    events,
} = self.app;

function createBackboard(frameEntity, isRemovable = false) {
    const furnitureEntity = modules.dataStore.getEntity(frameEntity.getFurnitureId());
    if (furnitureEntity.getHasBackboards()) {
        const backboardEntity = BackboardEntityBuilder.build(frameEntity, isRemovable);
        frameEntity.setIdBackboard(backboardEntity.id);
        modules.dataStore.addEntity(backboardEntity, "backboard");
        if (frameEntity.hasMovableshelf()) {
            MovableshelfHelper.setIsCoveredByBackboard(frameEntity, true);
        } else if (frameEntity.hasAccessorydrawer()) {
            AccessorydrawerHelper.setIsCoveredByBackboard(frameEntity, true);
        }
        if (frameEntity.hasRod()) {
            RodHelper.transformFromParentEntity(frameEntity);
        }
    }
    furnitureUiButtons.updateButtons();
}

const BackboardController = {
    createNormalBackboard(frameEntity) {
        createBackboard(frameEntity);
    },

    createRemovableBackboard(frameEntity) {
        createBackboard(frameEntity, true);
    },

    deleteBackboard(backboardEntityId) {
        const backboardEntity = modules.dataStore.getEntity(backboardEntityId);
        if (!backboardEntity) return;

        const frameEntity = modules.dataStore.getEntity(backboardEntity.getIdParent());
        modules.dataStore.removeEntity(backboardEntity);
        if (frameEntity.hasMovableshelf()) {
            MovableshelfHelper.setIsCoveredByBackboard(frameEntity, false);
        }
        if (frameEntity.hasAccessorydrawer()) {
            AccessorydrawerHelper.setIsCoveredByBackboard(frameEntity, false);
        }
        if (frameEntity.hasRod()) {
            RodHelper.transformFromParentEntity(frameEntity);
        }
        frameEntity.setIdBackboard("");
        furnitureUiButtons.updateButtons();
        events.emit("furniture:edited");
    },

    generateBackboardMeshAndMaterial(backboardEntity) {
        const furnitureEntity = modules.dataStore.getEntity(backboardEntity.getFurnitureId());
        BackboardMeshBuilder.buildDefault(backboardEntity, furnitureEntity.container);

        if (furnitureUiButtons.isUiMainTabFinishing) {
            setTimeout(() => {
                BackboardHelper.applyFinishMaterial(backboardEntity);
            });
        } else {
            BackboardHelper.applyConstructionMaterial(backboardEntity);
        }
    },

    addAllBackboards() {
        modules.dataStore.listEntities("frame").forEach((frameEntity) => {
            if (!frameEntity.getIsSplitted() && !frameEntity.getIdBackboard()) {
                createBackboard(frameEntity);
            }
        });
    },

    deleteAllBackboards() {
        modules.dataStore.listEntities("backboard").forEach((backboardEntity) => {
            BackboardController.deleteBackboard(backboardEntity.id);
        });
    },

    clearAllFromFurnitureId(furnitureId) {
        modules.dataStore.listEntities("backboard").forEach((backboardEntity) => {
            if (backboardEntity.furnitureId === furnitureId) {
                modules.dataStore.removeEntity(backboardEntity);
            }
        });
    },

    switchMaterialToConstruction() {
        modules.dataStore.listEntities("backboard").forEach((backboardEntity) => {
            BackboardHelper.applyConstructionMaterial(backboardEntity);
        });
    },

    switchMaterialsToFinition() {
        modules.dataStore.listEntities("backboard").forEach((backboardEntity) => {
            BackboardHelper.applyFinishMaterial(backboardEntity);
        });
    },


};

export default BackboardController;
