import { Vector3 } from "@babylonjs/core";

/**
 * USefull for composition
 * Can be used to create a board part of a drawer
 * @class BoardInfo
 */
class BoardInfo {

    constructor() {
        this.scaling = new Vector3(1, 1, 1);
        this.position = new Vector3(0, 0, 0);
    }

    /**
     * sort is usefull for hashmap key composition
     * Used by FurnitureCompositionBuilder.groupInCollectionByKey
     */
    get dimensions() {
        return [this.scaling.x, this.scaling.y, this.scaling.z].sort((a, b) => a - b);
    }

    clone() {
        const board = new BoardInfo();
        board.scaling = this.scaling.clone();
        board.position = this.position.clone();
        return board;
    }

}

export default BoardInfo;
