import ATool from "../../../tool-manager/src/atool";
import self from "../../index";
import {
    TOOL_MODE_BACKBOARD,
    TOOL_MODE_DOOR,
    TOOL_MODE_DRAWER,
    TOOL_MODE_MOVABLESHELF,
    TOOL_MODE_REMOVE,
    TOOL_MODE_ROD,
    TOOL_MODE_SELECT,
    TOOL_MODE_SPLIT,
} from "../constants";
import DoorHelper from "../door/door-helper";
import FrameHelper from "./frame-helper";

const {
    modules,
    events,
} = self.app;

class FrameATool extends ATool {

    constructor() {
        super({
            tool_name: "frame",
        });
        this.tool_modes = [
            TOOL_MODE_SPLIT,
            TOOL_MODE_SELECT,
            TOOL_MODE_REMOVE,
            TOOL_MODE_BACKBOARD,
            TOOL_MODE_DOOR,
            TOOL_MODE_DRAWER,
            TOOL_MODE_MOVABLESHELF,
            TOOL_MODE_ROD,
        ];
        modules.toolManager.controller.registerTool(this);
    }

    // Atool method
    // eslint-disable-next-line class-methods-use-this
    onActiveCallback() {
        FrameHelper.resetAllFramesInteraction();
        DoorHelper.validCurrentDoorInEdition();
    }

    // Atool method
    // eslint-disable-next-line class-methods-use-this
    onEndActiveCallback() {
        FrameHelper.resetAllFramesInteraction(true);
        DoorHelper.validCurrentDoorInEdition();
        events.emit("frame:tool:endActive");
    }

}

export default FrameATool;
