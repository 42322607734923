import self from "../../index";
import {
    TOOL_MODE_SELECT,
} from "../constants";
import furnitureUiButtons from "../furniture/furniture-ui-buttons";
import PulloutshelfController from "./pulloutshelf-controller";
import PulloutshelfHelper from "./pulloutshelf-helper";

const {
    modules,
    events,
} = self.app;
const data = {
    listenersAdded: false,
};

function onHistoryGo() {
    if (!PulloutshelfController.isActiveTool()) return;
    PulloutshelfController.currentPulloutshelfEntity = null;
    furnitureUiButtons.updateButtons();
}

function onToolPulloutshelfDeactivate() {
    modules.toolManager.controller.deactivateTool(PulloutshelfController.atool.tool_name);
    PulloutshelfController.atool.activateModeAlone("");
}

function onToolBoardUpdate(payload) {
    switch (payload.toolModeName) {
        case TOOL_MODE_SELECT:
            modules.toolManager.controller.activateToolAlone(PulloutshelfController.atool.tool_name);
            PulloutshelfController.atool.activateModeAlone(payload.toolModeName);
            break;
        default:
            onToolPulloutshelfDeactivate();
            break;
    }
}

function onPulloutshelfSelect(pulloutshelfEntity) {
    events.emit("accesorry:current:unselect");
    PulloutshelfController.currentPulloutshelfEntity = pulloutshelfEntity;
    events.emit("pulloutshelf:selected", pulloutshelfEntity);
    PulloutshelfHelper.showMeasures(pulloutshelfEntity);
    furnitureUiButtons.updateButtons();
}

function onPulloutshelfUnselect() {
    if (PulloutshelfController.currentPulloutshelfEntity) {
        PulloutshelfController.currentPulloutshelfEntity = null;
    }
}

function onPulloutshelfDelete() {
    PulloutshelfHelper.deleteCurrentPulloutshelf();
}

function editDistanceFrom(distance, from) {
    PulloutshelfHelper.setDistanceFrom(distance, from);
}

function onCameraChanged() {
    if (!PulloutshelfController.currentPulloutshelfEntity) return;
    PulloutshelfHelper.showMeasures(PulloutshelfController.currentPulloutshelfEntity);
}

function onDeletePulloutshelf() {
    PulloutshelfHelper.deleteCurrentPulloutshelf();
}

const PulloutshelfEventsManager = {
    addEventListeners() {
        if (data.listenersAdded) return;

        events.on("@history.history-go", onHistoryGo);
        events.on("@vuejs.tool:pulloutshelf:update", onToolBoardUpdate);
        events.on("@vuejs.pulloutshelf:delete", onPulloutshelfDelete);
        events.on("tool:pulloutshelf:update", onToolBoardUpdate);
        events.on("accesorry:current:unselect", onPulloutshelfUnselect);
        events.on("pulloutshelf:select", onPulloutshelfSelect);
        events.on("@vuejs.pulloutshelf:edit:distance", editDistanceFrom);
        events.on("@vuejs.pulloutshelf:delete", onDeletePulloutshelf);

        events.on("@cameras-manager.camera:move:end", onCameraChanged);
        events.on("@cameras-manager.camera:moved", onCameraChanged);
        events.on("@cameras-manager.camera:move:start", onCameraChanged);

        data.listenersAdded = true;
    },
};

export default PulloutshelfEventsManager;
