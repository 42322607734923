<template>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg"
             width="28.994"
             height="29.934"
             viewBox="0 0 28.994 29.934"
        >
            <title>{{ title }}</title>
            <desc>Une icône représentant un dos de meuble</desc>
            <g id="icon-back-color" transform="translate(0.5 -10.731)">
                <path id="Tracé_322"
                      data-name="Tracé 322"
                      d="M27.384,6.574H.61A.587.587,0,0,0,0,7.134V34.945a.587.587,0,0,0,.61.559.587.587,0,0,0,.61-.559v-1.9H26.774v1.906a.613.613,0,0,0,1.221,0V7.134A.587.587,0,0,0,27.384,6.574ZM15.135,31.925a4.276,4.276,0,0,0-1.164,0H1.221V7.693H26.774V31.925Z"
                      transform="translate(0 4.657)"
                      fill="#bdc9d5"
                      stroke="#bdc9d5"
                      stroke-width="1"
                />
                <rect id="Rectangle_688"
                      data-name="Rectangle 688"
                      width="10"
                      height="14"
                      rx="1"
                      transform="translate(14.997 21)"
                      class="transition duration-300"
                      :fill="isActive || isHovered ? '#ec910a': 'currentColor'"
                />
                <rect id="Rectangle_689"
                      data-name="Rectangle 689"
                      width="11"
                      height="21"
                      rx="1"
                      transform="translate(2.997 14)"
                      class="transition duration-300"
                      :fill="isActive || isHovered ? '#e83e8c': 'currentColor'"
                />
                <line id="Ligne_145"
                      data-name="Ligne 145"
                      x2="8"
                      y2="12"
                      transform="translate(15.997 22)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-width="1.5"
                />
                <line id="Ligne_146"
                      data-name="Ligne 146"
                      x1="8"
                      y2="12"
                      transform="translate(15.997 22)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-width="1.5"
                />
            </g>
        </svg>
    </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "back-icon",
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        isHovered: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "Icône de meuble de dos",
        },
    },
});
</script>
