/**
 * This Entity is extended by all entities that have a 3D representation.
 * It contains the position of the container
 * and the global scaling of the meshes, like a bounding box.
 * Each mesh has its own scaling, updated by the updateMeshes method.
 * UpdateMeshes is called when the global scaling is changed.
 * UpdateMeshes use setTimeout for update meshes after deserialization.
 * If we need and other entity scaling, we need to wait for the deserialization. *
 */
import {
    Mesh,
    TransformNode,
    Vector3,
} from "@babylonjs/core";
import cu from "vendors/class-utils";
import self from "../../index";
import { TransformNodeUtil } from "../../../../helpers/mesh-utility";

const Entity = require("@obsidianjs/data-store/src/entity");

require("helpers/serializers/babylon-vector3");

const {
    modules,
} = self.app;

const Object3DEntity = Entity.$extend({

    __name__: "object3D",

    __init__(params = {}) {

        this.$data.furnitureId = params.furnitureId || "";

        this.$data.idParent = params.idParent || "";

        this.$data.position = params.position ? params.position : Vector3.Zero();
        this.$data.scaling = params.scaling ? params.scaling : new Vector3(1, 1, 1);

        // will be used to move all meshes
        // Mesh Builder must parent meshes to this container
        // This container excepts furniture's container must be parented to this furniture's container
        this._container = new TransformNode("container");
        this._furnitureEntity = undefined;

        this._meshBoundingBox = new Mesh("meshBoundingBox"); // usefull for debug
        this._meshBoundingBox.isPickable = false;
        this._debugMeshBoundingBox = false;


        this._isVisible = true;

        this.$super(params);
    },

    setIsVisible(isVisible) {
        "@serializable false";

        this._isVisible = isVisible;
    },

    getIsVisible() {
        return this._isVisible;
    },

    setFurnitureId: cu.setter,
    getFurnitureId: cu.getter,

    setIdParent: cu.setter,
    getIdParent: cu.getter,

    getParentEntity() {
        return modules.dataStore.getEntity(this.$data.idParent);
    },

    setPosition(pos) {
        this.$data.position = pos;
        this.updateContainer();
    },

    getPosition() {
        "@serializer babylon-vector3";

        return this.$data.position;
    },

    setPositionX(x) {
        this.$data.position.x = x;
        this.updateContainer();
    },

    setPositionY(y) {
        this.$data.position.y = y;
        this.updateContainer();
    },

    setPositionZ(z) {
        this.$data.position.z = z;
        this.updateContainer();
    },

    setScaling(scale) {
        this.$data.scaling = scale;
        this.setScalingZ(scale.z, false);
        setTimeout(() => {
            this.updateMeshes();
        });
    },

    getScaling() {
        "@serializer babylon-vector3";

        return this.$data.scaling;
    },

    setScalingX(scaleX, refreshModels = true) {
        this.$data.scaling.x = scaleX;
        if (!refreshModels) return;
        this.updateMeshes();
    },

    setScalingY(scaleY, refreshModels = true) {
        this.$data.scaling.y = scaleY;
        if (!refreshModels) return;
        this.updateMeshes();
    },

    setScalingZ(scaleZ, refreshModels = true) {
        this.$data.scaling.z = scaleZ;
        if (!refreshModels) return;
        setTimeout(() => {
            this.updateMeshes();
        });
    },

    getContainer() {
        return this._container;
    },

    getMeshBoundingBox() {
        return this._meshBoundingBox;
    },

    updateContainer() {
        if (!this._container) return;
        this._container.position = this.$data.position;
    },

    updateMeshes() {

    },

    dispose() {
        if (this._container) {
            this._container.dispose();
        }
        if (this._meshBoundingBox) {
            this._meshBoundingBox.dispose();
        }
    },

    getFurnitureEntity() {
        if (!this._furnitureEntity) this._furnitureEntity = modules.dataStore.getEntity(this.$data.furnitureId);
        return this._furnitureEntity;
    },

    getDimensions() {
        return [this.$data.scaling.x, this.$data.scaling.y, this.$data.scaling.z].sort((a, b) => a - b);
    },

    getCenterAtFront() {
        const center = this.$data.position.clone();
        center.x += this.$data.scaling.x * 0.5;
        center.y += this.$data.scaling.y * 0.5;
        return center;
    },

    show() {
        TransformNodeUtil.childrenVisible(this._container, true);
    },

    hide() {
        TransformNodeUtil.childrenVisible(this._container, false);
    },
});
Entity.$register(Object3DEntity);
export default Object3DEntity;
