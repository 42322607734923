<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="22.751" height="26.001" viewBox="0 0 22.751 26.001">
        <title>Supprimer l'élément</title>
        <desc>Une icône représentant une poubelle</desc>
        <path id="icon-trash-big" d="M7.313,20.313a.813.813,0,0,1-1.625,0V8.938a.813.813,0,0,1,1.625,0Zm4.875,0a.813.813,0,0,1-1.625,0V8.938a.813.813,0,0,1,1.625,0Zm4.875,0a.813.813,0,0,1-1.625,0V8.938a.813.813,0,0,1,1.625,0ZM15.748,1.146l1.361,2.1h4.829a.813.813,0,0,1,0,1.625h-.813V21.938A4.061,4.061,0,0,1,17.063,26H5.688a4.062,4.062,0,0,1-4.063-4.063V4.875H.813a.813.813,0,1,1,0-1.625H5.642l1.315-2.1A2.442,2.442,0,0,1,9.024,0h4.7a2.31,2.31,0,0,1,2.021,1.146ZM7.562,3.25h7.628l-.777-1.243a.806.806,0,0,0-.686-.382h-4.7a.806.806,0,0,0-.686.382ZM3.25,21.938a2.438,2.438,0,0,0,2.438,2.438H17.063A2.438,2.438,0,0,0,19.5,21.938V4.875H3.25Z" fill="currentColor" />
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "trash-icon",
});
</script>
