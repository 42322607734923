import {
    Vector3,
} from "@babylonjs/core/Maths/math.vector";
import BackboardEntity from "./backboard-entity";
import self from "../../index";

const {
    config,
    modules,
} = self.app;

const BackboardEntityBuilder = {
    build(frameEntity, isRemovable = false) {
        const furnitureEntity = modules.dataStore.getEntity(frameEntity.getFurnitureId());
        return new BackboardEntity({
            furnitureId: furnitureEntity.id,
            idParent: frameEntity.id,
            isRemovable,
            scaling: new Vector3(
                frameEntity.getScaling().x + config.get("backboard_groove") * 2,
                frameEntity.getScaling().y + config.get("backboard_groove") * 2,
                config.get("backboard_thickness"),
            ),
            position: new Vector3(
                frameEntity.getPosition().x - config.get("backboard_groove"),
                frameEntity.getPosition().y - config.get("backboard_groove"),
                furnitureEntity.getScaling().z - config.get("backboard_z_shift") * 2,
            ),
        });
    },
};
export default BackboardEntityBuilder;
