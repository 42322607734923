<template>
    <div class="w-6 h-6 bg-white border border-black rounded-full flex justify-center items-center">
        <ExtendIcon
            :class="[
                'w-3 h-3 text-black',
                direction === 'right' && 'rotate-90',
                direction === 'bottom' && 'rotate-180',
                direction === 'left' && '-rotate-90'
            ]"
        />
    </div>
</template>
<script>
import ExtendIcon from "../icons/ExtendIcon.vue";

export default {
    components: { ExtendIcon },
    props: {
        direction: {
            type: String,
            required: true,
        },
    },
};
</script>
