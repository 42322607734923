import BaseboardEntity from "./baseboard-entity";

const BaseboardEntityBuilder = {
    build(furnitureEntity) {
        return new BaseboardEntity({
            furnitureId: furnitureEntity.id,
        });
    },
};
export default BaseboardEntityBuilder;
