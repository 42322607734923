<template>
    <svg id="Calque_1"
         version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink"
         x="0px"
         y="0px"
         width="40px"
         height="40px"
         viewBox="0 0 40 40"
         style="enable-background:new 0 0 40 40;"
         xml:space="preserve"
    >
        <path class="st0" d="M25.3-43" />
        <path class="st1"
              d="M33.3,9.6l-3.1-3.1c-1.1-1.1-2.9-1.1-4,0l-19,19c-0.2,0.2-0.3,0.4-0.3,0.6l-1.1,7.5c0,0.2,0,0.4,0.2,0.5
	c0,0,0,0,0,0c0.1,0.1,0.4,0.2,0.6,0.2l7.1-1.4h0c0.2,0,0.4-0.2,0.6-0.3l19-19C34.4,12.5,34.4,10.7,33.3,9.6z M13.6,31.9
	C13.6,31.9,13.6,31.9,13.6,31.9l-6.7,1.3l1-7l0,0l0,0.1c0,0,2.1,0.7,3.5,2.2C12.8,29.7,13.5,31.4,13.6,31.9L13.6,31.9z M14.4,31.1
	c-0.3-0.7-0.9-1.9-1.9-3c0,0,0,0,0.1,0l3.2-3.2c0.2-0.2,0.2-0.5,0-0.7c-0.2-0.2-0.5-0.2-0.7,0l-3.2,3.2c0,0,0,0,0,0.1
	c-1.1-1-2.4-1.7-3.2-2l14.5-14.5c0.2,0.1,1.9,0.7,3.2,1.9L18,21.2c-0.2,0.2-0.2,0.5,0,0.7s0.5,0.2,0.7,0l8.4-8.4
	c1.2,1.4,1.7,3,1.8,3.1L14.4,31.1z M29.7,15.8c-0.4-0.8-1.1-2.3-2.3-3.4c-1.2-1.2-2.6-1.9-3.4-2.2l2.9-2.9c0.7-0.7,1.8-0.7,2.5,0
	l3.1,3.1c0.7,0.7,0.7,1.8,0,2.5L29.7,15.8z"
        />
    </svg>
</template>

<style type="text/css">
    .st0{fill:#FFFFFF;stroke:#000000;stroke-miterlimit:10;}
    .st1{stroke:#000000;stroke-width:0.75;stroke-miterlimit:10;}
</style>
