export const TAB_STRUCTURE_EXT = "structure-externe";
export const TAB_STRUCTURE_INT = "structure-interieur";
export const TAB_FRONT = "facade";
export const TAB_BACK = "dos";
export const TAB_ACCESSORIES = "accessoires";
export const TAB_FINISHING_TOUCHES = "finitions";

export const FIELD_WIDTH = "width";
export const FIELD_HEIGHT = "height";
export const FIELD_DEPTH = "depth";
export const FIELD_PLINTH_HEIGHT = "plinthHeight";
export const FIELD_DRAWERS_AMOUNT = "drawersAmount";

export const TYPE_DRAWER_OVERLAY = "overlay";
export const TYPE_DRAWER_INSET = "inset";
export const TYPE_DRAWER_ACCESSORY = "accessory";

export const CAMERA_DEFAULT = "default";
export const CAMERA_SWITCH = "switch";

export const BASE_MODE_FEET = "feet";
export const BASE_MODE_BASEBOARD = "baseboard";

export const FINISHING_TOUCHES = {
    BASEBOARD: "Structure",
    TRIM: "Chants structure",
    FRONT: "Portes & tiroirs",
};

export const PRICE_UNITS = {
    SQUARE_METER: "m2",
    UNIT_PRICE: "pu",
    LINEAR_METER: "ml",
};

export const WEIGHT_UNITS = {
    KILOGRAM_PER_SQUARE_METER: "kg/m2",
    UNIT_WEIGHT: "kg",
    LINEAR_METER: "ml",
};
