<template>
    <div>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28.994"
            height="29.934"
            viewBox="0 0 28.994 29.934"
        >
            <title>{{ title }}</title>
            <desc>Une icône représentant la structure intérieure de meuble</desc>
            <g id="icon-struct-ext-color" transform="translate(0.5 0.5)">
                <path id="Tracé_117"
                      data-name="Tracé 117"
                      d="M27.384,6.574H.61A.587.587,0,0,0,0,7.134V34.945a.587.587,0,0,0,.61.559.587.587,0,0,0,.61-.559v-1.9H26.774v1.906a.613.613,0,0,0,1.221,0V7.134A.587.587,0,0,0,27.384,6.574ZM15.135,31.925a4.276,4.276,0,0,0-1.164,0H1.221V7.693H26.774V31.925Z"
                      transform="translate(0 -6.574)"
                      fill="#bdc9d5"
                      stroke="#bdc9d5"
                      stroke-width="1"
                />
                <path id="Tracé_120"
                      data-name="Tracé 120"
                      d="M0,0H21.365"
                      transform="translate(10.464 23.944) rotate(-90)"
                      fill="none"
                      class="transition duration-300"
                      :stroke="isActive || isHovered ? '#ec910a' : 'currentColor'"
                      stroke-linecap="round"
                      stroke-width="2"
                />
                <path id="Tracé_121"
                      data-name="Tracé 121"
                      d="M0,0H5.4"
                      transform="translate(16.647 7.983) rotate(-90)"
                      fill="none"
                      class="transition duration-300"
                      :stroke="isActive || isHovered ? '#ec910a' : 'currentColor'"
                      stroke-linecap="round"
                      stroke-width="2"
                />
                <path id="Tracé_124"
                      data-name="Tracé 124"
                      d="M0,0H5.135"
                      transform="translate(20.181 13.118) rotate(-90)"
                      fill="none"
                      class="transition duration-300"
                      :stroke="isActive || isHovered ? '#ec910a' : 'currentColor'"
                      stroke-linecap="round"
                      stroke-width="2"
                />
                <path id="Tracé_122"
                      data-name="Tracé 122"
                      d="M0,0H4.392"
                      transform="translate(16.647 19.582) rotate(-90)"
                      fill="none"
                      class="transition duration-300"
                      :stroke="isActive || isHovered ? '#ec910a' : 'currentColor'"
                      stroke-linecap="round"
                      stroke-width="2"
                />
                <path id="Tracé_118"
                      data-name="Tracé 118"
                      d="M0,0H22.346"
                      transform="translate(2.824 7.983)"
                      fill="none"
                      class="transition duration-300"
                      :stroke="isActive || isHovered ? '#e83e8c' : 'currentColor'"
                      stroke-linecap="round"
                      stroke-width="2"
                />
                <path id="Tracé_119"
                      data-name="Tracé 119"
                      d="M0,0H22.346"
                      transform="translate(2.824 14.013)"
                      fill="none"
                      class="transition duration-300"
                      :stroke="isActive || isHovered ? '#e83e8c' : 'currentColor'"
                      stroke-linecap="round"
                      stroke-width="2"
                />
                <path id="Tracé_123"
                      data-name="Tracé 123"
                      d="M0,0H22.346"
                      transform="translate(2.824 19.781)"
                      fill="none"
                      class="transition duration-300"
                      :stroke="isActive || isHovered ? '#e83e8c' : 'currentColor'"
                      stroke-linecap="round"
                      stroke-width="2"
                />
            </g>
        </svg>
    </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "struct-int-icon",
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        isHovered: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "Icône de structure intérieure de meuble colorée",
        },
    },
});
</script>

