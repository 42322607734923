<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="18.069" viewBox="0 0 42 18.069">
        <g id="icon-rod" transform="translate(1 0.5)">
            <line id="Ligne_107"
                  data-name="Ligne 107"
                  x2="40"
                  transform="translate(0 1.18)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="2"
            />
            <path id="Tracé_306"
                  data-name="Tracé 306"
                  d="M2100.647,5538.821v4.651l-10.109,9.322c-3.5,3.466-2.968,3.087,2.423,3.087,0,0,7.545-.016,7.57,0h6.979c4.891.037,5.823.146,2.367-3.087.021-.018-9.231-9.322-9.231-9.322"
                  transform="translate(-2073.923 -5538.821)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="1"
            />
            <path id="Tracé_307"
                  data-name="Tracé 307"
                  d="M2100.2,5538.821v4.651s-9.768,9.267-9.745,9.322c-3.375,3.466-2.861,3.087,2.336,3.087h3.825"
                  transform="translate(-2086.536 -5538.821)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="1"
            />
            <line id="Ligne_120"
                  data-name="Ligne 120"
                  x2="3.36"
                  y2="3.5"
                  transform="translate(14.14 5.06)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="1"
            />
            <line id="Ligne_122"
                  data-name="Ligne 122"
                  y1="1.5"
                  transform="translate(0 1.293)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="2"
            />
            <line id="Ligne_123"
                  data-name="Ligne 123"
                  y1="1.5"
                  transform="translate(40 1.293)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="2"
            />
        </g>
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "rod-icon",
});
</script>
