import {
    Vector3,
} from "@babylonjs/core";
import FootEntity from "./foot-entity";

const FootEntityBuilder = {
    build(furnitureEntity) {
        return new FootEntity({
            furnitureId: furnitureEntity.id,
            scaling: new Vector3(40, 40, 40),
        });
    },
};
export default FootEntityBuilder;
