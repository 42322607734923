import { Color3, StandardMaterial, Texture } from "@babylonjs/core";
import MatcapPBRCustomMaterial from "./matcapPBRCustomMaterial";
import FurnituresListController from "../furnitures-list-controller";
import WoodGrainTexture from "./wood-grain";
import self from "../../index";

const {
    config,
} = self.app;

const constructionReflectionColor = Color3.White();

let mainConstructionMaterial;
let frontConstructionMaterial;
let backConstructionMaterial;

let mainFinishMaterial;
let mainFinishMaterial90;

let frontFinishMaterial;
let frontFinishMaterial90;

let borderFinishMaterial;
let borderFinishMaterial90;

let debugMaterial;
let selectedMaterial;
let chromeMaterial;

let _finishReflectionTexture;
const _debugTexture = {};

const texturesByRef = {
    matcapMainConstruction: "./assets/textures/8B7469_443E39_C7A797_544443-64px.png",
    matcapFrontConstruction: "./assets/textures/A28766_E4D6C3_D6C4AA_CAB598-64px.png",
    matcapMainFinish: "./assets/textures/8B7469_443E39_C7A797_544443-64px-desaturate.png",
    matcapChrome: "./assets/textures/6E7181_D1CFDF_ABAFC7_B4BCCE-64px.png",
    checker: "./assets/textures/uv-checker.png",
};

function getFurnitureListEntity() {
    return FurnituresListController.getInstance().furnituresListEntity;
}

const MaterialManager = {
    set constructionReflectionColor(value) {
        constructionReflectionColor.copyFrom(value);
    },

    get debugMaterial() {
        if (!debugMaterial) {
            debugMaterial = new MatcapPBRCustomMaterial("debugMaterial");
            debugMaterial.reflectionTexture = this.finishReflectionMatcapTexture;
            debugMaterial.albedoTexture = new Texture(texturesByRef.checker);
            debugMaterial.reflectionTexture.coordinatesMode = Texture.SPHERICAL_MODE;
        }
        return debugMaterial;
    },

    get selectedMaterial() {
        if (selectedMaterial) return selectedMaterial;

        selectedMaterial = new StandardMaterial("selectedMaterial");
        selectedMaterial.emissiveColor = config.get("selectedBoardColor");
        return selectedMaterial;
    },

    get chromeMaterial() {
        if (chromeMaterial) return chromeMaterial;

        chromeMaterial = new MatcapPBRCustomMaterial("chromeMaterial");
        chromeMaterial.reflectionTexture = new Texture(texturesByRef.matcapChrome);
        chromeMaterial.reflectionTexture.coordinatesMode = Texture.SPHERICAL_MODE;
        return chromeMaterial;
    },

    /**
     * Construction materials
     */

    get mainConstructionMaterial() {
        if (!mainConstructionMaterial) {
            mainConstructionMaterial = new MatcapPBRCustomMaterial("mainConstructionMaterial");
            mainConstructionMaterial.reflectionColor = constructionReflectionColor;
            mainConstructionMaterial.reflectionTexture = new Texture(texturesByRef.matcapMainConstruction);
            mainConstructionMaterial.reflectionTexture.coordinatesMode = Texture.SPHERICAL_MODE;
        }
        return mainConstructionMaterial;
    },

    get frontConstructionMaterial() {
        if (!frontConstructionMaterial) {
            frontConstructionMaterial = new MatcapPBRCustomMaterial("frontConstructionMaterial");
            frontConstructionMaterial.reflectionColor = constructionReflectionColor;
            frontConstructionMaterial.reflectionTexture = new Texture(texturesByRef.matcapFrontConstruction);
            frontConstructionMaterial.reflectionTexture.coordinatesMode = Texture.SPHERICAL_MODE;
        }
        return frontConstructionMaterial;
    },

    get backConstructionMaterial() {
        if (!backConstructionMaterial) {
            backConstructionMaterial = new MatcapPBRCustomMaterial("backConstructionMaterial");
            backConstructionMaterial.emissiveColor = Color3.Yellow();
            backConstructionMaterial.reflectionColor = constructionReflectionColor;
            backConstructionMaterial.reflectionTexture = new Texture(texturesByRef.matcapMainConstruction);
            frontConstructionMaterial.reflectionTexture.coordinatesMode = Texture.SPHERICAL_MODE;
        }
        return backConstructionMaterial;
    },

    /**
     * Finish materials
     */

    get mainFinishMaterial() {
        if (!mainFinishMaterial) {
            mainFinishMaterial = new MatcapPBRCustomMaterial("mainFinishMaterial");
            if (getFurnitureListEntity().mainMaterialDatas.woodGrain) {
                mainFinishMaterial.albedoTexture = WoodGrainTexture.normal;
            }
            mainFinishMaterial.reflectionColor.copyFrom(Color3.FromHexString(getFurnitureListEntity().mainMaterialDatas.color));
            mainFinishMaterial.reflectionTexture = this.finishReflectionMatcapTexture;
        }

        return mainFinishMaterial;
    },

    get mainFinishMaterial90() {
        if (!mainFinishMaterial90) {
            mainFinishMaterial90 = new MatcapPBRCustomMaterial("mainFinishMaterial");
            if (getFurnitureListEntity().mainMaterialDatas.woodGrain) {
                mainFinishMaterial90.albedoTexture = WoodGrainTexture.rotate90;
            }
            mainFinishMaterial90.reflectionColor.copyFrom(Color3.FromHexString(getFurnitureListEntity().mainMaterialDatas.color));
            mainFinishMaterial90.reflectionTexture = this.finishReflectionMatcapTexture;
        }

        return mainFinishMaterial90;
    },

    get frontFinishMaterial() {
        if (!frontFinishMaterial) {
            frontFinishMaterial = new MatcapPBRCustomMaterial("frontFinishMaterial");
            if (getFurnitureListEntity().frontMaterialDatas.woodGrain) {
                frontFinishMaterial.albedoTexture = WoodGrainTexture.normal;
            }
            frontFinishMaterial.reflectionColor.copyFrom(Color3.FromHexString(getFurnitureListEntity().frontMaterialDatas.color));
            frontFinishMaterial.reflectionTexture = this.finishReflectionMatcapTexture;
        }

        return frontFinishMaterial;
    },

    get frontFinishMaterial90() {
        if (!frontFinishMaterial90) {
            frontFinishMaterial90 = new MatcapPBRCustomMaterial("frontFinishMaterial");
            if (getFurnitureListEntity().frontMaterialDatas.woodGrain) {
                frontFinishMaterial90.albedoTexture = WoodGrainTexture.rotate90;
            }
            frontFinishMaterial90.reflectionColor.copyFrom(Color3.FromHexString(getFurnitureListEntity().frontMaterialDatas.color));
            frontFinishMaterial90.reflectionTexture = this.finishReflectionMatcapTexture;
        }

        return frontFinishMaterial90;
    },

    get borderFinishMaterial() {
        if (!borderFinishMaterial) {
            borderFinishMaterial = new MatcapPBRCustomMaterial("borderFinishMaterial");
            if (getFurnitureListEntity().borderMaterialDatas.woodGrain) {
                borderFinishMaterial.albedoTexture = WoodGrainTexture.normal;
            }
            borderFinishMaterial.reflectionColor.copyFrom(Color3.FromHexString(getFurnitureListEntity().borderMaterialDatas.color));
            borderFinishMaterial.reflectionTexture = this.finishReflectionMatcapTexture;
        }

        return borderFinishMaterial;
    },

    get borderFinishMaterial90() {
        if (!borderFinishMaterial90) {
            borderFinishMaterial90 = new MatcapPBRCustomMaterial("borderFinishMaterial");
            if (getFurnitureListEntity().borderMaterialDatas.woodGrain) {
                borderFinishMaterial90.albedoTexture = WoodGrainTexture.rotate90;
            }
            borderFinishMaterial90.reflectionColor.copyFrom(Color3.FromHexString(getFurnitureListEntity().borderMaterialDatas.color));
            borderFinishMaterial90.reflectionTexture = this.finishReflectionMatcapTexture;
        }

        return borderFinishMaterial90;
    },

    /**
     * Reflection texture
     */

    get finishReflectionMatcapTexture() {
        if (!_finishReflectionTexture) {
            _finishReflectionTexture = new Texture(texturesByRef.matcapMainFinish);
            _finishReflectionTexture.coordinatesMode = Texture.SPHERICAL_MODE;
        }
        return _finishReflectionTexture;
    },

    get debugTexture() {
        if (!_debugTexture.checker) {
            _debugTexture.checker = new Texture(texturesByRef.checker);
        }
        return _debugTexture.checker;
    },

    /**
     * Finish Colors
     */

    set mainFinishColor(value) {
        const color = Color3.FromHexString(value);
        this.mainFinishMaterial.reflectionColor = color;
        this.mainFinishMaterial90.reflectionColor = color;
    },

    set frontFinishColor(value) {
        const color = Color3.FromHexString(value);
        this.frontFinishMaterial.reflectionColor = color;
        this.frontFinishMaterial90.reflectionColor = color;
    },

    set borderFinishColor(value) {
        const color = Color3.FromHexString(value);
        this.borderFinishMaterial.reflectionColor = color;
        this.borderFinishMaterial90.reflectionColor = color;
    },

    /**
     * Finish Wood Grain
     */

    set hasMainFinishWoodGrain(value) {
        if (value) {
            this.mainFinishMaterial.albedoTexture = WoodGrainTexture.normal;
            this.mainFinishMaterial90.albedoTexture = WoodGrainTexture.rotate90;
        } else {
            this.mainFinishMaterial.albedoTexture = null;
            this.mainFinishMaterial90.albedoTexture = null;
        }
    },

    set hasFrontFinishWoodGrain(value) {
        if (value) {
            this.frontFinishMaterial.albedoTexture = WoodGrainTexture.normal;
            this.frontFinishMaterial90.albedoTexture = WoodGrainTexture.rotate90;
        } else {
            this.frontFinishMaterial.albedoTexture = null;
            this.frontFinishMaterial90.albedoTexture = null;
        }
    },

    set hasBorderFinishWoodGrain(value) {
        if (value) {
            this.borderFinishMaterial.albedoTexture = WoodGrainTexture.normal;
            this.borderFinishMaterial90.albedoTexture = WoodGrainTexture.rotate90;
        } else {
            this.borderFinishMaterial.albedoTexture = null;
            this.borderFinishMaterial90.albedoTexture = null;
        }
    },
};
export default MaterialManager;
