import {
    Vector3,
} from "@babylonjs/core/Maths/math.vector";

import BoardEntity from "./board-entity";
import {
    ORIENTATION_HORIZONTAL,
    ORIENTATION_VERTICAL,
} from "../constants";

class BoardEntityBuilder {

    /**
     *
     * @param {FrameEntity} parentFrameEntity
     * @param {Number} positionX
     * @returns {BoardEntity}
     */
    static buildVertical(parentFrameEntity, positionX) {
        const leftFrameScalingX = positionX - parentFrameEntity.getPosition().x - parentFrameEntity.halfThickness;
        const entityParams = Object.assign(
            BoardEntityBuilder.getCommonEntityParams(parentFrameEntity), {
                scaling: new Vector3(parentFrameEntity.thickness, parentFrameEntity.getScaling().y, parentFrameEntity.getScaling().z),
                position: new Vector3(
                    parentFrameEntity.getPosition().x + leftFrameScalingX,
                    parentFrameEntity.getPosition().y,
                    parentFrameEntity.getPosition().z
                ),
                orientation: ORIENTATION_VERTICAL,
            }
        );
        return new BoardEntity(entityParams);
    }

    /**
     *
     * @param {FrameEntity} parentFrameEntity
     * @param {Number} positionX
     * @returns {BoardEntity}
     */
    static buildHorizontal(parentFrameEntity, positionY) {
        const leftFrameScalingY = positionY - parentFrameEntity.getPosition().y - parentFrameEntity.halfThickness;
        const entityParams = Object.assign(
            BoardEntityBuilder.getCommonEntityParams(parentFrameEntity), {
                scaling: new Vector3(parentFrameEntity.getScaling().x, parentFrameEntity.thickness, parentFrameEntity.getScaling().z),
                position: new Vector3(
                    parentFrameEntity.getPosition().x,
                    parentFrameEntity.getPosition().y + leftFrameScalingY,
                    parentFrameEntity.getPosition().z
                ),
                orientation: ORIENTATION_HORIZONTAL,
            }
        );
        return new BoardEntity(entityParams);
    }

    /**
     *
     * @param {FurnitureEntity} furnitureEntity
     * @returns BoardEntity
     */
    static buildLeftBlockPart(furnitureEntity) {

        const entityParams = Object.assign(
            BoardEntityBuilder.getCommonEntityBlockPartParams(furnitureEntity), {
                scaling: new Vector3(
                    furnitureEntity.getThickness(),
                    furnitureEntity.getScaling().y,
                    furnitureEntity.getScaling().z
                ),
                position: furnitureEntity.getPosition().clone(),
                orientation: ORIENTATION_VERTICAL,
                hasAllFacesVisible: true,
            }
        );

        return new BoardEntity(entityParams);
    }

    /**
     *
     * @param {FurnitureEntity} furnitureEntity
     * @returns BoardEntity
     */
    static buildRightBlockPart(furnitureEntity) {
        const entityParams = Object.assign(
            BoardEntityBuilder.getCommonEntityBlockPartParams(furnitureEntity), {
                scaling: new Vector3(
                    furnitureEntity.getThickness(),
                    furnitureEntity.getScaling().y,
                    furnitureEntity.getScaling().z
                ),
                position: new Vector3(
                    furnitureEntity.getScaling().x - furnitureEntity.getThickness(),
                    furnitureEntity.getPosition().y,
                    furnitureEntity.getPosition().z,
                ),
                orientation: ORIENTATION_VERTICAL,
                hasAllFacesVisible: true,
            }
        );

        return new BoardEntity(entityParams);
    }

    /**
     *
     * @param {FurnitureEntity} furnitureEntity
     * @returns BoardEntity
     */
    static buildBottomBlockPart(furnitureEntity) {
        const entityParams = Object.assign(
            BoardEntityBuilder.getCommonEntityBlockPartParams(furnitureEntity), {
                scaling: new Vector3(
                    furnitureEntity.getScaling().x - furnitureEntity.getThickness() * 2,
                    furnitureEntity.getThickness(),
                    furnitureEntity.getScaling().z
                ),
                position: new Vector3(
                    furnitureEntity.getThickness(),
                    furnitureEntity.getPosition().y,
                    furnitureEntity.getPosition().z,
                ),
                orientation: ORIENTATION_HORIZONTAL,
            }
        );
        return new BoardEntity(entityParams);
    }

    /**
     *
     * @param {FurnitureEntity} furnitureEntity
     * @returns BoardEntity
     */
    static buildTopBlockPart(furnitureEntity) {
        const entityParams = Object.assign(
            BoardEntityBuilder.getCommonEntityBlockPartParams(furnitureEntity), {
                scaling: new Vector3(
                    furnitureEntity.getScaling().x - furnitureEntity.getThickness() * 2,
                    furnitureEntity.getThickness(),
                    furnitureEntity.getScaling().z
                ),
                position: new Vector3(
                    furnitureEntity.getThickness(),
                    furnitureEntity.getScaling().y - furnitureEntity.getThickness(),
                    furnitureEntity.getPosition().z,
                ),
                orientation: ORIENTATION_HORIZONTAL,
            }
        );
        return new BoardEntity(entityParams);
    }

    /**
     *
     * @param {FrameEntity} parentFrameEntity
     * @returns {Object}
     */
    static getCommonEntityParams(parentFrameEntity) {
        if (!parentFrameEntity) throw new Error("parentFrameEntity is a required parameter.");
        return {
            furnitureId: parentFrameEntity.getFurnitureId(),
            idParent: parentFrameEntity.id,
        };
    }

    /**
     *
     * @param {FrameEntity} parentFrameEntity
     * @returns {Object}
     */
    static getCommonEntityBlockPartParams(furnitureEntity) {
        if (!furnitureEntity) throw new Error("furnitureEntity is a required parameter.");
        return {
            furnitureId: furnitureEntity.id,
            idParent: "",
            isBlockPart: true,
        };
    }

}

export default BoardEntityBuilder;
