import FootMesh from "./foot-mesh";

const FootMeshBuilder = {
    buildDefault(footEntity, parent) {
        footEntity.container.parent = parent;
        const footMesh = new FootMesh(footEntity);
        footMesh.parent = footEntity.container;
        footMesh.metadata = Object.assign(footMesh.metadata || {}, {
            footEntity: footEntity,
        });
        footEntity.mesh = footMesh;

        return footMesh;
    },
};

export default FootMeshBuilder;
