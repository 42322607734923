<template>
    <svg
        id="arrow-right"
        width="8"
        height="14.176"
        viewBox="0 0 8 14.176"
        stroke="currentColor"
        fill="currentColor"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:dc="http://purl.org/dc/elements/1.1/"
    >
        <defs
            id="defs10"
        />
        <title
            id="title2"
        >Chevron vers la droite</title>
        <desc
            id="desc4"
        >Une flèche vers la droite</desc>
        <path
            id="Tracé_131"
            data-name="Tracé 131"
            d="m 7.0642533,-0.00431486 a 1.416,1.416 0 0 1 0.978,2.42099896 l -4.635,4.6350001 4.635,4.6359998 a 1.42,1.42 0 1 1 -2.008,2.008001 L 0.42025405,7.9786842 a 1.4,1.4 0 0 1 0,-2.009 L 6.0342533,0.35568514 a 1.574,1.574 0 0 1 1.03,-0.36 z"
        />
        <metadata
            id="metadata160"
        >
            <rdf:RDF>
                <cc:Work
                    rdf:about=""
                >
                    <dc:title>Chevron vers la droite</dc:title>
                </cc:Work>
            </rdf:RDF>
        </metadata>
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "chevron-left-icon",
});
</script>
