import {
    Matrix,
} from "@babylonjs/core";
import FrameController from "../frame/frame-controller";
import self from "../../index";
import FrameMesh from "../frame/frame-mesh";
import OverlaydrawerController from "./overlaydrawer-controller";
import {
    BOARD_THICKNESS,
    DRAWER_NONE,
} from "../constants";
import OverlayHelper from "../overlay/overlay-helper";
import DrawerHelper from "../drawer/drawer-helper";

const {
    modules,
    events,
} = self.app;

const OverlaydrawerHelper = {
    actionToOverlaydrawer(event, type) {
        if (!FrameController.isActiveTool()) return;
        const {
            scene,
        } = modules.obsidianBabylonEngine;
        const ray = scene.createPickingRay(event.layerX, event.clientY, Matrix.Identity(), scene.activeCamera);
        const hit = scene.pickWithRay(ray);
        if (!(hit.pickedMesh instanceof FrameMesh)) return;
        const frameEntity = modules.dataStore.getEntity(hit.pickedMesh.metadata.frameId);
        if (!frameEntity) return;
        switch (type) {
            case DRAWER_NONE:
                OverlaydrawerController.deleteDrawer(frameEntity);
                break;

            default:
                if (DrawerHelper.canAddToFrame(frameEntity)) {
                    OverlaydrawerController.createDrawer(frameEntity, type);
                } else {
                    events.emit("notification:message", {
                        title: "Placement incorrecte",
                        text: "Emplacement déjà occupé par un autre élément.",
                        status: "error",
                    });
                }
                break;
        }
    },

    updatePosition(overlaydrawerEntity) {
        const positionX = overlaydrawerEntity.parentEntity.position.x -
            OverlayHelper.getOutsideDecaleOnBoard(overlaydrawerEntity.getHasNeighborLeft());
        overlaydrawerEntity.positionX = positionX;
        const positionY = overlaydrawerEntity.parentEntity.position.y -
            OverlayHelper.getOutsideDecaleOnBoard(overlaydrawerEntity.getHasNeighborBottom());
        overlaydrawerEntity.positionY = positionY;
        overlaydrawerEntity.positionZ = overlaydrawerEntity.parentEntity.position.z - BOARD_THICKNESS;
    },

    updateScaling(overlaydrawerEntity) {
        const scaleX = overlaydrawerEntity.parentEntity.scaling.x +
            OverlayHelper.getOutsideDecaleOnBoard(overlaydrawerEntity.getHasNeighborLeft()) +
            OverlayHelper.getOutsideDecaleOnBoard(overlaydrawerEntity.getHasNeighborRight());
        overlaydrawerEntity.scalingX = scaleX;

        const scaleY = overlaydrawerEntity.parentEntity.scaling.y +
            OverlayHelper.getOutsideDecaleOnBoard(overlaydrawerEntity.getHasNeighborBottom()) +
            OverlayHelper.getOutsideDecaleOnBoard(overlaydrawerEntity.getHasNeighborTop());
        overlaydrawerEntity.scalingY = scaleY;
        overlaydrawerEntity.scalingZ = BOARD_THICKNESS;
    },

};

export default OverlaydrawerHelper;
