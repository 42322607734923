import ATool from "../../../tool-manager/src/atool";
import {
    TOOL_MODE_SELECT,
} from "../constants";

import self from "../../index";
import PulloutshelfController from "./pulloutshelf-controller";

const {
    modules,
    events,
} = self.app;

class PulloutshelfATool extends ATool {

    constructor() {
        super({
            tool_name: "pulloutshelf",
        });
        this.tool_modes = [TOOL_MODE_SELECT];
        modules.toolManager.controller.registerTool(this);
    }

    // Atool method
    // eslint-disable-next-line class-methods-use-this
    onActiveCallback() {
        const allPulloutshelfs = modules.dataStore.listEntities("pulloutshelf");
        allPulloutshelfs.forEach((pulloutshelfEntity) => {
            pulloutshelfEntity.hitbox.isPickable = true;
        });
    }

    // Atool method
    // eslint-disable-next-line class-methods-use-this
    onEndActiveCallback() {
        const allPulloutshelfs = modules.dataStore.listEntities("pulloutshelf");
        allPulloutshelfs.forEach((pulloutshelfEntity) => {
            pulloutshelfEntity.hitbox.isPickable = false;
        });
        PulloutshelfController.currentPulloutshelfEntity = null;
        events.emit("pulloutshelf:tool:endActive");
    }

}

export default PulloutshelfATool;
