<!-- Main Vue for the obsidian app -->

<template>
    <!-- HTML template -->
    <div id="app" class="grid grid-rows-[minmax(0,_1fr)_160px] h-screen overflow-hidden">
        <div class="h-full relative">
            <!-- Contains the main menu (on the left ) -->
            <MainMenu />

            <HistoryView />

            <!-- The main  canvas, where the BabylonJs Scene is rendered -->
            <div id="export">
                <MainCanvas />

                <Measure />
            </div>

            <OverallDimensions />

            <RightMenu />

            <Tweakpane />

            <Notification />
            <Modal />
        </div>

        <BottomMenu />
        <CanvasUIOverlay />
        <WanadevLoaderView />
    </div>
</template>


<script>
// Js Part
import { defineComponent } from "@vue/runtime-core";
import MouseTrap from "mousetrap";
import self from "../../index";
import updateComposition from "../services/updateComposition";

import useFurnitureStore from "../stores/furniture";
import useGlobalStore from "../stores/global";

// Vue components
import CanvasUIOverlay from "./canvas-ui/CanvasUIOverlay.vue";
import MainMenu from "./menu/MainMenu.vue";
import MainCanvas from "./main-canvas.vue";
import Tweakpane from "./tweakpane.vue";
import HistoryView from "./history-view.vue";
import RightMenu from "./menu/RightMenu.vue";
import BottomMenu from "./bottom-menu/BottomMenu.vue";
import Notification from "./common/Notification.vue";
import Modal from "./common/Modal.vue";
import OverallDimensions from "./menu/OverallDimensions.vue";
import Measure from "./menu-tools/Measure.vue";
import WanadevLoaderView from "./wanadev-loader-view.vue";

const CamerasManagerController = require("modules/cameras-manager/src/cameras-manager-controller").default;

const {
    modules,
    events,
} = self.app;

// Minimal export
export default defineComponent({
    name: "app",
    components: {
        CanvasUIOverlay,
        MainMenu,
        MainCanvas,
        Modal,
        Tweakpane,
        HistoryView,
        RightMenu,
        BottomMenu,
        Notification,
        OverallDimensions,
        Measure,
        WanadevLoaderView,
    },
    data() {
        return {
            camerasManager: undefined,
        };
    },
    computed: {
        store() {
            return useFurnitureStore();
        },
        globalStore() {
            return useGlobalStore();
        },
    },
    created() {
        self.app.log.info("Hey, it's me, the main vue for your Obsidian app", this.meshManager);
        this.camerasManager = modules.camerasManager.controller;

        events.on("@furnitures.furniture:composition:updated", (furnitureComposition) => {
            updateComposition(furnitureComposition);
        });
        events.on("@furnitures.overlaydrawer:updated", (data) => {
            this.store.setOverlayDrawers(data);
            events.emit("front:activetab", "tiroirs_en_applique");
        });
        events.on("@furnitures.insetdrawer:updated", (data) => {
            this.store.setInsetDrawers(data);
            events.emit("front:activetab", "tiroirs_encastré");
        });
        events.on("@furnitures.accessorydrawer:updated", (data) => {
            this.store.setAccessoryDrawers(data);
        });
        events.on("@furnitures.drawer:deleted", () => {
            this.store.resetCurrentDrawer();
        });
        events.on("@furnitures.board:selected", (board) => {
            this.store.setCurrentBoard(board);
        });
        events.on("@furnitures.board:deselected", () => {
            this.store.setCurrentBoard(null);
            this.store.measures = [];
        });
        events.on("@furnitures.movableshelf:selected", (movableshelf) => {
            this.store.setCurrentMovableShelf(movableshelf);
        });
        const onMeasuresUpdated = (measures) => {
            this.store.measures = measures || [];
        };
        events.on("@furnitures.measures:updated", onMeasuresUpdated);
        events.on("@vuejs.measures:updated", onMeasuresUpdated);
    },
    mounted() {
        MouseTrap.bind("shift+c", () => {
            this.camerasManager.switchCamera();
        });
        MouseTrap.bind("t", () => {
            this.switchView("top");
        });
        MouseTrap.bind("f", () => {
            this.switchView("front");
        });
        MouseTrap.bind("s", () => {
            this.switchView("side");
        });
        MouseTrap.bind("c", () => {
            this.focusOnMeshes();
        });
    },
    unmounted() {
        MouseTrap.unbind("shift+c");
        MouseTrap.unbind("t");
        MouseTrap.unbind("f");
        MouseTrap.unbind("s");
    },
    methods: {
        focusOnMeshes() {
            this.camerasManager.focusOnMeshes();
        },
        switchView(viewName) {
            this.camerasManager.switchCamera(CamerasManagerController.CAMERA_ARC_ROTATE);
            this.camerasManager.setViewByName(viewName);
        },
    },
});
</script>

