import self from "../../index";
import InsetdrawerController from "./insetdrawer-controller";

const {
    events,
    modules,
} = self.app;

function onDeleteInsetdrawer(params) {
    const frameEntity = modules.dataStore.getEntity(params.id);
    if (!frameEntity) return;
    InsetdrawerController.deleteInsetdrawer(frameEntity);
}

function onSelectInsetdrawer(params) {
    const frameEntity = modules.dataStore.getEntity(params.id);
    if (!frameEntity) return;
    const insetdrawerEntity = modules.dataStore.getEntity(frameEntity.idDrawer);
    if (!insetdrawerEntity) return;
    InsetdrawerController.selectInsetdrawer(insetdrawerEntity);
}


const InsetdrawerEventsManager = {
    addEventListeners() {
        events.on("@vuejs.insetdrawer:select", InsetdrawerController.selectInsetdrawerById);
        events.on("@vuejs.insetdrawer:update:quantity", InsetdrawerController.updateQuantity);
        events.on("@vuejs.insetdrawer:showAll", InsetdrawerController.showAll);
        events.on("@vuejs.insetdrawer:hideAll", InsetdrawerController.hideAll);
        events.on("@vuejs.history:back", InsetdrawerEventsManager.onHistoryChange);
        events.on("@vuejs.history:forward", InsetdrawerEventsManager.onHistoryChange);

        events.on("insetdrawer:select", InsetdrawerController.selectInsetdrawer);
        events.on("insetdrawer:update:quantity", InsetdrawerController.updateQuantity);

        events.on("@vuejs.insetdrawer:delete", onDeleteInsetdrawer);
        events.on("@vuejs.insetdrawer:select", onSelectInsetdrawer);
    },

    emitUpdated() {
        events.emit("insetdrawer:updated", InsetdrawerController.getCurrentInsetdrawerEntityCopyForUi());
    },

    onHistoryChange() {
        InsetdrawerController.updateFromHistoryChange();
        if (!InsetdrawerController.currentInsetdrawerEntity) return;
        const currentInsetdrawerEntity = modules.dataStore.getEntity(InsetdrawerController.currentInsetdrawerEntity.id);
        if (!currentInsetdrawerEntity) {
            InsetdrawerController.currentInsetdrawerEntity = undefined;
        }
        InsetdrawerEventsManager.emitUpdated();
    },

    emitRejected(isMax) {
        events.emit("insetdrawer:amount:rejected");
        events.emit("show:modal", {
            text: isMax ? "Vous avez atteint le nombre maximal de tiroirs, il faut augmenter la hauteur de la niche pour augmenter le nombre"
                : "Vous avez atteint le nombre minimal de tiroirs, il faut ajouter une séparation pour réduire le nombre",
            isInfo: true,
        });
    },
};

export default InsetdrawerEventsManager;
