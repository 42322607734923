import {
    BOARD_THICKNESS,
} from "../constants";
import OverlayHelper from "../overlay/overlay-helper";
import DoorHelper from "./door-helper";

const DoorEntityHelper = {

    updateDoorOverlayPosition(doorEntity) {
        const bBox2 = DoorHelper.getDoorFramesBBox2Union(doorEntity);
        const positionX = bBox2.p1.x -
            OverlayHelper.getOutsideDecaleOnBoard(doorEntity.getHasNeighborLeft());
        doorEntity.positionX = positionX;
        const positionY = bBox2.p1.y -
            OverlayHelper.getOutsideDecaleOnBoard(doorEntity.getHasNeighborBottom());
        doorEntity.positionY = positionY;
        doorEntity.positionZ = 0;
    },

    updateDoorOverlayScaling(doorEntity) {
        const bBox2 = DoorHelper.getDoorFramesBBox2Union(doorEntity);
        const scaleX = bBox2.width +
            OverlayHelper.getOutsideDecaleOnBoard(doorEntity.getHasNeighborLeft()) +
            OverlayHelper.getOutsideDecaleOnBoard(doorEntity.getHasNeighborRight());
        doorEntity.scalingX = scaleX;

        const scaleY = bBox2.height +
            OverlayHelper.getOutsideDecaleOnBoard(doorEntity.getHasNeighborBottom()) +
            OverlayHelper.getOutsideDecaleOnBoard(doorEntity.getHasNeighborTop());
        doorEntity.scalingY = scaleY;
        doorEntity.scalingZ = BOARD_THICKNESS;
    },

    /**
     * Update this scaling of an inside or outside door.
     * Used by doorEntity
     * @param {DoorEntity} doorEntity
     */
    updateDoorPosition(doorEntity) {
        if (doorEntity.isFrontModeInside()) return;

        DoorEntityHelper.updateDoorOverlayPosition(doorEntity);
    },

    /**
     * Update this scaling of an inside or outside door.
     * Used by doorEntity
     * @param {DoorEntity} doorEntity
     */
    updateDoorScaling(doorEntity) {
        if (doorEntity.isFrontModeInside()) {
            const bBox2 = DoorHelper.getDoorFramesBBox2Union(doorEntity);
            doorEntity.scalingX = bBox2.width;
            doorEntity.scalingY = bBox2.height;
        } else {
            DoorEntityHelper.updateDoorOverlayScaling(doorEntity);
        }
        doorEntity.scalingZ = BOARD_THICKNESS;
    },
};

export default DoorEntityHelper;
