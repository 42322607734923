<template>
    <button
        :title="isActive ? t('Voir en 2D') : t('Voir en 3D')"
        :class="[
            'transition-all duration-300',
            'flex justify-center items-center w-11 h-11 rounded',
            !isActive && 'hover:from-white hover:via-theme-pink-400 hover:to-theme-orange-400 hover:bg-pos-100',
            !isActive && 'bg-gradient-to-br from-white via-white to-white bg-size-200 bg-pos-0',
            isActive && 'bg-gradient-to-br from-pink-400 to-theme-orange-400 bg-pos-100',
        ]"
        @mouseover="isHovered = true"
        @mouseleave="isHovered = false"
        @click="changeCamera"
    >
        <ThreeDimensionIcon
            :is-colored="!isActive && !isHovered"
            :title="isActive ? t('Voir en 2D') : t('Voir en 3D')"
        />
    </button>
</template>

<script>
import self from "../../..";
import useGlobalStore from "../../stores/global";

import ThreeDimensionIcon from "../icons/ThreeDimensionIcon.vue";
import { CAMERA_SWITCH } from "../../services/constants";

const { modules } = self.app;

export default {
    name: "unlock-camera-button",
    components: {
        ThreeDimensionIcon,
    },
    data() {
        return {
            isHovered: false,
        };
    },
    computed: {
        store() {
            return useGlobalStore();
        },
        isActive() {
            return this.store.cameraMode === CAMERA_SWITCH;
        },
    },
    methods: {
        t(...args) {
            return modules.stonejs.gettext(...args);
        },
        changeCamera() {
            this.store.switchCameraMode();
        },
    },
};

</script>
