// import "@babylonjs/core/Debug/debugLayer"; // Augments the scene with the debug methods
// Injects a local ES6 version of the inspector to prevent automatically relying on the none compatible version
// import "@babylonjs/inspector";

import {
    Color4,
    PointerEventTypes,
} from "@babylonjs/core";

import self from "../index";

const {
    modules,
} = self.app;

const {
    furnituresListController,
} = modules.furnitures;

/**
 * Main Controller module
 * It gives fast access to most important module and variables,
 * it's built to create POC quicker
 */
class MainController {

    constructor() {
        this.engine = modules.obsidianBabylonEngine;

        this.engine.waitForLoading().then(() => {
            modules.obsidianMeshManager.init(this.engine.scene);
            modules.wanadevLoader.controller.hide();
            this.initialize();
        });

        this.debugPointerDownRaycast = false;
    }

    async initialize() {
        this.canvas = this.engine.canvas;
        this.camera = this.engine.camera;
        // modules.cameraView.setupView(5, -(Math.PI / 2), (Math.PI / 2), true);
        // modules.cameraView.setViewByName("top");


        this.scene = this.engine.scene;
        this.scene.clearColor = new Color4(0, 0, 0, 0);
        this.scene.getBoundingBoxRenderer().frontColor.set(1, 0, 0);
        this.scene.getBoundingBoxRenderer().backColor.set(0, 1, 0);

        this.lights = this.scene.lights;

        // this.environmentController = modules.environment.controller;
        // this.ground = this.environment.ground;

        this.meshManager = modules.obsidianMeshManager;

        // FURNITURE
        furnituresListController.initialize();

        // wait first paint for focus on meshes

        modules.obsidianBabylonEngine.scene.onAfterRenderObservable.addOnce(() => {
            modules.camerasManager.controller.focusOnMeshes();
        });

        if (this.debugPointerDownRaycast) {
            this.pointerDownCallback = this.onPointerDown.bind(this);
            this.scene.onPointerObservable.add(this.pointerDownCallback, PointerEventTypes.POINTERDOWN);
        }

        // console.log("furnituresListEntity", this.furnituresListEntity);
        if (process.env.NODE_ENV === "development") {
            console.log("this.scene", this.scene);
            console.log("dataStore", modules.dataStore);
            console.log("history", modules.history);
            window.scene = this.scene;
            window.dataStore = modules.dataStore;
            window.hist = modules.history;
        }


    }

    // eslint-disable-next-line class-methods-use-this
    onPointerDown(pointerInfo) {
        const {
            pickedMesh,
        } = pointerInfo.pickInfo;
        console.log(pickedMesh);
    }

}

export default MainController;
