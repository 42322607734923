<template>
    <div class="pl-6">
        <InfoLabel>
            {{ t("Faites glisser un élément sur la scène") }}
        </InfoLabel>
        <div class="flex mt-2">
            <DraggableCard
                :start-drag-events="[
                    {
                        eventName: 'tool:frame:update',
                        params: { toolModeName: TOOL_MODE_SPLIT },
                    },
                ]"
                :drop-events="[
                    {
                        eventName: 'frame:action:split',
                        params: { orientation: ORIENTATION_HORIZONTAL },
                    },
                ]"
            >
                <HorizontalSplitIcon />
                <template #text>{{ t("Etagère\nfixe") }}</template>
            </DraggableCard>

            <DraggableCard
                :start-drag-events="[
                    {
                        eventName: 'tool:frame:update',
                        params: { toolModeName: TOOL_MODE_SPLIT },
                    },
                ]"
                :drop-events="[
                    {
                        eventName: 'frame:action:split',
                        params: { orientation: ORIENTATION_VERTICAL },
                    },
                ]"
            >
                <VerticalSplitIcon />
                <template #text>{{ t("Séparation\nverticale") }}</template>
            </DraggableCard>

            <SelectableCard
                v-if="currentBoard !== null"
                :selected="false"
                @click="removeBoard"
            >
                {{ t("Supprimer\nséparation") }}
                <template #svgIcon>
                    <TrashIcon class="text-white" />
                </template>
            </SelectableCard>
        </div>
    </div>
</template>

<script>
import {
    ORIENTATION_HORIZONTAL,
    ORIENTATION_VERTICAL,
    TOOL_MODE_SELECT,
    TOOL_MODE_SPLIT,
} from "../../../../furnitures/src/constants";

import self from "../../..";
import useFurnitureStore from "../../stores/furniture";

import DraggableCard from "./DraggableCard.vue";
import SelectableCard from "./SelectableCard.vue";
import HorizontalSplitIcon from "../icons/HorizontalSplitIcon.vue";
import VerticalSplitIcon from "../icons/VerticalSplitIcon.vue";
import TrashIcon from "../icons/TrashIcon.vue";
import InfoLabel from "../common/InfoLabel.vue";

const { modules, events } = self.app;

export default {
    name: "internal-structure",
    components: {
        DraggableCard,
        HorizontalSplitIcon,
        VerticalSplitIcon,
        TrashIcon,
        SelectableCard,
        InfoLabel,
    },
    data() {
        return {
            ORIENTATION_VERTICAL,
            ORIENTATION_HORIZONTAL,
            TOOL_MODE_SPLIT,
            TOOL_MODE_SELECT,
        };
    },
    computed: {
        store() {
            return useFurnitureStore();
        },
        currentBoard() {
            return this.store.currentBoard;
        },
    },
    methods: {
        t(...args) {
            return modules.stonejs.gettext(...args);
        },
        removeBoard() {
            events.emit("board:remove");
        },
    },
};
</script>
