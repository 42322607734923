<template>
    <div>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30.269"
            viewBox="0 0 30 30.269"
        >
            <title>{{ title }}</title>
            <desc>Une icône représentant la structure extérieure de meuble</desc>
            <g id="icon-struct-int-color" transform="translate(-39 -227.731)">
                <path
                    id="Tracé_117"
                    class="transition duration-300"
                    data-name="Tracé 117"
                    d="M27.384,6.574H.61A.587.587,0,0,0,0,7.134V34.945a.587.587,0,0,0,.61.559.587.587,0,0,0,.61-.559v-1.9H26.774v1.906a.613.613,0,0,0,1.221,0V7.134A.587.587,0,0,0,27.384,6.574ZM1.221,31.925V7.693H26.774V31.925Z"
                    transform="translate(40.003 221.657)"
                    :fill="isActive || isHovered ? '#e83e8c' : 'currentColor'"
                    :stroke="isActive || isHovered ? '#e83e8c' : 'currentColor'"
                    stroke-width="1"
                />
                <g id="Groupe_322" data-name="Groupe 322">
                    <line id="Ligne_5"
                          data-name="Ligne 5"
                          y2="20"
                          transform="translate(54.5 231.5)"
                          fill="none"
                          stroke="#bdc9d5"
                          stroke-linecap="round"
                          stroke-width="2"
                    />
                    <line id="Ligne_6"
                          data-name="Ligne 6"
                          x2="21"
                          transform="translate(43.5 241.5)"
                          fill="none"
                          stroke="#bdc9d5"
                          stroke-linecap="round"
                          stroke-width="2"
                    />
                </g>
                <g id="Rectangle_358"
                   data-name="Rectangle 358"
                   transform="translate(39 253)"
                   class="transition duration-300"
                   :fill="isActive || isHovered ? '#ffc107' : 'currentColor'"
                   :stroke="isActive || isHovered ? '#ffc107' : 'currentColor'"
                   stroke-width="1"
                >
                    <rect width="3" height="5" rx="1.5" stroke="none" />
                    <rect x="0.5"
                          y="0.5"
                          width="2"
                          height="4"
                          rx="1"
                          fill="none"
                    />
                </g>
                <g id="Rectangle_359"
                   class="transition duration-300"
                   data-name="Rectangle 359"
                   transform="translate(66 253)"
                   :fill="isActive || isHovered ? '#ffc107' : 'currentColor'"
                   :stroke="isActive || isHovered ? '#ffc107' : 'currentColor'"
                   stroke-width="1"
                >
                    <rect width="3" height="5" rx="1.5" stroke="none" />
                    <rect x="0.5"
                          y="0.5"
                          width="2"
                          height="4"
                          rx="1"
                          fill="none"
                    />
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "struct-ext-icon",
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        isHovered: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "Icône de structure extérieure de meuble",
        },
    },
});
</script>
