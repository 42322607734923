<script>
import self from "../../../index";

const { events } = self.app;

export default {
    data() {
        return {
            isVisible: false,
            text: "",
            isInfo: false,
            validateEvent: "",
            validateEventParams: null,
        };
    },
    mounted() {
        events.on("@furnitures.show:modal", (payload) => {
            this.buildFromPayload(payload);
        });
        events.on("show:modal", (payload) => {
            this.buildFromPayload(payload);
        });
    },
    methods: {
        resetModal() {
            this.isVisible = false;
            this.text = "";
            this.isInfo = false;
            this.validateEvent = "";
        },
        validateModal() {
            events.emit(this.validateEvent, this.validateEventParams);
            this.resetModal();
        },
        buildFromPayload(payload) {
            this.text = payload.text;
            this.isInfo = !!payload.isInfo;
            this.validateEvent = payload.validateEvent;
            this.validateEventParams = payload.validateEventParams;
            this.isVisible = true;
        },
    },
};
</script>

<template>
    <transition name="fade">
        <div
            v-if="isVisible"
            class="h-full w-full z-20 absolute top-0 left-0 bg-black bg-opacity-70 flex flex-col justify-center items-center gap-y-16"
        >
            <div class="w-1/3 text-center font-bold text-white text-base" v-html="text" />
            <div class="flex flex-row gap-x-4 pointer-events-auto">
                <button
                    v-if="!isInfo"
                    class="w-40 h-11 bg-black text-white text-base font-bold"
                    @click="validateModal"
                >
                    Continuer
                </button>
                <button
                    v-if="!isInfo"
                    class="w-40 h-11 bg-white text-theme-pink-400 font-bold border border-theme-pink-400"
                    @click="resetModal"
                >
                    Annuler
                </button>
                <button
                    v-if="isInfo"
                    class="w-40 h-11 bg-white text-theme-pink-400 font-bold border border-theme-pink-400"
                    @click="resetModal"
                >
                    Compris
                </button>
            </div>
        </div>
    </transition>
</template>
