import { BASE_MODE_BASEBOARD, BASE_MODE_FEET } from "./constants";
import useGlobalStore from "../stores/global";
import useFurnitureStore from "../stores/furniture";

function getProductFromRef(productRef, catalog) {
    if (productRef === null) {
        return null;
    }

    return catalog.find((product) => product.product_ref === productRef);
}

export default function updateComposition(furnitureComposition) {
    const {
        transform,
        mainMaterialRef,
        borderMaterialRef,
        frontMaterialRef,
    } = furnitureComposition.metadata;

    const globalStore = useGlobalStore();
    const store = useFurnitureStore();

    store.setDimensions(transform.scaling);
    store.setDimensionsOverall(transform.overall);
    store.setPlinthHeight(transform.baseHeight);
    store.setFrontMode(transform.frontMode);

    if (transform.containFeet) {
        store.setBaseMode(BASE_MODE_FEET);
    } else if (transform.hasBaseboard) {
        store.setBaseMode(BASE_MODE_BASEBOARD);
    } else {
        store.setBaseMode(null);
    }

    if (mainMaterialRef !== globalStore.selectedProducts.board.product_ref) {
        globalStore.setSelectedBoard(getProductFromRef(mainMaterialRef, globalStore.catalogs.boards));
    }

    if (frontMaterialRef !== globalStore.selectedProducts.front.product_ref) {
        globalStore.setSelectedFront(getProductFromRef(frontMaterialRef, globalStore.catalogs.boards));
    }

    if (borderMaterialRef !== globalStore.selectedProducts.border.product_ref) {
        globalStore.setSelectedBorder(getProductFromRef(borderMaterialRef, globalStore.catalogs.borders));
    }

    if (furnitureComposition.doorKnobs.ref !== globalStore.selectedProducts.doorButton?.product_ref) {
        globalStore.setSelectedButton(getProductFromRef(furnitureComposition.doorKnobs.ref, globalStore.catalogs.buttons));
    }

    if (furnitureComposition.feet.ref !== globalStore.selectedProducts.feet?.product_ref) {
        globalStore.setSelectedFeet(getProductFromRef(furnitureComposition.feet.ref, globalStore.catalogs.feet));
    }

    globalStore.setFurnitureComposition(furnitureComposition);
}
