import defaultConfig from "defaultConfig";
import self from "../index";

const path = require("path");

const {
    modules,
    events,
    log,
} = self.app;

/**
 * Project Controller module
 */
const ProjectHelper = {

    /**
     * Saves the current project as a blob
     * @param {String} fileName
     */
    saveProjectAsBlob(fileName, extension, appVersion) { // eslint-disable-line
        const projectBlob = modules.dataExporter.exportAsBlob(
            { appVersion }
        );
        const fullName = `${fileName}_${appVersion}.${extension}`;
        const a = document.createElement("a");
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(projectBlob);
        a.href = url;
        a.download = fullName;
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    },

    /**
     * Saves the current project as data64
     */
    saveProjectAsData64(appVersion) {
        return modules.dataExporter.exportAsData64(
            { version: appVersion }
        );
    },

    /**
     * Import a project from a blob
     * @param {Blob} projectBlob
     */
    openProjectFromBlob(projectBlob) {

        // Clear all the dataStore before importing new project
        modules.dataStore.clear();
        // Warning : some config info stored can be needed to be reinitialize
        // Always think about those cases when adding thingss into the data-store

        // Read blob
        const reader = new FileReader();
        reader.onloadend = () => {
            if (reader.readyState !== 2) {  // 2 -> DONE
                return;
            }
            const projectBuffer = Buffer.from(reader.result);
            try {
                events.emit("opening");
                modules.dataExporter.import(projectBuffer);
                modules.history.snapshot();
                events.emit("opened");
            } catch (err) {
                log.error("Can't import project :", err);
            }
        };
        reader.readAsArrayBuffer(projectBlob);
    },

    /**
     * Fetch an url and import project from it
     * Works fine for local, but you may have cors problem otherwise, if so use openProjectFromUrlProxy
     * @param {String} projectUrl
     */
    openProjectFromUrl(projectUrl) {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", projectUrl);
        xhr.responseType = "blob";
        xhr.addEventListener("load", () => {
            ProjectHelper.openProjectFromBlob(xhr.response);
        });
        xhr.send();
    },

    /**
     * Allow to remove CORS problem you could have with openProjectFromUrl
     * @param  {String} projectUrl
     */
    openProjectFromUrlProxy(projectUrl) {
        return modules.httpRequest.getRawProxy(projectUrl)
            .then((projectBuffer) => {
                try {
                    modules.dataExporter.import(projectBuffer);
                    modules.history.snapshot();
                } catch (err) {
                    log.error("Can't import project :", err);
                }
                return true;
            });
    },

};

// This is not accessible if env is caas or production
if (process.env.NODE_ENV === "development") {
    /**
     *
     * @param {String} fileName version folder + filename of the project (version/filename)
     */
    ProjectHelper.openProjectFromLocalFile = (fileName) => {
        const projectFilesPath = path.resolve("assets/data/project-files/");
        const filePath = `${projectFilesPath}/${fileName}${defaultConfig.fileExtension}`;
        const xhr = new XMLHttpRequest();
        xhr.open("GET", filePath);
        xhr.responseType = "blob";
        xhr.addEventListener("load",
            () => {
                ProjectHelper.openProjectFromBlob(xhr.response);
            });
        xhr.send();
    };

    window.openProjectFromLocalFile = ProjectHelper.openProjectFromLocalFile;

}

export default ProjectHelper;
