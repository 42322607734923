import {
    Vector3,
} from "@babylonjs/core/Maths/math.vector";

import FurnituresListEntity from "./furnitures-list-entity";
import DataStoreMeshGenerator from "./datastore.mesh-generator";
import FurnitureController from "./furniture/furniture-controller";
import Axis from "./axis";

import FurnituresTweaks from "./tweaks/furnitures-tweaks";
import FramesTweaks from "./tweaks/frames-tweaks";
import BoardsTweaks from "./tweaks/boards-tweaks";
import DoorsTweaks from "./tweaks/doors-tweaks";

import DragNDropManager from "./dragNdrop-manager";
import FurnituresEventsManager from "./furnitures-events-manager";

import BoardDisplacement from "./board/board-displacement";
import MovableshelfDisplacement from "./movableshelf/movableshelf-displacement";
import FurnitureEventsManager from "./furniture/furniture-event-managers";
import RodController from "./rod/rod-controller";
import AccessorydrawerEventsManager from "./accessorydrawer/accessorydrawer-events-manager";
import FrameEventsManager from "./frame/frame-events-manager";
import BoardEventsManager from "./board/board-events-manager";
import MeasureEventsManager from "./measure/measure-events-manager";
import BackboardEventsManager from "./backboard/backboard-events-manager";
import DoorEventsManager from "./door/door-events-manager";

import PulloutshelfDisplacement from "./pulloutshelf/pulloutshelf-displacement";

import self from "../index";
import RodEventsManager from "./rod/rod-events-manager";
import InsetdrawerEventsManager from "./insetdrawer/insetdrawer-events-manager";
import OverlaydrawerEventsManager from "./overlaydrawer/overlaydrawer-events-manager";
import MaterialManager from "./materials/material-manager";
import furnitureUiButtons from "./furniture/furniture-ui-buttons";
import BoardController from "./board/board-controller";
import DoorController from "./door/door-controller";
import MovableshelfController from "./movableshelf/movableshelf-controller";
import PulloutshelfController from "./pulloutshelf/pulloutshelf-controller";
import AccessorydrawerController from "./accessorydrawer/accessorydrawer-controller";
import InsetdrawerController from "./insetdrawer/insetdrawer-controller";
import OverlaydrawerController from "./overlaydrawer/overlaydrawer-controller";
import BackboardController from "./backboard/backboard-controller";
import BaseboardController from "./baseboard/baseboard-controller";
import DoorHelper from "./door/door-helper";
import FurnitureHelper from "./furniture/furniture-helper";

const {
    events,
    modules,
    config,
} = self.app;


/**
 * Furnitures Controller module
 * Initialized by modules.main.mainController
 * Manage multi furnitures
 */
class FurnituresListController {

    constructor() {
        if (!FurnituresListController.instance) FurnituresListController.instance = this;
        else throw new Error("FurnituresController is a singleton. Use FurnituresController.getInstance()");
        FurnituresListController.instance = this;
        this.dataStore = modules.dataStore;
    }

    initialize() {

        DataStoreMeshGenerator.start();

        this.furnituresListEntity = new FurnituresListEntity();

        this.dataStore.addEntity(this.furnituresListEntity, "list");

        Axis.getInstance().initialize(10000);

        BoardDisplacement.initialize();
        MovableshelfDisplacement.initialize();
        PulloutshelfDisplacement.initialize();

        this.generateDefault();

        modules.toolManager.controller.activateTool("board");

        if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "caas") {
            modules.tweakpane.controller.waitForLoading().then(FurnituresListController.onTweakpaneCreated.bind(this));
        }

        FurnituresEventsManager.addEventListeners();
        DragNDropManager.addEventListeners();
        FurnitureEventsManager.addEventListeners();
        FrameEventsManager.addEventListeners();
        BoardEventsManager.addEventListeners();
        MeasureEventsManager.addEventListeners();
        AccessorydrawerEventsManager.initialize();
        DoorEventsManager.addEventListeners();
        BackboardEventsManager.addEventListeners();
        RodEventsManager.addEventListeners();
        InsetdrawerEventsManager.addEventListeners();
        OverlaydrawerEventsManager.addEventListeners();

        events.emit("furniture:edited");
    }

    static onTweakpaneCreated(pane) {
        FurnituresTweaks.getInstance().addTweaks(pane);
        FramesTweaks.getInstance().addTweaks(pane);
        BoardsTweaks.getInstance().addTweaks(pane);
        DoorsTweaks.addTweaks(pane);
    }

    static onToolsAccessories() {
        modules.toolManager.controller.deactivateAllTools();
        RodController.atool.setToolActive();
    }

    generateDefault() {
        this.currentFurnitureEntity = FurnitureController.createFurniture(
            new Vector3(
                config.get("default_furniture_width"),
                config.get("default_furniture_height"),
                config.get("default_furniture_depth")
            )
        );
        Axis.getInstance().position.z = config.get("default_furniture_depth");
        this.furnituresListEntity.addFurnitureId(this.currentFurnitureEntity.id);

    }

    refreshBoardsView() {
        this.dataStore.listEntities("board").forEach((boardEntity) => {
            boardEntity.mesh.linesSystem.isVisible = self.config.boardLinesVisible;
        });
    }

    updateAllMaterialsFromFurnituresListEntity() {
        this.updateMainMaterialDatas(this.furnituresListEntity.mainMaterialDatas);
        this.updateBorderMaterialDatas(this.furnituresListEntity.borderMaterialDatas);
        this.updateFrontMaterialDatas(this.furnituresListEntity.frontMaterialDatas);
    }

    updateMainMaterialDatas(mainMaterialDatas) {
        this.furnituresListEntity.mainMaterialDatas = mainMaterialDatas;
        MaterialManager.hasMainFinishWoodGrain = mainMaterialDatas.woodGrain;
        MaterialManager.mainFinishColor = `${mainMaterialDatas.color}`;
    }

    updateBorderMaterialDatas(borderMaterialDatas) {
        this.furnituresListEntity.borderMaterialDatas = borderMaterialDatas;
        MaterialManager.hasBorderFinishWoodGrain = borderMaterialDatas.woodGrain;
        MaterialManager.borderFinishColor = `${borderMaterialDatas.color}`;
    }

    updateFrontMaterialDatas(frontMaterialDatas) {
        this.furnituresListEntity.frontMaterialDatas = frontMaterialDatas;
        MaterialManager.hasFrontFinishWoodGrain = frontMaterialDatas.woodGrain;
        MaterialManager.frontFinishColor = `${frontMaterialDatas.color}`;
    }

    getViewCropRect(cameraSide) {
        return FurnitureHelper.getFurnitureViewCropRect(this.currentFurnitureEntity, cameraSide);
    }

    static onUiMaintabChanged(tabName) {
        furnitureUiButtons.currentUiMainTab = tabName;

        if (tabName === "finishing") {
            BoardController.switchMaterialsToFinition();
            DoorController.switchMaterialsToFinition();
            MovableshelfController.switchMaterialsToFinition();
            PulloutshelfController.switchMaterialsToFinition();
            AccessorydrawerController.switchMaterialsToFinition();
            InsetdrawerController.switchMaterialsToFinition();
            OverlaydrawerController.switchMaterialsToFinition();
            BackboardController.switchMaterialsToFinition();
            BaseboardController.switchMaterialsToFinition();
        } else {
            BoardController.switchMaterialToConstruction();
            DoorController.switchMaterialToConstruction();
            MovableshelfController.switchMaterialToConstruction();
            PulloutshelfController.switchMaterialToConstruction();
            AccessorydrawerController.switchMaterialToConstruction();
            InsetdrawerController.switchMaterialToConstruction();
            OverlaydrawerController.switchMaterialToConstruction();
            BackboardController.switchMaterialToConstruction();
            BaseboardController.switchMaterialToConstruction();
        }
        if (tabName === "interior") {
            DoorHelper.hideAllDoors();
        }

        furnitureUiButtons.updateButtons();
    }

    static getInstance() {
        if (!FurnituresListController.instance) {
            return new FurnituresListController();
        }
        return FurnituresListController.instance;
    }

}

export default FurnituresListController;
