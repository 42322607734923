import self from "../../index";
import OverlaydrawerController from "./overlaydrawer-controller";

const {
    events,
    modules,
} = self.app;

function onDeleteOverlaydrawer(params) {
    const frameEntity = modules.dataStore.getEntity(params.id);
    if (!frameEntity) return;
    OverlaydrawerController.deleteDrawer(frameEntity);
}

function onSelectOverlaydrawer(params) {
    const frameEntity = modules.dataStore.getEntity(params.id);
    if (!frameEntity) return;
    const overlaydrawerEntity = modules.dataStore.getEntity(frameEntity.idDrawer);
    OverlaydrawerController.selectOverlaydrawer(overlaydrawerEntity);
}

const OverlaydrawerEventsManager = {
    addEventListeners() {
        events.on("@vuejs.overlaydrawer:select", OverlaydrawerController.selectOverlaydrawerById);
        events.on("@vuejs.overlaydrawer:update:quantity", OverlaydrawerController.updateQuantity);
        events.on("@vuejs.overlaydrawer:showAll", OverlaydrawerController.showAll);
        events.on("@vuejs.overlaydrawer:hideAll", OverlaydrawerController.hideAll);
        events.on("@vuejs.history:back", OverlaydrawerEventsManager.onHistoryChange);
        events.on("@vuejs.history:forward", OverlaydrawerEventsManager.onHistoryChange);

        events.on("overlaydrawer:select", OverlaydrawerController.selectOverlaydrawer);
        events.on("overlaydrawer:update:quantity", OverlaydrawerController.updateQuantity);

        events.on("@vuejs.overlaydrawer:delete", onDeleteOverlaydrawer);
        events.on("@vuejs.overlaydrawer:select", onSelectOverlaydrawer);

    },
    emitUpdated() {
        events.emit("overlaydrawer:updated", OverlaydrawerController.getCurrentOverlaydrawerEntityCopyForUi());
    },

    onHistoryChange() {
        OverlaydrawerController.updateFromHistoryChange();
        if (!OverlaydrawerController.currentOverlaydrawerEntity) return;
        const currentOverlaydrawerEntity = modules.dataStore.getEntity(OverlaydrawerController.currentOverlaydrawerEntity.id);
        if (!currentOverlaydrawerEntity) {
            OverlaydrawerController.currentOverlaydrawerEntity = undefined;
        }
        OverlaydrawerEventsManager.emitUpdated();
    },

    emitRejected(isMax) {
        events.emit("overlaydrawer:amount:rejected");
        events.emit("show:modal", {
            text: isMax ? "Vous avez atteint le nombre maximal de tiroirs, il faut augmenter la hauteur de la niche pour augmenter le nombre"
                : "Vous avez atteint le nombre minimal de tiroirs, il faut ajouter une séparation pour réduire le nombre",
            isInfo: true,
        });
    },
};

export default OverlaydrawerEventsManager;
