import {
    Color3,
} from "@babylonjs/core/Maths/math.color";
import constraints from "./src/constraints";

export default {

    name: "furnitures",
    requires: [
        "data-store",
        "obsidian-babylon-engine",
        "cameras-manager",
        "history",
        "tweakpane",
        "tool-manager",
        "project-manager",
        "data-exporter",
    ],


    config: {

        selectedFrameColor: Color3.FromHexString("#0077ff"),
        frameAlpha: 0,

        boardLinesVisible: true,
        boardLinesColor: Color3.FromHexString("#ffffff"),
        movableshelfLinesColor: Color3.FromHexString("#ffffff"),
        movableshelfHolesLinesColor: Color3.FromHexString("#2b2b2b"),
        pulloutshelfLinesColor: Color3.FromHexString("#ffffff"),
        selectedBoardColor: Color3.FromHexString("#0077ff"),
        backboardLinesColor: Color3.FromHexString("#414141"),
        footLinesVerticalColor: Color3.FromHexString("#414141"),
        footLinesHorizontalColor: Color3.FromHexString("#cfcfcf"),
        doorLinesColor: Color3.FromHexString("#cfcfcf"),

        debugUv: false,
        debugFrames: true,

        default_furniture_width: 750,
        default_furniture_height: 1200,
        default_furniture_depth: 500,
        default_baseboard_height: constraints.MIN_BASEBOARD_HEIGHT.min,
        default_foot_size: 40,

        backboard_z_shift: 19,
        backboard_thickness: 19,
        backboard_groove: 0,
    },

    load() {
        const FurnituresListController = require("./src/furnitures-list-controller").default;
        this.furnituresListController = FurnituresListController.getInstance();

        return {
            furnituresListController: this.furnituresListController,
        };
    },

    unload() {},

};
