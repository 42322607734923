<template>
    <svg xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink"
         width="38"
         height="38"
         viewBox="0 0 38 38"
    >
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_25"
                      data-name="Rectangle 25"
                      width="38"
                      height="38"
                      fill="#fd7e19"
                />
            </clipPath>
        </defs>
        <g id="icon-movable-v2" transform="translate(-9 -9)">
            <g id="Groupe_de_masques_2" data-name="Groupe de masques 2" transform="translate(9 9)" clip-path="url(#clip-path)">
                <path id="border-center-h-thin" d="M4,39.791a1.343,1.343,0,1,1-1.343-1.343A1.347,1.347,0,0,1,4,39.791ZM2.653,8.9A1.343,1.343,0,1,1,4,7.557,1.345,1.345,0,0,1,2.653,8.9Zm33.61,30.89a1.343,1.343,0,1,1-1.343-1.343A1.347,1.347,0,0,1,36.263,39.791Zm0-32.233A1.343,1.343,0,1,1,34.92,6.214,1.345,1.345,0,0,1,36.263,7.557ZM4,31.732a1.343,1.343,0,1,1-1.343-1.343A1.347,1.347,0,0,1,4,31.732ZM34.92,33.076a1.343,1.343,0,1,1,1.343-1.343A1.347,1.347,0,0,1,34.92,33.076Zm0-16.117a1.343,1.343,0,1,1,1.343-1.343A1.347,1.347,0,0,1,34.92,16.959Zm-32.267,0A1.343,1.343,0,1,1,4,15.616,1.347,1.347,0,0,1,2.653,16.959Z" transform="translate(0.197 -4.674)" fill="#fff" />
                <g id="Groupe_563" data-name="Groupe 563">
                    <path id="Tracé_325"
                          data-name="Tracé 325"
                          d="M-7.141,0H24.651"
                          transform="translate(10.113 19.109)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-width="2"
                    />
                    <path id="Tracé_326"
                          data-name="Tracé 326"
                          d="M2097.324,5538.821v2.715l-7.383,5.44c-2.558,2.022-2.168,1.8,1.77,1.8,0,0,5.511-.01,5.529,0h5.1c3.573.021,4.253.084,1.729-1.8.015-.011-6.742-5.44-6.742-5.44"
                          transform="translate(-2076.861 -5519.348)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-width="1"
                    />
                    <path id="Tracé_327"
                          data-name="Tracé 327"
                          d="M2097,5538.821v2.715s-7.134,5.407-7.118,5.44c-2.2,1.837-2.226,1.837-.36,1.8"
                          transform="translate(-2080.073 -5519.348)"
                          fill="none"
                          stroke="#fff"
                          stroke-linecap="round"
                          stroke-width="1"
                    />
                </g>
            </g>
            <path id="Icon_ionic-ios-arrow-round-back"
                  data-name="Icon ionic-ios-arrow-round-back"
                  d="M3.731.132a.467.467,0,0,1,0,.658L1.563,2.968H9.837a.465.465,0,0,1,0,.93H1.563L3.738,6.077a.471.471,0,0,1,0,.658.463.463,0,0,1-.655,0L.132,3.762h0a.522.522,0,0,1-.1-.147A.444.444,0,0,1,0,3.437a.466.466,0,0,1,.132-.326L3.08.142A.456.456,0,0,1,3.731.132Z"
                  transform="translate(31.434 10.459) rotate(90)"
                  fill="#fff"
                  stroke="#fff"
                  stroke-width="0.8"
            />
            <path id="Icon_ionic-ios-arrow-round-back-2"
                  data-name="Icon ionic-ios-arrow-round-back"
                  d="M3.731.132a.467.467,0,0,1,0,.658L1.563,2.968H9.837a.465.465,0,0,1,0,.93H1.563L3.738,6.077a.471.471,0,0,1,0,.658.463.463,0,0,1-.655,0L.132,3.762h0a.522.522,0,0,1-.1-.147A.444.444,0,0,1,0,3.437a.466.466,0,0,1,.132-.326L3.08.142A.456.456,0,0,1,3.731.132Z"
                  transform="translate(24.566 23.1) rotate(-90)"
                  fill="#fff"
                  stroke="#fff"
                  stroke-width="0.8"
            />
        </g>
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "movable-rod-icon",
});
</script>
