import FurnitureEntity from "./furniture-entity";
import FrameController from "../frame/frame-controller";
import BoardEntityBuilder from "../board/board-entity-builder";

import self from "../../index";
import PulloutshelfController from "../pulloutshelf/pulloutshelf-controller";
import MovableshelfController from "../movableshelf/movableshelf-controller";
import RodController from "../rod/rod-controller";

const {
    modules,
    config,
} = self.app;

const FurnitureController = {

    createFurniture(scaling, position, addToStore = true) {
        const furnitureEntity = new FurnitureEntity({
            position: position,
            scaling: scaling,
            baseHeight: config.get("default_baseboard_height"),
        });

        // add furniture to store
        if (addToStore) modules.dataStore.addEntity(furnitureEntity, "furniture");

        FrameController.createRootFrame(furnitureEntity, addToStore);

        FurnitureController.generateBlock(furnitureEntity);

        return furnitureEntity;
    },

    /**
     * Furniture's blocks
     * Left, right, bottom and top
     * @param {FurnitureEntity} furnitureEntity
     */
    generateBlock(furnitureEntity) {

        const blockEntityLeft = BoardEntityBuilder.buildLeftBlockPart(furnitureEntity);
        furnitureEntity.setIdLeftBlockBoard(blockEntityLeft.id);
        modules.dataStore.addEntity(blockEntityLeft, "board");

        const blockEntityRight = BoardEntityBuilder.buildRightBlockPart(furnitureEntity);
        furnitureEntity.setIdRightBlockBoard(blockEntityRight.id);
        modules.dataStore.addEntity(blockEntityRight, "board");

        const blockEntityBottom = BoardEntityBuilder.buildBottomBlockPart(furnitureEntity);
        furnitureEntity.setIdBottomBlockBoard(blockEntityBottom.id);
        modules.dataStore.addEntity(blockEntityBottom, "board");

        const blockEntityTop = BoardEntityBuilder.buildTopBlockPart(furnitureEntity);
        furnitureEntity.setIdTopBlockBoard(blockEntityTop.id);
        modules.dataStore.addEntity(blockEntityTop, "board");

    },

    /**
     *
     * @param {Furniture} furnitureEntity
     */
    updateBlockMesh(furnitureEntity) {
        const frameEntity = modules.dataStore.getEntity(furnitureEntity.getIdFrame());

        const blockEntityLeft = modules.dataStore.getEntity(furnitureEntity.getIdLeftBlockBoard());
        blockEntityLeft.setScalingY(frameEntity.getScaling().y + frameEntity.getThickness() * 2);
        blockEntityLeft.setScalingZ(furnitureEntity.scaling.z);
        blockEntityLeft.setPositionY(furnitureEntity.getPosition().y);
        if (furnitureEntity.getIdBaseboard()) {
            blockEntityLeft.setScalingY(blockEntityLeft.getScaling().y + furnitureEntity.getPosition().y);
            blockEntityLeft.setPositionY(-furnitureEntity.getPosition().y);
        } else if (furnitureEntity.getIdsFeet()) {
            blockEntityLeft.setPositionY(0);
        }
        blockEntityLeft.mesh.updateLinesSystem();

        const blockEntityRight = modules.dataStore.getEntity(furnitureEntity.getIdRightBlockBoard());
        blockEntityRight.setScalingY(frameEntity.getScaling().y + frameEntity.getThickness() * 2);
        blockEntityRight.setScalingZ(furnitureEntity.scaling.z);
        blockEntityRight.setPositionX(frameEntity.getScaling().x + frameEntity.getThickness());
        blockEntityRight.setPositionY(furnitureEntity.getPosition().y);
        if (furnitureEntity.getIdBaseboard()) {
            blockEntityRight.setScalingY(blockEntityRight.getScaling().y + furnitureEntity.getPosition().y);
            blockEntityRight.setPositionY(-furnitureEntity.getPosition().y);
        } else if (furnitureEntity.getIdsFeet()) {
            blockEntityRight.setPositionY(0);
        }
        blockEntityRight.mesh.updateLinesSystem();

        const blockEntityBottom = modules.dataStore.getEntity(furnitureEntity.getIdBottomBlockBoard());
        blockEntityBottom.setScalingX(frameEntity.getScaling().x);
        blockEntityBottom.setScalingZ(furnitureEntity.scaling.z);
        blockEntityBottom.mesh.updateLinesSystem();

        const blockEntityTop = modules.dataStore.getEntity(furnitureEntity.getIdTopBlockBoard());
        blockEntityTop.setScalingX(frameEntity.getScaling().x);
        blockEntityTop.setScalingZ(furnitureEntity.scaling.z);
        blockEntityTop.setPositionY(frameEntity.getScaling().y + frameEntity.getThickness());
        blockEntityTop.mesh.updateLinesSystem();
    },

    updateFurnituresHasBackboards(hasBackboards) {
        modules.dataStore.listEntities("furniture").forEach((furnitureEntity) => {
            furnitureEntity.setHasBackboards(hasBackboards);
        });
    },

    clearAllAccessoriesOf(furnitureEntityId) {
        PulloutshelfController.clearAllFromFurnitureId(furnitureEntityId);
    },

    showAllAccessories() {
        MovableshelfController.showAll();
        RodController.showAll();
        PulloutshelfController.showAll();
    },

    hideAllAccessories() {
        MovableshelfController.hideAll();
        RodController.hideAll();
        PulloutshelfController.hideAll();
    },

};

export default FurnitureController;
