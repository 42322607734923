import self from "../../index";
import { ACCESSORYDRAWER, INSET_DRAWER, OVERLAY_DRAWER, PULLOUTSHELF } from "../constants";
import ConstraintsManager from "../constraints-manager";
import DoorHelper from "../door/door-helper";
import DrawerRunnerHelper from "../drawer/drawer-runner-helper";
import FurnitureHelper from "./furniture-helper";

const {
    modules,
} = self.app;

class FurnitureCompositionBuilder {

    constructor() {

        if (FurnitureCompositionBuilder._instance) {
            throw new Error("FurnitureCompositionBuilder is a singleton. Use FurnitureCompositionBuilder.getInstance()");
        } else {
            FurnitureCompositionBuilder._instance = this;
        }

        this.furnituresListController = self.furnituresListController;
    }

    getComposition(params = {}) {

        this.onlyCompositionMetadata = params.onlyCompositionMetadata || false;
        this.initialize();

        if (!this.onlyCompositionMetadata) {
            this.addBoards();
            this.addDoors();
            this.addBaseboard();
            this.addInsetdrawer();
            this.addOverlaydrawer();
            this.addAccessorydrawer();
            this.addMovableshelf();
            this.addPulloutshelf();
            this.addBackboard();
            this.addRod();
            this.addFeet();
        }
        console.log("this.composition", this.composition);
        return this.composition;
    }

    initialize() {
        this.furnituresListEntity = this.furnituresListController.furnituresListEntity;
        this.currentFurnitureEntity = this.furnituresListController.currentFurnitureEntity;
        this.currentMainMaterialDatasRef = this.furnituresListEntity.mainMaterialDatas.ref;
        this.currentBorderMaterialDatasRef = this.furnituresListEntity.borderMaterialDatas.ref;
        this.currentFrontMaterialDatasRef = this.furnituresListEntity.frontMaterialDatas.ref;

        this.metadata = {
            mainMaterialRef: this.currentMainMaterialDatasRef,
            borderMaterialRef: this.currentBorderMaterialDatasRef,
            frontMaterialRef: this.currentFrontMaterialDatasRef,
            transform: FurnitureHelper.getCurrentFurnitureEntityCopyForUi(),
        };
        if (this.onlyCompositionMetadata && this.composition) {
            this.composition.metadata = this.metadata;
            return;
        }
        this.boardsForGroup = {};
        this.borderForGroup = {};
        this.rodForGroup = {};
        this.drawerRunnersForGroup = {};

        this.boardGroupCollection = [];
        this.borderGroupCollection = [];
        this.composition = {
            boardGroupCollection: this.boardGroupCollection,
            borderGroupCollection: this.borderGroupCollection,
            rodGroupCollection: [],
            drawerRunnersGroupCollection: [],
            doorKnobs: {
                ref: null,
                quantity: 0,
            },
            drawerKnobs: {
                ref: null,
                quantity: 0,
            },
            hinges: {
                quantity: 0,
            },
            feet: {
                ref: this.currentFurnitureEntity.getFeet().ref,
                quantity: 0,
                dimensions: [0, 0, 0],
            },
            metadata: this.metadata,
        };
        this.composition.doorKnobs = {
            ref: this.currentFurnitureEntity.knob.ref,
            quantity: 0,
        };
        this.composition.drawerKnobs = {
            ref: this.currentFurnitureEntity.knob.ref,
            quantity: 0,
        };
    }

    /**
     * Create a group in collection if not exist
     * Add 1 to quantity
     * @param {string} key : concat of params
     * @param {hash} group : hash of group by key
     * @param {*} collection : array of group
     * @param {*} params : params to add to group
     */
    static groupInCollectionByKey(key, group, collection, params = {}) {
        if (!group[key]) {
            group[key] = { key,
                quantity: 0,
                ...params };
            collection.push(group[key]);
        }
        group[key].quantity++;
    }

    /**
     * Add boards to composition
     */
    addBoards() {

        modules.dataStore.listEntities("board").forEach((board) => {
            const { dimensions } = board;
            const keyGroup = `${dimensions.toString()}-${this.currentMainMaterialDatasRef}`;
            FurnitureCompositionBuilder.groupInCollectionByKey(
                keyGroup, this.boardsForGroup, this.boardGroupCollection,
                {
                    dimensions,
                    ref: this.currentMainMaterialDatasRef,
                }
            );
            const borderDimension = (dimensions[1] + dimensions[2]);
            this.addBorderToCurrentFurniture(borderDimension, this.currentBorderMaterialDatasRef);
            ConstraintsManager.detectBoardWeakness(board);
        });
    }

    /**
     * Add doors to composition and their knobs
     */
    addDoors() {
        modules.dataStore.listEntities("door").forEach((door) => {

            const { dimensions } = door;
            dimensions.forEach((dimension) => {
                const keyGroup = `${dimension.toString()}-${this.currentFrontMaterialDatasRef}`;
                FurnitureCompositionBuilder.groupInCollectionByKey(
                    keyGroup, this.boardsForGroup, this.boardGroupCollection,
                    {
                        dimensions: dimension,
                        ref: this.currentFrontMaterialDatasRef,
                    }
                );
                const borderDimension = (dimension[1] + dimension[2]) * 2;
                this.addBorderToCurrentFurniture(borderDimension, this.currentFrontMaterialDatasRef);
                this.addHingesToCurrentFurniture(DoorHelper.hingeQuantityForDoorHeight(door.scaling.y));
            });


            // Knobs (fr: boutons, poignées)
            for (let i = 0; i < door.knobs.length; i++) {
                this.addDoorKnobToCurrentFurniture();
            }
        });
    }

    /**
     * Add baseboard to composition ( fr: plinthe)
     */
    addBaseboard() {
        modules.dataStore.listEntities("baseboard").forEach((baseboard) => {
            const { dimensions } = baseboard;
            const keyGroup = `${dimensions.toString()}-${this.currentFrontMaterialDatasRef}`;
            FurnitureCompositionBuilder.groupInCollectionByKey(
                keyGroup, this.boardsForGroup, this.boardGroupCollection,
                {
                    dimensions,
                    ref: this.currentFrontMaterialDatasRef,
                }
            );
        });
    }

    /**
     * Add insetdrawer, drawer runners and knobs to composition
     * Add drawer runners to composition
     * TODO: add inside board
     */
    addInsetdrawer() {
        modules.dataStore.listEntities("insetdrawer").forEach((insetdrawer) => {
            for (let i = 0; i < insetdrawer.quantity; i++) {
                Object.keys(insetdrawer.unitDrawerBoardComposition).forEach((boardName) => {
                    const board = insetdrawer.unitDrawerBoardComposition[boardName];
                    let materialRef = this.currentMainMaterialDatasRef;
                    if (boardName === "overlay") {
                        materialRef = this.currentFrontMaterialDatasRef;
                    }
                    const keyGroup = `${board.dimensions.toString()}-${materialRef}-${INSET_DRAWER}`;
                    FurnitureCompositionBuilder.groupInCollectionByKey(
                        keyGroup, this.boardsForGroup, this.boardGroupCollection,
                        {
                            dimensions: board.dimensions,
                            ref: materialRef,
                        }
                    );
                    const borderDimension = (board.dimensions[1] + board.dimensions[2]) * 2;
                    this.addBorderToCurrentFurniture(borderDimension, materialRef);
                });

                // Knobs (fr: boutons, poignées)
                this.addDrawerKnobToCurrentFurniture();

                // drawer runners (fr: coulisses)
                const frameEntity = modules.dataStore.getEntity(insetdrawer.idParent);
                const drawerRunner = DrawerRunnerHelper.getNearestDrawerRunnerForFrame(frameEntity, INSET_DRAWER, insetdrawer.drawerRunnerType);
                for (let j = 0; j < 2; j++) {
                    FurnitureCompositionBuilder.groupInCollectionByKey(
                        `${drawerRunner.ref}`,
                        this.drawerRunnersForGroup,
                        this.composition.drawerRunnersGroupCollection,
                        drawerRunner,
                    );
                }
            }
        });
    }

    /**
     * Add overlaydrawer and their knobs to composition
     * Add drawer runners to composition
     * TODO: add inside board
     */
    addOverlaydrawer() {
        modules.dataStore.listEntities("overlaydrawer").forEach((overlaydrawer) => {
            for (let i = 0; i < overlaydrawer.quantity; i++) {
                Object.keys(overlaydrawer.unitDrawerBoardComposition).forEach((boardName) => {
                    const board = overlaydrawer.unitDrawerBoardComposition[boardName];
                    let materialRef = this.currentMainMaterialDatasRef;
                    if (boardName === "overlay") {
                        materialRef = this.currentFrontMaterialDatasRef;
                    }
                    const keyGroup = `${board.dimensions.toString()}-${materialRef}-${OVERLAY_DRAWER}`;
                    FurnitureCompositionBuilder.groupInCollectionByKey(
                        keyGroup, this.boardsForGroup, this.boardGroupCollection,
                        {
                            dimensions: board.dimensions,
                            ref: materialRef,
                        }
                    );
                    const borderDimension = (board.dimensions[1] + board.dimensions[2]) * 2;
                    this.addBorderToCurrentFurniture(borderDimension, materialRef);
                });

                this.addDrawerKnobToCurrentFurniture();

                // drawer runners (fr: coulisses)
                const frameEntity = modules.dataStore.getEntity(overlaydrawer.idParent);
                const drawerRunner = DrawerRunnerHelper.getNearestDrawerRunnerForFrame(frameEntity, OVERLAY_DRAWER, overlaydrawer.drawerRunnerType);
                for (let j = 0; j < 2; j++) {
                    FurnitureCompositionBuilder.groupInCollectionByKey(
                        `${drawerRunner.ref}`,
                        this.drawerRunnersForGroup,
                        this.composition.drawerRunnersGroupCollection,
                        drawerRunner,
                    );
                }
            }
        });
    }

    /**
     * Add movableshelf to composition ( fr: étagère mobile)
     */
    addMovableshelf() {
        modules.dataStore.listEntities("movableshelf").forEach((movableshelf) => {
            const { dimensions } = movableshelf;
            const keyGroup = `${dimensions.toString()}-${this.currentMainMaterialDatasRef}`;
            FurnitureCompositionBuilder.groupInCollectionByKey(
                keyGroup, this.boardsForGroup, this.boardGroupCollection,
                {
                    dimensions,
                    ref: this.currentMainMaterialDatasRef,
                }
            );
            const borderDimension = (dimensions[1] + dimensions[2]);
            this.addBorderToCurrentFurniture(borderDimension, this.currentMainMaterialDatasRef);
        });
    }

    /**
     * Add pulloutshelf to composition ( fr: tirette)
     * Group pulloutshelf partsDimension by dimensions
     */
    addPulloutshelf() {
        modules.dataStore.listEntities("pulloutshelf").forEach((pulloutshelf) => {
            Object.keys(pulloutshelf.unitPulloutshelfBoardComposition).forEach((boardName) => {
                const board = pulloutshelf.unitPulloutshelfBoardComposition[boardName];
                const keyGroup = `${board.dimensions.toString()}-${this.currentMainMaterialDatasRef}-${PULLOUTSHELF}`;
                FurnitureCompositionBuilder.groupInCollectionByKey(
                    keyGroup, this.boardsForGroup, this.boardGroupCollection,
                    {
                        dimensions: board.dimensions,
                        ref: this.currentMainMaterialDatasRef,
                    }
                );
                const borderDimension = (board.dimensions[1] + board.dimensions[2]) * 2;
                this.addBorderToCurrentFurniture(borderDimension, this.currentMainMaterialDatasRef);
            });


            // drawer runners (fr: coulisses)
            const frameEntity = modules.dataStore.getEntity(pulloutshelf.idParent);
            const drawerRunner = DrawerRunnerHelper.getNearestDrawerRunnerForFrame(frameEntity, PULLOUTSHELF, pulloutshelf.drawerRunnerType);
            for (let j = 0; j < 2; j++) {
                FurnitureCompositionBuilder.groupInCollectionByKey(
                    `${drawerRunner.ref}`,
                    this.drawerRunnersForGroup,
                    this.composition.drawerRunnersGroupCollection,
                    drawerRunner,
                );
            }

            pulloutshelf.hingeBoardComposition.forEach((hingeBoard) => {
                const keyGroup = `${hingeBoard.dimensions.toString()}-${this.currentMainMaterialDatasRef}-${PULLOUTSHELF}_SPACER`;
                FurnitureCompositionBuilder.groupInCollectionByKey(
                    keyGroup, this.boardsForGroup, this.boardGroupCollection,
                    {
                        dimensions: hingeBoard.dimensions,
                        ref: this.currentMainMaterialDatasRef,
                    }
                );
                const borderDimension = (hingeBoard.dimensions[1] + hingeBoard.dimensions[2]) * 2;
                this.addBorderToCurrentFurniture(borderDimension, this.currentMainMaterialDatasRef);
            });
        });
    }

    /**
     * Add accessorydrawer to composition ( fr: tiroir accessoire)
     * Group accessorydrawer's parts by dimensions ( front, bottom, back, left, right)
     * Add drawer runners to composition
     */
    addAccessorydrawer() {
        modules.dataStore.listEntities("accessorydrawer").forEach((accessorydrawer) => {

            for (let i = 0; i < accessorydrawer.quantity; i++) {
                Object.keys(accessorydrawer.unitDrawerBoardComposition).forEach((boardName) => {
                    const board = accessorydrawer.unitDrawerBoardComposition[boardName];
                    const keyGroup = `${board.dimensions.toString()}-${this.currentMainMaterialDatasRef}-${ACCESSORYDRAWER}`;
                    FurnitureCompositionBuilder.groupInCollectionByKey(
                        keyGroup, this.boardsForGroup, this.boardGroupCollection,
                        {
                            dimensions: board.dimensions,
                            ref: this.currentMainMaterialDatasRef,
                        }
                    );
                    const borderDimension = (board.dimensions[1] + board.dimensions[2]) * 2;
                    this.addBorderToCurrentFurniture(borderDimension, this.currentMainMaterialDatasRef);
                });

                // drawer runners (fr: coulisses)
                const frameEntity = modules.dataStore.getEntity(accessorydrawer.idParent);
                const drawerRunner = DrawerRunnerHelper.getNearestDrawerRunnerForFrame(frameEntity, ACCESSORYDRAWER, accessorydrawer.drawerRunnerType);
                for (let j = 0; j < 2; j++) {
                    FurnitureCompositionBuilder.groupInCollectionByKey(
                        `${drawerRunner.ref}`,
                        this.drawerRunnersForGroup,
                        this.composition.drawerRunnersGroupCollection,
                        drawerRunner,
                    );
                }
            }

            accessorydrawer.hingeBoardComposition.forEach((hingeBoard) => {
                const keyGroup = `${hingeBoard.dimensions.toString()}-${this.currentMainMaterialDatasRef}-${ACCESSORYDRAWER}_SPACER`;
                FurnitureCompositionBuilder.groupInCollectionByKey(
                    keyGroup, this.boardsForGroup, this.boardGroupCollection,
                    {
                        dimensions: hingeBoard.dimensions,
                        ref: this.currentMainMaterialDatasRef,
                    }
                );
                const borderDimension = (hingeBoard.dimensions[1] + hingeBoard.dimensions[2]) * 2;
                this.addBorderToCurrentFurniture(borderDimension, this.currentMainMaterialDatasRef);
            });
        });
    }

    addBackboard() {
        modules.dataStore.listEntities("backboard").forEach((backboard) => {
            const keyGroup = `${backboard.dimensions.toString()}-${this.currentMainMaterialDatasRef}`;
            FurnitureCompositionBuilder.groupInCollectionByKey(
                keyGroup, this.boardsForGroup, this.boardGroupCollection,
                {
                    dimensions: backboard.dimensions,
                    ref: this.currentMainMaterialDatasRef,
                }
            );
        });
    }

    /**
     * Group rod by dimensions
     */
    addRod() {
        modules.dataStore.listEntities("rod").forEach((rod) => {
            FurnitureCompositionBuilder.groupInCollectionByKey(
                rod.dimensions.toString(), this.rodForGroup, this.composition.rodGroupCollection,
                {
                    dimensions: rod.dimensions,
                }
            );
        });
    }

    addDrawerKnobToCurrentFurniture() {
        if (this.currentFurnitureEntity.knob.ref) {
            this.composition.drawerKnobs.quantity++;
        }
    }

    addDoorKnobToCurrentFurniture() {
        if (this.currentFurnitureEntity.knob.ref) {
            this.composition.doorKnobs.quantity++;
        }
    }

    addBorderToCurrentFurniture(dimension, materialRef) {
        const keyGroup = `${String(dimension)}-${materialRef}`;
        FurnitureCompositionBuilder.groupInCollectionByKey(
            keyGroup, this.borderForGroup, this.borderGroupCollection,
            {
                dimensions: [dimension],
                ref: materialRef,
            }
        );
    }

    addHingesToCurrentFurniture(quantity = 1) {
        this.composition.hinges.quantity += quantity;
    }

    addFeet() {
        modules.dataStore.listEntities("foot").forEach((foot) => {
            this.composition.feet.quantity++;
            this.composition.feet.dimensions = foot.dimensions;
        });
    }

    static get instance() {
        if (!FurnitureCompositionBuilder._instance) {
            FurnitureCompositionBuilder._instance = new FurnitureCompositionBuilder();
        }
        return FurnitureCompositionBuilder._instance;
    }

}

export default FurnitureCompositionBuilder;
