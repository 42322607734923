<template>
    <div class="h-full w-100 flex flex-col">
        <div class="flex pl-6 bg-theme-gray-100 py-3 items-center">
            <SelectableButton
                v-for="finishingTouch in finishingTouches"
                :key="finishingTouch"
                :selected="selectedTab === finishingTouch"
                @click="selectedTab = finishingTouch"
            >
                {{ t(finishingTouch) }}
            </SelectableButton>
            <InfoLabel class="ml-auto mr-10">
                {{ t("Les finitions et le sens de fil de bois sont indicatif et non contractuel") }}
            </InfoLabel>
        </div>
        <div class="h-full overflow-y-auto flex items-center">
            <SelectableCard
                v-for="catalogItem in selectedCatalog"
                :key="catalogItem.product_id"
                :image-url="catalogItem.product_img"
                :selected="isSelected(catalogItem)"
                :description="catalogItem.product_detail"
                @click="onSelectFinishingTouch(catalogItem)"
            >
                {{ catalogItem.product_detail_finishing }}
            </SelectableCard>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";
import useGlobalStore from "../../stores/global";
import { FINISHING_TOUCHES } from "../../services/constants";
import self from "../../../index";
import SelectableButton from "./SelectableButton.vue";
import SelectableCard from "./SelectableCard.vue";
import InfoLabel from "../common/InfoLabel.vue";

const {
    modules,
    events,
} = self.app;

export default defineComponent({
    name: "finishing-touches",
    components: { SelectableButton, SelectableCard, InfoLabel },
    data() {
        return {
            selectedTab: FINISHING_TOUCHES.BASEBOARD,
            finishingTouches: Object.values(FINISHING_TOUCHES),
        };
    },
    computed: {
        globalStore() {
            return useGlobalStore();
        },
        selectedFinishingTouch() {
            switch (this.selectedTab) {
                case FINISHING_TOUCHES.FRONT:
                    return this.globalStore.selectedProducts.front;
                case FINISHING_TOUCHES.TRIM:
                    return this.globalStore.selectedProducts.border;
                default:
                case FINISHING_TOUCHES.BASEBOARD:
                    return this.globalStore.selectedProducts.board;
            }
        },
        selectedCatalog() {
            switch (this.selectedTab) {
                case FINISHING_TOUCHES.TRIM:
                    return this.globalStore.catalogs.borders;
                case FINISHING_TOUCHES.FRONT:
                case FINISHING_TOUCHES.BASEBOARD:
                default:
                    return this.globalStore.catalogs.boards;
            }
        },
    },
    methods: {
        isSelected(board) {
            return this.selectedFinishingTouch.product_id === board.product_id;
        },
        onSelectFinishingTouch(finishingTouch) {
            const payload = {
                ref: finishingTouch.product_ref,
                color: finishingTouch.product_color_hexa_code,
                woodGrain: finishingTouch.product_wood_grain,
            };
            switch (this.selectedTab) {
                case FINISHING_TOUCHES.FRONT:
                    this.globalStore.setSelectedFront(finishingTouch);
                    events.emit("materialDatas:front:update", payload);
                    break;
                case FINISHING_TOUCHES.TRIM:
                    this.globalStore.setSelectedBorder(finishingTouch);
                    events.emit("materialDatas:border:update", payload);
                    break;
                default:
                case FINISHING_TOUCHES.BASEBOARD:
                    this.globalStore.setSelectedBoard(finishingTouch);
                    events.emit("materialDatas:main:update", payload);
                    break;
            }
        },
        t(...args) {
            return modules.stonejs.gettext(...args);
        },
    },
});
</script>
