<template>
    <button
        :title="t('Enregistrer mon projet')"
        :class="[
            'transition-all duration-300',
            'bg-gradient-to-br from-white via-white to-white bg-size-200 bg-pos-0',
            'flex justify-center items-center w-11 h-11 rounded',
            'hover:from-white hover:via-theme-pink-400 hover:to-theme-orange-400 hover:bg-pos-100'
        ]"
        @mouseover="isHovered = true"
        @mouseleave="isHovered = false"
        @click="saveProject()"
    >
        <SaveIcon :is-colored="!isHovered" />
    </button>
</template>

<script>
import self from "../../..";

import SaveIcon from "../icons/SaveIcon.vue";

const { modules } = self.app;

export default {
    name: "save-project-button",
    components: {
        SaveIcon,
    },
    data() {
        return {
            projectManager: null,
            isHovered: false,
        };
    },
    mounted() {
        this.projectManager = modules.projectManager.helper;
    },
    methods: {
        t(...args) {
            return modules.stonejs.gettext(...args);
        },
        saveProject() {
            modules.iframeApi.sendEvent("save");
        },
    },
};

</script>
