import ConstraintsManager from "../constraints-manager";
import InsetdrawerEntityBuilder from "./insetdrawer-entity-builder";

import self from "../../index";
import InsetdrawerEventsManager from "./insetdrawer-events-manager";
import InsetdrawerATool from "./insetdrawer-atool";
import constraints, { DRAWER_CONSTRAINTS } from "../constraints";
import { FRONT_MODE_INSIDE, INSET_DRAWER } from "../constants";
import { KNOB_INSTANCE_NAME } from "../knob/knob-factory";
import DrawerMeshBuilder from "../drawer/drawer-mesh-builder";
import DrawerHelper from "../drawer/drawer-helper";
import furnitureUiButtons from "../furniture/furniture-ui-buttons";
import OverlayHelper from "../overlay/overlay-helper";
import MaterialManager from "../materials/material-manager";
import DrawerRunnerHelper from "../drawer/drawer-runner-helper";

const {
    events,
    modules,
} = self.app;

const data = {
    atool: new InsetdrawerATool(),
    currentInsetdrawerEntity: null,
};

const InsetdrawerController = {

    get atool() {
        return data.atool;
    },

    get currentInsetdrawerEntity() {
        return data.currentInsetdrawerEntity;
    },

    set currentInsetdrawerEntity(insetdrawerEntity) {
        data.currentInsetdrawerEntity = insetdrawerEntity;
    },

    createInsetdrawer(frameEntity, drawerRunnerType) {
        const furnitureEntity = modules.dataStore.getEntity(frameEntity.getFurnitureId());
        const canAddInsetdrawer = ConstraintsManager.canAddDrawer(frameEntity);
        if (!canAddInsetdrawer.isAdapted) {
            events.emit("show:modal", {
                text: DRAWER_CONSTRAINTS.message,
                isInfo: true,
            });
            return;
        }

        if (!DrawerRunnerHelper.canAddToFrame(frameEntity, INSET_DRAWER, drawerRunnerType)) {
            return;
        }

        if (!DrawerHelper.canAddToFrame(frameEntity)) return;

        furnitureEntity.frontMode = FRONT_MODE_INSIDE;

        const insetdrawerEntity = InsetdrawerEntityBuilder.build(frameEntity, drawerRunnerType);
        insetdrawerEntity.quantity = DrawerHelper.getMinimumQuantity(insetdrawerEntity, DRAWER_CONSTRAINTS);

        insetdrawerEntity.scaling = frameEntity.getScaling().clone();
        insetdrawerEntity.position = frameEntity.getPosition().clone();

        frameEntity.idDrawer = insetdrawerEntity.id;

        modules.dataStore.addEntity(insetdrawerEntity, "insetdrawer");
        events.emit("furniture:edited");

        InsetdrawerController.selectInsetdrawer(insetdrawerEntity);
        furnitureUiButtons.updateButtons();
    },

    generateDrawerMeshAndMaterial(insetdrawerEntity) {
        const furnitureEntity = modules.dataStore.getEntity(insetdrawerEntity.getFurnitureId());
        DrawerMeshBuilder.buildDefault(insetdrawerEntity, furnitureEntity.container);
        DrawerHelper.updateMeshScaleAndPosition(insetdrawerEntity);

        if (furnitureUiButtons.isUiMainTabFinishing) {
            DrawerHelper.applyFinishMaterial(insetdrawerEntity);
        } else {
            DrawerHelper.applyConstructionMaterial(insetdrawerEntity);
        }
    },

    deleteInsetdrawer(frameEntity) {
        const insetdrawerEntity = modules.dataStore.getEntity(frameEntity.idDrawer);
        if (!insetdrawerEntity) return;

        frameEntity.idDrawer = null;

        modules.dataStore.removeEntity(insetdrawerEntity);
        OverlayHelper.updateFurnitureFrontMode(frameEntity);

        events.emit("furniture:edited");
        InsetdrawerEventsManager.emitUpdated();
        furnitureUiButtons.updateButtons();
        events.emit("drawer:deleted");
    },

    selectInsetdrawerById(id) {
        const insetdrawerEntity = modules.dataStore.getEntity(id);
        if (!insetdrawerEntity) return;

        InsetdrawerController.selectInsetdrawer(insetdrawerEntity);
    },


    selectInsetdrawer(insetdrawerEntity) {
        if (data.currentInsetdrawerEntity) {
            DrawerHelper.applyConstructionMaterial(data.currentInsetdrawerEntity);
        }
        data.currentInsetdrawerEntity = insetdrawerEntity;
        if (!insetdrawerEntity) return;
        insetdrawerEntity.mesh.material = MaterialManager.selectedMaterial;
        modules.toolManager.controller.activateToolAlone(data.atool.tool_name);
        InsetdrawerEventsManager.emitUpdated();
    },

    updateQuantity(quantity) {
        const frameEntity = modules.dataStore.getEntity(data.currentInsetdrawerEntity.idParent);
        if (!data.currentInsetdrawerEntity || !frameEntity) return;

        const height = Math.ceil(frameEntity.scaling.y / quantity);
        if (height >= constraints.MIN_DRAWER_HEIGHT.min && height <= constraints.MAX_DRAWER_HEIGHT.max) {
            data.currentInsetdrawerEntity.quantity = quantity;
            DrawerHelper.updateMeshScaleAndPosition(data.currentInsetdrawerEntity);
            events.emit("furniture:edited");
            InsetdrawerEventsManager.emitUpdated();
        } else {
            InsetdrawerEventsManager.emitRejected(height <= constraints.MIN_DRAWER_HEIGHT.min);
        }
    },

    getCurrentInsetdrawerEntityCopyForUi() {
        if (!data.currentInsetdrawerEntity) {
            return {
                id: null,
                quantity: null,
                unitUtilSize: null,
            };
        }
        return {
            id: data.currentInsetdrawerEntity.id,
            quantity: data.currentInsetdrawerEntity.quantity,
            unitUtilSize: data.currentInsetdrawerEntity.unitUtilSize,
        };
    },

    hideAll() {
        const insetdrawerEntities = modules.dataStore.listEntities("insetdrawer");
        for (let i = 0; i < insetdrawerEntities.length; i++) {
            const insetdrawerEntity = insetdrawerEntities[i];
            insetdrawerEntity.isVisible = false;
            insetdrawerEntity.mesh.setVisible(false);
            insetdrawerEntity.groupInstances.forEach((group) => {
                group.getChildMeshes().forEach((mesh) => {
                    mesh.isVisible = false;
                });
            });
        }
    },

    showAll() {
        const insetdrawerEntities = modules.dataStore.listEntities("insetdrawer");
        for (let i = 0; i < insetdrawerEntities.length; i++) {
            const insetdrawerEntity = insetdrawerEntities[i];
            insetdrawerEntity.isVisible = true;
            insetdrawerEntity.mesh.setVisible(true);
            insetdrawerEntity.groupInstances.forEach((group, index) => {
                group.getChildMeshes().forEach((mesh) => {
                    const isVisibleByQuantity = index < insetdrawerEntity.quantity;
                    if (mesh.name === KNOB_INSTANCE_NAME) {
                        mesh.isVisible = isVisibleByQuantity && insetdrawerEntity.furnitureEntity.knobVisible;
                    } else {
                        mesh.isVisible = isVisibleByQuantity;
                    }
                });
            });
        }
    },

    clearAllFromFurnitureId(furnitureId) {
        const insetdrawerEntities = modules.dataStore.listEntities("insetdrawer");
        insetdrawerEntities.forEach((insetdrawerEntity) => {
            if (insetdrawerEntity.getFurnitureId() === furnitureId) {
                const frameEntity = modules.dataStore.getEntity(insetdrawerEntity.idParent);
                frameEntity.idDrawer = null;
                modules.dataStore.removeEntity(insetdrawerEntity);
            }
        });
    },

    switchMaterialToConstruction() {
        modules.dataStore.listEntities("insetdrawer").forEach((insetdrawerEntity) => {
            DrawerHelper.applyConstructionMaterial(insetdrawerEntity);
        });
    },

    switchMaterialsToFinition() {
        modules.dataStore.listEntities("insetdrawer").forEach((insetdrawerEntity) => {
            DrawerHelper.applyFinishMaterial(insetdrawerEntity);
        });
    },

    updateFromHistoryChange() {
        modules.dataStore.listEntities("insetdrawer").forEach((insetdrawerEntity) => {
            DrawerHelper.updateMeshScaleAndPosition(insetdrawerEntity);
        });
    },
};

export default InsetdrawerController;
