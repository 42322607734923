import MaterialManager from "../materials/material-manager";

const BaseboardHelper = {
    applyConstructionMaterial(baseboardEntity) {
        const { mesh } = baseboardEntity;
        const faces = [mesh.faceFront, mesh.faceBack, mesh.faceBottom];
        faces.forEach((face) => {
            face.material = MaterialManager.mainConstructionMaterial;
        });
    },

    applyFinishMaterial(baseboardEntity) {
        const { mesh } = baseboardEntity;
        const faces = [mesh.faceFront, mesh.faceBack, mesh.faceBottom];
        faces.forEach((face) => {
            face.material = MaterialManager.frontFinishMaterial90;
        });
        mesh.updateUVScaling();
    },
};

export default BaseboardHelper;
