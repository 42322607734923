import {
    MeshBuilder,
} from "@babylonjs/core/Meshes/meshBuilder";
import {
    Vector3,
} from "@babylonjs/core/Maths/math.vector";
import { StandardMaterial } from "@babylonjs/core";
import self from "../../index";
import CubeFacesMesh from "../common/cube-faces-mesh";

class BackboardMesh extends CubeFacesMesh {

    constructor(backboardEntity) {
        super(backboardEntity, { addFaces: false });

        this.material = new StandardMaterial("hitBoxMat");
        this.material.alpha = 0;

        this.addFaces();
        this.addCustomWireframe();
    }

    setFacesVisible(value) {
        if (this.faceFront) {
            this.faceFront.isVisible = value;
        }
        if (this.faceBack) {
            this.faceBack.isVisible = value;
        }
    }

    addFaces() {
        const faces = CubeFacesMesh.generateCubesFaces();
        this.faceFront = faces.front;
        this.faceBack = faces.back;
        this.faceFront.isVisible = false;
        this.faceBack.isVisible = false;
        this.addChild(this.faceFront);
        this.addChild(this.faceBack);
    }

    addCustomWireframe() {
        const lines = [];
        // Cross Front
        lines.push(
            [
                new Vector3(0, 0, 0),
                new Vector3(1, 1, 0),
            ],
            [
                new Vector3(0, 1, 0),
                new Vector3(1, 0, 0),
            ],
        );

        // Cross Back
        lines.push(
            [
                new Vector3(0, 0, 1),
                new Vector3(1, 1, 1),
            ],
            [
                new Vector3(0, 1, 1),
                new Vector3(1, 0, 1),
            ],
        );

        this.linesSystem = MeshBuilder.CreateLineSystem("lines", {
            lines: lines,
        });
        this.linesSystem.isVisible = this.metadata.entity.getIsRemovable();
        this.linesSystem.isPickable = false;
        this.linesSystem.color = self.config.backboardLinesColor;
    }

    /**
     * Lines scaling and position are independant for having a bigger size than the mesh.
     * Without flickering
     * @param {Number} enlargeValue in mm
     */
    updateLinesSystem(enlargeValue = 1) {
        this.linesSystem.scaling = this.scaling.clone();
        this.linesSystem.position = this.position.clone();
        this.linesSystem.scaling.z += enlargeValue;
        this.linesSystem.position.z -= enlargeValue * 0.5;
    }

}

export default BackboardMesh;
