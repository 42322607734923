export default {

    name: "wanadev-loader",
    requires: [],

    load() {
        const WanadevLoaderController = require("./src/wanadev-loader-controller.js").default;
        this.wanadevLoaderController = new WanadevLoaderController();
        return {
            controller: this.wanadevLoaderController,
        };
    },

    unload() {},

    config: {
        // whether or not loader will be directly displayed on load
        showAtStart: false,
    },

};
