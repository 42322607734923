import self from "../index";

const {
    events,
} = self.app;

/**
 * WanadevLoader Controller module
 */
class WanadevLoaderController {

    constructor() {
        this.isVisible = self.app.config.get("showAtStart");
        this.initListener();
    }

    initListener() {
        events.on("@vuejs.new-object-requested", () => {
            this.show("small");
        });
        events.on("@object-manager.mesh-instanciated-rendered", () => {
            this.hide();
        });
    }

    show(type = "fullscreen") {
        this.type = type;
        this.isVisible = true;
        events.emit("controller:update", { ...this });
    }

    hide() {
        this.isVisible = false;
        events.emit("controller:update", { ...this });
    }

}

export default WanadevLoaderController;
