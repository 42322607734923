<template>
    <div>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34.558"
            height="29.934"
            viewBox="0 0 34.558 29.934"
        >
            <title>{{ title }}</title>
            <desc>Une icône représentant un sceau de peinture sur un meuble</desc>
            <g id="icon-styling-color" transform="translate(-368.939 -417.731)">
                <path id="Tracé_117"
                      data-name="Tracé 117"
                      d="M27.384,6.574H.61A.587.587,0,0,0,0,7.134V34.945a.587.587,0,0,0,.61.559.587.587,0,0,0,.61-.559v-1.9H26.774v1.906a.613.613,0,0,0,1.221,0V7.134A.587.587,0,0,0,27.384,6.574ZM15.135,31.925a4.276,4.276,0,0,0-1.164,0H1.221V7.693H26.774V31.925Z"
                      transform="translate(375.003 411.657)"
                      class="transition duration-300"
                      :fill="isActive || isHovered ? '#ffc107' : '#bdc9d5'"
                      :stroke="isActive || isHovered ? '#ffc107' : '#bdc9d5'"
                      stroke-width="1"
                />
                <rect id="Rectangle_344"
                      data-name="Rectangle 344"
                      width="8"
                      height="9"
                      transform="translate(392 421)"
                      class="transition duration-300"
                      :fill="isActive || isHovered ? '#ec910a' : '#bdc9d5'"
                />
                <path id="Tracé_136"
                      data-name="Tracé 136"
                      d="M0,.8H4V11H0Z"
                      transform="translate(378 431)"
                      class="transition duration-300"
                      :fill="isActive || isHovered ? '#ec910a' : '#bdc9d5'"
                />
                <g id="Tracé_135" data-name="Tracé 135" transform="translate(384 431)" fill="#bdc9d5">
                    <path d="M 15.5 10.49999713897705 L 0.5 10.49999713897705 L 0.5 1.273196697235107 L 15.5 1.273196697235107 L 15.5 10.49999713897705 Z" stroke="none" />
                    <path d="M 1 1.773196220397949 L 1 9.999996185302734 L 15 9.999996185302734 L 15 1.773196220397949 L 1 1.773196220397949 M 0 0.7731962203979492 L 16 0.7731962203979492 L 16 10.99999618530273 L 0 10.99999618530273 L 0 0.7731962203979492 Z" stroke="none" fill="#bdc9d5" />
                </g>
                <rect id="Rectangle_345"
                      data-name="Rectangle 345"
                      width="12"
                      height="9"
                      transform="translate(378 421)"
                      class="transition duration-300"
                      :fill="isActive || isHovered ? '#ec910a' : '#bdc9d5'"
                />
                <g id="fill-color" transform="translate(368.939 420.939)">
                    <path id="Polygone_1"
                          data-name="Polygone 1"
                          d="M4.5,0,9,5H0Z"
                          transform="translate(2.06 4.261)"
                          fill="#fff"
                    />
                    <path id="Tracé_129" data-name="Tracé 129" d="M0,0H16.813V16.813H0Z" fill="none" />
                    <path id="Tracé_130"
                          data-name="Tracé 130"
                          d="M15.664,8.376l-7.7-7.7A.931.931,0,0,0,6.652,2L8.221,3.567l-4.81,4.81a1.392,1.392,0,0,0,0,1.98l5.137,5.137a1.4,1.4,0,0,0,1.98,0l5.137-5.137a1.392,1.392,0,0,0,0-1.98Zm-10.6.99L9.538,4.893l4.474,4.474Zm12.879,1.4s-1.868,2.027-1.868,3.269a1.868,1.868,0,0,0,3.736,0C19.811,12.794,17.943,10.767,17.943,10.767Z"
                          transform="translate(-2.997 -0.122)"
                          class="transition duration-300"
                          :fill="isActive || isHovered ? '#e83e8c' : 'currentColor'"
                    />
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "finishings-icon",
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        isHovered: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "Icône de finition de meuble",
        },
    },
});
</script>
