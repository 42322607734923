<template>
    <div class="pl-6">
        <InfoLabel>
            {{ t("Faites glisser un élément sur la scène ou sélectionnez une planche pour la modifier") }}
        </InfoLabel>
        <div class="flex mt-2 flex-wrap items-center gap-2">
            <DraggableCard
                :start-drag-events="[
                    {
                        eventName: 'tool:frame:update',
                        params: { toolModeName: TOOL_MODE_MOVABLESHELF },
                    },
                ]"
                :drop-events="[
                    {
                        eventName: 'frame:action:movableshelf',
                        params: {},
                    },
                ]"
                :end-drag-events="[
                    {
                        eventName: 'accessories:selectable',
                    }
                ]"
            >
                <MovableShelfIcon />
                <template #text>{{ t("Étagère\namovible") }}</template>
            </DraggableCard>

            <DraggableCard
                :start-drag-events="[
                    {
                        eventName: 'tool:frame:update',
                        params: { toolModeName: TOOL_MODE_MOVABLESHELF },
                    },
                ]"
                :drop-events="[
                    {
                        eventName: 'frame:action:movableshelf',
                        params: { rod: true },
                    },
                ]"
                :end-drag-events="[
                    {
                        eventName: 'accessories:selectable',
                    }
                ]"
            >
                <MovableRodIcon />
                <template #text>{{ t("Étagère\namovible\navec tringle") }}</template>
            </DraggableCard>
            <DraggableCard
                :start-drag-events="[
                    {
                        eventName: 'tool:frame:update',
                        params: { toolModeName: TOOL_MODE_ROD },
                    },
                ]"
                :drop-events="[
                    {
                        eventName: 'frame:action:rod',
                        params: {},
                    },
                ]"
                :end-drag-events="[
                    {
                        eventName: 'accessories:selectable',
                    }
                ]"
            >
                <RodIcon />
                <template #text>{{ t("Tringle") }}</template>
            </DraggableCard>
            <DraggableCard
                :start-drag-events="[
                    {
                        eventName: 'tool:frame:update',
                        params: { toolModeName: TOOL_MODE_DRAWER },
                    },
                ]"
                :drop-events="[
                    {
                        eventName: 'frame:action:accessorydrawer',
                        params: {},
                    },
                ]"
                :end-drag-events="[
                    {
                        eventName: 'accessories:selectable',
                    }
                ]"
            >
                <DrawersInsideIcon />
                <template #text>{{ t("Tiroir(s)") }}</template>
            </DraggableCard>

            <DraggableCard
                :start-drag-events="[
                    {
                        eventName: 'tool:frame:update',
                        params: { toolModeName: TOOL_MODE_MOVABLESHELF },
                    },
                ]"
                :drop-events="[
                    {
                        eventName: 'frame:action:pulloutshelf',
                        params: {},
                    },
                ]"
                :end-drag-events="[
                    {
                        eventName: 'accessories:selectable',
                    }
                ]"
            >
                <PullOutShelfIcon />
                <template #text>{{ t("Tirette") }}</template>
            </DraggableCard>
            <template
                v-if="currentDrawerQuantity"
            >
                <div class="flex items-center flex-none min-w-64 border-theme-pink-400 rounded-md border-2 p-2">
                    <label for="drawers-amount">{{ t("Nombre de tiroir(s)") }}</label>
                    <div class="flex border-2 border-theme-gray-200 h-12 rounded-md overflow-hidden mx-2">
                        <button type="button" class="h-full w-8 font-bold bg-theme-gray-100" @click="onDecrement()">
                            <span class="sr-only">{{ t("Moins") }}</span>
                            <span aria-hidden="true">-</span>
                        </button>
                        <input
                            id="drawers-amount"
                            v-model="currentDrawerQuantity"
                            type="number"
                            class="border-x-2 border-gray-200 px-2 h-full w-16 number-as-text text-center"
                            @keypress="onUpdateDrawersAmount($event)"
                            @blur="onUpdateDrawersAmount($event)"
                        >
                        <button type="button" class="h-full w-8 font-bold bg-theme-gray-100" @click="onIncrement()">
                            <span class="sr-only">{{ t("Plus") }}</span>
                            <span aria-hidden="true">+</span>
                        </button>
                    </div>
                </div>
                <div class="rounded-md px-2 bg-theme-gray-100 border-theme-pink-400 rounded-md border-2 p-2 flex-none min-w-64 flex flex-row items-center gap-2 mx-auto">
                    <span class="font-bold">{{ t("Dimensions utiles:") }}</span>
                    <span>{{ `Largeur: ${currentDrawer.unitUtilSize.x}mm` }}</span>
                    <span>{{ `Hauteur: ${currentDrawer.unitUtilSize.y}mm` }}</span>
                    <span>{{ `Profondeur: ${currentDrawer.unitUtilSize.z}mm` }}</span>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { TOOL_MODE_DRAWER, TOOL_MODE_MOVABLESHELF, TOOL_MODE_ROD } from "../../../../furnitures/src/constants";
import DraggableCard from "./DraggableCard.vue";
import DrawersInsideIcon from "../icons/DrawersInsideIcon.vue";
import PullOutShelfIcon from "../icons/PullOutShelfIcon.vue";
import RodIcon from "../icons/RodIcon.vue";
import MovableShelfIcon from "../icons/MovableShelfIcon.vue";
import MovableRodIcon from "../icons/MovableRodIcon.vue";
import TrashIcon from "../icons/TrashIcon.vue";
import useFurnitureStore from "../../stores/furniture";

import self from "../../../index";
import InfoLabel from "../common/InfoLabel.vue";

const { modules, events } = self.app;

const ENTER_KEY_CODE = 13;

export default {
    components: {
        DrawersInsideIcon,
        PullOutShelfIcon,
        RodIcon,
        MovableShelfIcon,
        MovableRodIcon,
        TrashIcon,
        DraggableCard,
        InfoLabel,
    },
    data() {
        return {
            TOOL_MODE_MOVABLESHELF,
            TOOL_MODE_ROD,
            TOOL_MODE_DRAWER,
        };
    },
    computed: {
        store() {
            return useFurnitureStore();
        },
        currentDrawerQuantity() {
            return this.store.currentDrawer?.quantity;
        },
        currentDrawer() {
            return this.store.currentDrawer;
        },
    },
    mounted() {
        events.on("@furnitures.accessorydrawer:amount:rejected", () => {
            this.$forceUpdate();
        });
    },
    unmounted() {
        events.removeListener("@furnitures.accessorydrawer:amount:rejected");
    },
    methods: {
        t(...args) {
            return modules.stonejs.gettext(...args);
        },
        onDecrement() {
            const amount = this.currentDrawerQuantity - 1;
            this.emitUpdateQuantity(amount);
        },
        onIncrement() {
            const amount = this.currentDrawerQuantity + 1;
            this.emitUpdateQuantity(amount);
        },
        onUpdateDrawersAmount(event) {
            if (event.type === "keypress" && event.keyCode === ENTER_KEY_CODE) {
                const amount = parseInt(event.target.value, 10);
                this.emitUpdateQuantity(amount);
                return;
            }
            if (event.type === "blur") {
                const amount = parseInt(event.target.value, 10);
                this.emitUpdateQuantity(amount);
            }
        },
        emitUpdateQuantity(amount) {
            events.emit("accessorydrawer:update:quantity", amount);
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
