import {
    Vector3,
} from "@babylonjs/core";
import self from "../../index";
import {
    BOARD_THICKNESS,
} from "../constants";
import PulloutshelfEntity from "./pulloutshelf-entity";

const {
    modules,
} = self.app;

const PulloutshelfEntityBuilder = {
    build(frameEntity) {
        const furnitureEntity = modules.dataStore.getEntity(frameEntity.getFurnitureId());

        const pulloutshelfEntity = new PulloutshelfEntity({
            furnitureId: furnitureEntity.id,
            idParent: frameEntity.id,
        });
        // scaling: new Vector3(
        //     frameEntity.scaling.x,
        //     BOARD_THICKNESS,
        //     frameEntity.scaling.z,
        // ),
        // position: new Vector3(frameEntity.position.x, frameEntity.position.y, frameEntity.position.z),

        pulloutshelfEntity.isCoveredByBackboard = frameEntity.hasBackboard();
        pulloutshelfEntity.scaling = new Vector3(
            frameEntity.scaling.x,
            BOARD_THICKNESS,
            frameEntity.scaling.z,
        );
        pulloutshelfEntity.position = new Vector3(frameEntity.position.x, frameEntity.position.y, frameEntity.position.z);
        pulloutshelfEntity.updateMesh();

        return pulloutshelfEntity;
    },
};
export default PulloutshelfEntityBuilder;
