import cu from "vendors/class-utils";

import KnobMixin from "../entity-mixins/knob-mixin";
import BoardInfo from "../board/board-info";
import DrawerAbstractEntity from "../drawer/drawer-abstract-entity";

const Entity = require("@obsidianjs/data-store/src/entity");

require("helpers/serializers/babylon-vector3");

const OverlaydrawerEntity = DrawerAbstractEntity.$extend({

    __name__: "overlaydrawer",

    __init__(params = {}) {

        this.$data.hasNeighborTop = params.hasNeighborTop || false;
        this.$data.hasNeighborRight = params.hasNeighborRight || false;
        this.$data.hasNeighborBottom = params.hasNeighborBottom || false;
        this.$data.hasNeighborLeft = params.hasNeighborLeft || false;

        this.isInEdition = false;

        this.$super(params);
        this.unitDrawerBoardComposition.overlay = new BoardInfo();
    },

    __include__: [KnobMixin.prototype],

    setHasNeighborTop: cu.setter,
    getHasNeighborTop: cu.getter,

    setHasNeighborRight: cu.setter,
    getHasNeighborRight: cu.getter,

    setHasNeighborBottom: cu.setter,
    getHasNeighborBottom: cu.getter,

    setHasNeighborLeft: cu.setter,
    getHasNeighborLeft: cu.getter,

    setHasNeighborAll(value) {
        this.setHasNeighborTop(value);
        this.setHasNeighborRight(value);
        this.setHasNeighborBottom(value);
        this.setHasNeighborLeft(value);
    },
});

Entity.$register(OverlaydrawerEntity);
export default OverlaydrawerEntity;

