<template>
    <div
        :class="[
            'flex flex-row justify-center items-center content-center border-black border',
            readOnly && 'h-4 w-14 bg-black rounded-md',
            !readOnly && 'h-7 w-16 bg-white rounded-xl'
        ]
        "
    >
        <div
            v-if="isVertical"
            :class="[
                'flex flex-col justify-center content-center',
                readOnly && 'h-3',
                !readOnly && 'h-5 pl-1']"
        >
            <ChevronUpIcon
                :class="[
                    readOnly && 'text-white hover:cursor-default',
                    !readOnly && 'text-theme-pink-400 hover:cursor-pointer'
                ]"
                @click="onIncreaseStep"
            />
            <ChevronDownIcon
                :class="[
                    readOnly && 'text-white hover:cursor-default',
                    !readOnly && 'text-theme-pink-400 hover:cursor-pointer'
                ]"
                @click="onDecreaseStep"
            />
        </div>
        <div
            v-else
            :class="[
                'flex flex-col justify-center content-center',
                readOnly && 'h-2',
                !readOnly && 'h-3 pl-1'
            ]"
        >
            <ChevronLeftIcon
                :class="[
                    readOnly && 'text-white hover:cursor-default',
                    !readOnly && 'text-theme-pink-400 hover:cursor-pointer'
                ]"
                @click="onDecreaseStep"
            />
        </div>
        <span v-if="readOnly" class="w-8 h-3 bg-black text-white text-xs text-center flex items-center justify-center">
            {{ value }}
        </span>
        <input
            v-else
            class="w-10 h-4 text-xs text-center"
            :value="value"
            :disabled="readOnly"
            @change="onChange"
        >
        <div
            v-if="!isVertical"
            :class="[
                'flex flex-col justify-center content-center',
                readOnly && 'h-2',
                !readOnly && 'h-3 pr-1'
            ]"
        >
            <ChevronRightIcon
                :class="[
                    readOnly && 'text-white hover:cursor-default',
                    !readOnly && 'text-theme-pink-400 hover:cursor-pointer'
                ]"
                @click="onIncreaseStep"
            />
        </div>
    </div>
</template>
<script>
import self from "../../../index";
import ChevronLeftIcon from "../icons/ChevronLeftIcon.vue";
import ChevronRightIcon from "../icons/ChevronRightIcon.vue";
import ChevronUpIcon from "../icons/ChevronUpIcon.vue";
import ChevronDownIcon from "../icons/ChevronDownIcon.vue";

const { events } = self.app;

export default {
    components: { ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon, ChevronDownIcon },
    props: {
        value: { required: true, type: Number },
        measure: { required: true, type: Object },
        readOnly: { default: false, type: Boolean },
        vertical: { default: false, type: Boolean },
    },
    computed: {
        isVertical() {
            if (this.readOnly) {
                return this.vertical;
            }
            return this.measure.from === "TOP" || this.measure.from === "BOTTOM";
        },
    },
    methods: {
        onChange(event) {
            const distance = Number.parseFloat(event.target.value);
            this.moveShelf(distance);
            event.target.blur();
        },
        onIncreaseStep() {
            if (!this.readOnly) {
                this.moveShelf(this.value + 1);
            }
        },
        onDecreaseStep() {
            if (!this.readOnly) {
                this.moveShelf(this.value - 1);
            }
        },
        moveShelf(distance) {
            switch (this.measure.event) {
                case "board:edit:distance":
                    events.emit(this.measure.event, distance, this.measure.from, this.measure.frameEntity);
                    break;
                case "movableshelf:edit:distance":
                case "pulloutshelf:edit:distance":
                    events.emit(this.measure.event, distance, this.measure.from);
                    break;
                default:
                    break;
            }
        },
    },
};
</script>
