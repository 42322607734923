import ObsidianApi from "obsidian-api";
import useGlobalStore from "../stores/global";

export default function initConfiguration() {
    const globalStore = useGlobalStore();
    const obsidianConfig = ObsidianApi.$getConfig();

    if (typeof obsidianConfig.display_price === "boolean") {
        globalStore.setDisplayPrice(obsidianConfig.display_price);
    }
}
