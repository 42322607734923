import BBox2Helper from "../../../../helpers/BBox2Helper.js";
import self from "../../index.js";
import { BOARD_HALF_THICKNESS, BOARD_THICKNESS } from "../constants.js";
import DoorEntityHelper from "../door/door-entity-helper.js";
import DoorEntity from "../door/door-entity.js";
import DrawerHelper from "../drawer/drawer-helper.js";

const {
    modules,
    events,
} = self.app;

function updateVerticalNeighborsBetweenTwoDoors(overlayEntity1, overlayEntity2, hasNeighborOnBottom, hasNeighborOnTop) {
    overlayEntity1.setHasNeighborTop(hasNeighborOnTop || overlayEntity1.getHasNeighborTop());
    overlayEntity1.setHasNeighborBottom(hasNeighborOnBottom || overlayEntity1.getHasNeighborBottom());

    overlayEntity2.setHasNeighborTop(hasNeighborOnBottom || overlayEntity2.getHasNeighborTop());
    overlayEntity2.setHasNeighborBottom(hasNeighborOnTop || overlayEntity2.getHasNeighborBottom());
}

function updateHorizontalNeighborsBetweenTwoDoors(overlayEntity1, overlayEntity2, hasNeighborOnLeft, hasNeighborOnRight) {
    overlayEntity1.setHasNeighborRight(hasNeighborOnRight || overlayEntity1.getHasNeighborRight());
    overlayEntity1.setHasNeighborLeft(hasNeighborOnLeft || overlayEntity1.getHasNeighborLeft());

    overlayEntity2.setHasNeighborRight(hasNeighborOnLeft || overlayEntity2.getHasNeighborRight());
    overlayEntity2.setHasNeighborLeft(hasNeighborOnRight || overlayEntity2.getHasNeighborLeft());
}

const OverlayHelper = {

    /**
     *
     * @param {DoorEntity | OverlaydrawerEntity} overlayEntity1
     * @param {DoorEntity | OverlaydrawerEntity} overlayEntity2
     */
    checkCollisionBetweenTwoOverlays(overlayEntity1, overlayEntity2) {
        if (overlayEntity1.id !== overlayEntity2.id) {
            const bb1 = BBox2Helper.increaseBBox2FromCenter(BBox2Helper.getBBox2FromObject3dEntity(overlayEntity1), BOARD_THICKNESS * 2);
            const bb2 = BBox2Helper.increaseBBox2FromCenter(BBox2Helper.getBBox2FromObject3dEntity(overlayEntity2), BOARD_THICKNESS * 2);
            const bbIntersection = BBox2Helper.getBBox2Intersection(bb1, bb2);
            if (bbIntersection) {
                const hasNeighborOnTop = bb1.p2.y === bbIntersection.p2.y;
                const hasNeighborOnRight = bb1.p2.x === bbIntersection.p2.x;
                const hasNeighborOnBottom = bb1.p1.y === bbIntersection.p1.y;
                const hasNeighborOnLeft = bb1.p1.x === bbIntersection.p1.x;

                if (bbIntersection.width > bbIntersection.height) {
                    updateVerticalNeighborsBetweenTwoDoors(overlayEntity1, overlayEntity2, hasNeighborOnBottom, hasNeighborOnTop);
                } else if (bbIntersection.width < bbIntersection.height) {
                    updateHorizontalNeighborsBetweenTwoDoors(overlayEntity1, overlayEntity2, hasNeighborOnLeft, hasNeighborOnRight);
                } else {
                    updateVerticalNeighborsBetweenTwoDoors(overlayEntity1, overlayEntity2, hasNeighborOnBottom, hasNeighborOnTop);
                    updateHorizontalNeighborsBetweenTwoDoors(overlayEntity1, overlayEntity2, hasNeighborOnLeft, hasNeighborOnRight);
                }
            }
        }
    },

    /**
     * overlayEntities: array of DoorEntity or OverlaydrawerEntity
     */
    detectOverlaysNeighbors() {
        const doors = modules.dataStore.listEntities("door");
        const overlaydrawer = modules.dataStore.listEntities("overlaydrawer");
        const overlayEntities = [];
        overlayEntities.push(...doors);
        overlayEntities.push(...overlaydrawer);
        const overlayLength = overlayEntities.length;


        // Reset neighbors state of all overlayEntities
        for (let i = 0; i < overlayLength; i++) {
            const overlayEntity = overlayEntities[i];
            overlayEntity.setHasNeighborAll(false);
        }

        for (let i = 0; i < overlayLength; i++) {
            const overlayEntity1 = overlayEntities[i];
            for (let j = i + 1; j < overlayLength; j++) {
                const overlayEntity2 = overlayEntities[j];
                OverlayHelper.checkCollisionBetweenTwoOverlays(overlayEntity1, overlayEntity2);
            }
        }

        // refresh scaling and position
        for (let i = 0; i < overlayLength; i++) {
            const overlayEntity = overlayEntities[i];
            if (overlayEntity instanceof DoorEntity) {
                DoorEntityHelper.updateDoorOverlayPosition(overlayEntity);
                DoorEntityHelper.updateDoorOverlayScaling(overlayEntity);
            } else {
                OverlayHelper.updatePosition(overlayEntity);
                OverlayHelper.updateScaling(overlayEntity);
                DrawerHelper.updateMeshScaleAndPosition(overlayEntity);
            }
        }
    },

    /**
     *
     * @param {Boolean} hasNeighborSide
     * @returns
     */
    getOutsideDecaleOnBoard(hasNeighborSide) {
        return hasNeighborSide ? BOARD_HALF_THICKNESS : BOARD_THICKNESS;
    },

    updatePosition(overlayEntity) {
        const positionX = overlayEntity.parentEntity.position.x -
            OverlayHelper.getOutsideDecaleOnBoard(overlayEntity.getHasNeighborLeft());
        overlayEntity.positionX = positionX;
        const positionY = overlayEntity.parentEntity.position.y -
            OverlayHelper.getOutsideDecaleOnBoard(overlayEntity.getHasNeighborBottom());
        overlayEntity.positionY = positionY;
        overlayEntity.positionZ = overlayEntity.parentEntity.position.z - BOARD_THICKNESS;
    },

    updateScaling(overlayEntity) {
        const scaleX = overlayEntity.parentEntity.scaling.x +
            OverlayHelper.getOutsideDecaleOnBoard(overlayEntity.getHasNeighborLeft()) +
            OverlayHelper.getOutsideDecaleOnBoard(overlayEntity.getHasNeighborRight());
        overlayEntity.scalingX = scaleX;

        const scaleY = overlayEntity.parentEntity.scaling.y +
            OverlayHelper.getOutsideDecaleOnBoard(overlayEntity.getHasNeighborBottom()) +
            OverlayHelper.getOutsideDecaleOnBoard(overlayEntity.getHasNeighborTop());
        overlayEntity.scalingY = scaleY;
        overlayEntity.scalingZ = BOARD_THICKNESS;
    },

    updateFurnitureFrontMode(frameEntity) {
        if (
            !modules.dataStore.listEntities("door").length &&
            !modules.dataStore.listEntities("overlaydrawer").length
        ) {
            const furnitureEntity = modules.dataStore.getEntity(frameEntity.getFurnitureId());
            furnitureEntity.frontMode = null;
            events.emit("furniture:edited", { onlyCompositionMetadata: true });
        }
    },

};

export default OverlayHelper;
