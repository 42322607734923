import cu from "vendors/class-utils";

import { Vector3 } from "@babylonjs/core";
import Object3DEntity from "../entity-mixins/object3D-entity";

import KnobMixin from "../entity-mixins/knob-mixin";
import BoardInfo from "../board/board-info";
import { DRAWER_RUNNER_WEIGHT_1 } from "../constants";

const Entity = require("@obsidianjs/data-store/src/entity");

require("helpers/serializers/babylon-vector3");

const DrawerAbstractEntity = Object3DEntity.$extend({

    __name__: "insetdrawer",

    __init__(params = {}) {

        this.$data.quantity = params.quantity || 1;

        this.$data.drawerRunnerType = params.drawerRunnerType || DRAWER_RUNNER_WEIGHT_1;

        this.mesh = null;

        // reference mesh, instanciated with createInstance
        this.meshesRef = [];
        this.meshesRefByName = {};

        // contains N group, each group contains 5 meshes
        this.groupInstances = [];

        this.unitDrawerBoardComposition = {
            front: new BoardInfo(),
            back: new BoardInfo(),
            left: new BoardInfo(),
            right: new BoardInfo(),
            bottom: new BoardInfo(),
        };

        this.unitUtilSize = new Vector3(0, 0, 0);

        this.$super(params);
        this.initKnobMixin();
    },

    __include__: [KnobMixin.prototype],

    getQuantity: cu.getter,
    setQuantity: cu.setter,

    setDrawerRunnerType: cu.setter,
    getDrawerRunnerType: cu.getter,

    addMeshRef(mesh) {
        this.meshesRef.push(mesh);
        this.meshesRefByName[mesh.name] = mesh;
    },

});

Entity.$register(DrawerAbstractEntity);
export default DrawerAbstractEntity;
