import self from "../../index";
import AccessorydrawerController from "./accessorydrawer-controller";
import AccessorydrawerHelper from "./accessorydrawer-helper";

const {
    events,
    modules,
} = self.app;

function onDeleteAccessorydrawer(params) {
    AccessorydrawerHelper.deleteAccessorydrawerFromFrameEntityId(params.id);
}

function onSelectAccessorydrawer(params) {
    const frameEntity = modules.dataStore.getEntity(params.id);
    if (!frameEntity) return;
    const accessorydrawerEntity = modules.dataStore.getEntity(frameEntity.idAccessorydrawer);
    if (!accessorydrawerEntity) return;
    AccessorydrawerController.selectAccessorydrawer(accessorydrawerEntity);
    events.emit("accessories:selectable");
}

const AccessorydrawerEventsManager = {
    initialize() {
        events.on("@vuejs.accessorydrawer:select", onSelectAccessorydrawer);
        events.on("@vuejs.accessorydrawer:update:quantity", AccessorydrawerController.updateQuantity);
        events.on("@vuejs.accessorydrawer:showAll", AccessorydrawerController.showAll);
        events.on("@vuejs.accessorydrawer:hideAll", AccessorydrawerController.hideAll);
        events.on("@vuejs.history:back", AccessorydrawerEventsManager.onHistoryChange);
        events.on("@vuejs.history:forward", AccessorydrawerEventsManager.onHistoryChange);

        events.on("@vuejs.accessorydrawer:delete", onDeleteAccessorydrawer);

        events.on("accessorydrawer:update:quantity", AccessorydrawerController.updateQuantity);
        events.on("accesorry:current:unselect", AccessorydrawerEventsManager.onAccessoryDrawerUnselect);
    },

    onAccessoryDrawerUnselect() {
        AccessorydrawerController.selectAccessorydrawer();
        AccessorydrawerEventsManager.emitUpdated();
    },

    emitUpdated() {
        events.emit("accessorydrawer:updated", AccessorydrawerController.getCurrentAccessorydrawerEntityCopyForUi());
    },

    onHistoryChange() {
        AccessorydrawerController.updateFromHistoryChange();
        if (!AccessorydrawerController.currentAccessorydrawerEntity) return;
        const currentAccessorydrawerEntity = modules.dataStore.getEntity(AccessorydrawerController.currentAccessorydrawerEntity.id);
        if (!currentAccessorydrawerEntity) {
            AccessorydrawerController.currentAccessorydrawerEntity = undefined;
        }
        AccessorydrawerEventsManager.emitUpdated();
    },

    emitRejected(isMax) {
        events.emit("accessorydrawer:amount:rejected");
        events.emit("show:modal", {
            text: isMax ? "Vous avez atteint le nombre maximal de tiroirs, il faut augmenter la hauteur de la niche pour augmenter le nombre"
                : "Vous avez atteint le nombre minimal de tiroirs, il faut ajouter une séparation pour réduire le nombre",
            isInfo: true,
        });
    },
};

export default AccessorydrawerEventsManager;
