export default {

    name: "tweakpane",
    requires: [],

    config: {
        title: "",
        expanded: true,
        width: 500,
    },

    load() {
        const TweakpaneController = require("./src/tweakpane-controller").default;
        this.tweakpaneController = new TweakpaneController();
        return {
            controller: this.tweakpaneController,
        };
    },

    unload() {},
};
