<template>
    <svg id="icon-door-int-right"
         xmlns="http://www.w3.org/2000/svg"
         width="32"
         height="33.359"
         viewBox="0 0 32 33.359"
    >
        <path id="Soustraction_65"
              data-name="Soustraction 65"
              d="M-2392.143-5710l-8.855,0a3,3,0,0,1-3-3v-26a3,3,0,0,1,3-3h26a3,3,0,0,1,3,3v26a3,3,0,0,1-3,3h-3.522v-2H-2376a2,2,0,0,0,2-2v-24a2,2,0,0,0-2-2h-24a2,2,0,0,0-2,2v24a2,2,0,0,0,2,2h.051l7.8,2Zm15.144-3.5h-1.522v-19.6a1.317,1.317,0,0,0-.911-1.22l-14.936-4.184H-2377a1.5,1.5,0,0,1,1.5,1.5v22A1.5,1.5,0,0,1-2377-5713.5Z"
              transform="translate(2403.999 5742)"
              fill="#fff"
        />
        <path id="Tracé_293"
              data-name="Tracé 293"
              d="M206.5,458.589c.065-.039-18.957-5.313-18.957-5.313l-.014,24.014v.595l18.971,5.166a.633.633,0,0,0,.819-.658V459.671A1.171,1.171,0,0,0,206.5,458.589Zm-2.223,13.549a.633.633,0,1,1-1.266,0v-4.451a.633.633,0,1,1,1.266,0Z"
              transform="translate(-184.028 -449.718)"
              fill="#fff"
        />
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "door-right-inside-icon",
});
</script>
