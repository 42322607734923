import {
    Matrix,
} from "@babylonjs/core";
import FrameController from "../frame/frame-controller";
import self from "../../index";
import FrameMesh from "../frame/frame-mesh";
import InsetdrawerController from "./insetdrawer-controller";
import {
    DRAWER_NONE,
} from "../constants";
import DrawerHelper from "../drawer/drawer-helper";

const {
    modules,
    events,
} = self.app;

const InsetdrawerHelper = {
    actionToInsetdrawer(event, type) {
        if (!FrameController.isActiveTool()) return;
        const {
            scene,
        } = modules.obsidianBabylonEngine;
        const ray = scene.createPickingRay(event.layerX, event.clientY, Matrix.Identity(), scene.activeCamera);
        const hit = scene.pickWithRay(ray);
        if (!(hit.pickedMesh instanceof FrameMesh)) return;
        const frameEntity = modules.dataStore.getEntity(hit.pickedMesh.metadata.frameId);
        if (!frameEntity) return;
        switch (type) {
            case DRAWER_NONE:
                InsetdrawerController.deleteInsetdrawer(frameEntity);
                break;

            default:
                if (DrawerHelper.canAddToFrame(frameEntity)) {
                    InsetdrawerController.createInsetdrawer(frameEntity, type);
                } else {
                    events.emit("notification:message", {
                        title: "Placement incorrecte",
                        text: "Emplacement déjà occupé par un autre élément.",
                        status: "error",
                    });
                }
                break;
        }
    },
};

export default InsetdrawerHelper;
