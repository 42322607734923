import {
    MeshBuilder,
} from "@babylonjs/core/Meshes/meshBuilder";
import {
    Vector3,
} from "@babylonjs/core/Maths/math.vector";
import self from "../../index";
import CubeFacesMesh from "../common/cube-faces-mesh";

class DrawerMesh extends CubeFacesMesh {

    constructor(drawerEntity) {
        super(drawerEntity, undefined);
        this.addCustomWireframe(drawerEntity);
    }

    setVisible(value) {
        this.isVisible = value;
        this.setFacesVisible(value);
        this.linesSystem.isVisible = value;
        this.updateLinesSystem(1, !value);
    }

    addCustomWireframe() {
        const lines = [];

        lines.push(
            [
                new Vector3(0, 0, 0),
                new Vector3(1, 0, 0),
            ],
            [
                new Vector3(0, 1, 0),
                new Vector3(1, 1, 0),
            ],
            [
                new Vector3(0, 0, 0),
                new Vector3(0, 1, 0),
            ],
            [
                new Vector3(1, 0, 0),
                new Vector3(1, 1, 0),
            ],
        );

        lines.push(
            [
                new Vector3(0, 0, 0),
                new Vector3(0, 0, 1),
            ],
            [
                new Vector3(1, 0, 0),
                new Vector3(1, 0, 1),
            ],
            [
                new Vector3(0, 1, 0),
                new Vector3(0, 1, 1),
            ],
            [
                new Vector3(1, 1, 0),
                new Vector3(1, 1, 1),
            ],
        );

        this.linesSystem = MeshBuilder.CreateLineSystem("lines", {
            lines: lines,
        });
        this.linesSystem.isPickable = false;
        this.linesSystem.color = self.app.config.get("doorLinesColor");
    }

    /**
     * Lines scaling and position are independant for having a bigger size than the mesh.
     * Without flickering
     * @param {Number} enlargeValue in mm
     */
    updateLinesSystem(enlargeValue = 1) {
        this.linesSystem.scaling = this.scaling.clone();
        this.linesSystem.position = this.position.clone();
        this.linesSystem.scaling.z += enlargeValue;
        this.linesSystem.position.z -= enlargeValue * 0.5;
    }

}

export default DrawerMesh;
