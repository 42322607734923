<script>
export default {
    data() {
        return {
            isVisible: false,
            tooltipLeftOffset: 0,
        };
    },
    computed: {
        leftOffsetClass() {
            return `left:${this.tooltipLeftOffset}px;`;
        },
    },
    methods: {
        onEnterHover(e) {
            this.tooltipLeftOffset = e.clientX - 64;
            this.isVisible = true;
        },
        onLeaveHover() {
            this.isVisible = false;
        },
    },
};
</script>
<template>
    <div
        class="text-slate-600 flex items-center h-7"
    >
        <span
            class="group/tooltip bg-slate-600 text-white rounded-full flex justify-center items-center h-3 w-3 text-xs mr-2 relative"
            @mouseover="onEnterHover"
            @mouseleave="onLeaveHover"
        >
            i
        </span>
        <span
            :style="leftOffsetClass"
            :class="
                [
                    'fixed bottom-10 z-10 w-32 bg-slate-900 text-white text-xs text-center rounded-md p-1',
                    !isVisible && 'invisible opacity-0',
                    isVisible && 'visible opacity-100',
                    'transition-opacity duration-300',
                ]"
        >
            <slot />
        </span>
    </div>
</template>
