import {
    UniversalCamera,
    Vector3,
    Vector2,
    Camera,
    ArcRotateCamera,
    Mesh,
} from "@babylonjs/core";

import TWEEN from "@tweenjs/tween.js";
import CameraTweaks from "./cameras-tweaks";

import self from "../index";
import {
    CAMERA_ARC_ROTATE,
    CAMERA_ORTHO,
} from "./constants";
import {
    CameraArcRotateSettings,
} from "./cameras-settings";

import {
    map,
} from "../../../helpers/utils";
import CameraHelper from "./camera-helper";
import constraints from "../../furnitures/src/constraints";

const {
    Tween,
    Easing,
} = TWEEN;

const {
    config,
    events,
    modules,
} = self.app;

/**
 * CamerasManager Controller module
 */
class CamerasManagerController {

    constructor() {
        this.cameraType = "arcRotate";

        this.engine = modules.obsidianBabylonEngine;
        this.engine.waitForLoading().then(() => {
            this.initialize();
        });
        if (process.env.NODE_ENV === "development") {
            window.cameraManager = this;
            modules.tweakpane.controller.waitForLoading().then(this.onTweakpaneCreated.bind(this));
        }
    }

    onTweakpaneCreated(pane) {
        CameraTweaks.addTweaks(this, pane);
    }

    initialize() {
        this.scene = this.engine.scene;
        this.canvas = this.engine.canvas;
        this.canvas.oncontextmenu = () => false;

        this.camOrtho = new UniversalCamera("UniversalCamera", new Vector3(0, 100, -1), this.scene);
        this.camOrtho.mode = Camera.ORTHOGRAPHIC_CAMERA;
        this.camOrtho.minZ = -1000;
        this.camOrtho.maxZ = 1000;
        this.zoomFactor = 1;
        this.resizeOrtho();

        this.camArcRotate = new ArcRotateCamera("camera", -Math.PI / 2, Math.PI / 2.5, 2000, new Vector3(0, 0, 0), this.scene);
        // Keep only mouse inputs
        this.camArcRotate.inputs.clear();
        this.camArcRotate.inputs.addPointers();
        this.camArcRotate.inputs.addMouseWheel();
        CameraArcRotateSettings.default(this.camArcRotate);

        if (process.env.NODE_ENV === "development") {
            window.camArcRotate = this.camArcRotate;
        }

        this.engine.mainLoop.start();

        this.defaultAnimationDuration = config.get("defaultAnimationDuration") || 700;
        this.currentTween = null;

        this.engine.mainLoop.addCallback(() => {
            TWEEN.update();
        });

        this.activeCameraArcRotate();

        this.onOrthoPointerDownCallback = null;
        this.onOrthoWheelCallback = null;

        this.isInMove = false;
        this.isInTransition = false;
        this.eventDispatcherEnabled = false;

        events.on("@furnitures.camera:lock", this.lockCamera.bind(this));
        events.on("@furnitures.camera:unlock", this.unlockCamera.bind(this));
        events.on("@vuejs.camera:eventsDispatcher:on", this.eventDispatchEnable.bind(this));
        events.on("@vuejs.camera:eventsDispatcher:off", this.eventDispatchDisable.bind(this));
        window.addEventListener("resize", this.resizeCamera.bind(this));

        this.handlePointerDown = this.onPointerDown.bind(this);
        this.handlePointerUp = this.onPointerUp.bind(this);
        this.handleMouseWheel = this.onMouseWheel.bind(this);
    }

    eventDispatchEnable() {
        if (this.eventDispatcherEnabled) return;
        this.eventDispatcherEnabled = true;
        this.canvas.addEventListener("pointerdown", this.handlePointerDown);
        window.addEventListener("pointerup", this.handlePointerUp);
        window.addEventListener("wheel", this.handleMouseWheel);
    }

    eventDispatchDisable() {
        if (!this.eventDispatcherEnabled) return;
        this.canvas.removeEventListener("pointerdown", this.handlePointerDown);
        window.removeEventListener("pointerup", this.handlePointerUp);
        window.removeEventListener("wheel", this.handleMouseWheel);
        this.eventDispatcherEnabled = false;
    }

    onPointerDown(event) {
        const picked = this.scene.pick(event.x, event.y);
        if (!picked || !picked.pickedMesh || !picked.pickedMesh.id.startsWith("drag")) {
            events.emit("camera:move:start");
            this.isInMove = true;
        }
    }

    onPointerUp() {
        if (!this.isInTransition && this.isInMove) {
            this.isInMove = false;
            events.emit("camera:move:end");
        }
    }

    onMouseWheel() {
        if (!this.isInMove) events.emit("camera:moved");
    }

    resizeCamera() {
        if (this.scene.activeCamera === this.camOrtho) {
            this.resizeOrtho(true);
        } else if (this.eventDispatcherEnabled) {
            events.emit("camera:moved");
        }
    }

    freeCamera() {
        CameraArcRotateSettings.default(this.camArcRotate);
        this.focusOnMeshes();
    }

    perspectiveCameraForSnapshot() {
        this.freeCamera();
        this.camArcRotate.alpha = Math.PI * 2 * 0.65;
        this.camArcRotate.beta = Math.PI * 2 * 0.25;
        this.camArcRotate.radius = 2000;
        this.focusOnMeshes();
    }

    lockToFrontWithPerspective() {
        CameraArcRotateSettings.lockToFront(this.camArcRotate);
        this.focusOnMeshes();
    }

    lockToBackWithPerspective() {
        CameraArcRotateSettings.lockToBack(this.camArcRotate);
        this.focusOnMeshes();
    }

    lockToRightWithPerspective() {
        CameraArcRotateSettings.lockToRight(this.camArcRotate);
        this.focusOnMeshes();
    }

    lockCamera() {
        this.camArcRotate.detachControl();
    }

    unlockCamera() {
        this.camArcRotate.attachControl(this.canvas, true);
    }

    switchCamera(cameraType) {
        if (cameraType === CAMERA_ORTHO) {
            this.activeCameraOrtho();
        } else if (cameraType === CAMERA_ARC_ROTATE) {
            this.activeCameraArcRotate();
        } else if (this.scene.activeCamera === this.camArcRotate) {
            this.activeCameraOrtho();
        } else if (this.scene.activeCamera === this.camOrtho) {
            this.activeCameraArcRotate();
        }
        if (this.eventDispatcherEnabled && !this.isInMove) events.emit("camera:moved");
    }

    activeCameraOrtho() {
        this.camArcRotate.detachControl();
        this.scene.activeCamera = this.camOrtho;

        this.onOrthoWheelCallback = this.onOrthoWheel.bind(this);
        this.canvas.addEventListener("wheel", this.onOrthoWheelCallback);
        this.onOrthoPointerDownCallback = this.onOrthoPointerDown.bind(this);
        this.canvas.addEventListener("pointerdown", this.onOrthoPointerDownCallback);
        this.cameraType = "ortho";
    }

    activeCameraArcRotate() {
        if (this.onOrthoWheelCallback) {
            this.canvas.removeEventListener("wheel", this.onOrthoWheelCallback);
        }
        if (this.onOrthoPointerDownCallback) {
            this.canvas.removeEventListener("pointerdown", this.onOrthoPointerDownCallback);
        }

        this.cameraType = "arcRotate";

        this.scene.activeCamera = this.camArcRotate;
        this.camArcRotate.attachControl(this.canvas, true);
    }

    resizeOrtho() {
        this.camOrtho.orthoTop = this.canvas.height * this.zoomFactor;
        this.camOrtho.orthoBottom = -this.canvas.height * this.zoomFactor;
        this.camOrtho.orthoLeft = -this.canvas.width * this.zoomFactor;
        this.camOrtho.orthoRight = this.canvas.width * this.zoomFactor;
        if (this.eventDispatcherEnabled) {
            events.emit("camera:moved");
        }
    }

    onOrthoWheel(event) {
        if (this.scene.activeCamera === this.camOrtho) {
            if (event.deltaY > 0) {
                this.zoomFactor = 1.2;
            } else {
                this.zoomFactor = 0.8;
            }

            // const xRatio = event.layerX / this.canvas.width; // hold for later
            // const yRatio = event.layerY / this.canvas.height; // hold for later

            this.camOrtho.orthoTop *= this.zoomFactor;
            this.camOrtho.orthoBottom *= this.zoomFactor;
            this.camOrtho.orthoLeft *= this.zoomFactor;
            this.camOrtho.orthoRight *= this.zoomFactor;
            if (this.eventDispatcherEnabled) {
                events.emit("camera:moved");
            }
        }
    }

    onOrthoPointerDown(event) {
        if (this.scene.activeCamera === this.camOrtho && event.which === 3) {
            const orthoMoveStart = new Vector2(event.x, event.y);

            const orthoTopStart = this.camOrtho.orthoTop;
            const orthoBottomStart = this.camOrtho.orthoBottom;
            const orthoLeftStart = this.camOrtho.orthoLeft;
            const orthoRightStart = this.camOrtho.orthoRight;

            const move = (eventMove) => {
                const orthoMove = new Vector2(eventMove.x, eventMove.y).subtract(orthoMoveStart);
                this.camOrtho.orthoTop = orthoTopStart + orthoMove.y * 1;
                this.camOrtho.orthoBottom = orthoBottomStart + orthoMove.y * 1;
                this.camOrtho.orthoLeft = orthoLeftStart - orthoMove.x * 1;
                this.camOrtho.orthoRight = orthoRightStart - orthoMove.x * 1;
            };

            const up = () => {
                this.canvas.removeEventListener("pointermove", move);
                this.canvas.removeEventListener("pointerup", up);
                this.isInMove = false;
                if (this.eventDispatcherEnabled) {
                    events.emit("camera:move:end");
                }
            };

            this.canvas.addEventListener("pointermove", move);
            this.canvas.addEventListener("pointerup", up);
            if (this.eventDispatcherEnabled) {
                events.emit("camera:move:start");
            }
            this.isInMove = true;
        }
    }

    focusOnMeshes() {
        const furnitures = [];
        modules.dataStore.listEntities("board").forEach((board) => {
            if (board.isBlockPart) furnitures.push(board.mesh);
        });

        if (furnitures.length > 0) {
            if (this.camArcRotate.fov < 0.2) {
                const furnituresHeight = Mesh.MinMax(furnitures).max.y;
                this.camArcRotate.focusOn(furnitures, true);
                if (CameraHelper.isBack(this.camArcRotate)) {
                    this.camArcRotate.radius = map(
                        furnituresHeight, constraints.MIN_FURNITURE_HEIGHT.min, constraints.MAX_FURNITURE_HEIGHT.max, 10000, 42000
                    );
                } else {
                    this.camArcRotate.radius = map(
                        furnituresHeight, constraints.MIN_FURNITURE_HEIGHT.min, constraints.MAX_FURNITURE_HEIGHT.max, 10000, 38000
                    );
                }
            } else {
                this.camArcRotate.zoomOn(furnitures, true);
            }
            if (this.eventDispatcherEnabled) {
                events.emit("camera:moved");
            }
        }
    }

    setViewByName(viewName, animate = true) {
        const views = config.get("views");
        const view = views[viewName] || [2000, 0, 0];
        this.setupView(...view, animate);
    }

    setupView(radius, alpha, beta, animate = true) {
        if (!this.canvas) {
            return;
        }

        const newView = {
            radius,
            alpha,
            beta,
        };
        this.setCameraView(newView, animate);
    }

    /**
     * Set the camera view, the transition can be animated according to the animate parameter
     * @param { alpha, beta, radius, target, position} view the view we want to set the camera
     * @param {Boolean} animate define if we want to animate the transition, false by default
     * @param {animationDuration, easeFunction} animationParams the parameters of the animation
     */
    setCameraView(view, animate = false, animationParams = {
        animationDuration: null,
        easeFunction: Easing.Quadratic.Out,
    }) {
        // Promise enable to use callback after animation ended
        return new Promise(
            (resolve) => {
                if (this.cameraType === "ortho") {
                    return;
                }
                if (this.currentTween) {
                    this.currentTween.stop();
                    this.currentTween = null;
                }
                this.camArcRotate.computeWorldMatrix(true);
                if (animate) {
                    if (this.eventDispatcherEnabled) {
                        events.emit("camera:move:start");
                    }
                    this.isInTransition = true;
                    this.isInMove = true;
                    // Set a modulo 2 * PI to avoid big Jump of value
                    this.camArcRotate.alpha %= (2 * Math.PI);
                    this.camArcRotate.beta %= (2 * Math.PI);
                    const duration = animationParams.animationDuration || this.defaultAnimationDuration;
                    this.currentTween = new Tween(this.camArcRotate)
                        .to({
                            alpha: view.alpha % (2 * Math.PI),
                            beta: view.beta % (2 * Math.PI),
                            radius: view.radius,
                        }, duration)
                        .easing(animationParams.easeFunction)
                        .onComplete(() => {
                            resolve();
                            this.isInMove = false;
                            this.isInTransition = false;
                            if (this.eventDispatcherEnabled) {
                                events.emit("camera:move:end");
                            }
                        });
                    if (view.target) {
                        this.currentTween.onStart(() => {
                            new Tween(this.camArcRotate.target)
                                .to({
                                    x: view.target.x,
                                    y: view.target.y,
                                    z: view.target.z,
                                }, duration)
                                .easing(animationParams.easeFunction)
                                .start();

                        });
                    }
                    if (view.position) {
                        // Getting old onStartCallback function in case it was set for target animation
                        const defaultOnStart = this.currentTween._onStartCallback;

                        this.currentTween.onStart(() => {
                            if (typeof defaultOnStart === "function") {
                                defaultOnStart();
                            }
                            new Tween(this.camArcRotate.position)
                                .to({
                                    x: view.position.x,
                                    y: view.position.y,
                                    z: view.position.z,
                                }, duration)
                                .easing(animationParams.easeFunction)
                                .start();
                        });
                    }
                    this.currentTween.start();
                } else {
                    // No animation
                    if (view.target) {
                        this.camArcRotate.target = new Vector3(
                            view.target.x, view.target.y, view.target.z
                        );
                    }
                    if (view.position) {
                        this.camArcRotate.position = new Vector3(
                            view.position.x, view.position.y, view.position.z
                        );
                    }

                    this.camArcRotate.alpha = view.alpha;
                    this.camArcRotate.beta = view.beta;
                    this.camArcRotate.radius = view.radius;

                    this.camArcRotate.computeWorldMatrix(true);
                    resolve();
                    if (this.eventDispatcherEnabled) {
                        events.emit("camera:moved");
                    }
                }
            }
        );
    }

}

export default CamerasManagerController;
