import {
    Geometry,
    Mesh,
    MeshBuilder,
    StandardMaterial,
} from "@babylonjs/core";


class Hitbox extends Mesh {

    constructor() {
        super("hitbox", null);
        Hitbox.getHitBoxGeometry().copy().applyToMesh(this);

        this.isPickable = false;

        this.material = Hitbox.getHitboxMaterial();
    }

    static getHitBoxGeometry() {
        // Generate geometries once
        if (!Hitbox.hitBoxGeometry) {
            const box = MeshBuilder.CreateBox("hitBox", {});
            box.position.set(0.5, 0.5, 0.5);
            box.bakeCurrentTransformIntoVertices();
            Hitbox.hitBoxGeometry = Geometry.ExtractFromMesh(box, "hitBox");
            box.dispose();
        }
        return Hitbox.hitBoxGeometry;
    }

    static getHitboxMaterial() {
        if (!Hitbox.hitboxMaterial) {
            Hitbox.hitboxMaterial = new StandardMaterial("hitBoxMat");
            Hitbox.hitboxMaterial.alpha = 0;
        }
        return Hitbox.hitboxMaterial;
    }

}

export default Hitbox;
