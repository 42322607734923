import {
    Vector3,
} from "@babylonjs/core";
import TransformManager from "../transformManager";
import self from "../../index";
import {
    BOARD_THICKNESS,
    FRONT_MODE_OUTSIDE,
    OVERLAY_DRAWER_DECAL_Z,
    RESIZE_FROM_BACK,
    RESIZE_FROM_RIGHT,
    RESIZE_FROM_TOP,
} from "../constants";
import ConstraintsManager from "../constraints-manager";
import {
    clamp,
} from "../../../../helpers/utils";
import Axis from "../axis";
import BaseboardController from "../baseboard/baseboard-controller";
import FurnitureController from "./furniture-controller";
import FootController from "../foot/foot-controller";
import constraints, {
    BASEBOARD_CONSTRAINTS, FURNITURE_CONSTRAINTS,
} from "../constraints";
import FrameHelper from "../frame/frame-helper";
import AccessorydrawerController from "../accessorydrawer/accessorydrawer-controller";
import PulloutshelfController from "../pulloutshelf/pulloutshelf-controller";
import MovableshelfController from "../movableshelf/movableshelf-controller";
import BackboardController from "../backboard/backboard-controller";
import DoorController from "../door/door-controller";
import OverlaydrawerController from "../overlaydrawer/overlaydrawer-controller";
import InsetdrawerController from "../insetdrawer/insetdrawer-controller";
import CameraHelper from "../../../cameras-manager/src/camera-helper";
import { CAMERA_FRONT, CAMERA_PERSPECTIVE, CAMERA_RIGHT } from "../../../cameras-manager/src/constants";
import FurnitureEventsManager from "./furniture-event-managers";

const {
    events,
    modules,
} = self.app;

const FurnitureHelper = {

    /**
     * Test a furniture's mode is the same or is empty.
     * @param {furnitureEntity} furnitureEntity
     * @param {String} mode : FRONT_MODE_INSIDE | FRONT_MODE_OUTSIDE
     * @returns
     */
    isValidFurnitureFrontMode(furnitureEntity, mode) {
        return furnitureEntity.frontMode === mode || !furnitureEntity.frontMode;
    },

    createBaseboard: () => {
        const {
            currentFurnitureEntity,
        } = self.furnituresListController;
        if (currentFurnitureEntity.getIdBaseboard()) return;
        if (currentFurnitureEntity.containFeet()) FurnitureHelper.deleteFoot();

        currentFurnitureEntity.baseHeight = clamp(
            currentFurnitureEntity.baseHeight,
            BASEBOARD_CONSTRAINTS.min.y,
            BASEBOARD_CONSTRAINTS.max.y
        );

        currentFurnitureEntity.setPositionY(currentFurnitureEntity.getBaseHeight());
        BaseboardController.createBaseboard(currentFurnitureEntity);

        FurnitureController.updateBlockMesh(currentFurnitureEntity);
        events.emit("furniture:edited");
    },

    createFoot: (feet) => {
        const {
            currentFurnitureEntity,
        } = self.furnituresListController;
        if (currentFurnitureEntity.getIdBaseboard()) FurnitureHelper.deleteBaseboard();
        if (currentFurnitureEntity.containFeet()) {
            currentFurnitureEntity.setBaseHeight(feet.height);
            currentFurnitureEntity.setFeet(feet);
            currentFurnitureEntity.setPositionY(currentFurnitureEntity.getBaseHeight());
            FootController.transformFeet(currentFurnitureEntity);
            events.emit("furniture:edited");
            return;
        }

        currentFurnitureEntity.setFeet(feet);
        currentFurnitureEntity.setBaseHeight(feet.height);
        currentFurnitureEntity.setPositionY(currentFurnitureEntity.getBaseHeight());
        FootController.createFeet(currentFurnitureEntity);
        events.emit("furniture:edited");
    },

    deleteBaseboard: () => {
        const {
            currentFurnitureEntity,
        } = self.furnituresListController;
        if (!currentFurnitureEntity.getIdBaseboard()) return;

        BaseboardController.deleteBaseboard(currentFurnitureEntity.getIdBaseboard());
        currentFurnitureEntity.setPositionY(0);
        FurnitureController.updateBlockMesh(currentFurnitureEntity);
        events.emit("furniture:edited");
    },

    deleteFoot: () => {
        const {
            currentFurnitureEntity,
        } = self.furnituresListController;
        if (!currentFurnitureEntity.containFeet()) return;

        FootController.deleteFeet(currentFurnitureEntity);
        currentFurnitureEntity.setPositionY(0);
        events.emit("furniture:edited");
    },

    updateBaseboardHeight: (newHeight) => {
        const {
            currentFurnitureEntity,
        } = self.furnituresListController;
        if (!currentFurnitureEntity.getIdBaseboard()) return;

        if (newHeight >= BASEBOARD_CONSTRAINTS.min.y && newHeight <= BASEBOARD_CONSTRAINTS.max.y) {
            currentFurnitureEntity.setBaseHeight(newHeight);
            currentFurnitureEntity.setPositionY(currentFurnitureEntity.getBaseHeight());
            BaseboardController.updateSize(currentFurnitureEntity.getIdBaseboard());
            FurnitureController.updateBlockMesh(currentFurnitureEntity);
        } else {
            events.emit("baseboard:height:rejected");
            events.emit("notification:message", {
                title: "Hauteur incorrecte",
                text: `Doit être comprise entre ${BASEBOARD_CONSTRAINTS.min.y} et ${BASEBOARD_CONSTRAINTS.max.y} mm`,
                status: "error",
            });
        }

        events.emit("furniture:edited");

    },

    tryToScaleX: (nextScaleX) => {
        const {
            currentFurnitureEntity,
        } = self.furnituresListController;

        if (nextScaleX < FURNITURE_CONSTRAINTS.min.x || nextScaleX > FURNITURE_CONSTRAINTS.max.x) {
            FurnitureHelper.rejectedScaleX();
            return;
        }

        const scalingDiff = new Vector3(nextScaleX - currentFurnitureEntity.scaling.x, 0, 0);
        const boardEntity = modules.dataStore.getEntity(currentFurnitureEntity.idRightBlockBoard);
        const limits = ConstraintsManager.getBoardDisplacementLimitsFromFrame(currentFurnitureEntity.idFrame, boardEntity);
        const scalingData = {
            furnitureEntity: currentFurnitureEntity,
            scalingDiff,
            resizeFrom: RESIZE_FROM_RIGHT,
        };
        if (nextScaleX >= limits.min + BOARD_THICKNESS && nextScaleX <= limits.max) {
            FurnitureHelper.scaleXorY(scalingData);
        } else {
            FurnitureHelper.showModalToDeleteContentForScaleXorY(scalingData);
        }

        events.emit("furniture:edited");
    },

    tryToScaleY: (nextScaleY) => {
        const {
            currentFurnitureEntity,
        } = self.furnituresListController;

        if (nextScaleY < FURNITURE_CONSTRAINTS.min.y || nextScaleY > FURNITURE_CONSTRAINTS.max.y) {
            FurnitureHelper.rejectedScaleY();
            return;
        }

        const scalingDiff = new Vector3(0, nextScaleY - currentFurnitureEntity.scaling.y, 0);
        const boardEntity = modules.dataStore.getEntity(currentFurnitureEntity.idTopBlockBoard);
        const limits = ConstraintsManager.getBoardDisplacementLimitsFromFrame(currentFurnitureEntity.idFrame, boardEntity);
        const scalingData = {
            furnitureEntity: currentFurnitureEntity,
            scalingDiff,
            resizeFrom: RESIZE_FROM_TOP,
        };
        if (nextScaleY >= limits.min + BOARD_THICKNESS && nextScaleY <= limits.max + BOARD_THICKNESS) {
            FurnitureHelper.scaleXorY(scalingData);
        } else {
            FurnitureHelper.showModalToDeleteContentForScaleXorY(scalingData);
        }
    },

    tryToScaleZ: (nextScaleZ) => {
        const {
            currentFurnitureEntity,
        } = self.furnituresListController;

        if (nextScaleZ < FURNITURE_CONSTRAINTS.min.z || nextScaleZ > FURNITURE_CONSTRAINTS.max.z) {
            FurnitureHelper.rejectedScaleZ();
            return;
        }

        const minDepthAcceptedForDrawerRunner = ConstraintsManager.getFurnitureMinDepthForDrawerRunner(currentFurnitureEntity);

        const scalingDiff = new Vector3(0, 0, nextScaleZ - currentFurnitureEntity.scaling.z);
        const limits = {
            min: Math.max(minDepthAcceptedForDrawerRunner, constraints.MIN_FURNITURE_DEPTH.min),
            max: constraints.MAX_FURNITURE_DEPTH.max,
        };
        const scalingData = {
            furnitureEntity: currentFurnitureEntity,
            scalingDiff,
            resizeFrom: RESIZE_FROM_BACK,
        };

        if (nextScaleZ >= limits.min && nextScaleZ <= limits.max) {
            FurnitureHelper.scaleZ(scalingData);
        } else {
            FurnitureHelper.showModalToDeleteContentForScaleZ(scalingData);
        }

        events.emit("furniture:edited");
    },

    showModalToDeleteContentForScaleXorY: (scalingData) => {
        events.emit("show:modal", {
            text: "Redimensionner le meuble supprimera son contenu\n Êtes-vous sûr de vouloir continuer ?",
            isInfo: false,
            validateEvent: "furniture:deleteContentForScaleXorY",
            validateEventParams: scalingData,
        });
    },

    showModalToDeleteContentForScaleZ: (scalingData) => {
        events.emit("show:modal", {
            text: "Redimensionner le meuble supprimera son contenu\n Êtes-vous sûr de vouloir continuer ?",
            isInfo: false,
            validateEvent: "furniture:deleteContentForScaleZ",
            validateEventParams: scalingData,
        });
    },

    rejectedScaleX() {
        events.emit("furniture:tryToScaleX:rejected");
        events.emit("notification:message", {
            title: "Largeur incorrecte",
            text: `Doit être comprise entre ${FURNITURE_CONSTRAINTS.min.x} et ${FURNITURE_CONSTRAINTS.max.x} mm`,
            status: "error",
        });
        FurnitureEventsManager.emitFurnitureCompositionUpdated({ onlyCompositionMetadata: true });
    },

    rejectedScaleY() {
        events.emit("furniture:tryToScaleY:rejected");
        events.emit("notification:message", {
            title: "Hauteur incorrecte",
            text: `Doit être comprise entre ${FURNITURE_CONSTRAINTS.min.y} et ${FURNITURE_CONSTRAINTS.max.y} mm`,
            status: "error",
        });
        FurnitureEventsManager.emitFurnitureCompositionUpdated({ onlyCompositionMetadata: true });
    },

    rejectedScaleZ() {
        events.emit("furniture:tryToScaleZ:rejected");
        events.emit("notification:message", {
            title: "Profondeur incorrecte",
            text: `Doit être comprise entre ${FURNITURE_CONSTRAINTS.min.z} et ${FURNITURE_CONSTRAINTS.max.z} mm`,
            status: "error",
        });
        FurnitureEventsManager.emitFurnitureCompositionUpdated({ onlyCompositionMetadata: true });
    },

    scaleXorY: (payload) => {
        TransformManager.resizeFurniture(payload.furnitureEntity, payload.scalingDiff, payload.resizeFrom);
        TransformManager.resizeDoors();
        TransformManager.resizeOverlaydrawer();
        TransformManager.resizeInsetdrawer();

        FurnitureHelper.focusFurniture();
        events.emit("furniture:edited");
    },

    scaleZ: (payload) => {
        TransformManager.resizeFurniture(payload.furnitureEntity, payload.scalingDiff, payload.resizeFrom);
        Axis.adjustDepthPosition(payload.furnitureEntity.scaling.z);
        FurnitureHelper.focusFurniture();
        events.emit("furniture:edited");
    },

    deleteContent: () => {
        const {
            currentFurnitureEntity,
        } = self.furnituresListController;

        DoorController.clearAllFromFurnitureId(currentFurnitureEntity.id);
        OverlaydrawerController.clearAllFromFurnitureId(currentFurnitureEntity.id);
        InsetdrawerController.clearAllFromFurnitureId(currentFurnitureEntity.id);

        AccessorydrawerController.clearAllFromFurnitureId(currentFurnitureEntity.id);
        PulloutshelfController.clearAllFromFurnitureId(currentFurnitureEntity.id);
        MovableshelfController.clearAllFromFurnitureId(currentFurnitureEntity.id);
        BackboardController.clearAllFromFurnitureId(currentFurnitureEntity.id);

        const frameEntity = modules.dataStore.getEntity(currentFurnitureEntity.idFrame);
        FrameHelper.deleteAllDescendants(frameEntity);
        BackboardController.createNormalBackboard(frameEntity);

        currentFurnitureEntity.frontMode = null;
        FurnitureEventsManager.emitFurnitureCompositionUpdated();
    },

    focusFurniture() {
        modules.obsidianBabylonEngine.scene.onAfterRenderObservable.addOnce(() => {
            modules.camerasManager.controller.focusOnMeshes();
        });
    },

    getCurrentFurnitureEntityCopyForUi() {
        const {
            currentFurnitureEntity,
        } = self.furnituresListController;
        const scaling = currentFurnitureEntity.scaling.clone();

        const overall = {
            width: scaling.x,
            height: scaling.y,
            depth: scaling.z,
        };
        if (currentFurnitureEntity.idBaseboard || currentFurnitureEntity.idsFeet.length) {
            overall.height += currentFurnitureEntity.baseHeight;
        }
        if (currentFurnitureEntity.frontMode === FRONT_MODE_OUTSIDE) {
            overall.depth += BOARD_THICKNESS + OVERLAY_DRAWER_DECAL_Z;
        }

        return {
            scaling,
            frontMode: currentFurnitureEntity.frontMode,
            baseHeight: currentFurnitureEntity.baseHeight,
            hasBaseboard: currentFurnitureEntity.hasBaseboard(),
            containFeet: currentFurnitureEntity.containFeet(),
            overall: { ...overall },
        };
    },

    deactivateAllTools() {
        modules.toolManager.controller.deactivateAllTools();
    },

    updateKnobs(knob) {
        const {
            currentFurnitureEntity,
        } = self.furnituresListController;

        currentFurnitureEntity.knob = knob;

        modules.dataStore.listEntities("door").forEach((door) => {
            if (door.furnitureId === currentFurnitureEntity.id) {
                door.knobs.forEach((doorKnob) => {
                    doorKnob.isVisible = currentFurnitureEntity.knobVisible;
                });
            }
        });
        modules.dataStore.listEntities("overlaydrawer").forEach((overlaydrawer) => {
            if (overlaydrawer.furnitureId === currentFurnitureEntity.id) {
                overlaydrawer.knobs.forEach((drawerKnob, index) => {
                    drawerKnob.isVisible = currentFurnitureEntity.knobVisible && index < overlaydrawer.quantity;
                });
            }
        });
        modules.dataStore.listEntities("insetdrawer").forEach((insetdrawer) => {
            if (insetdrawer.furnitureId === currentFurnitureEntity.id) {
                insetdrawer.knobs.forEach((drawerKnob, index) => {
                    drawerKnob.isVisible = currentFurnitureEntity.knobVisible && index < insetdrawer.quantity;
                });
            }
        });
        events.emit("furniture:edited");
    },

    getFurnitureViewCropRect(furnitureEntity, cameraSide) {
        const rect = {};
        const baseHeightVector = new Vector3();
        if (furnitureEntity.hasBaseboard() || furnitureEntity.containFeet()) {
            baseHeightVector.y = furnitureEntity.baseHeight;
        }

        if (cameraSide === CAMERA_FRONT) {
            const topLeft = CameraHelper.convert3DPointTo2D(furnitureEntity.position.add(new Vector3(0, furnitureEntity.scaling.y, 0)));
            const bottomRight = CameraHelper.convert3DPointTo2D(furnitureEntity.position.subtract(baseHeightVector).add(new Vector3(furnitureEntity.scaling.x, 0, 0)));
            const topRight = CameraHelper.convert3DPointTo2D(furnitureEntity.position.add(new Vector3(furnitureEntity.scaling.x, furnitureEntity.scaling.y, 0)));

            rect.x = topLeft.x;
            rect.y = topLeft.y;
            rect.width = topRight.x - topLeft.x;
            rect.height = bottomRight.y - topRight.y;
        }

        if (cameraSide === CAMERA_RIGHT) {
            const topLeft = CameraHelper.convert3DPointTo2D(furnitureEntity.position.add(new Vector3(furnitureEntity.scaling.x, furnitureEntity.scaling.y, 0)));
            const bottomRight = CameraHelper.convert3DPointTo2D(furnitureEntity.position.subtract(baseHeightVector).add(new Vector3(furnitureEntity.scaling.x, 0, furnitureEntity.scaling.z)));
            const topRight = CameraHelper.convert3DPointTo2D(furnitureEntity.position.add(furnitureEntity.scaling));

            rect.x = topLeft.x;
            rect.y = topLeft.y;
            rect.width = topRight.x - topLeft.x;
            rect.height = bottomRight.y - topRight.y;
        }

        if (cameraSide === CAMERA_PERSPECTIVE) {
            const frontTopLeft = CameraHelper.convert3DPointTo2D(furnitureEntity.position.add(new Vector3(0, furnitureEntity.scaling.y, 0)));
            const frontBottomLeft = CameraHelper.convert3DPointTo2D(furnitureEntity.position.subtract(baseHeightVector));
            const frontBottomRight = CameraHelper.convert3DPointTo2D(furnitureEntity.position.subtract(baseHeightVector).add(new Vector3(furnitureEntity.scaling.x, 0, 0)));
            const frontTopRight = CameraHelper.convert3DPointTo2D(furnitureEntity.position.add(new Vector3(furnitureEntity.scaling.x, furnitureEntity.scaling.y, 0)));

            const backTopLeft = CameraHelper.convert3DPointTo2D(furnitureEntity.position.add(new Vector3(0, furnitureEntity.scaling.y, furnitureEntity.scaling.z)));
            const backBottomLeft = CameraHelper.convert3DPointTo2D(furnitureEntity.position.subtract(baseHeightVector).add(new Vector3(0, 0, furnitureEntity.scaling.z)));
            const backBottomRight = CameraHelper.convert3DPointTo2D(furnitureEntity.position.subtract(baseHeightVector).add(new Vector3(furnitureEntity.scaling.x, 0, furnitureEntity.scaling.z)));
            const backTopRight = CameraHelper.convert3DPointTo2D(furnitureEntity.position.add(furnitureEntity.scaling));

            rect.x = Math.min(frontTopLeft.x, frontBottomLeft.x, backTopLeft.x, backBottomLeft.x);
            rect.y = Math.min(frontTopLeft.y, frontTopRight.y, backTopLeft.y, backTopRight.y);
            rect.width = Math.max(frontTopRight.x, frontBottomRight.x, backTopRight.x, backBottomRight.x) - rect.x;
            rect.height = Math.max(frontBottomLeft.y, frontBottomRight.y, backBottomLeft.y, backBottomRight.y) - rect.y;
        }

        return rect;
    },
};

export default FurnitureHelper;
