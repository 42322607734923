import {
    StandardMaterial,
} from "@babylonjs/core/Materials/standardMaterial";
import {
    Vector3,
} from "@babylonjs/core/Maths/math.vector";
import {
    MeshBuilder,
} from "@babylonjs/core/Meshes/meshBuilder";


import {
    ORIENTATION_HORIZONTAL,
    ORIENTATION_VERTICAL,
} from "../constants";

import self from "../../index";
import CubeFacesMesh from "../common/cube-faces-mesh";

/**
 * 1 geometryBox for interactivity with material alpha 0.
 * 4 children meshes, each have different uv scale. They are not pickable.
 *  - face
 *  - back
 *  - left with right
 *  - bottom with top
 * 1 lineSystem for the custom wireframe
 */
class BoardMesh extends CubeFacesMesh {

    constructor(boardEntity) {
        super(boardEntity, { addFaces: false });

        this.orientation = boardEntity.getOrientation();
        this.hasAllFacesVisible = boardEntity.getHasAllFacesVisible !== undefined ? boardEntity.getHasAllFacesVisible() : false;

        this.material = new StandardMaterial("hitBoxMat");
        this.material.alpha = 0;

        this.addFaces();
        this.addCustomWireframe();
    }

    dispose() {
        super.dispose();
        this.linesSystem.dispose();
    }

    setFacesVisible(value) {
        if (this.faceFront) {
            this.faceFront.isVisible = value;
        }
        if (this.faceBack) {
            this.faceBack.isVisible = value;
        }
        if (this.leftRight) {
            this.leftRight.isVisible = value;
        }
        if (this.bottomTop) {
            this.bottomTop.isVisible = value;
        }
    }

    // 3 Mesh for 3 materials and different UVscale
    addFaces() {
        super.addFaces();
    }

    /**
     * Because we have flickering when a line or polygon are at the same position
     * All inside's board have just necessary lines. Don't represent all cube edge
     *
     */
    addCustomWireframe() {
        const lines = [];
        if (this.orientation === ORIENTATION_VERTICAL) {
            lines.push(
                // Represent left face
                [
                    new Vector3(0, 0, 0),
                    new Vector3(0, 1, 0),
                    new Vector3(0, 1, 1),
                    new Vector3(0, 0, 1),
                    new Vector3(0, 0, 0),
                ],
                // Represent right face
                [
                    new Vector3(1, 0, 0),
                    new Vector3(1, 1, 0),
                    new Vector3(1, 1, 1),
                    new Vector3(1, 0, 1),
                    new Vector3(1, 0, 0),
                ],
            );
            // If this board is a part of a furniture's block
            // Add the last lines
            if (this.hasAllFacesVisible) {
                lines.push(
                    [
                        new Vector3(0, 0, 0),
                        new Vector3(1, 0, 0),
                    ],
                    [
                        new Vector3(0, 0, 1),
                        new Vector3(1, 0, 1),
                    ],
                    [
                        new Vector3(0, 1, 1),
                        new Vector3(1, 1, 1),
                    ],
                    [
                        new Vector3(0, 1, 0),
                        new Vector3(1, 1, 0),
                    ],
                );
            }
        } else if (this.orientation === ORIENTATION_HORIZONTAL) {
            lines.push(
                // Represent bottom face
                [
                    new Vector3(0, 0, 0),
                    new Vector3(1, 0, 0),
                    new Vector3(1, 0, 1),
                    new Vector3(0, 0, 1),
                    new Vector3(0, 0, 0),
                ],
                // Represent top face
                [
                    new Vector3(0, 1, 0),
                    new Vector3(1, 1, 0),
                    new Vector3(1, 1, 1),
                    new Vector3(0, 1, 1),
                    new Vector3(0, 1, 0),
                ],
            );
        }

        this.linesSystem = MeshBuilder.CreateLineSystem("lines", {
            lines: lines,
        });

        this.linesSystem.isVisible = self.config.boardLinesVisible;
        this.linesSystem.isPickable = false;
        this.linesSystem.color = self.config.boardLinesColor;
    }

    /**
     * Lines scaling and position are independant for having a bigger size than the mesh.
     * Without flickering
     * @param {Number} enlargeValue in mm
     */
    updateLinesSystem(enlargeValue = 1) {
        this.linesSystem.scaling = this.scaling.clone();
        this.linesSystem.position = this.position.clone();
        if (this.orientation === ORIENTATION_VERTICAL) {
            this.linesSystem.scaling.x += enlargeValue;
            this.linesSystem.position.x -= enlargeValue * 0.5;
        } else if (this.orientation === ORIENTATION_HORIZONTAL) {
            this.linesSystem.scaling.x -= enlargeValue;
            this.linesSystem.position.x += enlargeValue * 0.5;
        }
    }

}
export default BoardMesh;
