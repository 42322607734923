<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="31.651" height="35.628" viewBox="0 0 31.651 35.628">
        <g id="icon-door-ext-right" transform="translate(-187.543 -451.798)">
            <g id="Groupe_427" data-name="Groupe 427">
                <path id="Tracé_197" data-name="Tracé 197" d="M187.543,481.766l1.267.318.017-28.113-1.284-.323Z" fill="#fff" />
                <path id="Tracé_198" data-name="Tracé 198" d="M197.193,484.185h13.784v-1.2H192.4Z" fill="#fff" />
                <g id="Groupe_426" data-name="Groupe 426">
                    <path id="Tracé_199" data-name="Tracé 199" d="M215.49,481.281a.8.8,0,0,0,.8-.8V455.5a.8.8,0,0,0-.8-.8H199.954l11.51,2.891a2,2,0,0,1,1.513,1.939v21.747Z" fill="#fff" />
                    <path id="Tracé_200" data-name="Tracé 200" d="M219.134,453.332a2,2,0,0,0-1.94-1.534H188.806a2.088,2.088,0,0,0-.3.031l2.122.532,4.546,1.142H215.49a2,2,0,0,1,2,2v24.978a2,2,0,0,1-2,2h-2.513v1.7h4.217a2.055,2.055,0,0,0,.4-.04,2,2,0,0,0,1.516-1.426,1.966,1.966,0,0,0,.081-.534V453.8A1.99,1.99,0,0,0,219.134,453.332Z" fill="#fff" />
                </g>
            </g>
            <path id="Tracé_201" data-name="Tracé 201" d="M210.007,459.291l-21.494-5.4a.744.744,0,0,0-.97.756v26.119a1.352,1.352,0,0,0,.97,1.243l21.494,5.387a.744.744,0,0,0,.97-.756V460.534A1.352,1.352,0,0,0,210.007,459.291Zm-2.634,15.567a.75.75,0,0,1-1.5,0v-5.114a.75.75,0,0,1,1.5,0Z" fill="#fff" />
        </g>
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "door-right-outside-icon",
});
</script>
