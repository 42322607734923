import {
    Texture,
} from "@babylonjs/core/Materials/Textures/texture";
import BoardController from "./board-controller";

import self from "../../index";
import ConstraintsManager from "../constraints-manager";
import { clamp } from "../../../../helpers/utils";
import { BOARD_THICKNESS, BOTTOM, LEFT, RESIZE_FROM_BOTTOM, RESIZE_FROM_LEFT, RESIZE_FROM_RIGHT, RESIZE_FROM_TOP, RIGHT, TOP } from "../constants";
import MeasureController from "../measure/measure-controller";
import TransformManager from "../transformManager";
import MaterialManager from "../materials/material-manager";

const {
    modules,
    events,
} = self.app;

const BoardHelper = {
    boardIsBelowFrame(boardEntity, frameEntity) {
        return boardEntity.getPosition().y < frameEntity.getPosition().y;
    },

    boardIsAboveFrame(boardEntity, frameEntity) {
        return boardEntity.getPosition().y > frameEntity.getPosition().y;
    },

    boardIsLeftOfFrame(boardEntity, frameEntity) {
        return boardEntity.getPosition().x + BOARD_THICKNESS === frameEntity.getPosition().x;
    },

    boardIsRightOfFrame(boardEntity, frameEntity) {
        return boardEntity.getPosition().x === frameEntity.getPosition().x + frameEntity.getScaling().x;
    },

    getDebugTextureUV() {
        if (!BoardController.debugTextureUV) {
            BoardController.debugTextureUV = new Texture("./assets/textures/uv-checker.png");
        }
        return BoardController.debugTextureUV;
    },

    deselectCurrentBoardEntity() {
        if (BoardController.currentBoardEntity) {
            const {
                mesh,
            } = BoardController.currentBoardEntity;
            mesh.material.alpha = 0;
            mesh.setFacesVisible(true);
            events.emit("board:deselected");
        }
    },

    deleteCurrentBoard() {
        const boardEntity = BoardController.currentBoardEntity;
        if (boardEntity) {
            modules.dataStore.removeEntity(boardEntity);
            BoardController.currentBoardEntity = null;
        }
    },

    deleteBoard(boardEntityId) {
        const boardEntity = modules.dataStore.getEntity(boardEntityId);
        if (boardEntity === BoardController.currentBoardEntity) {
            BoardController.currentBoardEntity = null;
        }
        modules.dataStore.removeEntity(boardEntity);
    },

    setDistanceFrom(distance, from, fromFrameEntity) {
        const { currentBoardEntity } = BoardController;
        if (!currentBoardEntity) return;

        if (currentBoardEntity.isHorizontal()) {
            BoardHelper.moveY(distance, from, fromFrameEntity);
        } else {
            BoardHelper.moveX(distance, from, fromFrameEntity);
        }
    },

    getRelativeDistanceLimitForInput(boardEntity, from, fromFrameEntity) {
        const positionLimits = ConstraintsManager.getBoardDisplacementLimits(boardEntity);

        const convertGlobalLimitToFrameLocal = {
            min: 0,
            max: 0,
        };

        switch (from) {
            case LEFT:
                convertGlobalLimitToFrameLocal.min = positionLimits.min - fromFrameEntity.position.x;
                convertGlobalLimitToFrameLocal.max = positionLimits.max - fromFrameEntity.position.x;
                break;
            case RIGHT:
                convertGlobalLimitToFrameLocal.min = fromFrameEntity.position.x + fromFrameEntity.scaling.x - positionLimits.max - BOARD_THICKNESS;
                convertGlobalLimitToFrameLocal.max = fromFrameEntity.position.x + fromFrameEntity.scaling.x - positionLimits.min - BOARD_THICKNESS;
                break;
            case TOP:
                convertGlobalLimitToFrameLocal.min = fromFrameEntity.position.y + fromFrameEntity.scaling.y - positionLimits.max - BOARD_THICKNESS;
                convertGlobalLimitToFrameLocal.max = fromFrameEntity.position.y + fromFrameEntity.scaling.y - positionLimits.min - BOARD_THICKNESS;
                break;
            case BOTTOM:
                convertGlobalLimitToFrameLocal.min = positionLimits.min - fromFrameEntity.position.y;
                convertGlobalLimitToFrameLocal.max = positionLimits.max - fromFrameEntity.position.y;
                break;
            default:
                break;
        }

        return convertGlobalLimitToFrameLocal;
    },

    moveX(distance, from, fromFrameEntity) {
        const { currentBoardEntity } = BoardController;
        if (!currentBoardEntity) return;

        const frameEntityParent = modules.dataStore.getEntity(currentBoardEntity.getIdParent());
        const currentSide1Entity = modules.dataStore.getEntity(frameEntityParent.getIdFrameLeftOrBottom());
        const currentSide2Entity = modules.dataStore.getEntity(frameEntityParent.getIdFrameRightOrTop());

        const nextScalingSide1 = currentSide1Entity.getScaling().clone();
        const nextScalingSide2 = currentSide2Entity.getScaling().clone();

        const positionLimits = ConstraintsManager.getBoardDisplacementLimits(currentBoardEntity);

        let positionX;

        switch (from) {
            case LEFT:
                positionX = fromFrameEntity.position.x + distance;
                break;
            case RIGHT:
                positionX = fromFrameEntity.position.x + fromFrameEntity.scaling.x - distance - BOARD_THICKNESS;
                break;
            default:
                break;
        }

        const clampPositionX = clamp(positionX, positionLimits.min, positionLimits.max);
        if (positionX === clampPositionX) {
            if (positionX < currentBoardEntity.position.x) {
                nextScalingSide1.x -= currentBoardEntity.position.x - positionX;
                nextScalingSide2.x += currentBoardEntity.position.x - positionX;
            } else {
                nextScalingSide1.x += positionX - currentBoardEntity.position.x;
                nextScalingSide2.x -= positionX - currentBoardEntity.position.x;
            }

            const scalingDiffSide1 = nextScalingSide1.subtract(currentSide1Entity.getScaling());
            const scalingDiffSide2 = nextScalingSide2.subtract(currentSide2Entity.getScaling());

            TransformManager.resizeFrame(currentSide1Entity.id, scalingDiffSide1, RESIZE_FROM_RIGHT);
            TransformManager.resizeFrame(currentSide2Entity.id, scalingDiffSide2, RESIZE_FROM_LEFT);
            TransformManager.resizeDoors();
            TransformManager.resizeOverlaydrawer();
            TransformManager.resizeInsetdrawer();

            currentBoardEntity.setPositionX(clampPositionX);
            MeasureController.updateMeasures(currentBoardEntity);
        } else {
            const convertGlobalLimitToFrameLocal = BoardHelper.getRelativeDistanceLimitForInput(currentBoardEntity, from, fromFrameEntity);
            events.emit("board:edit:distance:rejected");
            events.emit("notification:message", {
                title: "position incorrecte",
                text: `Doit être comprit entre ${convertGlobalLimitToFrameLocal.min} et ${convertGlobalLimitToFrameLocal.max} mm`,
                status: "error",
            });
        }

    },

    moveY(distance, from, fromFrameEntity) {
        const { currentBoardEntity } = BoardController;
        if (!currentBoardEntity) return;

        const frameEntityParent = modules.dataStore.getEntity(currentBoardEntity.getIdParent());
        const currentSide1Entity = modules.dataStore.getEntity(frameEntityParent.getIdFrameLeftOrBottom());
        const currentSide2Entity = modules.dataStore.getEntity(frameEntityParent.getIdFrameRightOrTop());

        const nextScalingSide1 = currentSide1Entity.getScaling().clone();
        const nextScalingSide2 = currentSide2Entity.getScaling().clone();

        const positionLimits = ConstraintsManager.getBoardDisplacementLimits(currentBoardEntity);

        let positionY;

        switch (from) {
            case TOP:
                positionY = fromFrameEntity.position.y + fromFrameEntity.scaling.y - distance - BOARD_THICKNESS;
                break;
            case BOTTOM:
                positionY = fromFrameEntity.position.y + distance;
                break;
            default:
                break;
        }

        const clampPositionY = clamp(positionY, positionLimits.min, positionLimits.max);

        if (positionY === clampPositionY) {

            if (positionY < currentBoardEntity.position.y) {
                nextScalingSide1.y -= currentBoardEntity.position.y - positionY;
                nextScalingSide2.y += currentBoardEntity.position.y - positionY;
            } else {
                nextScalingSide1.y += positionY - currentBoardEntity.position.y;
                nextScalingSide2.y -= positionY - currentBoardEntity.position.y;
            }

            const scalingDiffSide1 = nextScalingSide1.subtract(currentSide1Entity.getScaling());
            const scalingDiffSide2 = nextScalingSide2.subtract(currentSide2Entity.getScaling());

            TransformManager.resizeFrame(currentSide1Entity.id, scalingDiffSide1, RESIZE_FROM_TOP);
            TransformManager.resizeFrame(currentSide2Entity.id, scalingDiffSide2, RESIZE_FROM_BOTTOM);
            TransformManager.resizeDoors();
            TransformManager.resizeOverlaydrawer();
            TransformManager.resizeInsetdrawer();

            currentBoardEntity.setPositionY(clampPositionY);
            MeasureController.updateMeasures(currentBoardEntity);
        } else {
            const convertGlobalLimitToFrameLocal = BoardHelper.getRelativeDistanceLimitForInput(currentBoardEntity, from, fromFrameEntity);
            events.emit("board:edit:distance:rejected");
            events.emit("notification:message", {
                title: "position incorrecte",
                text: `Doit être comprit entre ${convertGlobalLimitToFrameLocal.min} et ${convertGlobalLimitToFrameLocal.max} mm`,
                status: "error",
            });
        }

    },

    applyFinishMaterial(boardEntity) {
        const { mesh } = boardEntity;
        const faces = [mesh.faceLeft, mesh.faceRight, mesh.faceTop, mesh.faceBottom];

        faces.forEach((face) => {
            if (face) {
                if (mesh.scaling.x > mesh.scaling.z) {
                    face.material = MaterialManager.mainFinishMaterial90;
                } else {
                    face.material = MaterialManager.mainFinishMaterial;
                }
            }
        });
        if (boardEntity.isHorizontal()) {
            mesh.faceFront.material = MaterialManager.borderFinishMaterial90;
            mesh.faceBack.material = MaterialManager.mainFinishMaterial90;
            mesh.faceTop.material = MaterialManager.mainFinishMaterial90;
            mesh.faceBottom.material = MaterialManager.mainFinishMaterial90;
        } else {
            mesh.faceFront.material = MaterialManager.borderFinishMaterial;
            mesh.faceBack.material = MaterialManager.mainFinishMaterial;
        }
        mesh.updateUVScaling();
    },

    applyConstructionMaterial(boardEntity) {
        const { mesh } = boardEntity;
        const faces = [mesh.faceFront, mesh.faceBack, mesh.faceLeft, mesh.faceRight, mesh.faceTop, mesh.faceBottom];
        faces.forEach((face) => {
            if (face) {
                face.material = MaterialManager.mainConstructionMaterial;
            }
        });
    },
};


export default BoardHelper;
