import self from "../index";

const {
    events,
} = self.app;

const data = {
    targetType: "",
    dropEvents: [],
    endEvents: [],
};

function onDragStart(payload) {
    data.targetType = payload.targetType;
    data.dropEvents = payload.dropEvents;
    data.endEvents = payload.endEvents;

    payload.startEvents.forEach((item) => {
        events.emit(item.eventName, item.params);
    });
}

function onDragEnd() {
    data.endEvents.forEach((item) => {
        events.emit(item.eventName, item.params);
    });
}

function onDragOver() {}

function onDragLeave() {}

function onDrop(event) {
    data.dropEvents.forEach((item) => {
        events.emit(item.eventName, {
            ...item.params,
            event,
        });
    });
}

const DragNDropManager = {

    addEventListeners() {
        events.on("@vuejs.drag:start", onDragStart);
        events.on("@vuejs.drag:end", onDragEnd);
        events.on("@vuejs.drag:over", onDragOver);
        events.on("@vuejs.drag:drop", onDrop);
        events.on("@vuejs.drag:leave", onDragLeave);
    },

    get targetType() {
        return data.targetType;
    },

};



export default DragNDropManager;
