<template>
    <button
        :class="[
            'group bg-white border-b-theme-gray-200 border-b flex flex-col h-min shadow-xl shadow-gray-300',
            'transition-[width] duration-300 ease-out',
            displayTitle && 'w-32',
            !displayTitle && 'w-12'
        ]"
        :title="title"
        @mouseover="isHovered = true"
        @mouseleave="isHovered = false"
    >
        <div
            :class="[
                'flex flex-col items-center w-full p-2 transition-[max-height] duration-300 ease-out',
                displayTitle && 'justify-end max-h-32',
                !displayTitle && 'justify-center max-h-12'
            ]"
        >
            <slot :is-hovered="isHovered" :title="title" />
            <div
                :class="[
                    'overflow-x-hidden w-full transition-transform duration-300 ease-out',
                    displayTitle && 'scale-100',
                    !displayTitle && 'scale-0'
                ]"
            >
                <p
                    :class="[
                        'text-xs mt-1 w-28',
                    ]"
                >
                    {{ title }}
                </p>
            </div>
        </div>
        <div
            :class="[
                'h-1 w-full opacity-0 transition-opacity duration-300',
                tabName === activeTab && 'opacity-100 bg-gradient-to-r from-theme-pink-400 to-theme-orange-400',
                'group-hover:bg-gradient-to-r group-hover:from-theme-pink-400 group-hover:to-theme-orange-400 group-hover:opacity-100'
            ]"
        />
    </button>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";
import { TAB_ACCESSORIES, TAB_BACK, TAB_FRONT, TAB_FINISHING_TOUCHES, TAB_STRUCTURE_EXT, TAB_STRUCTURE_INT } from "../../services/constants";
import useGlobalStore from "../../stores/global";

const tabs = [
    TAB_STRUCTURE_EXT,
    TAB_STRUCTURE_INT,
    TAB_FRONT,
    TAB_BACK,
    TAB_ACCESSORIES,
    TAB_FINISHING_TOUCHES,
];

export default defineComponent({
    name: "menu-button",
    props: {
        title: {
            type: String,
            required: true,
        },
        tabName: {
            type: String,
            required: true,
            validator(tab) {
                return tabs.includes(tab);
            },
        },
        displayTitle: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            active: true,
            isHovered: false,
        };
    },
    computed: {
        store() {
            return useGlobalStore();
        },
        activeTab() {
            return this.store.activeTab;
        },
    },
});
</script>
