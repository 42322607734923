import {
    ActionManager,
    ExecuteCodeAction,
    Matrix,
    MeshBuilder,
    StandardMaterial,
    Vector3,
} from "@babylonjs/core";
import {
    clamp,
} from "../../../../helpers/utils";
import self from "../../index";

import FurnituresListController from "../furnitures-list-controller";
import PulloutshelfController from "./pulloutshelf-controller";
import PulloutshelfHelper from "./pulloutshelf-helper";
import furnitureUiButtons from "../furniture/furniture-ui-buttons";

const {
    modules,
    events,
} = self.app;

const data = {
    initialized: false,
    moving: false,
    planeFront: null,
    planeBack: null,
    scene: null,
    canvas: null,
    startPosition: null,
    endPosition: null,
    delta: null,
    currentPulloutshelfEntity: null,
    clickDecale: null,
    positionLimits: {
        min: 0,
        max: 0,
    },
};

const PulloutshelfDisplacement = {
    initialize() {
        if (data.initialized) return;

        data.scene = modules.obsidianBabylonEngine.scene;
        data.canvas = modules.obsidianBabylonEngine.canvas;

        data.planeFront = MeshBuilder.CreatePlane("dragZoneFront", {
            size: 20000,
        }, data.scene);
        data.planeFront.position.z = -1;
        data.planeFront.material = new StandardMaterial("dragZoneMaterial", data.scene);
        data.planeFront.material.alpha = 0;
        data.planeFront.isVisible = false;
        data.planeBack = data.planeFront.clone("dragZoneBack");
        data.planeBack.rotation.y = Math.PI;

        const OnPointerOutTrigger = new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, PulloutshelfDisplacement.stop);
        const OnPickUpTrigger = new ExecuteCodeAction(ActionManager.OnPickUpTrigger, PulloutshelfDisplacement.stop);

        data.planeFront.actionManager = new ActionManager();
        data.planeFront.actionManager.registerAction(OnPickUpTrigger);
        data.planeFront.actionManager.registerAction(OnPointerOutTrigger);
        data.planeBack.actionManager = new ActionManager();
        data.planeBack.actionManager.registerAction(OnPickUpTrigger);
        data.planeBack.actionManager.registerAction(OnPointerOutTrigger);

        data.initialized = true;
    },

    get plane() {
        return data.plane;
    },

    start() {
        if (!data.initialized) PulloutshelfDisplacement.initialize();
        events.emit("camera:lock");
        const {
            currentFurnitureEntity,
        } = FurnituresListController.getInstance();
        data.moving = true;

        data.currentPulloutshelfEntity = PulloutshelfController.currentPulloutshelfEntity;
        data.planeBack.position.z = currentFurnitureEntity.getScaling().z + 1;

        data.planeFront.isVisible = true;
        data.planeBack.isVisible = true;

        const ray = data.scene.createPickingRay(data.scene.pointerX, data.scene.pointerY, Matrix.Identity(), data.scene.activeCamera);
        const hit = data.scene.pickWithRay(ray);
        data.clickDecale = hit.pickedPoint.y - data.currentPulloutshelfEntity.position.y;
        data.startPosition = new Vector3(Math.round(hit.pickedPoint.x), Math.round(hit.pickedPoint.y - data.clickDecale), 0);

        data.positionLimits = PulloutshelfHelper.getPulloutshelfDisplacementLimits(data.currentPulloutshelfEntity);

        data.canvas.addEventListener("pointermove", PulloutshelfDisplacement.onMove);
    },

    onMove(event) {
        const ray = data.scene.createPickingRay(event.layerX, event.clientY, Matrix.Identity(), data.scene.activeCamera);
        const hit = data.scene.pickWithRay(ray);
        data.endPosition = new Vector3(Math.round(hit.pickedPoint.x), Math.round(hit.pickedPoint.y - data.clickDecale), 0);
        data.delta = data.endPosition.subtract(data.startPosition);
        PulloutshelfDisplacement.moveY();
    },

    moveY() {
        data.endPosition.y = clamp(data.endPosition.y, data.positionLimits.min, data.positionLimits.max);
        data.currentPulloutshelfEntity.setPositionY(data.endPosition.y);
        PulloutshelfHelper.showMeasures(data.currentPulloutshelfEntity);
        furnitureUiButtons.updateButtons();
    },

    stop() {
        if (!data.moving) return;
        data.moving = false;
        data.planeFront.isVisible = false;
        data.planeBack.isVisible = false;
        data.currentPulloutshelfEntity.updateBbox2();
        events.emit("furniture:edited");
        data.canvas.removeEventListener("pointermove", PulloutshelfDisplacement.onMove);
        events.emit("camera:unlock");
    },

};


export default PulloutshelfDisplacement;
