<template>
    <button :title="t('Éditer le nombre de tiroirs, et voir les dimensions utiles')" class="w-6 h-6 bg-white border border-black rounded-full flex justify-center items-center pointer-events-auto" @click="$emit('click')">
        <EditIcon class="w-5 h-5 text-black" />
    </button>
</template>
<script>
import self from "../../..";
import EditIcon from "../icons/EditIcon.vue";

const { modules } = self.app;

export default {
    components: { EditIcon },
    emits: ["click"],
    methods: {
        t(...args) {
            return modules.stonejs.gettext(...args);
        },
    },
};
</script>
