import { createPinia } from "pinia";
import { createApp, toRaw } from "vue";
import App from "./components/app.vue";
import initCatalogs from "./services/initCatalog";
import initConfiguration from "./services/initConfiguration";
import useGlobalStore from "./stores/global";
import useFurnitureStore from "./stores/furniture";
import generatePdf from "./services/generatePdf";

/**
 * Instanciate the main Vue, on the app element
 * @type {Boolean}
 */
export default class VuejsController {

    constructor() {

        // Data binding and vue creation
        const pinia = createPinia();
        this.app = createApp(App);
        this.app.use(pinia);
        this.store = useGlobalStore();
        this.furnitureStore = useFurnitureStore();
        initCatalogs();
        initConfiguration();
        this.app.mount("#app");
    }

    getWeight() {
        return this.store.weight;
    }

    getPrice() {
        return this.store.price;
    }

    getComposition() {
        return toRaw(this.store.furnitureComposition);
    }

    // eslint-disable-next-line class-methods-use-this
    async exportAsPdf() {
        return generatePdf();
    }

    getVolume() {
        return this.furnitureStore.volume;
    }

}
