// VueJs Module : contains the main vue component
// and ui parts not binded to a specific module (ex : main-menu)
export default {
    name: "vuejs",
    requires: [
        "cameras-manager",
        "data-exporter",
        "history",
        "iframe-api",
        "obsidian-babylon-engine",
        "project-manager",
        "stonejs",
        "furnitures",
        "tool-manager",
        "wanadev-loader",
    ],
    load() {
        const VuejsController = require("./src/vuejs-controller").default;
        return new VuejsController();
    },
    unload() {},
};
