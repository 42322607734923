import DoorEntity from "./door-entity";
import self from "../../index";

const {
    modules,
} = self.app;

const DoorEntityBuilder = {
    build(frameEntity, type, mode) {
        const furnitureEntity = modules.dataStore.getEntity(frameEntity.getFurnitureId());
        return new DoorEntity({
            furnitureId: furnitureEntity.id,
            idParent: frameEntity.id,
            type: type,
            mode: mode,
            position: frameEntity.position.clone(),
            scaling: frameEntity.scaling.clone(),
        });
    },
};
export default DoorEntityBuilder;
