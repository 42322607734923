import {
    Color3,
} from "@babylonjs/core/Maths/math.color";
import {
    StandardMaterial,
} from "@babylonjs/core/Materials/standardMaterial";

let material;

const FootMaterialsBuilder = {
    getDefaultMaterial() {
        if (!material) {
            material = new StandardMaterial("footMaterial");
            material.emissiveColor = Color3.FromHexString("#8f8f8f");
        }
        return material;

    },
};
export default FootMaterialsBuilder;
