import self from "../index";

const {
    events,
} = self.app;

/**
 * Tweakpane Controller module
 */
class TweakpaneController {

    constructor() {
        this.waitForLoading();
    }

    waitForLoading() {
        return new Promise((resolve) => {
            if (this.pane) {
                resolve(this.pane);
            } else {

                events.on("@vuejs.tweakpane:created", (pane) => {
                    this.pane = pane;
                    resolve(pane);
                });
            }
        });
    }

}

export default TweakpaneController;
