<template>
    <button
        :class="[
            'shadow-gray-300 shadow-lg border border-gray-200 rounded-lg px-2 py-2 mr-4 font-bold transition-colors duration-300',
            'hover:bg-black hover:text-white',
            'disabled:opacity-60 disabled:cursor-not-allowed',
            selected ? 'bg-black text-white' : 'bg-white'
        ]"
        :disabled="disabled"
        @click="$emit('click')"
    >
        <slot />
    </button>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["click"],
};
</script>
