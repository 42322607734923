export default {

    name: "tool-manager",
    requires: [],

    load() {
        const ToolManagerController = require("./src/tool-manager-controller.js").default;
        const ToolManagerModel = require("./src/tool-manager-model.js").default;
        const toolManagerModel = new ToolManagerModel();
        const ATool = require("./src/atool.js").default;
        return {
            controller: new ToolManagerController(toolManagerModel),
            atool: ATool,
        };
    },

    unload() {},

};
