import self from "../../index";
import DoorHelper from "./door-helper";

const {
    events,
} = self.app;

const data = {
    listenersAdded: false,
};

function onDeleteDoor(params) {
    DoorHelper.deleteDoorFromFrameEntityId(params.id);
}

const DoorEventsManager = {
    addEventListeners() {
        if (data.listenersAdded) return;
        events.on("@vuejs.doors:showAll", DoorHelper.showAllDoors);
        events.on("doors:showAll", DoorHelper.showAllDoors);
        events.on("@vuejs.doors:hideAll", DoorHelper.hideAllDoors);
        events.on("doors:hideAll", DoorHelper.hideAllDoors);
        events.on("@vuejs.door:delete", onDeleteDoor);
        data.listenersAdded = true;
    },
};

export default DoorEventsManager;
