import CameraHelper from "../../../cameras-manager/src/camera-helper";
import self from "../../index";
import DoorController from "./door-controller";

const {
    modules: {
        dataStore,
    },
} = self.app;

function getDirection(doorPositionCenter, extendPositionCenter) {
    const directionVector = extendPositionCenter.subtract(doorPositionCenter).normalize();
    const angle = Math.atan2(directionVector.y, directionVector.x);
    const angleDeg = (angle * 180) / Math.PI;
    if (angleDeg >= -45 && angleDeg <= 45) {
        return "right";
    }
    if (angleDeg >= 45 && angleDeg <= 135) {
        return "top";
    }
    if (angleDeg >= 135 || angleDeg <= -135) {
        return "left";
    }
    if (angleDeg >= -135 && angleDeg <= -45) {
        return "bottom";
    }
    return null;
}

const DoorUiButtons = {
    getAllButtons() {
        const buttons = [];
        let currentDoorCenter;
        dataStore.listEntities("door").forEach((doorEntity) => {
            const furnitureEntity = dataStore.getEntity(doorEntity.furnitureId);
            const center = doorEntity.getCenterAtFront().add(furnitureEntity.position);
            const center2D = CameraHelper.convert3DPointTo2D(center);
            if (doorEntity === DoorController.currentDoorEntity) {
                currentDoorCenter = center;
            }
            buttons.push({
                id: doorEntity.idParent,
                x: center2D.x,
                y: center2D.y,
                buttons: [
                    {
                        deletable: true,
                        event: "door:delete",
                    },
                ],
            });
        });
        if (DoorController.framesForExtendDoor.length > 0) {
            DoorController.framesForExtendDoor.forEach((frameEntityId) => {
                const frameEntity = dataStore.getEntity(frameEntityId);
                if (!frameEntity) return;
                const furnitureEntity = dataStore.getEntity(frameEntity.furnitureId);
                if (!furnitureEntity) return;
                const center = frameEntity.getCenterAtFront().add(furnitureEntity.position);
                const center2D = CameraHelper.convert3DPointTo2D(center);
                const direction = getDirection(currentDoorCenter, center);
                buttons.push({
                    id: frameEntity.id,
                    x: center2D.x,
                    y: center2D.y,
                    buttons: [
                        {
                            extendable: true,
                            direction,
                        },
                    ],
                });
            });
        }
        return buttons;
    },
};

export default DoorUiButtons;
