/**
 * ToolManager Entity module
 */
class ToolManagerModel {

    constructor() {
        this.toolList = [];
    }

    /**
     * Add tool in the tool list
     * @param  {Atool} tool tool object which should inherit from abstract tool class
     */
    registerTool(tool) {
        this.toolList.push(tool);
    }

    /**
     * Remove (if found) tool from the tool list
     * @param  {Atool} tool tool object which should inherit from abstract tool class
     * @return {Boolean} return true if succeed else if it could not be unregister (if not found for eg)
     */
    unregisterTool(tool) {
        if (!tool && !tool.tool_id) {
            return false;
        }
        for (let i = this.toolList.length - 1; i >= 0; i--) {
            if (this.toolList[i].tool_id === tool.tool_id) {
                this.toolList.splice(i, 1);
                return true;
            }
        }
        return false;
    }

    /**
     * Retrieve all active tools
     * @return {Atool[]} array of active tools
     */
    getActiveTools() {
        const activeTools = [];
        for (let i = 0; i < this.toolList.length; i++) {
            if (this.toolList[i].tool_isActive) {
                activeTools.push(this.toolList[i]);
            }
        }
        return activeTools;
    }

    /**
     * Return list of all tools which are active, it return only name of tools
     * Use case is when you want to inactivate all currents tool for a particular action and then restore old state
     * @return {Array} List of tools name which are active
     */
    getActiveToolsName() {
        const activeToolsName = [];
        for (let i = 0; i < this.toolList.length; i++) {
            if (this.toolList[i].tool_isActive === true) {
                activeToolsName.push(this.toolList[i].tool_name);
            }
        }
        return activeToolsName;
    }

    /**
     * Informs if a specific tool is active or inactive
     * @param {String} toolName
     * @return {boolean} true if the tool is active, false if inactive
     */
    isToolActive(toolName) {
        for (let i = 0; i < this.toolList.length; i++) {
            if (this.toolList[i].tool_name === toolName && this.toolList[i].tool_isActive) {
                return true;
            }
        }
        return false;
    }

    /**
     * Retrieve tool object from tool name
     * @param  {String} toolName Name of the tool you want to retrieve tool object
     * @return {ATool}        found tool, if not found it will return undefined
     */
    getToolFromName(toolName) {
        return this.toolList.find((tool) => (tool.tool_name === toolName));
    }

}

export default ToolManagerModel;
