import self from "../../index";
import BackboardController from "./backboard-controller";

const {
    events,
} = self.app;

function onDeleteBackboard(params) {
    BackboardController.deleteBackboard(params.id);
}
const BackboardEventsManager = {
    addEventListeners() {
        events.on("@vuejs.backboard:delete", onDeleteBackboard);
    },
};

export default BackboardEventsManager;
