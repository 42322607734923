<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="31.986" viewBox="0 0 32 31.986">
        <g id="icon-drawer-int" transform="translate(0 0.5)">
            <path id="Soustraction_27"
                  data-name="Soustraction 27"
                  d="M-819.03,1621.639H-845a1,1,0,0,1-1-1v-9.5a1,1,0,0,1,1-1h25.971a1,1,0,0,1,1,1v9.5A1,1,0,0,1-819.03,1621.639Zm-15.3-8.672a.665.665,0,0,0-.663.664.664.664,0,0,0,.663.663h4.628a.664.664,0,0,0,.663-.663.665.665,0,0,0-.663-.664Z"
                  transform="translate(848.009 -1592.153)"
                  fill="#fff"
            />
            <path id="Tracé_180"
                  data-name="Tracé 180"
                  d="M1430.15-1698.635s2.476-1.877,2.5-1.872a3.874,3.874,0,0,1,2.006-.34h18.056c1.46,0,1.684.121,2.006.34l2.531,1.872Z"
                  transform="translate(-1427.632 1700.847)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="1"
            />
            <g id="Rectangle_642"
               data-name="Rectangle 642"
               transform="translate(0 3.486)"
               fill="none"
               stroke="#fff"
               stroke-width="1"
            >
                <rect width="32" height="28" rx="2" stroke="none" />
                <rect x="0.5"
                      y="0.5"
                      width="31"
                      height="27"
                      rx="1.5"
                      fill="none"
                />
            </g>
            <path id="Soustraction_76"
                  data-name="Soustraction 76"
                  d="M-819.03,1621.639H-845a1,1,0,0,1-1-1v-9.5a1,1,0,0,1,1-1h25.971a1,1,0,0,1,1,1v9.5A1,1,0,0,1-819.03,1621.639Zm-15.3-8.672a.665.665,0,0,0-.663.664.664.664,0,0,0,.663.663h4.628a.664.664,0,0,0,.663-.663.665.665,0,0,0-.663-.664Z"
                  transform="translate(848.009 -1604.653)"
                  fill="#fff"
            />
        </g>
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "drawers-inside-icon",
});
</script>
