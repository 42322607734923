<template>
    <svg id="icon-door-int-center"
         xmlns="http://www.w3.org/2000/svg"
         width="32"
         height="33.979"
         viewBox="0 0 32 33.979"
    >
        <path id="Soustraction_70"
              data-name="Soustraction 70"
              d="M-1251.346-5169l-4.654,0a3,3,0,0,1-3-3v-26a3,3,0,0,1,3-3h26a3,3,0,0,1,3,3v26a3,3,0,0,1-3,3h-4.706l3.882-2.009A2.012,2.012,0,0,0-1229-5173v-24a2,2,0,0,0-2-2h-24a2,2,0,0,0-2,2v24a2,2,0,0,0,1.758,1.984l3.9,2.016Zm8.9,0h-1.153l0-2h1.156l0,2Zm0-3.5h-1.158l-.015-20.312a.915.915,0,0,0-.483-.924l-7.571-3.763h17.3l-7.572,3.763a.916.916,0,0,0-.482.924l-.015,20.312Z"
              transform="translate(1258.999 5201)"
              fill="#fff"
        />
        <path id="Soustraction_68"
              data-name="Soustraction 68"
              d="M0,29.978H0V4.944C.022,4.943.467,4.8,9.643.026A.211.211,0,0,1,9.741,0c.188,0,.319.289.319.7V24.041a1.386,1.386,0,0,1-.417,1.111C.665,29.843.043,29.978,0,29.978Zm8.073-19.1a.624.624,0,0,0-.623.623v3.629a.623.623,0,1,0,1.246,0V11.5A.624.624,0,0,0,8.075,10.882Z"
              transform="translate(28.529 33.479) rotate(180)"
              fill="#fff"
              stroke="rgba(0,0,0,0)"
              stroke-width="1"
        />
        <path id="Soustraction_69"
              data-name="Soustraction 69"
              d="M9.74,29.979a.211.211,0,0,1-.1-.026C.465,25.173.021,25.035,0,25.035V0H0C.045,0,.684.146,9.643,4.827a1.388,1.388,0,0,1,.417,1.112V29.277C10.059,29.684,9.925,29.979,9.74,29.979ZM8.075,14.222a.624.624,0,0,0-.623.623v3.629a.623.623,0,1,0,1.246,0V14.845A.624.624,0,0,0,8.075,14.222Z"
              transform="translate(3.47 3.5)"
              fill="#fff"
              stroke="rgba(0,0,0,0)"
              stroke-width="1"
        />
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "door-center-inside-icon",
});
</script>
