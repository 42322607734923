import {
    Vector2,
    Vector3,
} from "@babylonjs/core/Maths/math.vector";
import BBox2Helper, { BBox2 } from "../../../../helpers/BBox2Helper";
import self from "../../index";
import AccessorydrawerHelper from "../accessorydrawer/accessorydrawer-helper";
import {
    BOARD_THICKNESS,
    DOOR_DOUBLE,
    DOOR_SIMPLE_LEFT,
    DOOR_SIMPLE_RIGHT,
} from "../constants";
import ConstraintsManager from "../constraints-manager";
import FrameHelper from "../frame/frame-helper";
import ConstraintsHelper from "../helpers/constrainsHelper";
import MovableshelfHelper from "../movableshelf/movableshelf-helper";
import OverlayHelper from "../overlay/overlay-helper";
import PulloutshelfHelper from "../pulloutshelf/pulloutshelf-helper";
import DoorController from "./door-controller";
import furnitureUiButtons from "../furniture/furniture-ui-buttons";
import MaterialManager from "../materials/material-manager";

const {
    modules,
    events,
} = self.app;

const DoorHelper = {

    /**
     * Add all frames id behind the door to the door entity
     * @param {DoorEntity} doorEntity
     */
    addAllFramesIdBehindTheDoorToTheDoor(doorEntity) {
        const bbDoor = BBox2Helper.getBBox2FromObject3dEntity(doorEntity);
        const furnitureEntity = modules.dataStore.getEntity(doorEntity.getFurnitureId());
        const framesEntitites = modules.dataStore.listEntities("frame");
        for (let i = 0, il = framesEntitites.length; i < il; i++) {
            const frameEntity = framesEntitites[i];
            if (frameEntity.getFurnitureId() === furnitureEntity.id) {
                const bbFrame = BBox2Helper.getBBox2FromObject3dEntity(frameEntity);
                const bbIntersection = BBox2Helper.getBBox2Intersection(bbDoor, bbFrame);
                /**
                 * If the intersection is not empty, it means that the frame is behind the door
                 */
                if (bbIntersection && bbIntersection.isArea) {
                    doorEntity.addFrameEntityId(frameEntity.id);
                    if (!BBox2Helper.BBox2containBBox2(bbDoor, bbFrame)) {
                        frameEntity.setIsPartialyCoveredByADoor(true);
                    }
                }
            }
        }
    },

    canExtendDoor(frameEntity, bb, type) {
        const currentConstraints = [];
        let isAdapted = true;
        ConstraintsHelper.addDoorConstraints(currentConstraints, type);
        const constraintsReactivity = ConstraintsManager.checkConstraints(
            currentConstraints,
            frameEntity,
            null,
            new Vector2(bb.width, bb.height),
        );

        isAdapted = !constraintsReactivity.hasResistanceHard;

        return {
            isAdapted,
            constraintsReactivity,
        };
    },

    extendCurrentDoorToFrame(frameEntity) {
        if (DoorController.framesForExtendDoor.indexOf(frameEntity.id) === -1) {
            return;
        }
        const doorEntity = DoorController.currentDoorEntity;

        doorEntity.addFrameToBuildOrder(frameEntity.id);
        DoorHelper.linkFramesHierarchyWithDoor(frameEntity, doorEntity);
        DoorHelper.addAllFramesIdBehindTheDoorToTheDoor(doorEntity);

        const bbDoorFramesUnion = DoorHelper.getDoorFramesBBox2Union(doorEntity);

        doorEntity.position = new Vector3(
            bbDoorFramesUnion.p1.x,
            bbDoorFramesUnion.p1.y,
            0
        );
        doorEntity.scaling = new Vector3(
            bbDoorFramesUnion.width,
            bbDoorFramesUnion.height,
            BOARD_THICKNESS,
        );

        DoorHelper.updateHinges(doorEntity);

        if (doorEntity.isFrontModeOutside()) {
            OverlayHelper.detectOverlaysNeighbors();
        }

        DoorHelper.updateItemsCoveredByDoor(doorEntity);
        DoorHelper.updateCurrentFrameAncestor(doorEntity);
        events.emit("furniture:edited");
        FrameHelper.resetAllFramesInteraction();
        DoorHelper.showExtendableZones(doorEntity);
        furnitureUiButtons.updateButtons();
    },

    updateHinges(doorEntity) {
        const verticalPadding = doorEntity.scaling.y <= 400 ? 52 : 80;
        const horizontalPadding = 19;
        const {
            type,
            position,
            scaling,
            hingeTopLeft,
            hingeBottomLeft,
            hingeTopRight,
            hingeBottomRight,

        } = doorEntity;

        switch (type) {
            case DOOR_DOUBLE:
                hingeTopLeft.x = position.x + horizontalPadding;
                hingeTopLeft.y = position.y + scaling.y - verticalPadding;

                hingeBottomLeft.x = position.x + horizontalPadding;
                hingeBottomLeft.y = position.y + verticalPadding;

                hingeTopRight.x = position.x + scaling.x - horizontalPadding;
                hingeTopRight.y = position.y + scaling.y - verticalPadding;

                hingeBottomRight.x = position.x + scaling.x - horizontalPadding;
                hingeBottomRight.y = position.y + verticalPadding;
                break;

            case DOOR_SIMPLE_LEFT:
                hingeTopRight.x = position.x + scaling.x - horizontalPadding;
                hingeTopRight.y = position.y + scaling.y - verticalPadding;

                hingeBottomRight.x = position.x + scaling.x - horizontalPadding;
                hingeBottomRight.y = position.y + verticalPadding;
                break;

            case DOOR_SIMPLE_RIGHT:
                hingeTopLeft.x = position.x + horizontalPadding;
                hingeTopLeft.y = position.y + scaling.y - verticalPadding;

                hingeBottomLeft.x = position.x + horizontalPadding;
                hingeBottomLeft.y = position.y + verticalPadding;
                break;

            default:
        }
    },

    /**
     * get bounding box union of all door's frames
     * @param {DoorEntity} doorEntity
     * @returns BBox2 || null
     */
    getDoorFramesBBox2Union(doorEntity) {
        let bbDoorFramesUnion;
        for (let i = 0, il = doorEntity.getFrameEntitiesIds().length; i < il; i++) {
            const frameEntityID = doorEntity.getFrameEntitiesIds()[i];
            const frameEntity = modules.dataStore.getEntity(frameEntityID);
            if (!frameEntity.getIsPartialyCoveredByADoor()) {
                if (!bbDoorFramesUnion) {
                    bbDoorFramesUnion = BBox2Helper.getBBox2FromObject3dEntity(frameEntity);
                } else {
                    const bboxAdded = BBox2Helper.getBBox2FromObject3dEntity(frameEntity);
                    bbDoorFramesUnion = BBox2Helper.getBBox2Union(bbDoorFramesUnion, bboxAdded);
                }
            }
        }
        return bbDoorFramesUnion;
    },

    /**
     *
     * @param {DoorEntity} doorEntity
     * @returns
     */
    getFramesForExtendDoor(doorEntity) {
        const bbDoorFramesUnion = DoorHelper.getDoorFramesBBox2Union(doorEntity);
        const framesDescendants = FrameHelper.getDescendantsFrames(DoorController.currentFrameAncestor);
        const framesSelected = [];
        for (let i = 0, il = framesDescendants.length; i < il; i++) {
            const frameEntity = framesDescendants[i];
            const hasDescendantsDoor = FrameHelper.hasDescendantsDoor(frameEntity);
            const hasDescendantsDrawer = FrameHelper.hasDescendantsDrawer(frameEntity);
            if (!frameEntity.getIdDoor() && !hasDescendantsDoor && !frameEntity.getIdDrawer() && !hasDescendantsDrawer) {
                const bbFrame = BBox2Helper.getBBox2FromObject3dEntity(frameEntity);
                const distance = BBox2Helper.getDistanceBetweenBBox2(bbFrame, bbDoorFramesUnion);
                const isAdjacents = distance === BOARD_THICKNESS;

                const nextBBUnion = BBox2Helper.getBBox2Union(bbFrame, bbDoorFramesUnion);
                const canExtendDoor = DoorHelper.canExtendDoor(frameEntity, nextBBUnion, doorEntity.getType());
                const hasSameAncestor = DoorController.currentFrameAncestor === FrameHelper.getAncestorWithSameOrientation(frameEntity);

                if (isAdjacents && canExtendDoor.isAdapted && hasSameAncestor) {
                    framesSelected.push(frameEntity.id);
                    FrameHelper.disableChildren(frameEntity);
                }
            }
        }
        return framesSelected;
    },

    hideAllDoors() {
        const doorsEntity = modules.dataStore.listEntities("door");
        for (let i = 0; i < doorsEntity.length; i++) {
            const doorEntity = doorsEntity[i];
            doorEntity.mesh.setVisible(false);
            doorEntity.knobs.forEach((knob) => {
                knob.isVisible = false;
            });
        }
    },

    historyBack() {
        if (!DoorController.currentDoorEntity) return;
        DoorController.resetValues();
        FrameHelper.resetAllFramesInteraction();
        events.emit("tool:frame:deactivate");
    },

    historyForward() {},

    linkFramesHierarchyWithDoor(startFrameEntity, doorEntity) {
        const traverse = (frameEntityId) => {
            const frameEntity = modules.dataStore.getEntity(frameEntityId);
            frameEntity.setIdDoor(doorEntity.id);
            doorEntity.addFrameEntityId(frameEntityId);
            if (frameEntity.getIsSplitted()) {
                traverse(frameEntity.getIdFrameLeftOrBottom());
                traverse(frameEntity.getIdFrameRightOrTop());
            }
        };
        traverse(startFrameEntity.id);
    },

    unlinkFramesOfDoor(doorEntity) {
        for (let i = 0, il = doorEntity.getFrameEntitiesIds().length; i < il; i++) {
            const frameEntity = modules.dataStore.getEntity(doorEntity.getFrameEntitiesIds()[i]);
            frameEntity.setIdDoor();
        }
    },

    unlinkDeadFramesOfDoor(doorEntity) {
        const deadEntitiesIds = [];
        for (let i = 0, il = doorEntity.getFrameEntitiesIds().length; i < il; i++) {
            const frameEntity = modules.dataStore.getEntity(doorEntity.getFrameEntitiesIds()[i]);
            if (!frameEntity) {
                deadEntitiesIds.push(i);
            }
        }
        for (let i = 0, il = deadEntitiesIds.length; i < il; i++) {
            doorEntity.getFrameEntitiesIds().splice(deadEntitiesIds[i], 1);
        }
    },

    cleanLinkWithDeadFrameOfFurniture(furnitureEntityId) {
        const doorsEntity = modules.dataStore.listEntities("door");
        for (let i = 0, il = doorsEntity.length; i < il; i++) {
            const doorEntity = doorsEntity[i];
            if (doorEntity.getFurnitureId() === furnitureEntityId) {
                DoorHelper.unlinkDeadFramesOfDoor(doorEntity);
            }
        }
    },


    refreshFrameAncestorFromLastDoorFrame(doorEntity) {
        const frameEntity = modules.dataStore.getEntity(doorEntity.getLastFrameFromBuildOrder());
        if (!frameEntity) return;
        DoorController.currentFrameAncestor = FrameHelper.getAncestorWithSameOrientation(frameEntity);
    },

    showAllDoors() {
        const doorsEntity = modules.dataStore.listEntities("door");
        for (let i = 0; i < doorsEntity.length; i++) {
            const doorEntity = doorsEntity[i];
            doorEntity.mesh.setVisible(true);
            doorEntity.knobs.forEach((knob) => {
                knob.isVisible = doorEntity.furnitureEntity.knobVisible;
            });
        }
    },

    /**
     *
     * @param {DoorEntity} doorEntity
     */
    showExtendableZones(doorEntity) {
        DoorController.framesForExtendDoor = DoorHelper.getFramesForExtendDoor(doorEntity);
        for (let i = 0; i < DoorController.framesForExtendDoor.length; i++) {
            const frame = modules.dataStore.getEntity(DoorController.framesForExtendDoor[i]);
            FrameHelper.showFrame(frame);
            if (frame.getIdParent()) {
                const parent = modules.dataStore.getEntity(frame.getIdParent());
                FrameHelper.hideFrame(parent);
            }
        }
    },

    /**
     * Decale Frames, Boards and accessories in depth ( position and scaling ) after a door is added, extended or removed.
     * for isBoardCoveredByDoor, show https://git.wanadev.org/clickandmeubles/clickandmeubles-3d/-/wikis/BBox2Helper
     * @param {DoorEntity} doorEntity
     */
    updateItemsCoveredByDoor(doorEntity) {
        const bbDoor = doorEntity.container.parent ? DoorHelper.getDoorFramesBBox2Union(doorEntity) : new BBox2();
        for (let i = 0, il = doorEntity.getFrameEntitiesIds().length; i < il; i++) {
            const frameEntity = modules.dataStore.getEntity(doorEntity.getFrameEntitiesIds()[i]);
            const bbFrame = BBox2Helper.getBBox2FromObject3dEntity(frameEntity);
            const isFrameCoveredByDoor = BBox2Helper.BBox2containBBox2(bbDoor, bbFrame);
            frameEntity.setIsCoveredByDoor(isFrameCoveredByDoor);
            if (frameEntity.getIsSplitted()) {
                const boardEntity = modules.dataStore.getEntity(frameEntity.getIdBoard());
                const bbBoard = BBox2Helper.getBBox2FromObject3dEntity(boardEntity);
                const doorDivided = BBox2Helper.BBox2divideWithContainedBBox2(bbDoor, bbBoard).length;
                const isBoardCoveredByDoor = doorDivided === 3 || doorDivided >= 6;
                boardEntity.setIsCoveredByDoor(isBoardCoveredByDoor);
            } else if (frameEntity.hasMovableshelf()) {
                MovableshelfHelper.setIsCoveredByDoor(frameEntity, frameEntity.hasDoor());
            } else if (frameEntity.hasAccessorydrawer()) {
                AccessorydrawerHelper.setIsCoveredByDoor(frameEntity, frameEntity.hasDoor());
            } else if (frameEntity.hasPulloutshelf()) {
                PulloutshelfHelper.setIsCoveredByDoor(frameEntity, frameEntity.hasDoor());
            }
        }
    },

    /**
     * Update if the boudingbox of the door's frames is equal to the current frame ancestor's boundingbox.
     */
    updateCurrentFrameAncestor(doorEntity) {
        const bbDoorFramesUnion = DoorHelper.getDoorFramesBBox2Union(doorEntity);
        const equalBB = BBox2Helper.getBBox2Equals(DoorController.currentFrameAncestor.bbox, bbDoorFramesUnion);
        if (equalBB) {
            DoorController.currentFrameAncestor = FrameHelper.getAncestorWithSameOrientation(DoorController.currentFrameAncestor);
        }
    },

    /**
     * Reset variables of the door controller, make an history snapshot and can deactive frame's tool.
     * @param {Boolean} deactivateTool
     * @returns
     */
    validCurrentDoorInEdition(deactivateTool = false) {
        if (!DoorController.currentDoorEntity || !DoorController.currentDoorEntity.isInEdition) return;

        DoorController.currentDoorEntity.isInEdition = false;
        DoorController.resetValues();
        if (deactivateTool) {
            events.emit("tool:frame:deactivate");
        }

        modules.history.snapshots.splice(modules.history.pointer, 1);
        modules.history.cropLength();

        events.emit("furniture:edited");
    },

    applyFinishMaterial(doorEntity) {
        const { mesh } = doorEntity;
        const faces = [mesh.faceFront, mesh.faceBack, mesh.faceLeft, mesh.faceRight, mesh.faceTop, mesh.faceBottom];
        faces.forEach((face) => {
            if (face === mesh.faceTop || face === mesh.faceBottom) {
                face.material = MaterialManager.frontFinishMaterial90;
            } else {
                face.material = MaterialManager.frontFinishMaterial;
            }
        });
        mesh.updateUVScaling();
    },

    applyConstructionMaterial(doorEntity) {
        const { mesh } = doorEntity;
        const faces = [mesh.faceFront, mesh.faceBack, mesh.faceLeft, mesh.faceRight, mesh.faceTop, mesh.faceBottom];
        faces.forEach((face) => {
            face.material = MaterialManager.frontConstructionMaterial;
        });
    },

    deleteDoorFromFrameEntityId(frameEntityId) {
        const doorEntity = modules.dataStore.getEntity(frameEntityId);
        if (!doorEntity) return;
        DoorController.deleteDoor(doorEntity);
    },

    hingeQuantityForDoorHeight(height) {
        if (height < 750) {
            return 2;
        } if (height < 1500) {
            return 3;
        } if (height < 2100) {
            return 4;
        } if (height < 2500) {
            return 5;
        } if (height < 2750) {
            return 6;
        }
        return 7;
    },
};
export default DoorHelper;
