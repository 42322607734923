import CameraHelper from "../../../cameras-manager/src/camera-helper";
import self from "../../index";

const {
    modules: {
        dataStore,
    },
} = self.app;

const AccessoryDrawerUiButtons = {
    getAllButtons() {
        const buttons = [];
        dataStore.listEntities("accessorydrawer").forEach((accessorydrawerEntity) => {
            const furnitureEntity = dataStore.getEntity(accessorydrawerEntity.furnitureId);
            const center = accessorydrawerEntity.getCenterAtFront().add(furnitureEntity.position);
            const center2D = CameraHelper.convert3DPointTo2D(center);
            buttons.push({
                id: accessorydrawerEntity.idParent,
                x: center2D.x,
                y: center2D.y,
                buttons: [
                    {
                        deletable: true,
                        event: "accessorydrawer:delete",
                    },
                    {
                        editable: true,
                        event: "accessorydrawer:select",
                    },
                ],
            });
        });
        return buttons;
    },
};

export default AccessoryDrawerUiButtons;
