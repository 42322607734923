import self from "../../index";
import CameraHelper from "../../../cameras-manager/src/camera-helper";

const {
    modules: {
        dataStore,
    },
} = self.app;

const DrawerUIButtons = {
    getAllButtons(entityNameType) {
        const buttons = [];
        dataStore.listEntities(entityNameType).forEach((drawerEntity) => {
            const furnitureEntity = dataStore.getEntity(drawerEntity.furnitureId);
            const center = drawerEntity.getCenterAtFront().add(furnitureEntity.position);
            const center2D = CameraHelper.convert3DPointTo2D(center);
            buttons.push({
                id: drawerEntity.getIdParent(),
                x: center2D.x,
                y: center2D.y,
                buttons: [
                    {
                        deletable: true,
                        event: `${entityNameType}:delete`,
                    },
                    {
                        editable: true,
                        event: `${entityNameType}:select`,
                    },
                ],
            });
        });
        return buttons;
    },
};

export default DrawerUIButtons;
