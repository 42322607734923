// All distances are expressed in millimeters
export const BOARD_THICKNESS = 19;
export const BOARD_HALF_THICKNESS = BOARD_THICKNESS / 2;

export const DISTANCE_BEHIND_DOOR = 22;

export const MOVABLESHELF_HOLE_SEPARATION_DISTANCE = 32;
export const MOVABLESHELF_HOLES = 5;
export const MOVABLESHELF_HOLE_RADIUS = 4;
export const MOVABLESHELF_HOLE_INSIDE_DEPTH = 20;
export const MOVABLESHELF_BBOX_HEIGHT = MOVABLESHELF_HOLE_SEPARATION_DISTANCE * MOVABLESHELF_HOLES;
export const MOVABLESHELF_BBOX_HALF_HEIGHT = MOVABLESHELF_BBOX_HEIGHT * 0.5;

export const ROD_RADIUS = 8;
export const ROD_RADIUS_HALF = ROD_RADIUS / 2;
export const ROD_DIAMETER = ROD_RADIUS * 2;
export const ROD_DISTANCE_FROM_BOARD = 40;
export const ROD_LATERIAL_DISTANCE = 20;
export const ROD_SUPPORT_RING_WIDTH = 10;
export const ROD_SUPPORT_RING_DIAMETER = ROD_DIAMETER + 4;
export const ROD_ORIENTED_IN_DEPTH_UP_TO = 500;

export const ACCESSORYDRAWER_SPACE_HEIGHT = 30;

export const ORIENTATION_HORIZONTAL = "horizontal";
export const ORIENTATION_VERTICAL = "vertical";
export const ORIENTATION_DEPTH = "depth";
export const ORIENTATION_NONE = "none";

export const RESIZE_FROM_RIGHT = "RESIZE_FROM_RIGHT";
export const RESIZE_FROM_TOP = "RESIZE_FROM_TOP";
export const RESIZE_FROM_BOTTOM = "RESIZE_FROM_BOTTOM";
export const RESIZE_FROM_LEFT = "RESIZE_FROM_LEFT";
export const RESIZE_FROM_BACK = "RESIZE_FROM_BACK";

export const ORIGIN_RIGHT = "ORIGIN_RIGHT";
export const ORIGIN_TOP = "ORIGIN_TOP";
export const ORIGIN_BOTTOM = "ORIGIN_BOTTOM";
export const ORIGIN_LEFT = "ORIGIN_LEFT";

export const LEFT = "LEFT";
export const RIGHT = "RIGHT";
export const TOP = "TOP";
export const BOTTOM = "BOTTOM";

export const TOOL_MODE_SELECT = "TOOL_MODE_SELECT";
export const TOOL_MODE_SPLIT = "TOOL_MODE_SPLIT";
export const TOOL_MODE_REMOVE = "TOOL_MODE_REMOVE";
export const TOOL_MODE_BACKBOARD = "TOOL_MODE_BACKBOARD";
export const TOOL_MODE_DOOR = "TOOL_MODE_DOOR";
export const TOOL_MODE_DRAWER = "TOOL_MODE_DRAWER";
export const TOOL_MODE_MOVABLESHELF = "TOOL_MODE_MOVABLESHELF";
export const TOOL_MODE_ROD = "TOOL_MODE_ROD";
export const TOOL_MODE_PULLOUTSHELF = "TOOL_MODE_PULLOUTSHELF";

export const BACKBOARD_REMOVABLE = "BACKBOARD_REMOVABLE";
export const BACKBOARD_NORMAL = "BACKBOARD_NORMAL";
export const BACKBOARD_NONE = "BACKBOARD_NONE";
export const BACKBOARD_ALL_NORMAL = "BACKBOARD_ALL_NORMAL";
export const BACKBOARD_ALL_NONE = "BACKBOARD_ALL_NONE";

export const DOOR_OPENING_LEFT = "DOOR_OPENING_LEFT";
export const DOOR_OPENING_RIGHT = "DOOR_OPENING_RIGHT";

export const DOOR_SIMPLE_LEFT = "DOOR_SIMPLE_LEFT";
export const DOOR_SIMPLE_RIGHT = "DOOR_SIMPLE_RIGHT";
export const DOOR_DOUBLE = "DOOR_DOUBLE";
export const DOOR_NONE = "DOOR_NONE";

export const OVERLAY_PADDING = 1.5;
export const OVERLAY_DRAWER_DECAL_Z = 3;

export const FRONT_MODE_INSIDE = "FRONT_MODE_INSIDE";
export const FRONT_MODE_OUTSIDE = "FRONT_MODE_OUTSIDE";

export const OVERLAY_DRAWER = "OVERLAY_DRAWER";
export const INSET_DRAWER = "INSET_DRAWER";
export const ACCESSORYDRAWER = "ACCESSORYDRAWER";
export const PULLOUTSHELF = "PULLOUTSHELF";
export const DRAWER_NONE = "DRAWER_NONE";

export const DRAWER_RUNNER_WEIGHT_1 = "40Kg";
export const DRAWER_RUNNER_WEIGHT_2 = "70Kg";
export const DRAWER_RUNNER_BACKSPACE = 7;
export const DRAWER_RUNNER_LATERAL_SPACE = 5;
export const DRAWER_RUNNER_PULLOUTSHEF_FRONT_LATERAL_SPACE = 3;
export const DRAWER_RUNNER_PULLOUTSHEF_LATERAL_SPACE = 11;
export const DRAWER_RUNNER_SPACE_BEHIND_BOTTOM_BOARD = 15;
export const DRAWER_RUNNER_PULLOUTSHELF_VOID_SPACE = 10 + 28;

export const SPACE_MOVEMENT = 3; // jeu de fonctionnement
export const DRAWER_SPACE_BELOW_TOP = 13;
export const DRAWER_SPACE_ABOVE_BOTTOM = 19;
export const DRAWER_SPACE_ABOVE_UTIL = 2;
export const DRAWER_MIN_HEIGHT_UTIL = 70;
export const DRAWER_MAX_HEIGHT_UTIL = 380;
export const DRAWER_BACKSPACE = DRAWER_RUNNER_BACKSPACE + 3;
export const DRAWER_BOTTOM_BOARD_SPACE_INSIDE_FRONT_AND_BACK = 5;
export const OVERLAYDRAWER_TOP_SPACE = 30.5;
export const OVERLAYDRAWER_BOTTOM_SPACE = 36.5;
export const INSETDRAWER_TOP_SPACE = 10;
export const INSETDRAWER_BOTTOM_SPACE = 16;

export const ACCESSORYDRAWER_SPACE_DEPTH = 90;
export const ACCESSORYDRAWER_HAND_SPACE_HEIGHT = 30;
export const ACCESSORYDRAWER_SPACE_FROM_TOP_OF_FRONT_BOARD = 10;
export const ACCESSORYDRAWER_SPACE_FROM_BOTTOM_OF_FRONT_BOARD = 16;


export const PULLOUTSHELF_FRONT_HEIGHT = 50;
export const PULLOUTSHELF_BACK_HEIGHT = 50;
export const PULLOUTSHELF_MARGIN_TOP = 7;
export const PULLOUTSHELF_MARGIN_BOTTOM = 30;
export const PULLOUTSHELF_SPACE_DEPTH = 0;
export const PULLOUTSHELF_SPACE_ABOVE_BACK_BOARD = 10;
export const PULLOUTSHELF_SPACE_ABOVE_BOTTOM_BOARD = 16;
export const PULLOUTSHELF_SPACE_BELOW_BOTTOM_BOARD = 15;
export const PULLOUTSHELF_SPACE_BELOW_BACK_BOARD = PULLOUTSHELF_SPACE_ABOVE_BOTTOM_BOARD + 30;

