import { TransformNode } from "@babylonjs/core";
import Class from "abitbol";

const KnobMixin = Class.$extend({

    initKnobMixin() {
        this.knobs = [];
        this.knobsContainer = new TransformNode("knobsContainer");
        this.knobsContainer.parent = this.container;
        this.knobsContainer.isVisible = false;
    },

    addKnob(knob, parent) {
        if (!parent) knob.parent = this.knobsContainer;
        else knob.parent = parent;
        knob.position.z = -1;

        const furnitureEntity = this.getFurnitureEntity();
        knob.isVisible = furnitureEntity.knobVisible;

        this.knobs.push(knob);
    },

});

module.exports = KnobMixin;
