const debounce = (func, wait, immediate) => {
    let timeout;
    // eslint-disable-next-line func-names
    return function () {
        const context = this;
        // eslint-disable-next-line prefer-rest-params
        const args = arguments;
        // eslint-disable-next-line func-names
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

const throttle = (context, func, limit) => {
    let lastFunc;
    let lastRan;
    return (...args) => {
        if (!lastRan) {
            func.apply(context, args);
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);
            // eslint-disable-next-line func-names
            lastFunc = setTimeout(function () {
                if (Date.now() - lastRan >= limit) {
                    // eslint-disable-next-line prefer-rest-params
                    func.apply(context, arguments);
                    lastRan = Date.now();
                }
            }, limit - (Date.now() - lastRan));
        }
    };
};

const clamp = (value, min, max) => Math.max(min, Math.min(max, value));
const lerp = (a, b, n) => (1 - n) * a + n * b;
const map = (value, start1, stop1, start2, stop2) => ((value - start1) / (stop1 - start1)) * (stop2 - start2) + start2;

export {
    debounce,
    clamp,
    lerp,
    map,
    throttle,
};
