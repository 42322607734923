import Object3DEntity from "../entity-mixins/object3D-entity";

const Entity = require("@obsidianjs/data-store/src/entity");

require("helpers/serializers/babylon-vector3");

/**
 * Backboard Entity in Furnitures module
 */
const BaseboardEntity = Object3DEntity.$extend({

    __name__: "baseboard",

    __init__(params = {}) {

        this.mesh = null;

        this.$super(params);
    },

    updateMeshes() {
        if (!this.mesh) return;
        this.mesh.scaling = this.$data.scaling.clone();
        this.mesh.updateLinesSystem();
    },
});

Entity.$register(BaseboardEntity);
export default BaseboardEntity;
