<template>
    <div v-show="isVisible" class="h-full w-full z-10 absolute pointer-events-none">
        <div v-for="group in buttonGroups" :key="group.id" class="absolute" :style="getPositionButtonGroup(group)">
            <div class="flex flex-row gap-1">
                <div v-for="button in group.buttons" :key="button.event" class="flex flex-row gap-1">
                    <DeleteButton
                        v-if="button.deletable"
                        @click="handleDelete(button.event, group.id)"
                    />
                    <Extendbutton
                        v-if="button.extendable"
                        :direction="button.direction"
                    />
                    <EditButton
                        v-if="button.editable"
                        @click="handleSelect(button.event, group.id)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import self from "../../../index";
import DeleteButton from "./DeleteButton.vue";
import Extendbutton from "./ExtendButton.vue";
import EditButton from "./EditButton.vue";

const {
    events,
} = self.app;

export default {
    components: { DeleteButton, Extendbutton, EditButton },
    data() {
        return {
            buttonGroups: [],
            isVisible: true,
        };
    },
    created() {
        events.on("@furnitures.3D:buttons:updated", (buttons) => {
            this.buttonGroups = buttons;
        });
        events.on("@cameras-manager.camera:move:start", this.onCameraStart);
        events.on("@cameras-manager.camera:move:end", this.onCameraStop);
    },
    methods: {
        getPositionButtonGroup(group) {
            return {
                top: `${group.y}px`,
                left: `${group.x}px`,
                transform: "translate(-50%, -50%)",
            };
        },
        handleDelete(eventName, id) {
            events.emit(eventName, { id });
        },
        handleSelect(eventName, id) {
            events.emit(eventName, { id });
        },
        onCameraStart() {
            this.isVisible = false;
        },
        onCameraStop() {
            this.isVisible = true;
        },
    },
};
</script>
