import CameraHelper from "../../../cameras-manager/src/camera-helper";
import self from "../../index";
import MovableshelfController from "./movableshelf-controller";

const {
    modules: {
        dataStore,
    },
} = self.app;

const MovableshelfUiButtons = {
    getAllButtons() {
        const buttons = [];
        const { currentMovableshelfEntity } = MovableshelfController;
        dataStore.listEntities("movableshelf").forEach((movableshelfEntity) => {
            if (currentMovableshelfEntity === movableshelfEntity) {
                const furnitureEntity = dataStore.getEntity(movableshelfEntity.furnitureId);
                const center = movableshelfEntity.getCenterAtRight().add(furnitureEntity.position);
                const center2D = CameraHelper.convert3DPointTo2D(center);
                buttons.push({
                    id: movableshelfEntity.id,
                    x: center2D.x,
                    y: center2D.y,
                    buttons: [
                        {
                            deletable: true,
                            event: "movableshelf:delete",
                        },
                    ],
                });
            }
        });
        return buttons;
    },
};

export default MovableshelfUiButtons;
