import self from "../../index";
import FrameHelper from "../frame/frame-helper";
import MeasureHelper from "./measure-helper";

const { events } = self.app;

const data = {
    showMeasure: false,
};

const MeasureController = {

    get showMeasure() {
        return data.showMeasure;
    },

    set showMeasure(value) {
        data.showMeasure = value;
    },

    updateMeasures(boardEntity) {
        if (boardEntity) { // Update only adjacent measures
            this.adjacentFrames = FrameHelper.getAdjacentFramesOfBoard(boardEntity);
            const dimensions = [];
            this.adjacentFrames.forEach((frameEntity) => {
                dimensions.push(MeasureHelper.getFrameMeasure(frameEntity, boardEntity));
            });
            events.emit("measures:updated", dimensions);
        } else if (MeasureController.showMeasure) {
            // If in measure mode, update all measures
            events.emit("measures:updated", MeasureHelper.getAllMeasures());
        }
    },
};

export default MeasureController;
