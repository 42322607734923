import ObsidianApi from "obsidian-api";
import useGlobalStore from "../stores/global";
import catalog from "../mocks/catalog.json";
import defaultMaterialData from "../../../../constants/defaultMaterialData";
import { DEFAULT_HINGE_REF, DEFAULT_ROD_REF, DEFAULT_ROD_SUPPORT_REF } from "../../../../constants/defaultAccessories";

export default function initCatalogs() {
    const store = useGlobalStore();

    let productCatalog = {};

    const obsidianConfig = ObsidianApi.$getConfig();
    if (obsidianConfig.catalogue) {
        productCatalog = JSON.parse(decodeURIComponent(escape(obsidianConfig.catalogue)));
    } else {
        productCatalog = catalog;
    }

    const boardsCatalog = productCatalog.planches_bois;
    const bordersCatalog = productCatalog.chants;
    const buttonsCatalog = productCatalog.boutons;
    const feetCatalog = productCatalog.pieds;
    const drawersCatalog = productCatalog.tiroirs;
    const accessoriesCatalog = productCatalog.accessoires;

    store.setBoardsCatalog(boardsCatalog);
    store.setBordersCatalog(bordersCatalog);
    store.setButtonsCatalog(buttonsCatalog);
    store.setFeetCatalog(feetCatalog);
    store.setDrawersCatalog(drawersCatalog);
    store.setAccessoriesCatalog(accessoriesCatalog);

    const defaultBoard = boardsCatalog.find((board) => board.product_ref === defaultMaterialData.ref);
    store.setSelectedBoard(defaultBoard);
    store.setSelectedFront(defaultBoard);

    const defaultBorder = bordersCatalog.find((border) => border.product_ref === defaultMaterialData.ref);
    store.setSelectedBorder(defaultBorder);

    const defaultHinge = accessoriesCatalog.find((accessory) => accessory.product_ref === DEFAULT_HINGE_REF);
    store.setSelectedHinge(defaultHinge);

    const defaultRod = accessoriesCatalog.find((accessory) => accessory.product_ref === DEFAULT_ROD_REF);
    store.setSelectedRod(defaultRod);

    const defaultRodSupport = accessoriesCatalog.find((accessory) => accessory.product_ref === DEFAULT_ROD_SUPPORT_REF);
    store.setSelectedRodSupport(defaultRodSupport);
}
