/* eslint-disable indent */
import {
    Color3,
    Vector3,
} from "@babylonjs/core";

import {
    TOOL_MODE_SELECT,
} from "../constants";

import BoardController from "../board/board-controller";

import self from "../../index";
import FrameHelper from "../frame/frame-helper";

const {
    modules,
    events,
} = self.app;

class BoardsTweaks {

    constructor() {
        if (!BoardsTweaks.instance) BoardsTweaks.instance = this;
        else throw new Error("BoardsTweaks is a singleton. Use BoardsTweaks.getInstance()");
        this.toolManager = modules.toolManager.controller;
    }

    addTweaks(pane) {
        this.tweakpane = pane;
        this.params = {
            boardLinesColor: self.config.boardLinesColor.toHexString(),
            boardLinesVisible: true,
            currentBoardEntityParams: {
                scaling: Vector3.Zero(),
                position: Vector3.Zero(),
                id: "",
                idParent: "",
            },
            position: 0.5,

            side1: {
                scaling: Vector3.Zero(),
                position: Vector3.Zero(),
                id: "",
                idParent: "",
            },
            side2: {
                scaling: Vector3.Zero(),
                position: Vector3.Zero(),
                id: "",
                idParent: "",
            },
        };

        this.boardFolder = this.tweakpane.addFolder({
            title: "Boards",
            expanded: false,
        });

        this.toolModes = this.boardFolder.addBlade({
            view: "list",
            label: "board tools",
            options: [{
                    text: "",
                    value: "",
                },
                {
                    text: "select",
                    value: TOOL_MODE_SELECT,
                },
            ],
            value: "",
        }).on("change", this.onToolModeChanged.bind(this));

        this.boardFolder.addInput(this.params, "boardLinesColor", {
            label: "color lines",
        }).on("change", (event) => {
            self.config.boardLinesColor.copyFrom(Color3.FromHexString(event.value));
        });
        this.boardFolder.addInput(this.params, "boardLinesVisible", {
            label: "show lines",
        }).on("change", (event) => {
            self.config.boardLinesVisible = event.value;
            self.furnituresListController.refreshBoardsView();
        });

        this.currentBoardFolder = this.tweakpane.addFolder({
            title: "Current Board",
            expanded: false,
        });
        this.deleteBoardButton = this.currentBoardFolder.addButton({
                title: "delete",
                disabled: true,
            })
            .on("click", () => {
                if (!this.currentBoardEntity) return;
                FrameHelper.collapseFrame(this.currentBoardEntity.getIdParent());
            });
        this.currentBoardFolder.addInput(this.params.currentBoardEntityParams, "id", {
            disabled: true,
        });
        this.currentBoardFolder.addInput(this.params.currentBoardEntityParams, "idParent", {
            disabled: true,
        });
        this.currentBoardFolder.addInput(this.params.currentBoardEntityParams, "scaling", {
            disabled: true,
        });
        this.currentBoardFolder.addInput(this.params.currentBoardEntityParams, "position", {
            disabled: true,
        });

        events.on("board:selected", this.onBoardSelected.bind(this));
        events.on("board:tool:endActive", this.onBoardToolEndActive.bind(this));
    }

    onToolModeChanged(event) {
        if (event.value === "") {
            this.deleteBoardButton.disabled = true;
            if (this.inputBoardPosition) this.inputBoardPosition.disabled = true;
            this.toolManager.deactivateTool(BoardController.atool.tool_name);
            BoardController.atool.activateModeAlone(event.value);
        } else {
            this.toolManager.activateToolAlone(BoardController.atool.tool_name);
            BoardController.atool.activateModeAlone(event.value);
        }
    }

    onBoardToolEndActive() {
        this.toolModes.value = "";
    }

    onBoardSelected(currentBoardEntity) {
        this.currentBoardEntity = currentBoardEntity;
        this.params.currentBoardEntityParams.id = this.currentBoardEntity.id;
        this.params.currentBoardEntityParams.idParent = this.currentBoardEntity.getIdParent();
        this.params.currentBoardEntityParams.scaling = this.currentBoardEntity.getScaling().clone();
        this.params.currentBoardEntityParams.position = this.currentBoardEntity.getPosition().clone();

        if (BoardController.isActiveTool() &&
            BoardController.isSelectActiveToolMode()
        ) {
            this.deleteBoardButton.disabled = false;
            if (!this.currentBoardEntity.getIsBlockPart()) {
                this.regenerateInputBoardPosition();
            } else if (this.inputBoardPosition) {
                this.inputBoardPosition.dispose();
            }

        }
        this.tweakpane.refresh();
    }

    regenerateInputBoardPosition() {
        const frameEntityParent = modules.dataStore.getEntity(this.currentBoardEntity.getIdParent());

        this.currentSide1Entity = modules.dataStore.getEntity(frameEntityParent.getIdFrameLeftOrBottom());
        this.currentSide2Entity = modules.dataStore.getEntity(frameEntityParent.getIdFrameRightOrTop());

        if (this.inputBoardPosition) {
            this.inputBoardPosition.dispose();
        }

        if (this.currentBoardEntity.isVertical()) {
            this.params.position = this.currentBoardEntity.getPosition().clone().x;
        } else if (this.currentBoardEntity.isHorizontal()) {
            this.params.position = this.currentBoardEntity.getPosition().y;
        }
    }

    static getInstance() {
        if (!BoardsTweaks.instance) {
            return new BoardsTweaks();
        }
        return BoardsTweaks.instance;
    }

}

export default BoardsTweaks;
