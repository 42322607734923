import {
    MeshBuilder,
} from "@babylonjs/core/Meshes/meshBuilder";
import {
    Vector3,
} from "@babylonjs/core/Maths/math.vector";
import {
    StandardMaterial,
    TransformNode,
} from "@babylonjs/core";

import self from "../../index";
import {
    MOVABLESHELF_HOLES,
    MOVABLESHELF_HOLE_INSIDE_DEPTH,
    MOVABLESHELF_HOLE_RADIUS,
    MOVABLESHELF_HOLE_SEPARATION_DISTANCE,
} from "../constants";
import CubeFacesMesh from "../common/cube-faces-mesh";
import { TransformNodeUtil } from "../../../../helpers/mesh-utility";

class MovableshelfMesh extends CubeFacesMesh {

    constructor(movableshelfEntity) {
        super(movableshelfEntity, undefined);

        this.material = new StandardMaterial("hitBoxMat");
        this.material.alpha = 0;

        this.addCustomWireframe();
    }

    dispose() {
        super.dispose();
        this.linesSystem.dispose();
        this.holesFrontLeft.dispose();
        this.holesFrontRight.dispose();
        this.holesBackLeft.dispose();
        this.holesBackRight.dispose();
    }

    setVisible(value) {
        super.setVisible(value);
        TransformNodeUtil.childrenVisible(this.holesFrontLeft, value);
        TransformNodeUtil.childrenVisible(this.holesFrontRight, value);
        TransformNodeUtil.childrenVisible(this.holesBackLeft, value);
        TransformNodeUtil.childrenVisible(this.holesBackRight, value);
    }

    addCustomWireframe() {
        const lines = [];
        lines.push(
            // Represent bottom face
            [
                new Vector3(0, 0, 0),
                new Vector3(1, 0, 0),
                new Vector3(1, 0, 1),
                new Vector3(0, 0, 1),
                new Vector3(0, 0, 0),
            ],
            // Represent top face
            [
                new Vector3(0, 1, 0),
                new Vector3(1, 1, 0),
                new Vector3(1, 1, 1),
                new Vector3(0, 1, 1),
                new Vector3(0, 1, 0),
            ],
        );

        this.linesSystem = MeshBuilder.CreateLineSystem("lines", {
            lines: lines,
        });

        this.linesSystem.isVisible = self.config.boardLinesVisible;
        this.linesSystem.isPickable = false;
        this.linesSystem.color = self.config.movableshelfLinesColor;

        if (!MovableshelfMesh.holeModel) {

            MovableshelfMesh.holeModel = MeshBuilder.CreateLineSystem("hole", {
                lines: [
                    [
                        new Vector3(0, -MOVABLESHELF_HOLE_RADIUS, 0),
                        new Vector3(0, 0, -MOVABLESHELF_HOLE_RADIUS),
                        new Vector3(0, MOVABLESHELF_HOLE_RADIUS, 0),
                        new Vector3(0, 0, MOVABLESHELF_HOLE_RADIUS),
                        new Vector3(0, -MOVABLESHELF_HOLE_RADIUS, 0),
                    ],
                ],
            });
            MovableshelfMesh.holeModel.color = self.config.movableshelfHolesLinesColor;
            MovableshelfMesh.holeModel.isVisible = false;
        }

        this.holesFrontLeft = MovableshelfMesh.buildHolesLine("holesFrontLeft");
        this.holesFrontRight = MovableshelfMesh.buildHolesLine("holesFrontRight");
        this.holesBackLeft = MovableshelfMesh.buildHolesLine("holesBackLeft");
        this.holesBackRight = MovableshelfMesh.buildHolesLine("holesBackRight");
    }

    updateLinesSystem(enlargeValue = 1) {
        super.updateLinesSystem(enlargeValue);

        this.holesFrontLeft.position.x = this.position.x + enlargeValue;
        this.holesFrontLeft.position.y = this.position.y - MOVABLESHELF_HOLE_RADIUS;
        this.holesFrontLeft.position.z = this.position.z + MOVABLESHELF_HOLE_INSIDE_DEPTH;

        this.holesFrontRight.position.x = this.position.x + this.scaling.x - enlargeValue;
        this.holesFrontRight.position.y = this.holesFrontLeft.position.y;
        this.holesFrontRight.position.z = this.position.z + MOVABLESHELF_HOLE_INSIDE_DEPTH;

        this.holesBackLeft.position.x = this.position.x + enlargeValue;
        this.holesBackLeft.position.y = this.holesFrontLeft.position.y;
        this.holesBackLeft.position.z = this.position.z + this.scaling.z - MOVABLESHELF_HOLE_INSIDE_DEPTH;

        this.holesBackRight.position.x = this.position.x + this.scaling.x - enlargeValue;
        this.holesBackRight.position.y = this.holesFrontLeft.position.y;
        this.holesBackRight.position.z = this.position.z + this.scaling.z - MOVABLESHELF_HOLE_INSIDE_DEPTH;
    }

    static buildHolesLine(lineName) {
        const startPositionY = -((MOVABLESHELF_HOLE_SEPARATION_DISTANCE * (MOVABLESHELF_HOLES - 1)) / 2);
        const linesHoles = new TransformNode();
        for (let i = 0; i < MOVABLESHELF_HOLES; i++) {
            const hole = MovableshelfMesh.holeModel.createInstance(lineName + i);
            hole.position.y = startPositionY + (i * MOVABLESHELF_HOLE_SEPARATION_DISTANCE);
            hole.parent = linesHoles;
        }

        return linesHoles;
    }

}

export default MovableshelfMesh;
