import "core-js/stable";
// import "core-js/fn/array/flat-map";
import "regenerator-runtime/runtime";

import obsidian from "@obsidianjs/obsidian";
import stonejs from "@obsidianjs/stonejs";
import iframeApi from "@obsidianjs/iframe-api";
import httpRequest from "@obsidianjs/http-request";
import MainLoop from "@obsidianjs/main-loop";
import DataStore from "@obsidianjs/data-store";
import DataExporter from "@obsidianjs/data-exporter";
import ObsidianHistory from "@obsidianjs/history";
import Sentry from "@obsidianjs/sentry";
import ObsidianBabylonEngine from "obsidian-babylon-engine";
import ObsidianMaterialManager from "obsidian-material-manager";
import ObsidianMeshManager from "obsidian-mesh-manager";
import ObsidianScreenshotManager from "obsidian-screenshot-manager";

// import Cursor from "cantor/src/modules/cursor";
import IFrameHeight from "cantor/src/modules/iframe-height";
import InternationalizationUtility from "cantor/src/modules/internationalization-utility";
// import FullScreen from "cantor/src/modules/fullscreen";
// import SelectTool from "cantor/src/modules/select-tool";
// import ContextualMenu from "cantor/src/modules/contextual-menu";
import SimpleUi from "cantor/src/modules/simple-ui";
import ProjectManager from "./modules/project-manager";
// import MaterialApplier from "cantor/src/modules/material-applier";

import Api from "./modules/api";
import CamerasManager from "./modules/cameras-manager";
import Furnitures from "./modules/furnitures";
import Main from "./modules/main";
import Vuejs from "./modules/vuejs";
import ToolManager from "./modules/tool-manager";
import Tweakpane from "./modules/tweakpane";
import WanadevLoader from "./modules/wanadev-loader";

import "./style/index.scss";

HTMLCanvasElement.prototype.getContext = (function (origFn) {
    return function (type, attribs) {
        attribs = attribs || {};
        attribs.preserveDrawingBuffer = true;
        return origFn.call(this, type, attribs);
    };
}(HTMLCanvasElement.prototype.getContext));

// IMPORTANT WANADEV
// You should change this DSNKEY whenever you start a new projet, ask an administrator for this key plz
const sentryDSNKey =
    process.env.sentryDSNKey ||
    "https://797ab3bb32ea0cebb093755c07dc40c7@o509192.ingest.sentry.io/4506400648462336";

const app = obsidian("starter-app");

// Internationalization modules
app.use(stonejs);
app.use(InternationalizationUtility);

app.use(httpRequest);
app.use(ObsidianScreenshotManager);
app.use(Vuejs);
app.use(WanadevLoader, {
    config: {
        showAtStart: true,
    },
});
app.use(MainLoop, {
    config: {
        debug: false,
        idleFps: 10,
    },
});
app.use(ObsidianBabylonEngine, {
    config: {
        antialias: true,
        exampleCamera: false,
        exampleLight: false,
        canvasId: "main-canvas",
        autostart: false,
    },
});
app.use(ObsidianMaterialManager);
app.use(ObsidianMeshManager, {
    config: {
        assetPath: "assets/models/", // default : "assets/models"
        // realTimeFilteringByDefault: true,
        useAOFromORMRedByDefault: true,
    },
});
app.use(iframeApi);

app.use(DataStore);
app.use(DataExporter);
app.use(Sentry, {
    config: {
        dsnKey: sentryDSNKey,
        capturedLevels: ["error", "warning"],
        environment: process.env.NODE_ENV || "dev",
        release: process.env.VERSION,
        // Performance Monitoring
        tracesSampleRate: 1, // Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    },
});
app.use(ObsidianHistory, {
    config: {
        maxLength: 100,
    },
});
app.use(Main);
// app.use(FullScreen);

app.use(Api);

app.use(IFrameHeight);
app.use(ProjectManager);

app.use(SimpleUi);
app.use(ToolManager);

app.use(CamerasManager);
app.use(Tweakpane);
app.use(Furnitures);

app.start();
