import {
    DOOR_DOUBLE,
} from "../constants";
import constraints from "../constraints";

const ConstraintsHelper = {

    addConstraint(activedConstraints, constraint) {
        if (constraint.enabled) {
            activedConstraints.push(constraint);
        }
    },

    addDoorConstraints(activedConstraints, doorType) {
        if (doorType === DOOR_DOUBLE) {
            ConstraintsHelper.addConstraint(activedConstraints, constraints.MIN_DOOR_DOUBLE_WIDTH);
            ConstraintsHelper.addConstraint(activedConstraints, constraints.MAX_DOOR_DOUBLE_WIDTH);
            ConstraintsHelper.addConstraint(activedConstraints, constraints.MIN_DOOR_DOUBLE_HEIGHT);
            ConstraintsHelper.addConstraint(activedConstraints, constraints.MAX_DOOR_DOUBLE_HEIGHT);
        } else {
            ConstraintsHelper.addConstraint(activedConstraints, constraints.MIN_DOOR_WIDTH);
            ConstraintsHelper.addConstraint(activedConstraints, constraints.MAX_DOOR_WIDTH);
            ConstraintsHelper.addConstraint(activedConstraints, constraints.MIN_DOOR_HEIGHT);
            ConstraintsHelper.addConstraint(activedConstraints, constraints.MAX_DOOR_HEIGHT);
        }
    },

    addDrawerConstraints(activedConstraints) {
        ConstraintsHelper.addConstraint(activedConstraints, constraints.MIN_DRAWER_WIDTH);
        ConstraintsHelper.addConstraint(activedConstraints, constraints.MAX_DRAWER_WIDTH);
    },

    addPulloutshelfConstraints(activedConstraints) {
        ConstraintsHelper.addConstraint(activedConstraints, constraints.MIN_PULLOUTSHELF_WIDTH);
        ConstraintsHelper.addConstraint(activedConstraints, constraints.MAX_PULLOUTSHELF_WIDTH);
    },

    addRodConstraints(activedConstraints) {
        ConstraintsHelper.addConstraint(activedConstraints, constraints.MIN_ROD_WIDTH);
        ConstraintsHelper.addConstraint(activedConstraints, constraints.MAX_ROD_WIDTH);
    },

};

export default ConstraintsHelper;
