import {
    Vector2, Vector3,
} from "@babylonjs/core/Maths/math.vector";
import cu from "vendors/class-utils";
import { BoundingInfo } from "@babylonjs/core";
import {
    BOARD_THICKNESS,
    DISTANCE_BEHIND_DOOR,
    ORIENTATION_HORIZONTAL,
    ROD_RADIUS_HALF,
} from "../constants";

import self from "../../index";
import BBox2Helper, {
    BBox2,
} from "../../../../helpers/BBox2Helper";
import RodHelper from "../rod/rod-helper";
import Object3DEntity from "../entity-mixins/object3D-entity";

const {
    modules,
    config,
} = self.app;

const Entity = require("@obsidianjs/data-store/src/entity");

require("helpers/serializers/babylon-vector3");

/**
 * Board Entity in Furnitures module
 */
const MovableshelfEntity = Object3DEntity.$extend({

    __name__: "movableshelf",

    __init__(params = {}) {

        this.$data.isCoveredByDoor = params.isCoveredByDoor || false;
        this.$data.isCoveredByBackboard = params.isCoveredByBackboard || false;
        this.$data.idRod = params.idRod || false;

        this.mesh = null;
        this._bbox2 = null;

        this.$super(params);
        this._debugMeshBoundingBox = false;
    },

    setIdRod: cu.setter,
    getIdRod: cu.getter,

    getHasRod() {
        return Boolean(this.$data.idRod);
    },

    getOrientation() {
        return ORIENTATION_HORIZONTAL;
    },

    setIsCoveredByBackboard(isCoveredByBackboard) {
        this.$data.isCoveredByBackboard = isCoveredByBackboard;
    },
    getIsCoveredByBackboard: cu.getter,

    setIsCoveredByDoor(isCoveredByDoor) {
        this.$data.isCoveredByDoor = isCoveredByDoor;
    },
    getIsCoveredByDoor: cu.getter,


    setPositionX(x) {
        this.$super(x);
        if (this.hasRod) {
            RodHelper.transformFromParentEntity(this);
        }
    },

    setPositionY(y) {
        this.$super(y);
        if (this.hasRod) {
            RodHelper.transformFromParentEntity(this);
        }
    },

    setPositionZ(z, refreshModels = true) {
        if (this.isCoveredByDoor) {
            const furnitureEntity = modules.dataStore.getEntity(this.getFurnitureId());
            this.$data.position.z = furnitureEntity.position.z + DISTANCE_BEHIND_DOOR;
        } else {
            this.$data.position.z = z;
        }
        if (!refreshModels) return;
        this.updateMeshes();

        if (this.hasRod) {
            RodHelper.positionZFromParentEntity(this);
        }
    },

    setScalingZ(scaleZ, refreshModels = true) {
        this.$data.scaling.z = scaleZ;
        const furnitureEntity = modules.dataStore.getEntity(this.getFurnitureId());
        if (this.isCoveredByDoor) {
            this.$data.scaling.z = furnitureEntity.scaling.z - DISTANCE_BEHIND_DOOR;
        }
        if (this.isCoveredByBackboard) {
            this.$data.scaling.z -= config.get("backboard_z_shift") + BOARD_THICKNESS;
        }

        if (!refreshModels) return;
        this.updateMeshes();

        if (this.hasRod) {
            RodHelper.transformFromParentEntity(this);
            RodHelper.positionZFromParentEntity(this);
        }
    },

    getBbox2() {
        if (!this._bbox2 && this.mesh) {
            this.updateBbox2();
        }
        return this._bbox2;
    },

    setBbox2(bbox2) {
        this._bbox2 = bbox2;
    },

    updateBbox2() {
        const bbox2Mesh = BBox2Helper.getBBox2FromMesh(this.mesh);
        const sideHeight = 32 * 2.5;
        this._bbox2 = new BBox2(
            new Vector2(this._container.position.x + bbox2Mesh.p1.x, this._container.position.y - sideHeight - ROD_RADIUS_HALF),
            new Vector2(this._container.position.x + bbox2Mesh.p2.x, this._container.position.y + sideHeight - ROD_RADIUS_HALF)
        );

        if (this._debugMeshBoundingBox) {
            this.meshBoundingBox.setBoundingInfo(
                new BoundingInfo(
                    new Vector3(this._bbox2.p1.x, this._bbox2.p1.y, 0),
                    new Vector3(this._bbox2.p2.x, this._bbox2.p2.y, this.scaling.z)
                )
            );
        }
        this.meshBoundingBox.showBoundingBox = this._debugMeshBoundingBox;
    },

    updateMeshes() {
        if (this.mesh) {
            this.mesh.scaling = this.$data.scaling;
            this.mesh.updateLinesSystem();
        }
    },

    getCenterAtRight() {
        const center = this.$data.position.clone();
        center.x += this.$data.scaling.x;
        center.y += this.$data.scaling.y * 0.5;
        return center;
    },
});

Entity.$register(MovableshelfEntity);
export default MovableshelfEntity;
