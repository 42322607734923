import self from "../../index";
import MeasureHelper from "./measure-helper";
import MeasureController from "./measure-controller";

const { events } = self.app;
const data = {
    listenersAdded: false,
};

function onMeasureHideAll() {
    MeasureController.showMeasure = false;
    events.emit("measures:updated", []);
}

function onMeasureShowAll() {
    MeasureController.showMeasure = true;
    events.emit("measures:updated", MeasureHelper.getAllMeasures());
}

const MeasureEventsManager = {
    addEventListeners() {
        if (data.listenersAdded) return;
        events.on("@vuejs.measures:hideAll", onMeasureHideAll);
        events.on("@vuejs.measures:showAll", onMeasureShowAll);

        data.listenersAdded = true;
    },
};

export default MeasureEventsManager;
