import ConstraintsManager from "../constraints-manager";
import AccessorydrawerEntityBuilder from "./accessorydrawer-entity-builder";
import AccessorydrawerMeshBuilder from "./accessorydrawer-mesh-builder";

import self from "../../index";
import AccessorydrawerEventsManager from "./accessorydrawer-events-manager";
import AccessorydrawerATool from "./accessorydrawer-atool";
import constraints, { DRAWER_CONSTRAINTS } from "../constraints";
import AccessorydrawerHelper from "./accessorydrawer-helper";
import DrawerHelper from "../drawer/drawer-helper";
import furnitureUiButtons from "../furniture/furniture-ui-buttons";
import DrawerRunnerHelper from "../drawer/drawer-runner-helper";
import { ACCESSORYDRAWER, DRAWER_RUNNER_WEIGHT_1 } from "../constants";

const {
    events,
    modules,
} = self.app;

const data = {
    atool: new AccessorydrawerATool(),
    currentAccessorydrawerEntity: null,
};

const AccessorydrawerController = {

    get atool() {
        return data.atool;
    },

    get currentAccessorydrawerEntity() {
        return data.currentAccessorydrawerEntity;
    },

    set currentAccessorydrawerEntity(accessorydrawerEntity) {
        data.currentAccessorydrawerEntity = accessorydrawerEntity;
    },

    createAccessorydrawer(frameEntity) {
        const canAddAccessorydrawer = ConstraintsManager.canAddDrawer(frameEntity);
        if (!canAddAccessorydrawer.isAdapted) {
            events.emit("show:modal", {
                text: DRAWER_CONSTRAINTS.message,
                isInfo: true,
            });
            return;
        }

        if (!DrawerRunnerHelper.canAddToFrame(frameEntity, ACCESSORYDRAWER, DRAWER_RUNNER_WEIGHT_1)) {
            return;
        }

        if (!AccessorydrawerHelper.canAddToFrame(frameEntity)) return;

        const accessorydrawerEntity = AccessorydrawerEntityBuilder.build(frameEntity);
        accessorydrawerEntity.quantity = DrawerHelper.getMinimumQuantity(accessorydrawerEntity, DRAWER_CONSTRAINTS);

        frameEntity.idAccessorydrawer = accessorydrawerEntity.id;

        modules.dataStore.addEntity(accessorydrawerEntity, "accessorydrawer");
        AccessorydrawerController.selectAccessorydrawer(accessorydrawerEntity);
        events.emit("furniture:edited");
        furnitureUiButtons.updateButtons();
    },

    generateDrawerMeshAndMaterial(accessorydrawerEntity) {
        const furnitureEntity = modules.dataStore.getEntity(accessorydrawerEntity.getFurnitureId());
        AccessorydrawerMeshBuilder.buildDefault(accessorydrawerEntity, furnitureEntity.container);
        AccessorydrawerHelper.updateMeshScaleAndPosition(accessorydrawerEntity);

        if (furnitureUiButtons.isUiMainTabFinishing) {
            AccessorydrawerHelper.applyFinishMaterial(accessorydrawerEntity);
        } else {
            AccessorydrawerHelper.applyConstructionMaterial(accessorydrawerEntity);
        }
    },

    deleteAccessorydrawerFromFrameEntityId(frameEntityId) {
        const frameEntity = modules.dataStore.getEntity(frameEntityId);
        if (!frameEntity) return;
        AccessorydrawerController.deleteAccessorydrawer(frameEntity);
    },

    deleteAccessorydrawer(frameEntity) {
        const accessorydrawerEntity = modules.dataStore.getEntity(frameEntity.idAccessorydrawer);
        if (!accessorydrawerEntity) return;

        frameEntity.idAccessorydrawer = null;
        if (data.currentAccessorydrawerEntity === accessorydrawerEntity) {
            data.currentAccessorydrawerEntity = null;
        }
        modules.dataStore.removeEntity(accessorydrawerEntity);

        events.emit("furniture:edited");
        AccessorydrawerEventsManager.emitUpdated();
        furnitureUiButtons.updateButtons();
        events.emit("drawer:deleted");
    },

    selectAccessorydrawerById(id) {
        const accessorydrawerEntity = modules.dataStore.getEntity(id);
        AccessorydrawerController.selectAccessorydrawer(accessorydrawerEntity);
    },


    selectAccessorydrawer(accessorydrawerEntity) {
        if (data.currentAccessorydrawerEntity) {
            AccessorydrawerHelper.applyConstructionMaterial(data.currentAccessorydrawerEntity);
        }
        data.currentAccessorydrawerEntity = accessorydrawerEntity;

        if (!accessorydrawerEntity) return;

        AccessorydrawerHelper.applySelectedMaterial(accessorydrawerEntity);
        modules.toolManager.controller.activateToolAlone(data.atool.tool_name);
        AccessorydrawerEventsManager.emitUpdated();
    },

    updateQuantity(quantity) {
        if (!data.currentAccessorydrawerEntity) return;

        const height = Math.ceil(data.currentAccessorydrawerEntity.scaling.y / quantity);
        if (height >= constraints.MIN_DRAWER_HEIGHT.min && height <= constraints.MAX_DRAWER_HEIGHT.max) {
            data.currentAccessorydrawerEntity.quantity = quantity;
            AccessorydrawerHelper.updateMeshScaleAndPosition(data.currentAccessorydrawerEntity);
            AccessorydrawerHelper.rebuildClones(data.currentAccessorydrawerEntity);
            AccessorydrawerController.selectAccessorydrawer(data.currentAccessorydrawerEntity);
            events.emit("furniture:edited");
            AccessorydrawerEventsManager.emitUpdated();
        } else {
            AccessorydrawerEventsManager.emitRejected(height <= constraints.MIN_DRAWER_HEIGHT.min);
        }
    },

    getCurrentAccessorydrawerEntityCopyForUi() {
        if (!data.currentAccessorydrawerEntity) {
            return {
                id: null,
                quantity: null,
                unitUtilSize: null,
            };
        }
        return {
            id: data.currentAccessorydrawerEntity.id,
            quantity: data.currentAccessorydrawerEntity.quantity,
            unitUtilSize: data.currentAccessorydrawerEntity.unitUtilSize,
        };
    },

    hideAll() {
        const accessorydrawerEntities = modules.dataStore.listEntities("accessorydrawer");
        for (let i = 0; i < accessorydrawerEntities.length; i++) {
            const accessorydrawerEntity = accessorydrawerEntities[i];
            accessorydrawerEntity.isVisible = false;
            accessorydrawerEntity.groupInstances.forEach((group) => {
                group.getChildMeshes().forEach((mesh) => {
                    mesh.isVisible = false;
                });
            });
        }
    },

    showAll() {
        const accessorydrawerEntities = modules.dataStore.listEntities("accessorydrawer");
        for (let i = 0; i < accessorydrawerEntities.length; i++) {
            const accessorydrawerEntity = accessorydrawerEntities[i];
            accessorydrawerEntity.isVisible = true;
            accessorydrawerEntity.groupInstances.forEach((group) => {
                group.getChildMeshes().forEach((mesh) => {
                    mesh.isVisible = true;
                });
            });
        }
    },

    clearAllFromFurnitureId(furnitureEntityId) {
        const accessorydrawerEntities = modules.dataStore.listEntities("accessorydrawer");
        accessorydrawerEntities.forEach((accessorydrawerEntity) => {
            if (accessorydrawerEntity.furnitureId === furnitureEntityId) {
                const frameEntity = modules.dataStore.getEntity(accessorydrawerEntity.idParent);
                frameEntity.idAccessorydrawer = null;
                modules.dataStore.removeEntity(accessorydrawerEntity);
            }
        });
    },

    switchMaterialToConstruction() {
        modules.dataStore.listEntities("accessorydrawer").forEach((accessorydrawerEntity) => {
            AccessorydrawerHelper.applyConstructionMaterial(accessorydrawerEntity);
        });
    },

    switchMaterialsToFinition() {
        modules.dataStore.listEntities("accessorydrawer").forEach((accessorydrawerEntity) => {
            AccessorydrawerHelper.applyFinishMaterial(accessorydrawerEntity);
        });
    },

    updateFromHistoryChange() {
        modules.dataStore.listEntities("accessorydrawer").forEach((accessorydrawerEntity) => {
            DrawerHelper.updateMeshScaleAndPosition(accessorydrawerEntity);
            AccessorydrawerHelper.rebuildClones(accessorydrawerEntity);
        });
    },
};

export default AccessorydrawerController;
