import cu from "vendors/class-utils";
import {
    DISTANCE_BEHIND_DOOR,
    ORIENTATION_HORIZONTAL,
    ORIENTATION_VERTICAL,
} from "../constants";

import self from "../../index";
import Object3DEntity from "../entity-mixins/object3D-entity";

const {
    modules,
} = self.app;

const Entity = require("@obsidianjs/data-store/src/entity");

require("helpers/serializers/babylon-vector3");

/**
 * Board Entity in Furnitures module
 */
const BoardEntity = Object3DEntity.$extend({

    __name__: "board",

    __init__(params = {}) {

        this.$data.hasAllFacesVisible = params.hasAllFacesVisible || false;

        // false is board in frame, true is a furniture block's part
        this.$data.isBlockPart = params.isBlockPart || false;

        // vertical or horizontal
        this.$data.orientation = params.orientation;

        this.$data.isCoveredByDoor = params.isCoveredByDoor || false;

        this.mesh = null;

        this.$super(params);
    },

    setOrientation: cu.setter,
    getOrientation: cu.getter,

    setHasAllFacesVisible: cu.setter,
    getHasAllFacesVisible: cu.getter,

    setIsBlockPart: cu.setter,
    getIsBlockPart: cu.getter,

    setIsVisible(isVisible) {
        this._isVisible = isVisible;
        if (!this.mesh) return;
        this.mesh.isVisible = isVisible;
        this.mesh.linesSystem.isVisible = isVisible;
        this.mesh.getChildren().forEach((child) => {
            child.isVisible = isVisible;
        });
    },

    setIsCoveredByDoor(isCoveredByDoor) {
        this.$data.isCoveredByDoor = isCoveredByDoor;
        const furnitureEntity = modules.dataStore.getEntity(this.getFurnitureId());
        if (furnitureEntity) {
            this.setPositionZ(furnitureEntity.getPosition().z);
            this.setScalingZ(furnitureEntity.getScaling().z);
        }
    },
    getIsCoveredByDoor: cu.getter,

    updateMeshes() {
        if (!this.mesh) return;
        this.mesh.scaling = this.$data.scaling.clone();
        this.mesh.updateLinesSystem();
        this.mesh.updateUVScaling();
    },

    setPositionZ(z) {
        if (this.getIsCoveredByDoor()) {
            this.$data.position.z = DISTANCE_BEHIND_DOOR;
        } else {
            this.$data.position.z = z;
        }
        this.updateContainer();
    },

    setScalingZ(scaleZ) {
        if (this.getIsCoveredByDoor()) {
            const furnitureEntity = modules.dataStore.getEntity(this.getFurnitureId());
            this.$data.scaling.z = furnitureEntity.getScaling().z - DISTANCE_BEHIND_DOOR;
        } else {
            this.$data.scaling.z = scaleZ;
        }
        this.updateMeshes();
    },

    isVertical() {
        return this.$data.orientation === ORIENTATION_VERTICAL;
    },

    isHorizontal() {
        return this.$data.orientation === ORIENTATION_HORIZONTAL;
    },
});

Entity.$register(BoardEntity);
export default BoardEntity;
