import { MeshBuilder, TransformNode, Vector3 } from "@babylonjs/core";
import { ACCESSORYDRAWER_SPACE_DEPTH, BOARD_THICKNESS } from "../constants";
import { ACCESSORYDRAWER_MESHPART_NAMES, MESHPART_BACK, MESHPART_BOTTOM, MESHPART_FRONT, MESHPART_FRONT_LINES, MESHPART_LEFT, MESHPART_RIGHT } from "./accessorydrawer-entity";
import AccessorydrawerMesh from "./accessorydrawer-mesh";
import AccessorydrawerHelper from "./accessorydrawer-helper";

const AccessorydrawerMeshBuilder = {

    /**
     *
     * @param {DoorEntity} accessorydrawerEntity
     * @param {TransformNode} parent : furnitureEntity.container
     */
    buildDefault(accessorydrawerEntity, parent) {
        accessorydrawerEntity.container.parent = parent;

        AccessorydrawerMeshBuilder.createMeshesRef(accessorydrawerEntity);
        AccessorydrawerMeshBuilder.adjustMeshes(accessorydrawerEntity);
        AccessorydrawerHelper.applyConstructionMaterial(accessorydrawerEntity);
    },

    // All meshes references used for instancing
    createMeshesRef(accessorydrawerEntity) {
        for (let i = 0; i <= 4; i++) {
            const meshPart = new AccessorydrawerMesh(accessorydrawerEntity);
            meshPart.name = ACCESSORYDRAWER_MESHPART_NAMES[i];
            meshPart.parent = accessorydrawerEntity.container;
            meshPart.metadata = Object.assign(meshPart.metadata || {}, {
                accessorydrawerEntity,
            });
            meshPart.isPickable = false;
            accessorydrawerEntity.addMeshRef(meshPart);
        }
        AccessorydrawerMeshBuilder.buildFrontLines(accessorydrawerEntity);
    },

    buildFrontLines(accessorydrawerEntity) {
        const lines = [];
        lines.push(
            [
                new Vector3(0, 0, 0),
                new Vector3(1, 0, 0),
                new Vector3(1, 0, 1),
                new Vector3(0, 0, 1),
                new Vector3(0, 0, 0),
            ],
            [
                new Vector3(0, 1, 0),
                new Vector3(1, 1, 0),
                new Vector3(1, 1, 1),
                new Vector3(0, 1, 1),
                new Vector3(0, 1, 0),
            ],
        );
        const lineSystem = MeshBuilder.CreateLineSystem(MESHPART_FRONT_LINES, {
            lines,
        });
        lineSystem.isPickable = false;
        accessorydrawerEntity.addMeshRef(lineSystem);
    },

    adjustMeshes(accessorydrawerEntity) {
        const meshFront = accessorydrawerEntity.meshesRefByName[MESHPART_FRONT];
        meshFront.scaling.z = BOARD_THICKNESS;
        meshFront.position.z = ACCESSORYDRAWER_SPACE_DEPTH;

        const meshBottom = accessorydrawerEntity.meshesRefByName[MESHPART_BOTTOM];
        meshBottom.scaling.y = BOARD_THICKNESS;
        meshBottom.position.y = BOARD_THICKNESS;
        meshBottom.position.z = BOARD_THICKNESS + ACCESSORYDRAWER_SPACE_DEPTH;

        const meshBack = accessorydrawerEntity.meshesRefByName[MESHPART_BACK];
        meshBack.scaling.z = BOARD_THICKNESS;

        const meshLeft = accessorydrawerEntity.meshesRefByName[MESHPART_LEFT];
        meshLeft.position.z = BOARD_THICKNESS + ACCESSORYDRAWER_SPACE_DEPTH;
        meshLeft.scaling.x = BOARD_THICKNESS;

        const meshRight = accessorydrawerEntity.meshesRefByName[MESHPART_RIGHT];
        meshRight.position.z = BOARD_THICKNESS + ACCESSORYDRAWER_SPACE_DEPTH;
        meshRight.scaling.x = BOARD_THICKNESS;

        const frontLines = accessorydrawerEntity.meshesRefByName[MESHPART_FRONT_LINES];
        frontLines.scaling.z = BOARD_THICKNESS;
        frontLines.position.z = ACCESSORYDRAWER_SPACE_DEPTH;
    },

    // A Group is a set of instances for a single accessorydrawer
    // A Group contains instance of front, back, left, right, bottom and lines
    // Each instance has reference scaling and position of the original mesh
    createGroupWithInstances(accessorydrawerEntity) {
        for (let i = 0; i < accessorydrawerEntity.quantity; i++) {
            const group = new TransformNode("accessorydrawer_group_" + i);
            group.parent = accessorydrawerEntity.container;


            for (let j = 0; j < accessorydrawerEntity.meshesRef.length; j++) {
                const meshRef = accessorydrawerEntity.meshesRef[j];
                const clonedMesh = meshRef.clone(`accessorydrawer_clone_${i}_${j}`, group);
                clonedMesh.scaling = meshRef.scaling;
                clonedMesh.position = meshRef.position;
                if (meshRef.name !== MESHPART_FRONT_LINES) {
                    clonedMesh.isVisible = false;
                    clonedMesh.name = MESHPART_FRONT_LINES;
                } else if (accessorydrawerEntity.isVisible) {
                    clonedMesh.isVisible = true;
                }
                meshRef.isVisible = false;
            }

            accessorydrawerEntity.groupInstances.push(group);
        }
    },
};

export default AccessorydrawerMeshBuilder;
