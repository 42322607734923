import MaterialManager from "../materials/material-manager";
import RodMesh from "./rod-mesh";
import RodSupportMesh from "./rod-support-mesh";

const RodMeshBuilder = {

    /**
     *
     * @param {BoardEntity} movableshelfEntity
     * @param {TransformNode} parent : furnitureEntity.container
     */
    buildDefault(rodEntity, parent) {
        rodEntity.container.parent = parent;

        const rodMesh = new RodMesh(rodEntity);
        rodMesh.isPickable = false;
        rodMesh.scaling = rodEntity.getScaling().clone();
        rodMesh.parent = rodEntity.container;
        rodMesh.metadata = Object.assign(rodMesh.metadata || {}, {
            rodEntity,
        });
        rodEntity.mesh = rodMesh;
        rodMesh.material = MaterialManager.chromeMaterial;

        const rodMesh2 = new RodSupportMesh();
        rodMesh2.isPickable = false;
        rodMesh2.material = MaterialManager.chromeMaterial;
        rodMesh2.parent = rodEntity.container;
        rodEntity.mesh2 = rodMesh2;

        const rodMesh3 = new RodSupportMesh();
        rodMesh3.isPickable = false;
        rodMesh3.material = MaterialManager.chromeMaterial;
        rodEntity.mesh3 = rodMesh3;
        rodMesh3.parent = rodEntity.container;

        return rodMesh;
    },
};

export default RodMeshBuilder;
