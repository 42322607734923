import {
    Matrix,
} from "@babylonjs/core";
import FrameController from "../frame/frame-controller";
import self from "../../index";
import FrameMesh from "../frame/frame-mesh";
import AccessorydrawerController from "./accessorydrawer-controller";
import {
    ACCESSORYDRAWER_SPACE_DEPTH,
    ACCESSORYDRAWER_SPACE_HEIGHT,
    BOARD_THICKNESS,
    DRAWER_NONE,
} from "../constants";
import { ACCESSORYDRAWER_CONSTRAINTS } from "../constraints";
import { MESHPART_BACK, MESHPART_BOTTOM, MESHPART_FRONT, MESHPART_FRONT_LINES, MESHPART_LEFT, MESHPART_RIGHT } from "./accessorydrawer-entity";
import DrawerHelper from "../drawer/drawer-helper";
import MaterialManager from "../materials/material-manager";
import AccessorydrawerMesh from "./accessorydrawer-mesh";
import AccessorydrawerMeshBuilder from "./accessorydrawer-mesh-builder";
import AccessorydrawerMaterialsBuilder from "./accessorydrawer-materials-builder";
import DrawerComposition from "../drawer/drawer-composition";

const {
    modules,
    events,
} = self.app;

const AccessorydrawerHelper = {
    actionToAccessorydrawer(event, type) {
        if (!FrameController.isActiveTool()) return;
        const {
            scene,
        } = modules.obsidianBabylonEngine;
        const ray = scene.createPickingRay(event.layerX, event.clientY, Matrix.Identity(), scene.activeCamera);
        const hit = scene.pickWithRay(ray);
        if (!(hit.pickedMesh instanceof FrameMesh)) return;
        const frameEntity = modules.dataStore.getEntity(hit.pickedMesh.metadata.frameId);
        if (!frameEntity) return;
        switch (type) {
            case DRAWER_NONE:
                AccessorydrawerController.deleteAccessorydrawer(frameEntity);
                break;

            default:
                if (AccessorydrawerHelper.canAddToFrame(frameEntity)) {
                    AccessorydrawerController.createAccessorydrawer(frameEntity, type);
                } else {
                    events.emit("notification:message", {
                        title: "Placement incorrecte",
                        text: "Emplacement déjà occupé par un autre élément.",
                        status: "error",
                    });
                }
                break;
        }
    },

    canAddToFrame(frameEntity) {
        return !frameEntity.idMovableshelfs.length &&
            !frameEntity.idPulloutshelfs.length &&
            !frameEntity.idDrawer &&
            !frameEntity.idAccessorydrawer &&
            !frameEntity.idRod;
    },

    setIsCoveredByBackboard: (frameEntity, isCovered) => {
        const accessorydrawerEntity = modules.dataStore.getEntity(frameEntity.idAccessorydrawer);
        accessorydrawerEntity.isCoveredByBackboard = isCovered;
        accessorydrawerEntity.position = frameEntity.position.clone();
        accessorydrawerEntity.scaling = frameEntity.scaling.clone();
    },

    setIsCoveredByDoor: (frameEntity, isCovered) => {
        const accessorydrawerEntity = modules.dataStore.getEntity(frameEntity.idAccessorydrawer);
        accessorydrawerEntity.isCoveredByDoor = isCovered;
        accessorydrawerEntity.position = frameEntity.position.clone();
        accessorydrawerEntity.scaling = frameEntity.scaling.clone();
        DrawerComposition.updateDrawerComposition(accessorydrawerEntity);
    },

    updateMeshScaleAndPosition(accessorydrawerEntity) {
        DrawerHelper.adjustQuantityAfterFrameScaled(accessorydrawerEntity, ACCESSORYDRAWER_CONSTRAINTS);

        const height = Math.floor(accessorydrawerEntity.scaling.y / accessorydrawerEntity.quantity);

        for (let i = 0; i < accessorydrawerEntity.meshesRef.length; i++) {
            const mesh = accessorydrawerEntity.meshesRef[i];
            switch (mesh.name) {
                case MESHPART_FRONT:
                    mesh.scaling.x = accessorydrawerEntity.scaling.x;
                    mesh.scaling.y = height - ACCESSORYDRAWER_SPACE_HEIGHT;
                    break;
                case MESHPART_BOTTOM:
                    mesh.scaling.x = accessorydrawerEntity.scaling.x;
                    mesh.scaling.z = accessorydrawerEntity.scaling.z - BOARD_THICKNESS * 2 - (accessorydrawerEntity.isCoveredByBackboard ? BOARD_THICKNESS : 0) - ACCESSORYDRAWER_SPACE_DEPTH;
                    break;

                case MESHPART_BACK:
                    mesh.scaling.x = accessorydrawerEntity.scaling.x;
                    mesh.scaling.y = height - ACCESSORYDRAWER_SPACE_HEIGHT * 2;
                    mesh.position.z = accessorydrawerEntity.scaling.z - BOARD_THICKNESS * 2 - (accessorydrawerEntity.isCoveredByBackboard ? BOARD_THICKNESS : 0);
                    break;
                case MESHPART_LEFT:
                case MESHPART_RIGHT:
                    mesh.scaling.z = accessorydrawerEntity.scaling.z - BOARD_THICKNESS * 2 - (accessorydrawerEntity.isCoveredByBackboard ? BOARD_THICKNESS * 2 : 0) - ACCESSORYDRAWER_SPACE_DEPTH;
                    mesh.scaling.y = height - ACCESSORYDRAWER_SPACE_HEIGHT * 2;
                    if (mesh.name === MESHPART_RIGHT) {
                        mesh.position.x = accessorydrawerEntity.scaling.x - BOARD_THICKNESS;
                    }
                    break;
                case MESHPART_FRONT_LINES:
                    mesh.position.x = 1;
                    mesh.scaling.x = accessorydrawerEntity.scaling.x - 1;
                    mesh.scaling.y = height - ACCESSORYDRAWER_SPACE_HEIGHT;
                    break;
                default:
                    break;
            }

            mesh.isVisible = false;
        }


        for (let i = 0; i < accessorydrawerEntity.groupInstances.length; i++) {
            const group = accessorydrawerEntity.groupInstances[i];
            group.position.y = i * height;
        }
        DrawerComposition.updateDrawerComposition(accessorydrawerEntity);
    },

    deleteAccessorydrawerFromFrameEntityId(frameEntityId) {
        const frameEntity = modules.dataStore.getEntity(frameEntityId);
        if (!frameEntity) return;
        AccessorydrawerController.deleteAccessorydrawer(frameEntity);
    },

    rebuildClones(accessorydrawerEntity) {
        const { groupInstances } = accessorydrawerEntity;
        groupInstances.forEach((group) => {
            group.dispose();
        });
        groupInstances.length = 0;
        AccessorydrawerMeshBuilder.createGroupWithInstances(accessorydrawerEntity);
        AccessorydrawerHelper.updateMeshScaleAndPosition(accessorydrawerEntity);
    },

    updateMeshesRefMaterial(accessorydrawerEntity, materialType) {
        const { meshesRef } = accessorydrawerEntity;
        let material;
        switch (materialType) {
            case "construction":
                material = MaterialManager.mainConstructionMaterial;
                break;
            case "selected":
                material = AccessorydrawerMaterialsBuilder.selectedMaterial;
                break;
            default:
                break;
        }
        meshesRef.forEach((mesh) => {
            if (mesh instanceof AccessorydrawerMesh) {

                const faces = [mesh.faceFront, mesh.faceBack, mesh.faceLeft, mesh.faceRight, mesh.faceTop, mesh.faceBottom];
                faces.forEach((face) => {
                    if (face) {
                        if (materialType === "finish") {
                            material = MaterialManager.mainFinishMaterial90;
                            if ((face === mesh.faceTop || face === mesh.faceBottom) && mesh.scaling.z > mesh.scaling.x) {
                                material = MaterialManager.mainFinishMaterial;
                            }
                        }
                        face.material = material;
                    }
                });
                if (accessorydrawerEntity.isVisible) {
                    mesh.setVisible(true);
                    mesh.isVisible = false;
                } else {
                    mesh.setVisible(false);
                }
                if (materialType === "finish") {
                    mesh.updateUVScaling();
                }
            }

        });
    },

    applySelectedMaterial(accessorydrawerEntity) {
        AccessorydrawerHelper.updateMeshesRefMaterial(accessorydrawerEntity, "selected");
        AccessorydrawerHelper.rebuildClones(accessorydrawerEntity);
    },

    applyFinishMaterial(accessorydrawerEntity) {
        AccessorydrawerHelper.updateMeshesRefMaterial(accessorydrawerEntity, "finish");
        AccessorydrawerHelper.rebuildClones(accessorydrawerEntity);
    },

    applyConstructionMaterial(accessorydrawerEntity) {
        AccessorydrawerHelper.updateMeshesRefMaterial(accessorydrawerEntity, "construction");
        AccessorydrawerHelper.rebuildClones(accessorydrawerEntity);
    },
};

export default AccessorydrawerHelper;
