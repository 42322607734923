import FrameMesh from "./frame-mesh";
import self from "../../index";

const {
    modules,
} = self.app;

const FrameMeshBuilder = {

    build(frameEntity) {
        const furniture = modules.dataStore.getEntity(frameEntity.getFurnitureId());
        frameEntity.container.parent = furniture.container;
        const mesh = new FrameMesh("frameMesh");
        frameEntity.mesh = mesh;
        mesh.parent = frameEntity.container;
        mesh.metadata.frameId = frameEntity.id;

        return mesh;
    },

};

export default FrameMeshBuilder;
