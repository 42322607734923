import {
    ActionManager,
    ExecuteCodeAction,
    Matrix,
    MeshBuilder,
    StandardMaterial,
    Vector3,
} from "@babylonjs/core";
import {
    clamp,
} from "../../../../helpers/utils";
import self from "../../index";
import BoardController from "./board-controller";
import {
    RESIZE_FROM_BOTTOM,
    RESIZE_FROM_LEFT,
    RESIZE_FROM_RIGHT,
    RESIZE_FROM_TOP,
} from "../constants";
import ConstraintsManager from "../constraints-manager";
import FurnituresListController from "../furnitures-list-controller";
import TransformManager from "../transformManager";
import MeasureController from "../measure/measure-controller";

const {
    modules,
    events,
} = self.app;

const data = {
    initialized: false,
    moving: false,
    planeFront: null,
    planeBack: null,
    scene: null,
    canvas: null,
    startPosition: null,
    endPosition: null,
    delta: null,
    currentFurnitureEntity: null,
    currentBoardEntity: null,
    currentSide1Entity: null,
    currentSide2Entity: null,
    move: null,
    positionLimits: {
        min: 0,
        max: 0,
    },
};

const BoardDisplacement = {
    initialize() {
        if (data.initialized) return;

        data.scene = modules.obsidianBabylonEngine.scene;
        data.canvas = modules.obsidianBabylonEngine.canvas;

        data.planeFront = MeshBuilder.CreatePlane("dragZoneFront", {
            size: 20000,
        }, data.scene);
        data.planeFront.position.z = -1;
        data.planeFront.material = new StandardMaterial("dragZoneMaterial", data.scene);
        data.planeFront.material.alpha = 0;
        data.planeFront.isVisible = false;
        data.planeBack = data.planeFront.clone("dragZoneBack");
        data.planeBack.rotation.y = Math.PI;

        const OnPointerOutTrigger = new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, BoardDisplacement.stop);
        const OnPickUpTrigger = new ExecuteCodeAction(ActionManager.OnPickUpTrigger, BoardDisplacement.stop);

        data.planeFront.actionManager = new ActionManager();
        data.planeFront.actionManager.registerAction(OnPickUpTrigger);
        data.planeFront.actionManager.registerAction(OnPointerOutTrigger);
        data.planeBack.actionManager = new ActionManager();
        data.planeBack.actionManager.registerAction(OnPickUpTrigger);
        data.planeBack.actionManager.registerAction(OnPointerOutTrigger);

        data.initialized = true;
    },

    get plane() {
        return data.plane;
    },

    start() {
        events.emit("camera:lock");
        const {
            currentFurnitureEntity,
        } = FurnituresListController.getInstance();

        data.moving = true;

        data.currentFurnitureEntity = currentFurnitureEntity;
        data.currentBoardEntity = BoardController.currentBoardEntity;
        const frameEntityParent = modules.dataStore.getEntity(data.currentBoardEntity.getIdParent());
        data.currentSide1Entity = modules.dataStore.getEntity(frameEntityParent.getIdFrameLeftOrBottom());
        data.currentSide2Entity = modules.dataStore.getEntity(frameEntityParent.getIdFrameRightOrTop());

        data.planeBack.position.z = currentFurnitureEntity.getScaling().z + 1;

        data.planeFront.isVisible = true;
        data.planeBack.isVisible = true;

        const ray = data.scene.createPickingRay(data.scene.pointerX, data.scene.pointerY, Matrix.Identity(), data.scene.activeCamera);
        const hit = data.scene.pickWithRay(ray);
        data.startPosition = new Vector3(Math.round(hit.pickedPoint.x), Math.round(hit.pickedPoint.y), 0);

        if (data.currentBoardEntity.isHorizontal()) {
            data.move = BoardDisplacement.moveY;
        } else {
            data.move = BoardDisplacement.moveX;
        }

        data.positionLimits = ConstraintsManager.getBoardDisplacementLimits(data.currentBoardEntity);

        data.canvas.addEventListener("pointermove", BoardDisplacement.onMove);
    },

    onMove(event) {
        const ray = data.scene.createPickingRay(event.layerX, event.clientY, Matrix.Identity(), data.scene.activeCamera);
        const hit = data.scene.pickWithRay(ray);
        data.endPosition = new Vector3(Math.round(hit.pickedPoint.x), Math.round(hit.pickedPoint.y), 0);
        data.endPosition.y -= data.currentFurnitureEntity.position.y;
        if (!data.endPosition) return;
        data.delta = data.endPosition.subtract(data.startPosition);
        data.move();
        MeasureController.updateMeasures(data.currentBoardEntity);
    },

    moveY() {
        data.endPosition.y = clamp(data.endPosition.y, data.positionLimits.min, data.positionLimits.max);

        const nextScalingSide1 = data.currentSide1Entity.getScaling().clone();
        const nextScalingSide2 = data.currentSide2Entity.getScaling().clone();


        if (data.endPosition.y < data.currentBoardEntity.getPosition().y) {
            nextScalingSide1.y -= data.currentBoardEntity.getPosition().y - data.endPosition.y;
            nextScalingSide2.y += data.currentBoardEntity.getPosition().y - data.endPosition.y;
        } else {
            nextScalingSide1.y += data.endPosition.y - data.currentBoardEntity.getPosition().y;
            nextScalingSide2.y -= data.endPosition.y - data.currentBoardEntity.getPosition().y;
        }

        const scalingDiffSide1 = nextScalingSide1.subtract(data.currentSide1Entity.getScaling());
        const scalingDiffSide2 = nextScalingSide2.subtract(data.currentSide2Entity.getScaling());

        TransformManager.resizeFrame(data.currentSide1Entity.id, scalingDiffSide1, RESIZE_FROM_TOP);
        TransformManager.resizeFrame(data.currentSide2Entity.id, scalingDiffSide2, RESIZE_FROM_BOTTOM);
        TransformManager.resizeDoors();
        TransformManager.resizeOverlaydrawer();
        TransformManager.resizeInsetdrawer();
        data.currentBoardEntity.setPositionY(data.endPosition.y);
    },

    moveX() {
        data.endPosition.x = clamp(data.endPosition.x, data.positionLimits.min, data.positionLimits.max);

        const nextScalingSide1 = data.currentSide1Entity.getScaling().clone();
        const nextScalingSide2 = data.currentSide2Entity.getScaling().clone();

        if (data.endPosition.x < data.currentBoardEntity.getPosition().x) {
            nextScalingSide1.x -= data.currentBoardEntity.getPosition().x - data.endPosition.x;
            nextScalingSide2.x += data.currentBoardEntity.getPosition().x - data.endPosition.x;
        } else {
            nextScalingSide1.x += data.endPosition.x - data.currentBoardEntity.getPosition().x;
            nextScalingSide2.x -= data.endPosition.x - data.currentBoardEntity.getPosition().x;
        }

        const scalingDiffSide1 = nextScalingSide1.subtract(data.currentSide1Entity.getScaling());
        const scalingDiffSide2 = nextScalingSide2.subtract(data.currentSide2Entity.getScaling());

        TransformManager.resizeFrame(data.currentSide1Entity.id, scalingDiffSide1, RESIZE_FROM_RIGHT);
        TransformManager.resizeFrame(data.currentSide2Entity.id, scalingDiffSide2, RESIZE_FROM_LEFT);
        TransformManager.resizeDoors();
        TransformManager.resizeOverlaydrawer();
        TransformManager.resizeInsetdrawer();
        data.currentBoardEntity.setPositionX(data.endPosition.x);
    },

    stop() {
        if (!data.moving) return;
        data.moving = false;
        data.planeFront.isVisible = false;
        data.planeBack.isVisible = false;
        events.emit("furniture:edited");
        data.canvas.removeEventListener("pointermove", BoardDisplacement.onMove);
        events.emit("overlaydrawer:hideAll");
        events.emit("insetdrawer:hideAll");
        events.emit("accessorydrawer:hideAll");
        events.emit("camera:unlock");
    },

};


export default BoardDisplacement;
