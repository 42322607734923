import BaseboardEntityBuilder from "./baseboard-entity-builder";
import BaseboardMeshBuilder from "./baseboard-mesh-builder";
import self from "../../index";
import {
    BOARD_THICKNESS,
} from "../constants";
import BaseboardHelper from "./baseboard-helper";

const {
    modules,
} = self.app;


const BaseboardController = {
    createBaseboard(furnitureEntity) {
        const baseboardEntity = BaseboardEntityBuilder.build(furnitureEntity);
        modules.dataStore.addEntity(baseboardEntity, "baseboard");
        furnitureEntity.setIdBaseboard(baseboardEntity.id);

        BaseboardController.updateSize(baseboardEntity.id);
    },
    generateBaseboardMeshAndMaterial(baseboardEntity) {
        const furnitureEntity = modules.dataStore.getEntity(baseboardEntity.getFurnitureId());
        BaseboardMeshBuilder.buildDefault(baseboardEntity, furnitureEntity.container);

        BaseboardHelper.applyConstructionMaterial(baseboardEntity);
    },
    deleteBaseboard(baseboardEntityId) {
        const baseboardEntity = modules.dataStore.getEntity(baseboardEntityId);
        if (!baseboardEntity) return;

        const furnitureEntity = modules.dataStore.getEntity(baseboardEntity.getFurnitureId());
        modules.dataStore.removeEntity(baseboardEntity);
        furnitureEntity.setIdBaseboard("");
    },
    updateSize(baseboardEntityId) {
        if (!baseboardEntityId) return;

        const baseboardEntity = modules.dataStore.getEntity(baseboardEntityId);
        const furnitureEntity = modules.dataStore.getEntity(baseboardEntity.getFurnitureId());

        baseboardEntity.setPositionX(furnitureEntity.getThickness());
        baseboardEntity.setPositionY(-furnitureEntity.getPosition().y);
        baseboardEntity.setPositionZ(furnitureEntity.getPosition().z);
        baseboardEntity.setScalingX(furnitureEntity.getScaling().x - BOARD_THICKNESS * 2);
        baseboardEntity.setScalingY(furnitureEntity.getPosition().y);
        baseboardEntity.setScalingZ(furnitureEntity.getThickness());

        baseboardEntity.mesh.updateUVScaling();
    },

    switchMaterialToConstruction() {
        modules.dataStore.listEntities("baseboard").forEach((baseboardEntity) => {
            BaseboardHelper.applyConstructionMaterial(baseboardEntity);
        });
    },

    switchMaterialsToFinition() {
        modules.dataStore.listEntities("baseboard").forEach((baseboardEntity) => {
            BaseboardHelper.applyFinishMaterial(baseboardEntity);
        });
    },
};

export default BaseboardController;
