/* eslint-disable max-classes-per-file */
import {
    Matrix,
} from "@babylonjs/core/Maths/math.vector";
import {
    ExecuteCodeAction,
} from "@babylonjs/core/Actions/directActions";
import {
    ActionManager,
} from "@babylonjs/core/Actions/actionManager";

import {
    ORIENTATION_HORIZONTAL,
    ORIENTATION_VERTICAL,
    TOOL_MODE_BACKBOARD,
    TOOL_MODE_DOOR,
    TOOL_MODE_SELECT,
    TOOL_MODE_SPLIT,
} from "../constants";

import FrameEntityBuilder from "./frame-entity-builder";
import FrameMeshBuilder from "./frame-mesh-builder";
import FrameMaterialBuilder from "./frame-materials-builder";
import FrameHelper from "./frame-helper";
import BackboardController from "../backboard/backboard-controller";
import FrameATool from "./frame-atool";


import self from "../../index";
import DoorHelper from "../door/door-helper";

const {
    modules,
    events,
} = self.app;


const data = {
    atool: new FrameATool(),
    currentFrameEntity: null,
    orientationForced: "",
    isDebugFrameVisible: false,
};

/**
 * Frame Controller
 * add frame ( frameEntity with mesh )
 * split frame
 * add interactivity
 *      temp : click on frame for split it, hold shift key for horizontal orientation
 */
const FrameController = {
    get atool() {
        return data.atool;
    },

    get currentFrameEntity() {
        return data.currentFrameEntity;
    },

    set currentFrameEntity(frameEntity) {
        data.currentFrameEntity = frameEntity;
    },

    get orientationForced() {
        return data.orientationForced;
    },

    set orientationForced(value) {
        data.orientationForced = value;
    },

    get isDebugFrameVisible() {
        return data.isDebugFrameVisible;
    },

    set isDebugFrameVisible(value) {
        data.isDebugFrameVisible = value;
    },

    /**
     *
     * @param {FurnitureEntity} furnitureEntity
     * @param {boolean} addToStore
     * @returns FurnitureEntity
     */
    createRootFrame(furnitureEntity, addToStore = true) {
        // add root frame to this furniture
        const rootFrameEntity = FrameEntityBuilder.buildRootFrameEntity(furnitureEntity);
        furnitureEntity.setIdFrame(rootFrameEntity.id);

        BackboardController.createNormalBackboard(rootFrameEntity);

        // add frame to store
        if (addToStore) modules.dataStore.addEntity(rootFrameEntity, "frame");

        return rootFrameEntity;
    },



    generateFrameMeshAndMaterial(frameEntity) {
        const frameMesh = FrameMeshBuilder.build(frameEntity);
        frameMesh.material = FrameMaterialBuilder.buildDefault();

        frameMesh.isPickable = true;
        if (!FrameController.isActiveTool()) {
            frameMesh.isPickable = false;
        }
        FrameController.addMouseActionsToFrameMesh(frameEntity);

        if (frameEntity.getIsSplitted()) {
            frameMesh.isVisible = false;
        }
    },

    /**
     * Add Mouse actions : over, out, pick
     * @param {FrameEntity} frameEntity
     * @returns
     */
    addMouseActionsToFrameMesh(frameEntity) {
        const {
            mesh,
        } = frameEntity;
        const {
            scene,
        } = modules.obsidianBabylonEngine;

        // Actions
        mesh.actionManager = new ActionManager();

        const OnPickTrigger = new ExecuteCodeAction(ActionManager.OnPickTrigger, (event) => {
            if (!FrameController.isActiveTool()) return;

            const ray = scene.createPickingRay(scene.pointerX, scene.pointerY, Matrix.Identity(), scene.activeCamera);
            const hit = scene.pickWithRay(ray);
            if (FrameController.isSplitToolMode()) { // usefull for tweakpane
                let orientation;
                if (FrameController.orientationForced) {
                    orientation = FrameController.orientationForced;
                } else {
                    orientation = event.sourceEvent.shiftKey ? ORIENTATION_HORIZONTAL : ORIENTATION_VERTICAL;
                }
                FrameHelper.splitFrame(frameEntity, orientation, hit.pickedPoint.floor());
            } else if (FrameController.isSelectToolMode()) {
                FrameHelper.deselectCurrentFrame();
                FrameController.currentFrameEntity = frameEntity;
                FrameHelper.updateSelectedFrameDebug(frameEntity);
                events.emit("frame:selected", frameEntity.id);
            } else if (FrameController.isDoorToolMode()) {
                DoorHelper.extendCurrentDoorToFrame(frameEntity);
            }
        });

        const OnPointerOutTrigger = new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, () => {
            mesh.actionManager.unregisterAction(OnPointerOutTrigger);
            mesh.actionManager.unregisterAction(OnPickTrigger);
        });

        const OnPointerOverTrigger = new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, () => {
            mesh.actionManager.registerAction(OnPointerOutTrigger);
            mesh.actionManager.registerAction(OnPickTrigger);
        });

        mesh.actionManager.registerAction(OnPointerOverTrigger);

        return mesh;
    },

    isSplitToolMode() {
        return FrameController.atool.isModeActive(TOOL_MODE_SPLIT);
    },

    isSelectToolMode() {
        return FrameController.atool.isModeActive(TOOL_MODE_SELECT);
    },

    isBackboardToolMode() {
        return FrameController.atool.isModeActive(TOOL_MODE_BACKBOARD);
    },

    isDoorToolMode() {
        return FrameController.atool.isModeActive(TOOL_MODE_DOOR);
    },

    isActiveTool() {
        return FrameController.atool.tool_isActive;
    },

};

export default FrameController;
