<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="38.581"
        height="38.767"
        viewBox="0 0 38.581 38.767"
    >
        <desc>Une icône de plinthe</desc>
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_695"
                      data-name="Rectangle 695"
                      width="38.581"
                      height="38.767"
                      rx="2"
                      transform="translate(0 0)"
                      fill="none"
                      stroke="#fff"
                      stroke-width="1.5"
                />
            </clipPath>
        </defs>
        <g id="icon-plinthe-full" transform="translate(0 0)" clip-path="url(#clip-path)">
            <rect id="Rectangle_691"
                  data-name="Rectangle 691"
                  width="4.063"
                  height="37.767"
                  transform="translate(0.5 0.5)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
            />
            <rect id="Rectangle_692"
                  data-name="Rectangle 692"
                  width="4.063"
                  height="37.767"
                  transform="translate(34.019 0.5)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
            />
            <rect id="Rectangle_693"
                  data-name="Rectangle 693"
                  width="29.456"
                  height="4.063"
                  rx="1"
                  transform="translate(4.563 0.5)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
            />
            <rect id="Rectangle_694"
                  data-name="Rectangle 694"
                  width="29.456"
                  height="4.063"
                  rx="1"
                  transform="translate(4.563 25.204)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
            />
            <path id="Tracé_323"
                  data-name="Tracé 323"
                  d="M0,0H29.456V9H0Z"
                  transform="translate(4.563 29.267)"
                  fill="#fff"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-width="1.5"
            />
        </g>
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "with-plinth-icon",
});
</script>
