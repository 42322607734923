<template>
    <!-- canvas template -->
    <div id="canvas-container">
        <canvas
            id="main-canvas"
            @dragover="onDragOver"
            @dragleave="onDragLeave"
            @drop="onDrop"
        />
    </div>
</template>

<script>
// We start the BabylonJs engine once the canvas html is builted (=> mounted function)
import self from "../../index";

const { events } = self.app;

export default {
    name: "main-canvas",
    data() {
        return {
            displayMatcapPreviewContainer:
                process.env.NODE_ENV === "development",
        };
    },
    mounted() {
        self.app.modules.obsidianBabylonEngine.start();
    },

    methods: {
        onDragOver(event) {
            event.preventDefault();
            events.emit("drag:over", event);
        },

        onDragLeave(event) {
            events.emit("drag:leave", event);
        },

        onDrop(event) {
            events.emit("drag:drop", event);
        },
    },
};
</script>

<!-- Basic style -->
<style lang="scss">
$width-menu: 150px;
#main-canvas {
    touch-action: none;
    height: 100%;
    width: 100%;
    background: radial-gradient(
        circle,
        rgba(251, 247, 239, 1) 33%,
        rgba(227, 227, 227, 1) 100%
    );
}
</style>
