import {
    Matrix,
    Vector3,
} from "@babylonjs/core/Maths/math.vector";
import {
    BACKBOARD_NONE,
    BACKBOARD_NORMAL,
    BACKBOARD_REMOVABLE,
    BOARD_HALF_THICKNESS,
    BOTTOM,
    DOOR_NONE,
    LEFT,
    ORIENTATION_HORIZONTAL,
    ORIENTATION_VERTICAL,
    ORIGIN_BOTTOM,
    ORIGIN_LEFT,
    ORIGIN_RIGHT,
    ORIGIN_TOP,
    RIGHT,
    TOOL_MODE_SELECT,
    TOP,
} from "../constants";

import { FRAME_CONSTRAINTS } from "../constraints";
import TransformHelper from "../helpers/transformHelper";
import self from "../../index";
import FrameController from "./frame-controller";
import FrameMesh from "./frame-mesh";
import BackboardController from "../backboard/backboard-controller";
import DoorController from "../door/door-controller";
import FrameEntityBuilder from "./frame-entity-builder";
import BoardEntityBuilder from "../board/board-entity-builder";
import TransformManager from "../transformManager";
import BoardHelper from "../board/board-helper";
import DoorHelper from "../door/door-helper";
import MeasureHelper from "../measure/measure-helper";
import MovableshelfController from "../movableshelf/movableshelf-controller";
import MovableshelfHelper from "../movableshelf/movableshelf-helper";
import PulloutshelfHelper from "../pulloutshelf/pulloutshelf-helper";
import PulloutshelfController from "../pulloutshelf/pulloutshelf-controller";

const {
    modules,
    events,
    config,
} = self.app;

const FrameHelper = {

    actionToBackboard(event, type) {
        if (!FrameController.isActiveTool()) return;
        const {
            scene,
        } = modules.obsidianBabylonEngine;
        const ray = scene.createPickingRay(event.layerX, event.clientY, Matrix.Identity(), scene.activeCamera);
        const hit = scene.pickWithRay(ray);
        if (hit.pickedMesh instanceof FrameMesh && FrameController.isBackboardToolMode()) {
            const frameEntity = modules.dataStore.getEntity(hit.pickedMesh.metadata.frameId);
            if (!frameEntity) return;
            const backboardEntity = modules.dataStore.getEntity(frameEntity.getIdBackboard());
            switch (type) {
                case BACKBOARD_REMOVABLE:
                    if (!backboardEntity) {
                        BackboardController.createRemovableBackboard(frameEntity);
                    } else {
                        backboardEntity.setIsRemovable(true);
                    }
                    break;
                case BACKBOARD_NORMAL:
                    if (!backboardEntity) {
                        BackboardController.createNormalBackboard(frameEntity);
                    } else {
                        backboardEntity.setIsRemovable(false);
                    }
                    break;
                case BACKBOARD_NONE:
                    if (backboardEntity) BackboardController.deleteBackboard(backboardEntity.id);
                    break;

                default:
                    break;
            }
        }
    },

    actionToDoor(event, type, mode) {
        if (!FrameController.isActiveTool()) return;
        const {
            scene,
        } = modules.obsidianBabylonEngine;
        const ray = scene.createPickingRay(event.layerX, event.clientY, Matrix.Identity(), scene.activeCamera);
        const hit = scene.pickWithRay(ray);
        if (hit.pickedMesh instanceof FrameMesh) {
            const frameEntity = modules.dataStore.getEntity(hit.pickedMesh.metadata.frameId);
            if (!frameEntity) return;
            switch (type) {
                case DOOR_NONE:

                    DoorController.deleteDoor(frameEntity);
                    break;

                default:
                    DoorController.createDoor(frameEntity, type, mode);
                    break;
            }
        }
    },

    actionToMovableshelf(event, params) {
        if (!FrameController.isActiveTool()) return;
        const {
            scene,
        } = modules.obsidianBabylonEngine;
        const ray = scene.createPickingRay(event.layerX, event.clientY, Matrix.Identity(), scene.activeCamera);
        const hit = scene.pickWithRay(ray);
        if (!(hit.pickedMesh instanceof FrameMesh)) return;
        const frameEntity = modules.dataStore.getEntity(hit.pickedMesh.metadata.frameId);
        if (!frameEntity) return;
        const position = new Vector3(Math.round(hit.pickedPoint.x), Math.round(hit.pickedPoint.y), Math.round(hit.pickedPoint.z));
        if (MovableshelfHelper.canAddToFrame(frameEntity, position)) {
            MovableshelfController.createMovableshelf(frameEntity, position, params);
            events.emit("furniture:edited");
        } else {
            events.emit("notification:message", {
                title: "Placement incorrecte",
                text: "Emplacement déjà occupé par un autre élément.",
                status: "error",
            });
        }
    },

    actionToPulloutshelf(event, params) {
        if (!FrameController.isActiveTool()) return;
        const {
            scene,
        } = modules.obsidianBabylonEngine;
        const ray = scene.createPickingRay(event.layerX, event.clientY, Matrix.Identity(), scene.activeCamera);
        const hit = scene.pickWithRay(ray);
        if (!(hit.pickedMesh instanceof FrameMesh)) return;
        const frameEntity = modules.dataStore.getEntity(hit.pickedMesh.metadata.frameId);
        if (!frameEntity) return;
        const position = new Vector3(Math.round(hit.pickedPoint.x), Math.round(hit.pickedPoint.y), Math.round(hit.pickedPoint.z));
        if (PulloutshelfHelper.canAddToFrame(frameEntity, position)) {
            PulloutshelfController.createPulloutshelf(frameEntity, position, params);
        } else {
            events.emit("notification:message", {
                title: "Placement incorrecte",
                text: "Emplacement déjà occupé par un autre élément.",
                status: "error",
            });
        }
    },



    actionToSplit(event, orientation) {
        if (!FrameController.isActiveTool()) return;
        const {
            scene,
        } = modules.obsidianBabylonEngine;
        const ray = scene.createPickingRay(event.layerX, event.clientY, Matrix.Identity(), scene.activeCamera);
        const hit = scene.pickWithRay(ray);
        if (hit.pickedMesh instanceof FrameMesh && FrameController.isSplitToolMode()) {
            const frameEntity = modules.dataStore.getEntity(hit.pickedMesh.metadata.frameId);
            const furnitureEntity = modules.dataStore.getEntity(frameEntity.getFurnitureId());
            const hitPosition = hit.pickedPoint.clone();
            hitPosition.subtractInPlace(furnitureEntity.getPosition());
            hitPosition.copyFrom(hitPosition.floor());
            let canSplit = FrameHelper.canSplitFrame(frameEntity, orientation, hitPosition);

            // Try to split at center if manual split is not possible
            if (!canSplit.accepted) {
                if (canSplit.distance) {
                    const { center } = frameEntity;
                    center.x = Math.round(center.x * 2) / 2;
                    center.y = Math.round(center.y * 2) / 2;
                    canSplit = FrameHelper.canSplitFrame(frameEntity, orientation, center);
                    hitPosition.copyFrom(center);
                } else {
                    events.emit("tool:frame:update");
                    events.emit("notification:message", {
                        title: "Placement impossible",
                        text: canSplit.msg,
                        status: "error",
                    });
                    return;
                }
            }

            if (!canSplit.accepted) {
                events.emit("tool:frame:update");
                events.emit("notification:message", {
                    title: "Placement impossible",
                    text: "Vous êtes trop proche d'un autre élément",
                    status: "error",
                });
                return;
            }
            FrameHelper.splitFrame(frameEntity, orientation, hitPosition);
        }
    },

    canSplitFrame(frameEntity, orientation, position) {
        if (!frameEntity) return { accepted: false };
        if (frameEntity.getIsSplitted()) return { accepted: false };

        if (frameEntity.hasMovableshelf() || frameEntity.hasPulloutshelf() || frameEntity.hasRod()) {
            return {
                msg: "Impossible d'ajouter une séparation dans une niche contenant des accessoires. Supprimer d'abord vos accessoires.",
                accepted: false,
            };
        }
        if (frameEntity.hasDrawer() || frameEntity.hasAccessorydrawer()) {
            return {
                msg: "Impossible d'ajouter une séparation dans une niche contenant des tiroirs. Supprimer d'abord vos tiroirs.",
                accepted: false,
            };
        }

        const virtualFrames = {
            frameLeft: position.x - frameEntity.position.x - BOARD_HALF_THICKNESS,
            frameRight: frameEntity.position.x + frameEntity.scaling.x - position.x - BOARD_HALF_THICKNESS,
            frameBottom: position.y - frameEntity.position.y - BOARD_HALF_THICKNESS,
            frameTop: frameEntity.position.y + frameEntity.scaling.y - position.y - BOARD_HALF_THICKNESS,
        };
        if (orientation === ORIENTATION_HORIZONTAL) {
            if (virtualFrames.frameBottom < FRAME_CONSTRAINTS.min.y || virtualFrames.frameTop < FRAME_CONSTRAINTS.min.y) return { distance: true, accepted: false };
        } else if (orientation === ORIENTATION_VERTICAL) {
            if (virtualFrames.frameLeft < FRAME_CONSTRAINTS.min.x || virtualFrames.frameRight < FRAME_CONSTRAINTS.min.x) return { distance: true, accepted: false };
        }

        return { accepted: true };
    },

    canCollapseFrame(frameEntity) {
        const boardEntity = modules.dataStore.getEntity(frameEntity.idBoard);
        const adjacentFrames = FrameHelper.getAdjacentFramesOfBoard(boardEntity);
        let canCollapse = true;
        adjacentFrames.forEach((adjacentFrameEntity) => {
            if (
                adjacentFrameEntity.hasMovableshelf() ||
                adjacentFrameEntity.hasPulloutshelf() ||
                adjacentFrameEntity.hasAccessorydrawer() ||
                adjacentFrameEntity.hasRod() ||
                adjacentFrameEntity.hasDoor() ||
                adjacentFrameEntity.hasDrawer()
            ) {
                canCollapse = false;
            }
        });
        return canCollapse;
    },

    collapseFrame(frameEntityID) {
        const frameEntity = modules.dataStore.getEntity(frameEntityID);
        const furnitureId = frameEntity.getFurnitureId();
        if (!frameEntity) return;
        if (!frameEntity.getIsSplitted()) return;
        if (!FrameHelper.canCollapseFrame(frameEntity)) {
            events.emit("notification:message", {
                title: "Impossible de supprimer",
                text: "Les niches adjacent contiennent des éléments",
                status: "error",
            });
            return;
        }

        const frameParent = modules.dataStore.getEntity(frameEntity.getIdParent());
        const frameLeftOrBottom = modules.dataStore.getEntity(frameEntity.getIdFrameLeftOrBottom());
        const frameRightOrTop = modules.dataStore.getEntity(frameEntity.getIdFrameRightOrTop());
        const board = modules.dataStore.getEntity(frameEntity.getIdBoard());

        BackboardController.deleteBackboard(frameLeftOrBottom.getIdBackboard());
        BackboardController.deleteBackboard(frameRightOrTop.getIdBackboard());

        if (!FrameHelper.disconnectFrameToHisParent(frameRightOrTop)) return;

        FrameHelper.deleteFrame(frameLeftOrBottom);

        if (FrameHelper.hasSameBoardOrientation(frameEntity, frameRightOrTop)) {
            FrameHelper.replaceFrame(frameParent, frameEntity, frameRightOrTop);
            modules.dataStore.removeEntity(frameEntityID);
        } else {
            FrameHelper.deleteFrame(frameRightOrTop);
        }

        DoorHelper.cleanLinkWithDeadFrameOfFurniture(furnitureId);

        frameEntity.setIsSplitted(false);

        BoardHelper.deleteBoard(board.id);
        BackboardController.createNormalBackboard(frameEntity);
        FrameHelper.hideAllFrames();

        if (FrameController.isDebugFrameVisible) {
            FrameHelper.refreshDebugFrames(modules.dataStore.getEntity(frameEntity.getFurnitureId()), true, 0.26, 0.1);
        }

        events.emit("furniture:edited");
    },

    /**
     * Connect 2 frames and 1 board to a parent
     *
     * @param {Object} { idFrameLeftOrBottom, idFrameRightOrTop, idBoard }
     * @param {FrameEntity} frameEntityParent
     * @returns
     */
    connectPartsToFrame({
        idFrameLeftOrBottom,
        idFrameRightOrTop,
        idBoard,
    }, frameEntityParent) {
        if (!idFrameLeftOrBottom || !idFrameRightOrTop || !idBoard) return;

        frameEntityParent.setIsSplitted(true);
        frameEntityParent.setIdBoard(idBoard);
        frameEntityParent.setIdFrameLeftOrBottom(idFrameLeftOrBottom);
        frameEntityParent.setIdFrameRightOrTop(idFrameRightOrTop);

        const frameLeftOrTop = modules.dataStore.getEntity(idFrameLeftOrBottom);
        const frameRightOrBottom = modules.dataStore.getEntity(idFrameRightOrTop);

        const boardEntity = modules.dataStore.getEntity(idBoard);
        boardEntity.setIdParent(frameEntityParent.id);

        frameLeftOrTop.setIdParent(frameEntityParent.getId());
        frameRightOrBottom.setIdParent(frameEntityParent.getId());

        if (boardEntity.isVertical()) {
            frameLeftOrTop.setScalingX(boardEntity.getPosition().x - frameEntityParent.getPosition().x);
            frameLeftOrTop.setPositionX(frameEntityParent.getPosition().x);
        } else if (boardEntity.isHorizontal()) {
            frameLeftOrTop.setScalingY(boardEntity.getPosition().y - frameEntityParent.getPosition().y);
            frameLeftOrTop.setPositionY(frameEntityParent.getPosition().y);
        }
    },

    /**
     * Delete all descendants of a frame ( board and frames )
     * @param {FrameEntity} frameEntityStart
     * @returns
     */
    deleteAllDescendants(frameEntityStart) {
        if (!frameEntityStart.getIsSplitted()) return;

        const traverseDown = (frameEntityId) => {
            const frameEntity = modules.dataStore.getEntity(frameEntityId);
            if (frameEntity) {
                if (frameEntity.getIsSplitted()) {
                    traverseDown(frameEntity.getIdFrameLeftOrBottom());
                    traverseDown(frameEntity.getIdFrameRightOrTop());
                    modules.dataStore.removeEntity(frameEntity.getIdBoard());
                }
                modules.dataStore.removeEntity(frameEntity.id);
                modules.dataStore.removeEntity(frameEntity.getIdBackboard());
            }
        };
        traverseDown(frameEntityStart.getIdFrameLeftOrBottom());
        traverseDown(frameEntityStart.getIdFrameRightOrTop());

        modules.dataStore.removeEntity(frameEntityStart.getIdBoard());
        modules.dataStore.removeEntity(frameEntityStart.getIdBackboard());

        frameEntityStart.setIsSplitted(false);
        frameEntityStart.setIdBoard(null);
        frameEntityStart.setIdFrameLeftOrBottom(null);
        frameEntityStart.setIdFrameRightOrTop(null);
    },

    /**
     * Delete frame and all descendants
     * @param {FrameEntity} frameEntity
     */
    deleteFrame(frameEntity) {
        FrameHelper.deleteAllDescendants(frameEntity);
        modules.dataStore.removeEntity(frameEntity.id);
    },

    deselectCurrentFrame() {
        if (FrameController.currentFrameEntity) {
            const currentDebugMesh = FrameController.currentFrameEntity.mesh.debugMesh;
            if (currentDebugMesh) {
                currentDebugMesh.material.alpha = 0;
                currentDebugMesh.material.diffuseColor = currentDebugMesh.metadata.defaultDiffuseColor.clone();
            }
            FrameController.currentFrameEntity = undefined;
        }
    },

    disableChildren(startFrameEntity) {
        const traverse = (frameEntityId) => {
            const frameEntity = modules.dataStore.getEntity(frameEntityId);
            frameEntity.mesh.isPickable = false;
            if (frameEntity.getIsSplitted()) {
                traverse(frameEntity.getIdFrameLeftOrBottom());
                traverse(frameEntity.getIdFrameRightOrTop());
            }
        };
        if (startFrameEntity.getIsSplitted()) {
            traverse(startFrameEntity.getIdFrameLeftOrBottom());
            traverse(startFrameEntity.getIdFrameRightOrTop());
        }
    },

    /**
     *
     * @param {FrameEntity} frameEntity
     * @returns Object {String idFrameLeftOrBottom, String idFrameRightOrTop, String idBoard}
     */
    disconnectChildrenOfFrame(frameEntity) {
        const firstChildrenIds = FrameHelper.getFirstChildrenIds(frameEntity);
        frameEntity.setIdFrameLeftOrBottom(null);
        frameEntity.setIdFrameRightOrTop(null);
        frameEntity.setIdBoard(null);
        return firstChildrenIds;
    },

    /**
     *
     * @param {FrameEntity} frameEntity
     * @returns
     */
    disconnectFrameToHisParent(frameEntity) {
        const frameParent = modules.dataStore.getEntity(frameEntity.getIdParent());
        if (!frameParent) return false;
        if (FrameHelper.isFrameLeftOrBottom(frameEntity)) {
            frameParent.setIdFrameLeftOrBottom(null);
        } else if (FrameHelper.isFrameRightOrTop(frameEntity)) {
            frameParent.setIdFrameRightOrTop(null);
        } else {
            throw new Error(`FrameHelper.disconnectFrame: frameEntity is not child of frameParent ${frameParent.id}`);
        }
        frameEntity.setIdParent(null);
        return true;
    },


    /**
     * Start from frameParent of a board
     * and traverse 2 times (left or bottom AND right or top)
     * keep only frame who touch the board
     * @param {BoardEntity} boardEntity
     * @returns
     */
    getAdjacentFramesOfBoard(boardEntity) {

        const frameEntityParent = modules.dataStore.getEntity(boardEntity.getIdParent());
        const boardOriginLeftOrBottom = boardEntity.isVertical() ? ORIGIN_RIGHT : ORIGIN_TOP;
        const boardOriginRightOrTop = boardEntity.isVertical() ? ORIGIN_LEFT : ORIGIN_BOTTOM;
        const adjacentFrames = [];

        const traverse = (startFrameEntityId, frameEntityId, boardOrigin) => {
            const startFrameEntity = modules.dataStore.getEntity(startFrameEntityId);
            const frameEntity = modules.dataStore.getEntity(frameEntityId);
            if (frameEntity.getIsSplitted()) {
                traverse(startFrameEntityId, frameEntity.getIdFrameLeftOrBottom(), boardOrigin);
                traverse(startFrameEntityId, frameEntity.getIdFrameRightOrTop(), boardOrigin);
            } else {
                switch (boardOrigin) {
                    case ORIGIN_RIGHT:
                        if (TransformHelper.isFramesAreAlignedToRightSide(frameEntity, startFrameEntity)) {
                            adjacentFrames.push(frameEntity);
                        }
                        break;

                    case ORIGIN_LEFT:
                        if (TransformHelper.isFramesAreAlignedToLeftSide(frameEntity, startFrameEntity)) {
                            adjacentFrames.push(frameEntity);
                        }
                        break;

                    case ORIGIN_TOP:
                        if (TransformHelper.isFramesAreAlignedToTopSide(frameEntity, startFrameEntity)) {
                            adjacentFrames.push(frameEntity);
                        }
                        break;

                    case ORIGIN_BOTTOM:
                        if (TransformHelper.isFramesAreAlignedToBottomSide(frameEntity, startFrameEntity)) {
                            adjacentFrames.push(frameEntity);
                        }
                        break;

                    default:
                        break;
                }
            }

        };


        traverse(frameEntityParent.getIdFrameLeftOrBottom(), frameEntityParent.getIdFrameLeftOrBottom(), boardOriginLeftOrBottom);
        traverse(frameEntityParent.getIdFrameRightOrTop(), frameEntityParent.getIdFrameRightOrTop(), boardOriginRightOrTop);

        return adjacentFrames;
    },

    getBoardOrientation(frameEntity) {
        if (!frameEntity || !frameEntity.getIsSplitted()) return null;

        const boardEntity = modules.dataStore.getEntity(frameEntity.getIdBoard());
        return boardEntity.getOrientation();
    },

    getAncestorWithSameOrientation(startFrameEntity) {
        if (!startFrameEntity.getIdParent()) return startFrameEntity;
        const traverse = (frameEntityId) => {
            const frameEntity = modules.dataStore.getEntity(frameEntityId);
            const frameEntityParent = modules.dataStore.getEntity(frameEntity.getIdParent());

            if (FrameHelper.getBoardOrientation(frameEntityParent) !== FrameHelper.getBoardOrientation(frameEntity)) {
                return frameEntity;
            }
            return traverse(frameEntityParent.id);
        };
        return traverse(startFrameEntity.getIdParent());
    },

    getDescendantsDoors(startFrameEntity) {
        const doors = [];
        const traverse = (frameEntityId) => {
            const frameEntity = modules.dataStore.getEntity(frameEntityId);
            if (frameEntity.getIsSplitted()) {
                traverse(frameEntity.getIdFrameLeftOrBottom());
                traverse(frameEntity.getIdFrameRightOrTop());
            } else if (frameEntity.getIdDoor()) {
                doors.push(frameEntity.getIdDoor());
            }
        };
        traverse(startFrameEntity.id);
        return doors;
    },

    getDirectionFromBoard(frameEntity, boardEntity) {
        if (boardEntity.isVertical()) {
            if (frameEntity.position.x < boardEntity.position.x) {
                return LEFT;
            }
            return RIGHT;

        }
        if (frameEntity.position.y < boardEntity.position.y) {
            return BOTTOM;
        }
        return TOP;
    },

    getDescendantsDrawers(startFrameEntity) {
        const drawers = [];
        const traverse = (frameEntityId) => {
            const frameEntity = modules.dataStore.getEntity(frameEntityId);
            if (frameEntity.getIsSplitted()) {
                traverse(frameEntity.getIdFrameLeftOrBottom());
                traverse(frameEntity.getIdFrameRightOrTop());
            } else if (frameEntity.getIdDrawer()) {
                drawers.push(frameEntity.getIdDrawer());
            }
        };
        traverse(startFrameEntity.id);
        return drawers;
    },

    setIdDoorToAllDescendants(startFrameEntity, idDoor) {
        const traverse = (frameEntityId) => {
            const frameEntity = modules.dataStore.getEntity(frameEntityId);
            if (frameEntity.getIsSplitted()) {
                traverse(frameEntity.getIdFrameLeftOrBottom());
                traverse(frameEntity.getIdFrameRightOrTop());
            } else if (frameEntity.getIdDoor()) {
                frameEntity.setIdDoor(idDoor);
            }
        };
        traverse(startFrameEntity.id);
    },

    getDescendantsBoards(startFrameEntity) {
        const boards = [];
        const traverse = (frameEntityId) => {
            const frameEntity = modules.dataStore.getEntity(frameEntityId);
            if (frameEntity.getIsSplitted()) {
                traverse(frameEntity.getIdFrameLeftOrBottom());
                traverse(frameEntity.getIdFrameRightOrTop());
                boards.push(frameEntity.getIdBoard());
            }
        };
        traverse(startFrameEntity.id);
        return boards;
    },

    getDescendantsFrames(startFrameEntity, keepSplittedFrames = true) {
        if (!startFrameEntity.getIsSplitted()) return [];
        const frames = [];
        const traverse = (frameEntityId) => {
            const frameEntity = modules.dataStore.getEntity(frameEntityId);
            if (frameEntity.getIsSplitted()) {
                if (keepSplittedFrames) frames.push(frameEntity);
                traverse(frameEntity.getIdFrameLeftOrBottom());
                traverse(frameEntity.getIdFrameRightOrTop());
            } else {
                frames.push(frameEntity);
            }
        };
        traverse(startFrameEntity.getIdFrameLeftOrBottom());
        traverse(startFrameEntity.getIdFrameRightOrTop());
        return frames;
    },

    getDescendantsUnsplittedFrames(startFrameEntity) {
        return FrameHelper.getDescendantsFrames(startFrameEntity, false);
    },

    /**
     *
     * @param {String} frameEntityID
     * @returns ids: idFrameLeftOrBottom, idFrameRightOrTop, idBoard
     */
    getFirstChildrenIds(frameEntity) {
        if (!frameEntity || !frameEntity.getIsSplitted()) return null;

        return {
            idFrameLeftOrBottom: frameEntity.getIdFrameLeftOrBottom(),
            idFrameRightOrTop: frameEntity.getIdFrameRightOrTop(),
            idBoard: frameEntity.getIdBoard(),
        };
    },

    getSibblingId(frameEntity) {
        if (!frameEntity || !frameEntity.getIdParent()) return null;

        const frameEntityParent = modules.dataStore.getEntity(frameEntity.getIdParent());
        return frameEntity.id === frameEntityParent.getIdFrameLeftOrBottom() ? frameEntityParent.getIdFrameRightOrTop() : frameEntityParent.getIdFrameLeftOrBottom();
    },

    hasBottomFrameSplittedVerticaly(frameEntity) {
        if (!frameEntity.getIsSplitted()) return false;
        const boardEntity = modules.dataStore.getEntity(frameEntity.getIdBoard());
        return boardEntity.isVertical();
    },

    hasDescendantsDoor(frameEntity) {
        return FrameHelper.getDescendantsDoors(frameEntity).length > 0;
    },

    hasDescendantsDrawer(frameEntity) {
        return FrameHelper.getDescendantsDrawers(frameEntity).length > 0;
    },

    hasSameBoardOrientation(frameEntity1, frameEntity2) {
        return FrameHelper.getBoardOrientation(frameEntity1) === FrameHelper.getBoardOrientation(frameEntity2);
    },

    hideAllFrames() {
        const allFrames = modules.dataStore.listEntities("frame");
        allFrames.forEach((frameEntity) => {
            frameEntity.mesh.material.alpha = 0;
        });
    },

    hideFrame(frameEntity) {
        frameEntity.mesh.isPickable = false;
        frameEntity.mesh.isVisible = false;
        frameEntity.mesh.material.alpha = 0;
    },

    isFrameLeftOrBottom(frameEntity) {
        const frameParent = modules.dataStore.getEntity(frameEntity.getIdParent());
        if (!frameParent) return false;
        return frameParent.getIdFrameLeftOrBottom() === frameEntity.id;
    },

    isFrameRightOrTop(frameEntity) {
        const frameParent = modules.dataStore.getEntity(frameEntity.getIdParent());
        if (!frameParent) return false;
        return frameParent.getIdFrameRightOrTop() === frameEntity.id;
    },

    /**
     * Find if the direct parent's board has same orientation and is at right or top as splitPoint
     *
     * @param {frameEntity} frameParent
     * @param {String} orientation
     * @param {Vector3} splitPoint
     * @returns Boolean
     */
    needReorder(frameParent, orientation, splitPoint) {
        if (!frameParent) return false;

        const boardEntity = modules.dataStore.getEntity(frameParent.getIdBoard());

        if (boardEntity.getOrientation() === orientation) {
            if (orientation === ORIENTATION_VERTICAL) {
                if (boardEntity.getPosition().x > splitPoint.x - frameParent.halfThickness) {
                    return true;
                }
            } else if (boardEntity.getPosition().y > splitPoint.y - frameParent.halfThickness) {
                return true;
            }
        }

        return false;
    },

    setIdDoorToFrameDescendantsLeaf(startFrameEntity, idDoor) {
        const traverse = (frameEntityId) => {
            const frameEntity = modules.dataStore.getEntity(frameEntityId);
            if (frameEntity.getIsSplitted()) {
                traverse(frameEntity.getIdFrameLeftOrBottom());
                traverse(frameEntity.getIdFrameRightOrTop());
            } else {
                frameEntity.setIdDoor(idDoor);
            }
        };
        traverse(startFrameEntity.id);
    },

    showAdjacentFramesOfBoard(boardEntity) {
        const adjacentFrames = FrameHelper.getAdjacentFramesOfBoard(boardEntity);
        const measures = [];
        adjacentFrames.forEach((frameEntity) => {
            FrameHelper.showFrame(frameEntity, false);
            const measure = MeasureHelper.getFrameMeasure(frameEntity, boardEntity);
            measure.from = FrameHelper.getDirectionFromBoard(frameEntity, boardEntity);
            measures.push(measure);
        });
        events.emit("measures:updated", measures);
    },

    showFrame(frameEntity, isPickable = true) {
        frameEntity.mesh.isPickable = isPickable;
        frameEntity.mesh.isVisible = true;
        frameEntity.mesh.material.alpha = 0.3;
    },

    refreshDebugFrames(furnitureEntity, isVisible = false, debugSpacing = 0.01, opacity = 0) {
        if (!config.get("debugFrames")) return;

        FrameController.isDebugFrameVisible = isVisible;
        const rootFrameEntity = modules.dataStore.getEntity(furnitureEntity.getIdFrame());
        const startLevel = 0;

        const traverse = (frameEntityId, level) => {
            const frameEntity = modules.dataStore.getEntity(frameEntityId);
            if (!frameEntity) {
                console.warn("refreshDebugFrames", "Frame not found", frameEntityId);
                return;
            }
            const {
                debugMesh,
            } = frameEntity.mesh;
            debugMesh.isVisible = isVisible;
            debugMesh.metadata.lastAlpha = debugMesh.material.alpha;
            debugMesh.material.alpha = opacity;
            debugMesh.position.z = -(debugSpacing * (level + 1));
            if (frameEntity.getIsSplitted()) {
                traverse(frameEntity.getIdFrameLeftOrBottom(), level + 1);
                traverse(frameEntity.getIdFrameRightOrTop(), level + 1);
            }
        };

        traverse(rootFrameEntity.id, startLevel);
    },

    /**
     *
     * @param {FrameEntity} frameEntityParent
     * @param {FrameEntity} curentFrameEntity, The frame who will be replaced
     * @param {FrameEntity} newFrameEntity, The frame who replace currentFrameEntity
     */
    replaceFrame(frameEntityParent, curentFrameEntity, newFrameEntity) {

        if (frameEntityParent) {
            if (frameEntityParent.getIdFrameLeftOrBottom() === curentFrameEntity.id) {
                frameEntityParent.setIdFrameLeftOrBottom(newFrameEntity.id);
            } else {
                frameEntityParent.setIdFrameRightOrTop(newFrameEntity.id);
            }
            newFrameEntity.setIdParent(frameEntityParent.id);
        } else {
            const furnitureEntity = modules.dataStore.getEntity(curentFrameEntity.getFurnitureId());
            furnitureEntity.setIdFrame(newFrameEntity.id);
        }

        newFrameEntity.setPosition(curentFrameEntity.getPosition().clone());
        newFrameEntity.setScaling(curentFrameEntity.getScaling().clone());

        const board = modules.dataStore.getEntity(curentFrameEntity.getIdBoard());
        const firstChild = modules.dataStore.getEntity(newFrameEntity.getIdFrameLeftOrBottom());
        if (board.isVertical()) {
            firstChild.setScalingX(firstChild.getScaling().x + firstChild.getPosition().x - curentFrameEntity.getPosition().x);
        } else if (board.isHorizontal()) {
            firstChild.setScalingY(firstChild.getScaling().y + firstChild.getPosition().y - curentFrameEntity.getPosition().y);
        }

        firstChild.setPosition(curentFrameEntity.getPosition().clone());

        FrameHelper.deleteAllDescendants(firstChild);
    },

    resetAllFramesInteraction(disableAllInteraction = false) {
        const frames = modules.dataStore.listEntities("frame");
        frames.forEach((frame) => {
            frame.mesh.isPickable = disableAllInteraction ? false : !frame.getIsSplitted();
            frame.mesh.isVisible = !frame.getIsSplitted();
            frame.mesh.material.alpha = 0;
        });
    },

    /**
     * Add 2 subframes and 1 board
     * Can reorder the tree of frames for keeping growing branches in the same direction ( right or top ).
     * -> Very useful for collpasing frames later.
     * @param {FrameEntity} frameEntity
     * @param {Integer} orientation : ORIENTATION_VERTICAL or ORIENTATION_HORIZONTAL
     * @param {Vector3} splitPoint
     * @param {Boolean} addToStore
     */
    splitFrame(frameEntity, orientation, splitPoint, addToStore = true) {
        let frameSide1;
        let frameSide2;
        let boardEntity;
        let frameToSplit = frameEntity;

        const frameParent = modules.dataStore.getEntity(frameToSplit.getIdParent());
        const needReorder = FrameHelper.needReorder(frameParent, orientation, splitPoint);
        let copiedFirstChildrenIds;
        if (needReorder) {
            copiedFirstChildrenIds = FrameHelper.disconnectChildrenOfFrame(frameParent);
            frameToSplit = frameParent;
        }


        // Build Entities
        if (orientation === ORIENTATION_VERTICAL) {
            frameSide1 = FrameEntityBuilder.buildLeftFrameEntity(frameToSplit, splitPoint);
            frameSide2 = FrameEntityBuilder.buildRightFrameEntity(frameToSplit, splitPoint);
            boardEntity = BoardEntityBuilder.buildVertical(frameToSplit, splitPoint.x);
        } else if (orientation === ORIENTATION_HORIZONTAL) {
            frameSide1 = FrameEntityBuilder.buildBottomFrameEntity(frameToSplit, splitPoint);
            frameSide2 = FrameEntityBuilder.buildTopFrameEntity(frameToSplit, splitPoint);
            boardEntity = BoardEntityBuilder.buildHorizontal(frameToSplit, splitPoint.y);
        } else {
            return;
        }

        frameToSplit.setIsSplitted(true);
        frameToSplit.setIdFrameLeftOrBottom(frameSide1.id);
        frameToSplit.setIdFrameRightOrTop(frameSide2.id);
        frameToSplit.setIdBoard(boardEntity.id);


        if (addToStore) {
            modules.dataStore.addEntity(frameSide1, "frame");
            modules.dataStore.addEntity(frameSide2, "frame");
            modules.dataStore.addEntity(boardEntity, "board");
        }

        if (needReorder) {
            FrameHelper.connectPartsToFrame(copiedFirstChildrenIds, frameSide2);
            BackboardController.createNormalBackboard(frameSide1);
            TransformManager.resizeBackboard(frameEntity);
        } else {
            BackboardController.createNormalBackboard(frameSide1);
            BackboardController.createNormalBackboard(frameSide2);
            BackboardController.deleteBackboard(frameEntity.getIdBackboard());
        }

        if (frameEntity.hasDoor()) {
            const doorEntity = modules.dataStore.getEntity(frameEntity.getIdDoor());
            frameSide1.setIdDoor(doorEntity.id);
            frameSide2.setIdDoor(doorEntity.id);
            doorEntity.addFrameEntityId(frameSide1.id);
            doorEntity.addFrameEntityId(frameSide2.id);
            DoorHelper.updateItemsCoveredByDoor(doorEntity);
        }

        if (FrameController.isDebugFrameVisible) {
            FrameHelper.refreshDebugFrames(modules.dataStore.getEntity(frameToSplit.getFurnitureId()), true, 0.26, 0.1);
        }

        events.emit("tool:frame:update");
        events.emit("tool:board:update", {
            toolModeName: TOOL_MODE_SELECT,
        });
        events.emit("board:select", boardEntity);
        events.emit("furniture:edited");
    },

    updateSelectedFrameDebug(frameEntity) {
        if (!frameEntity) return;
        const newCurrentDebugMesh = frameEntity.mesh.debugMesh;
        newCurrentDebugMesh.material.alpha = 0.5;
        newCurrentDebugMesh.material.diffuseColor = newCurrentDebugMesh.metadata.highlightDiffuseColor;
    },

};

export default FrameHelper;
