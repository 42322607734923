import CameraHelper from "../../../cameras-manager/src/camera-helper";
import self from "../../index";
import PulloutshelfController from "./pulloutshelf-controller";

const {
    modules: {
        dataStore,
    },
} = self.app;

const PulloutshelfUiButtons = {
    getAllButtons() {
        const buttons = [];
        const { currentPulloutshelfEntity } = PulloutshelfController;
        dataStore.listEntities("pulloutshelf").forEach((pulloutshelfEntity) => {
            if (currentPulloutshelfEntity === pulloutshelfEntity) {
                const furnitureEntity = dataStore.getEntity(pulloutshelfEntity.furnitureId);
                const center = pulloutshelfEntity.getCenterAtRight().add(furnitureEntity.position);
                const center2D = CameraHelper.convert3DPointTo2D(center);
                buttons.push({
                    id: pulloutshelfEntity.id,
                    x: center2D.x,
                    y: center2D.y,
                    buttons: [
                        {
                            deletable: true,
                            event: "pulloutshelf:delete",
                        },
                    ],
                });
            }
        });
        return buttons;
    },
};

export default PulloutshelfUiButtons;
