<template>
    <svg id="icon-back-move"
         xmlns="http://www.w3.org/2000/svg"
         width="32"
         height="32"
         viewBox="0 0 32 32"
    >
        <path id="Soustraction_50"
              data-name="Soustraction 50"
              d="M22.959,24H1.042L12,12.88,22.957,24Zm1.007-.737h0L12.867,12,23.965.737A1,1,0,0,1,24,1V23a1,1,0,0,1-.034.261Zm-23.931,0v0A1,1,0,0,1,0,23V1A1,1,0,0,1,.035.737L11.134,12,.036,23.261ZM12,11.12h0L1.043,0H22.958L12,11.12Z"
              transform="translate(4 4)"
              fill="#fff"
        />
        <g id="Rectangle_617"
           data-name="Rectangle 617"
           fill="none"
           stroke="#fff"
           stroke-width="2"
        >
            <rect width="32" height="32" rx="2" stroke="none" />
            <rect x="1"
                  y="1"
                  width="30"
                  height="30"
                  rx="1"
                  fill="none"
            />
        </g>
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "back-move-icon",
});
</script>
