import self from "../../index";
import CameraHelper from "../../../cameras-manager/src/camera-helper";

const {
    modules: {
        dataStore,
    },
} = self.app;

const BackboardUIButtons = {
    getAllButtons() {
        const buttons = [];
        dataStore.listEntities("backboard").forEach((backboardEntity) => {
            const furnitureEntity = dataStore.getEntity(backboardEntity.furnitureId);
            const center = backboardEntity.getCenterAtFront().add(furnitureEntity.position);
            const center2D = CameraHelper.convert3DPointTo2D(center);
            buttons.push({
                id: backboardEntity.id,
                x: center2D.x,
                y: center2D.y,
                buttons: [
                    {
                        deletable: true,
                        event: "backboard:delete",
                    },
                ],
            });
        });
        return buttons;
    },
};

export default BackboardUIButtons;
