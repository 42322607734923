import {
    BACKBOARD_ALL_NONE,
    BACKBOARD_ALL_NORMAL,
    BACKBOARD_NONE,
    BACKBOARD_NORMAL,
    BACKBOARD_REMOVABLE,
    DOOR_DOUBLE,
    DOOR_NONE,
    DOOR_SIMPLE_LEFT,
    DOOR_SIMPLE_RIGHT,
    TOOL_MODE_BACKBOARD,
    TOOL_MODE_DOOR,
    TOOL_MODE_DRAWER,
    TOOL_MODE_MOVABLESHELF,
    TOOL_MODE_PULLOUTSHELF,
    TOOL_MODE_ROD,
    TOOL_MODE_SELECT,
    TOOL_MODE_SPLIT,
} from "../constants";
import BoardController from "../board/board-controller";
import FrameController from "./frame-controller";
import FrameHelper from "./frame-helper";
import BackboardController from "../backboard/backboard-controller";
import DoorHelper from "../door/door-helper";
import FurnitureController from "../furniture/furniture-controller";
import self from "../../index";
import OverlaydrawerHelper from "../overlaydrawer/overlaydrawer-helper";
import RodHelper from "../rod/rod-helper";
import InsetdrawerHelper from "../insetdrawer/insetdrawer-helper";
import AccessorydrawerHelper from "../accessorydrawer/accessorydrawer-helper";
import MeasureController from "../measure/measure-controller";
import furnitureUiButtons from "../furniture/furniture-ui-buttons";

const {
    events,
    modules,
} = self.app;
const data = {
    listenersAdded: false,
};

function onToolFrameDeactivate() {
    modules.toolManager.controller.deactivateTool(FrameController.atool.tool_name);
    FrameController.atool.activateModeAlone("");
}

function onToolFrameUpdate(payload = {
    toolModeName: "",
}) {
    switch (payload.toolModeName) {
        case TOOL_MODE_SPLIT:
        case TOOL_MODE_SELECT:
        case TOOL_MODE_BACKBOARD:
        case TOOL_MODE_DOOR:
        case TOOL_MODE_MOVABLESHELF:
        case TOOL_MODE_DRAWER:
        case TOOL_MODE_ROD:
        case TOOL_MODE_PULLOUTSHELF:
            modules.toolManager.controller.activateToolAlone(FrameController.atool.tool_name);
            FrameController.atool.activateModeAlone(payload.toolModeName);
            break;
        default:
            onToolFrameDeactivate();
            break;
    }
}

function onFrameActionToSplit(payload) {
    FrameHelper.actionToSplit(payload.event, payload.orientation);
}

function onFrameActionBackboard(payload) {
    switch (payload.type) {
        case BACKBOARD_ALL_NORMAL:
            FurnitureController.updateFurnituresHasBackboards(true);
            BackboardController.addAllBackboards();
            events.emit("furniture:edited");
            break;
        case BACKBOARD_ALL_NONE:
            FurnitureController.updateFurnituresHasBackboards(false);
            BackboardController.deleteAllBackboards();
            events.emit("furniture:edited");
            break;
        case BACKBOARD_REMOVABLE:
        case BACKBOARD_NORMAL:
        case BACKBOARD_NONE:
            FrameHelper.actionToBackboard(payload.event, payload.type);
            events.emit("furniture:edited");
            break;
        default:

    }
}

function onFrameActionDoor(payload) {
    switch (payload.type) {
        case DOOR_SIMPLE_LEFT:
        case DOOR_SIMPLE_RIGHT:
        case DOOR_DOUBLE:
        case DOOR_NONE:
            FrameHelper.actionToDoor(payload.event, payload.type, payload.mode);
            break;
        default:
            break;
    }
}

function onBoardSelected(boardEntity) {
    FrameHelper.hideAllFrames();
    if (boardEntity.getIsBlockPart()) return;
    FrameHelper.showAdjacentFramesOfBoard(boardEntity);
}

function onFrameActionMovableshelf(payload) {
    FrameHelper.actionToMovableshelf(payload.event, payload.rod);
}

function onFrameActionPulloutshelf(payload) {
    FrameHelper.actionToPulloutshelf(payload.event);
}


function onFrameActionOverlaydrawer(payload) {
    OverlaydrawerHelper.actionToOverlaydrawer(payload.event, payload.type);
}

function onFrameActionInsetdrawer(payload) {
    InsetdrawerHelper.actionToInsetdrawer(payload.event, payload.type);
}

function onFrameActionAccessorydrawer(payload) {
    AccessorydrawerHelper.actionToAccessorydrawer(payload.event, payload.type);
}

function onFrameActionRod(payload) {
    RodHelper.actionToRod(payload.event);
}

function onFrameUpdateMeasures() {
    MeasureController.updateMeasures(BoardController.currentBoardEntity);
    furnitureUiButtons.updateButtons();
}

function onFrameHideMeasures() {
    events.emit("measures:updated", []);
}

function onHistoryBack() {
    if (!FrameController.isActiveTool()) return;
    if (FrameController.isDoorToolMode()) {
        DoorHelper.historyBack();
    } else {
        FrameHelper.deselectCurrentFrame();
        FrameHelper.resetAllFramesInteraction();
    }
}

function onHistoryForward() {}

const FrameEventsManager = {
    addEventListeners() {
        if (data.listenersAdded) return;

        events.on("@vuejs.history:back", onHistoryBack);
        events.on("@vuejs.history:forward", onHistoryForward);
        events.on("board:selected", onBoardSelected);

        events.on("tool:frame:update", onToolFrameUpdate);
        events.on("tool:frame:deactivate", onToolFrameDeactivate);
        events.on("frame:action:split", onFrameActionToSplit);
        events.on("frame:action:backboard", onFrameActionBackboard);
        events.on("frame:action:door", onFrameActionDoor);
        events.on("frame:action:movableshelf", onFrameActionMovableshelf);
        events.on("frame:action:overlaydrawer", onFrameActionOverlaydrawer);
        events.on("frame:action:insetdrawer", onFrameActionInsetdrawer);
        events.on("frame:action:accessorydrawer", onFrameActionAccessorydrawer);
        events.on("frame:action:rod", onFrameActionRod);
        events.on("frame:action:pulloutshelf", onFrameActionPulloutshelf);

        events.on("@vuejs.frame:action:backboard", onFrameActionBackboard);
        events.on("@vuejs.frame:action:door", onFrameActionDoor);

        events.on("@cameras-manager.camera:move:end", onFrameUpdateMeasures);
        events.on("@cameras-manager.camera:moved", onFrameUpdateMeasures);
        events.on("@cameras-manager.camera:move:start", onFrameHideMeasures);

        data.listenersAdded = true;
    },
};

export default FrameEventsManager;
