<template>
    <div>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28.994"
            height="29.934"
            viewBox="0 0 28.994 29.934"
        >
            <title>{{ title }}</title>
            <desc>Une icône représentant une façade de meuble</desc>
            <g id="icon-front-color" transform="translate(-47.503 -161.731)">
                <rect id="Rectangle_609"
                      data-name="Rectangle 609"
                      width="9"
                      height="4"
                      rx="1"
                      transform="translate(64 176)"
                      class="transition duration-300"
                      :fill="isActive || isHovered ? '#e83e8c' : 'currentColor'"
                />
                <g id="picto-facade" transform="translate(8 152)">
                    <path id="Tracé_117"
                          data-name="Tracé 117"
                          d="M27.384,6.574H.61A.587.587,0,0,0,0,7.134V34.945a.587.587,0,0,0,.61.559.587.587,0,0,0,.61-.559v-1.9H26.774v1.906a.613.613,0,0,0,1.221,0V7.134A.587.587,0,0,0,27.384,6.574ZM15.135,31.925a4.276,4.276,0,0,0-1.164,0H1.221V7.693H26.774V31.925Z"
                          transform="translate(40.003 3.657)"
                          fill="#bdc9d5"
                          stroke="#bdc9d5"
                          stroke-width="1"
                    />
                    <rect id="Rectangle_339"
                          data-name="Rectangle 339"
                          width="10"
                          height="19"
                          rx="1"
                          transform="translate(44 14)"
                          class="transition duration-300"
                          :fill="isActive || isHovered ? '#e83e8c' : 'currentColor'"
                    />
                    <g id="Rectangle_340"
                       data-name="Rectangle 340"
                       transform="translate(56 14)"
                       fill="none"
                       class="transition duration-300"
                       :stroke="isActive || isHovered ? '#ec910a': 'currentColor'"
                       stroke-width="2"
                    >
                        <rect width="9" height="8" rx="1" stroke="none" />
                        <rect x="1"
                              y="1"
                              width="7"
                              height="6"
                              fill="none"
                        />
                    </g>
                    <line id="Ligne_7"
                          data-name="Ligne 7"
                          x1="3"
                          transform="translate(50.5 23.5)"
                          fill="none"
                          stroke="#dbe1e6"
                          stroke-linecap="round"
                          stroke-width="1"
                    />
                    <rect id="Rectangle_610"
                          data-name="Rectangle 610"
                          width="9"
                          height="4"
                          rx="1"
                          transform="translate(56 29)"
                          class="transition duration-300"
                          :fill="isActive || isHovered ? '#ffc107' : 'currentColor'"
                    />
                    <rect id="Rectangle_611"
                          data-name="Rectangle 611"
                          width="9"
                          height="4"
                          rx="1"
                          transform="translate(56 24)"
                          class="transition duration-300"
                          :fill="isActive || isHovered ? '#fa5757' : 'currentColor'"
                    />
                    <line id="Ligne_8"
                          data-name="Ligne 8"
                          x1="3"
                          transform="translate(59 26)"
                          fill="none"
                          stroke="#dbe1e6"
                          stroke-linecap="round"
                          stroke-width="1"
                    />
                    <line id="Ligne_89"
                          data-name="Ligne 89"
                          x1="3"
                          transform="translate(59 31)"
                          fill="none"
                          stroke="#dbe1e6"
                          stroke-linecap="round"
                          stroke-width="1"
                    />
                </g>
                <line id="Ligne_88"
                      data-name="Ligne 88"
                      x1="3"
                      transform="translate(67 178)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-width="1"
                />
            </g>
        </svg>
    </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "front-icon",
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        isHovered: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "Icône de façade de meuble",
        },
    },
});
</script>
