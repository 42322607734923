<template>
    <div v-if="measures.length > 0 && !shouldDisplayMeasures" id="measures">
        <div
            v-for="measure in measures"
            :key="measure.id"
            :class="['absolute']"
            :style="getPositionForMeasure(measure)"
        >
            <MeasureField class="measure-text" :value="getFormattedMeasure(measure)" :measure="measure" />
        </div>
    </div>
    <div v-else>
        <div
            v-for="measure in measures"
            :key="measure.id"
            :class="['absolute flex flex-col gap-2']"
            :style="getPositionForMeasure(measure)"
        >
            <MeasureField class="measure-text" :value="measure.values.width" :measure="measure" :read-only="true" />
            <MeasureField
                class="measure-text"
                :value="measure.values.height"
                :measure="measure"
                :read-only="true"
                :vertical="true"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";
import useFurnitureStore from "../../stores/furniture";
import useGlobalStore from "../../stores/global";

import MeasureField from "./MeasureField.vue";


export default defineComponent({
    name: "measure",
    components: { MeasureField },
    computed: {
        store() {
            return useFurnitureStore();
        },
        measures() {
            return this.store.measures;
        },
        currentBoard() {
            return this.store.currentBoard;
        },
        currentMovableshelf() {
            return this.store.currentMovableshelf;
        },
        shouldDisplayMeasures() {
            return useGlobalStore().shouldDisplayMeasures;
        },
    },
    methods: {
        getPositionForMeasure(measure) {
            return {
                top: `${measure.y}px`,
                left: `${measure.x}px`,
                transform: "translate(-50%, -50%)",
            };
        },
        getFormattedMeasure(measure) {
            const { values: { length, width, height }, allFrames } = measure;
            if (allFrames) {
                return width + " - " + height;
            }
            if (typeof length === "number") {
                return length;
            }
            if (this.currentBoard) {
                return this.currentBoard.vertical ? width : height;
            }
            return "NOTHING";
        },
    },
});
</script>

<style scoped>
.measure-text {
    user-select: none;
}
</style>
