import BaseboardMesh from "./baseboard-mesh";

const BaseboardMeshBuilder = {
    buildDefault(backboardEntity, parent) {
        backboardEntity.container.parent = parent;
        const backboardMesh = new BaseboardMesh(backboardEntity);
        backboardMesh.parent = backboardEntity.container;
        backboardMesh.metadata = Object.assign(backboardMesh.metadata || {}, {
            backboardEntity: backboardEntity,
        });
        backboardEntity.mesh = backboardMesh;

        backboardMesh.linesSystem.parent = backboardEntity.container;
        return backboardMesh;
    },
};

export default BaseboardMeshBuilder;
