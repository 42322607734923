<template>
    <button
        :title="areMeasureDisplayed ? t('Cacher les dimensions des niches') : t('Afficher les dimensions des niches')"
        :class="[
            'transition-all duration-300',
            'bg-gradient-to-br',
            'flex justify-center items-center w-11 h-11 rounded',
            !areMeasureDisplayed && 'hover:from-white hover:via-theme-pink-400 hover:to-theme-orange-400 hover:bg-pos-100',
            !areMeasureDisplayed && ' from-white via-white to-white bg-size-200 bg-pos-0',
            areMeasureDisplayed && 'from-pink-400 to-theme-orange-400 bg-pos-100'
        ]"
        @mouseover="isHovered = true"
        @mouseleave="isHovered = false"
        @click="shouldDisplayMeasures()"
    >
        <MeasuresIcon :is-colored="!isHovered && !areMeasureDisplayed" />
    </button>
</template>

<script>
import useGlobalStore from "../../stores/global";
import self from "../../..";
import { TAB_STRUCTURE_INT } from "../../services/constants";

import MeasuresIcon from "../icons/MeasuresIcon.vue";

const { modules } = self.app;

export default {
    name: "display-measures-button",
    components: {
        MeasuresIcon: MeasuresIcon,
    },
    data() {
        return {
            isHovered: false,
        };
    },
    computed: {
        store() {
            return useGlobalStore();
        },
        areMeasureDisplayed() {
            return this.store.shouldDisplayMeasures;
        },
    },
    methods: {
        t(...args) {
            return modules.stonejs.gettext(...args);
        },
        shouldDisplayMeasures() {
            this.store.setActiveTab(TAB_STRUCTURE_INT);
            this.store.toggleMeasures();
        },
    },
};

</script>
