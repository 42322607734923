<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="38.581"
        height="38.767"
        viewBox="0 0 38.581 38.767"
        :class="$attrs.class"
    >
        <desc>Une icône de plinthe</desc>
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_366"
                      data-name="Rectangle 366"
                      width="38.581"
                      height="38.767"
                      rx="2"
                      transform="translate(0 0)"
                      fill="none"
                      stroke="#fff"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                />
            </clipPath>
        </defs>
        <g id="icon-plinthe" transform="translate(0 0)">
            <g id="Groupe_de_masques_336" data-name="Groupe de masques 336" clip-path="url(#clip-path)">
                <rect id="Rectangle_362"
                      data-name="Rectangle 362"
                      width="4.063"
                      height="37.767"
                      transform="translate(0.5 0.5)"
                      fill="none"
                      stroke="#fff"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                />
                <rect id="Rectangle_363"
                      data-name="Rectangle 363"
                      width="4.063"
                      height="37.767"
                      transform="translate(34.019 0.5)"
                      fill="none"
                      stroke="#fff"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                />
                <rect id="Rectangle_364"
                      data-name="Rectangle 364"
                      width="29.456"
                      height="4.063"
                      rx="1"
                      transform="translate(4.563 0.5)"
                      fill="none"
                      stroke="#fff"
                      stroke-width="1.5"
                />
                <rect id="Rectangle_365"
                      data-name="Rectangle 365"
                      width="29.456"
                      height="4.063"
                      transform="translate(4.563 34.204)"
                      fill="none"
                      stroke="#fff"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                />
            </g>
        </g>
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "without-plinth-icon",
});
</script>
