import {
    ActionManager,
    ExecuteCodeAction,
} from "@babylonjs/core";
import MovableshelfEntityBuilder from "./movableshelf-entity-builder";
import MovableshelfMeshBuilder from "./movableshelf-mesh-builder";

import self from "../../index";
import MovableshelfHelper from "./movableshelf-helper";
import {
    TOOL_MODE_SELECT,
} from "../constants";
import MovableshelfATool from "./movableshelf-atool";
import MovableshelfEventsManager from "./movableshelf-events-manager";
import MovableshelfDisplacement from "./movableshelf-displacement";
import RodController from "../rod/rod-controller";
import furnitureUiButtons from "../furniture/furniture-ui-buttons";

const {
    modules,
    events,
    config,
} = self.app;

const data = {
    atool: new MovableshelfATool(),
    currentMovableshelfEntity: null,
};

const MovableshelfController = {

    get atool() {
        return data.atool;
    },

    get currentMovableshelfEntity() {
        return data.currentMovableshelfEntity;
    },
    set currentMovableshelfEntity(movableshelfEntity) {
        MovableshelfHelper.deselectCurrentMovableshelfEntityMaterial();
        data.currentMovableshelfEntity = movableshelfEntity;
        events.emit("measures:updated");

        if (!movableshelfEntity) return;
        movableshelfEntity.mesh.material.emissiveColor = config.get("selectedBoardColor");
        movableshelfEntity.mesh.material.alpha = 1;

        movableshelfEntity.mesh.setFacesVisible(false);
    },

    /**
     * Add Mouse actions : over, out, pick
     * @param {MovableshelfEntity} movableshelfEntity
     * @returns
     */
    addMouseActionsToFrameMesh(movableshelfEntity) {
        const {
            mesh,
        } = movableshelfEntity;

        // Actions
        mesh.actionManager = new ActionManager();

        const OnPickDownTrigger = new ExecuteCodeAction(ActionManager.OnPickDownTrigger, () => {
            if (!MovableshelfController.isActiveTool()) return;
            events.emit("movableshelf:select", movableshelfEntity);
            MovableshelfDisplacement.start();
        });

        const OnPointerOutTrigger = new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, () => {
            mesh.actionManager.unregisterAction(OnPointerOutTrigger);
            mesh.actionManager.unregisterAction(OnPickDownTrigger);
        });

        const OnPointerOverTrigger = new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, () => {
            mesh.actionManager.registerAction(OnPointerOutTrigger);
            mesh.actionManager.registerAction(OnPickDownTrigger);
        });

        mesh.actionManager.registerAction(OnPointerOverTrigger);

        return mesh;
    },

    createMovableshelf: (frameEntity, position, hasRod = false) => {
        const movableshelfEntity = MovableshelfEntityBuilder.build(frameEntity);
        movableshelfEntity.setPositionY(position.y);
        MovableshelfHelper.addShelfToFrame(movableshelfEntity, frameEntity);
        modules.dataStore.addEntity(movableshelfEntity, "movableshelf");

        movableshelfEntity.isCoveredByBackboard = frameEntity.hasBackboard();

        if (hasRod) {
            RodController.createRod(movableshelfEntity);
        }

        movableshelfEntity.scalingZ = movableshelfEntity.scaling.z;

        events.emit("movableshelf:select", movableshelfEntity);
        furnitureUiButtons.updateButtons();
    },

    generateMovableshelfMeshAndMaterial(movableshelfEntity) {
        const furnitureEntity = modules.dataStore.getEntity(movableshelfEntity.getFurnitureId());
        const movableshelfMesh = MovableshelfMeshBuilder.buildDefault(movableshelfEntity, furnitureEntity.container);
        movableshelfEntity.mesh = movableshelfMesh;
        if (furnitureUiButtons.isUiMainTabFinishing) {
            MovableshelfHelper.applyFinishMaterial(movableshelfEntity);
        } else {
            MovableshelfHelper.applyConstructionMaterial(movableshelfEntity);
        }
        MovableshelfController.addMouseActionsToFrameMesh(movableshelfEntity);
    },

    isSelectActiveToolMode() {
        return MovableshelfController.atool.isModeActive(TOOL_MODE_SELECT);
    },

    isActiveTool() {
        return MovableshelfController.atool.tool_isActive;
    },

    switchMaterialToConstruction() {
        modules.dataStore.listEntities("movableshelf").forEach((movableshelfEntity) => {
            MovableshelfHelper.applyConstructionMaterial(movableshelfEntity);
        });
    },

    switchMaterialsToFinition() {
        modules.dataStore.listEntities("movableshelf").forEach((movableshelfEntity) => {
            MovableshelfHelper.applyFinishMaterial(movableshelfEntity);
        });
    },

    clearAllFromFurnitureId(furnitureEntityId) {
        const movableshelfEntities = modules.dataStore.listEntities("movableshelf");
        movableshelfEntities.forEach((movableshelfEntity) => {
            if (movableshelfEntity.furnitureId === furnitureEntityId) {
                const frameEntity = modules.dataStore.getEntity(movableshelfEntity.idParent);
                frameEntity.idMovableshelfs = [];
                if (movableshelfEntity.hasRod) {
                    modules.dataStore.removeEntity(movableshelfEntity.idRod);
                }
                modules.dataStore.removeEntity(movableshelfEntity);
            }
        });
    },

    hideAll() {
        const movableshelfEntities = modules.dataStore.listEntities("movableshelf");
        movableshelfEntities.forEach((movableshelfEntity) => {
            movableshelfEntity.hide();
        });
    },

    showAll() {
        const movableshelfEntities = modules.dataStore.listEntities("movableshelf");
        movableshelfEntities.forEach((movableshelfEntity) => {
            movableshelfEntity.show();
        });
    },
};

MovableshelfEventsManager.addEventListeners();
export default MovableshelfController;
