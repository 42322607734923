import {
    Vector2,
} from "@babylonjs/core/Maths/math.vector";
import BBox2Helper, {
    BBox2,
} from "../../../../helpers/BBox2Helper";

import self from "../../index";
import {
    BOTTOM,
    MOVABLESHELF_BBOX_HALF_HEIGHT,
    PULLOUTSHELF_MARGIN_BOTTOM,
    PULLOUTSHELF_SPACE_ABOVE_BACK_BOARD,
    TOP,
} from "../constants";
import PulloutshelfController from "./pulloutshelf-controller";
import MeasureHelper from "../measure/measure-helper";
import { clamp } from "../../../../helpers/utils";
import furnitureUiButtons from "../furniture/furniture-ui-buttons";
import MaterialManager from "../materials/material-manager";
import PulloutComposition from "./pullout-composition";

const {
    events,
    modules,
} = self.app;

const PulloutshelfHelper = {

    canAddToFrame: (frameEntity, position) => {

        if (frameEntity.idDrawer) return false;

        const bbFrame = BBox2Helper.getBBox2FromObject3dEntity(frameEntity);
        const sideHeight = 32 * 2.5;
        const bbPulloutshelf = new BBox2(new Vector2(bbFrame.p1.x, position.y - sideHeight), new Vector2(bbFrame.p2.x, position.y + sideHeight));

        if (!BBox2Helper.BBox2containBBox2(bbFrame, bbPulloutshelf)) return false;

        const otherPulloutshelfs = frameEntity.getIdPulloutshelfs();
        for (let i = 0, il = otherPulloutshelfs.length; i < il; i++) {
            const otherPulloutshelf = modules.dataStore.getEntity(otherPulloutshelfs[i]);
            if (BBox2Helper.BBox2intersectBBox2(bbPulloutshelf, otherPulloutshelf.bbox2)) return false;
        }
        return true;
    },

    /**
     * ordered by position.y. index 0 is the lowest pulloutshelf
     * @param {PulloutshelfEntity} pulloutshelfEntity
     * @param {FrameEntity} frameEntity
     * @returns
     */

    addShelfToFrame: (pulloutshelfEntity, frameEntity) => {
        const otherPulloutshelfs = frameEntity.getIdPulloutshelfs();
        if (frameEntity.getIdPulloutshelfs().indexOf(pulloutshelfEntity.id) > -1) return;

        if (!frameEntity.hasPulloutshelf()) {
            otherPulloutshelfs.push(pulloutshelfEntity.id);
            frameEntity.setIdPulloutshelfs(otherPulloutshelfs);
            return;
        }
        let added = false;
        for (let i = 0, il = otherPulloutshelfs.length; i < il; i++) {
            const otherPulloutshelf = modules.dataStore.getEntity(otherPulloutshelfs[i]);
            if (pulloutshelfEntity.getPosition().y < otherPulloutshelf.getPosition().y) {
                otherPulloutshelfs.splice(i, 0, pulloutshelfEntity.id);
                added = true;
                break;
            }
        }
        if (!added) otherPulloutshelfs.push(pulloutshelfEntity.id);
        frameEntity.setIdPulloutshelfs(otherPulloutshelfs);
    },

    updateBbox2: (pulloutshelfEntity) => {
        pulloutshelfEntity.bbox2.updateFromMesh(pulloutshelfEntity.mesh);
        pulloutshelfEntity.bbox2 = new BBox2(
            new Vector2(pulloutshelfEntity.bbox2.p1.x, pulloutshelfEntity.bbox2.p1.y - MOVABLESHELF_BBOX_HALF_HEIGHT),
            new Vector2(pulloutshelfEntity.bbox2.p2.x, pulloutshelfEntity.bbox2.p2.y + MOVABLESHELF_BBOX_HALF_HEIGHT)
        );
    },

    setIsCoveredByBackboard: (frameEntity, isCovered) => {
        const pulloutshelfs = frameEntity.idPulloutshelfs;
        const furnitureEntity = modules.dataStore.getEntity(frameEntity.getFurnitureId());
        for (let i = 0, il = pulloutshelfs.length; i < il; i++) {
            const pulloutshelf = modules.dataStore.getEntity(pulloutshelfs[i]);
            pulloutshelf.isCoveredByBackboard = isCovered;
            pulloutshelf.scalingZ = furnitureEntity.scaling.z;
        }
    },

    setIsCoveredByDoor: (frameEntity, isCovered) => {
        const pulloutshelfs = frameEntity.idPulloutshelfs;
        const furnitureEntity = modules.dataStore.getEntity(frameEntity.getFurnitureId());
        for (let i = 0, il = pulloutshelfs.length; i < il; i++) {
            const pulloutshelf = modules.dataStore.getEntity(pulloutshelfs[i]);
            pulloutshelf.isCoveredByDoor = isCovered;
            pulloutshelf.positionZ = furnitureEntity.position.z;
            pulloutshelf.scalingZ = furnitureEntity.scaling.z;
            PulloutComposition.updatePulloutComposition(pulloutshelf);
        }
    },

    deleteCurrentPulloutshelf: () => {
        const pulloutshelfEntity = PulloutshelfController.currentPulloutshelfEntity;
        if (!pulloutshelfEntity) return;
        PulloutshelfController.currentPulloutshelfEntity = null;
        const frameEntity = modules.dataStore.getEntity(pulloutshelfEntity.getIdParent());
        const pulloutshelfs = frameEntity.getIdPulloutshelfs();
        pulloutshelfs.splice(pulloutshelfs.indexOf(pulloutshelfEntity.id), 1);
        if (pulloutshelfEntity.hasRod) {
            modules.dataStore.removeEntity(pulloutshelfEntity.idRod);
        }
        modules.dataStore.removeEntity(pulloutshelfEntity.id);
        events.emit("furniture:edited");
        events.emit("measures:updated");
        furnitureUiButtons.updateButtons();
    },

    deselectCurrentPulloutshelfEntityMaterials() {
        if (PulloutshelfController.currentPulloutshelfEntity) {
            const {
                mesh,
                meshDepth,
                meshFront,
            } = PulloutshelfController.currentPulloutshelfEntity;

            mesh.material.alpha = 0;
            meshDepth.material.alpha = 0;
            meshFront.material.alpha = 0;

            mesh.setFacesVisible(true);
            meshDepth.setFacesVisible(true);
            meshFront.setFacesVisible(true);
        }
    },

    getPulloutshelfDisplacementLimits: (pulloutshelfEntity) => {
        const frameEntityParent = modules.dataStore.getEntity(pulloutshelfEntity.getIdParent());
        const allPulloutshelfOfframeEntity = frameEntityParent.getIdPulloutshelfs();
        const index = allPulloutshelfOfframeEntity.indexOf(pulloutshelfEntity.id);
        let min;
        let max;

        if (index > 0) {
            const previousPulloutshelfEntity = modules.dataStore.getEntity(allPulloutshelfOfframeEntity[index - 1]);
            min = previousPulloutshelfEntity.bbox2.p2.y;
        } else {
            min = frameEntityParent.getPosition().y;
        }
        if (index < allPulloutshelfOfframeEntity.length - 1) {
            const nextPulloutshelfEntity = modules.dataStore.getEntity(allPulloutshelfOfframeEntity[index + 1]);
            max = nextPulloutshelfEntity.bbox2.p1.y - pulloutshelfEntity.bbox2.height;
        } else {
            max = frameEntityParent.getPosition().y + frameEntityParent.getScaling().y - pulloutshelfEntity.bbox2.height - PULLOUTSHELF_SPACE_ABOVE_BACK_BOARD;
        }

        return {
            min,
            max,
        };
    },

    showMeasures: (pulloutshelfEntity) => {
        events.emit("measures:updated", MeasureHelper.getPulloutshelfMeasures(pulloutshelfEntity));
    },

    getRelativeDistanceLimitForInput(pulloutshelfEntity, from) {
        const frameEntityParent = modules.dataStore.getEntity(pulloutshelfEntity.getIdParent());
        const positionLimits = PulloutshelfHelper.getPulloutshelfDisplacementLimits(pulloutshelfEntity);

        let convertGlobalLimitToFrameLocal = {
            min: 0,
            max: 0,
        };
        switch (from) {
            case TOP:
                convertGlobalLimitToFrameLocal = {
                    min: frameEntityParent.position.y + frameEntityParent.scaling.y - positionLimits.max - pulloutshelfEntity.bbox2.height,
                    max: frameEntityParent.position.y + frameEntityParent.scaling.y - positionLimits.min - pulloutshelfEntity.bbox2.height,
                };
                break;
            case BOTTOM:
                convertGlobalLimitToFrameLocal = {
                    min: positionLimits.min - frameEntityParent.position.y + PULLOUTSHELF_MARGIN_BOTTOM,
                    max: positionLimits.max - frameEntityParent.position.y + PULLOUTSHELF_MARGIN_BOTTOM,
                };
                break;
            default:
                break;
        }

        return convertGlobalLimitToFrameLocal;
    },

    /**
     *
     * @param {number} distance
     * @param {string} from : TOP or BOTTOM
     * @returns
     */
    setDistanceFrom: (distance, from) => {
        const {
            currentPulloutshelfEntity,
        } = PulloutshelfController;
        if (!currentPulloutshelfEntity) return;

        const frameEntityParent = modules.dataStore.getEntity(currentPulloutshelfEntity.getIdParent());

        const positionLimits = PulloutshelfHelper.getPulloutshelfDisplacementLimits(currentPulloutshelfEntity);

        let positionY;
        switch (from) {
            case TOP:
                positionY = frameEntityParent.position.y + frameEntityParent.scaling.y - distance - currentPulloutshelfEntity.bbox2.height;
                break;
            case BOTTOM:
                positionY = frameEntityParent.position.y + distance - PULLOUTSHELF_MARGIN_BOTTOM;
                break;
            default:
                break;
        }

        const clampedPositionY = clamp(positionY, positionLimits.min, positionLimits.max);
        if (positionY === clampedPositionY) {
            events.emit("pulloutshelf:edit:distance:edited");
            currentPulloutshelfEntity.setPositionY(positionY);
            PulloutshelfHelper.showMeasures(currentPulloutshelfEntity);
        } else {
            const convertGlobalLimitToFrameLocal = PulloutshelfHelper.getRelativeDistanceLimitForInput(currentPulloutshelfEntity, from);
            events.emit("pulloutshelf:edit:distance:rejected");
            events.emit("notification:message", {
                title: "position incorrecte",
                text: `Doit être comprit entre ${convertGlobalLimitToFrameLocal.min} et ${convertGlobalLimitToFrameLocal.max} mm`,
                status: "error",
            });
        }
    },

    applyFinishMaterial(pulloutshelfEntity) {
        const { mesh, meshDepth, meshFront } = pulloutshelfEntity;
        const meshes = [mesh, meshDepth, meshFront];
        meshes.forEach((board) => {
            const faces = [board.faceFront, board.faceBack, board.faceLeft, board.faceRight, board.faceTop, board.faceBottom];
            faces.forEach((face) => {
                if ((face === board.faceTop || face === board.faceBottom || face === board.faceFront)) {
                    face.material = MaterialManager.mainFinishMaterial90;
                } else {
                    face.material = MaterialManager.mainFinishMaterial;
                }
            });
            board.updateUVScaling();
        });
    },

    applyConstructionMaterial(pulloutshelfEntity) {
        const { mesh, meshDepth, meshFront } = pulloutshelfEntity;
        const meshes = [mesh, meshDepth, meshFront];
        meshes.forEach((board) => {
            const faces = [board.faceFront, board.faceBack, board.faceLeft, board.faceRight, board.faceTop, board.faceBottom];
            faces.forEach((face) => {
                face.material = MaterialManager.mainConstructionMaterial;
            });
        });

    },

};

export default PulloutshelfHelper;
