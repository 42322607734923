<template>
    <div class="w-48 flex flex-col bg-black">
        <div v-if="displayPrice" class="bg-black text-white flex flex-col flex-grow">
            <span class="text-sm text-center pt-2 font-bold">{{ t("Prix HT") }} :</span>
            <span class="flex-grow font-bold items-center flex justify-center text-3xl">{{ price }} €</span>
        </div>
        <button
            :class="[
                'group bg-gradient-to-r from-theme-pink-400 to-theme-orange-400 text-white opacity-100 transition-opacity duration-300',
                'hover:opacity-70',
                displayPrice && 'h-11',
                !displayPrice && 'h-full'
            ]"
        >
            <span class="font-bold group-hover:opacity-100" @click="debounceAddToCart">{{ addToCartLabel }}</span>
        </button>
    </div>
</template>

<script>
import ObsidianApi from "obsidian-api";
import { defineComponent } from "@vue/runtime-core";
import self from "../../..";

import useGlobalStore from "../../stores/global";
import { debounce } from "../../../../../helpers/utils";

const { modules } = self.app;

export default defineComponent({
    name: "prices",
    data() {
        return {
            debounceAddToCart: debounce(() => {
                this.addToCart();
            }, 500),
        };
    },
    computed: {
        store() {
            return useGlobalStore();
        },
        price() {
            return this.store.price.toFixed(2);
        },
        displayPrice() {
            return this.store.displayPrice;
        },
        addToCartLabel() {
            const obsidianConfig = ObsidianApi.$getConfig();
            if (obsidianConfig.add_to_cart_label) {
                return obsidianConfig.add_to_cart_label;
            }
            return "Ajouter au panier";
        },
    },
    methods: {
        t(...args) {
            return modules.stonejs.gettext(...args);
        },
        addToCart() {
            modules.iframeApi.sendEvent("addToCart");
        },
    },
});
</script>
