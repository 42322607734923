import cu from "vendors/class-utils";
import Object3DEntity from "../entity-mixins/object3D-entity";
import {
    BOARD_THICKNESS,
    FRONT_MODE_INSIDE,
    FRONT_MODE_OUTSIDE,
} from "../constants";

const Entity = require("@obsidianjs/data-store/src/entity");


require("helpers/serializers/babylon-vector3");

/**
 * Furniture Entity in Furnitures module
 * Contains the root frame, the main border's boards
 */
const FurnitureEntity = Object3DEntity.$extend({

    __name__: "furniture",

    __init__(params = {}) {

        this.$data.idFrame = null; // First frame of this furniture

        this.$data.thickness = params.thickness ? params.thickness : BOARD_THICKNESS;
        this.idFurnitureList = params.idFurnitureList || "";

        this.idLeftBlockBoard = params.idLeftBlockBoard || "";
        this.idRightBlockBoard = params.idRightBlockBoard || "";
        this.idBottomBlockBoard = params.idBottomBlockBoard || "";
        this.idTopBlockBoard = params.idTopBlockBoard || "";

        this.$data.hasBackboards = true;
        this.$data.idBaseboard = params.idBaseboard || "";
        this.$data.idsFeet = params.idsFeet || [];
        this.$data.baseHeight = params.baseHeight || 0;
        this.$data.frontMode = params.frontMode || null;

        this.setKnob(params.knob);
        this.setFeet(params.feet);

        this.constrains = [];

        this.$super(params);
    },

    getIdFrame: cu.getter,
    setIdFrame: cu.setter,

    getThickness: cu.getter,
    setThickness: cu.setter,

    getIdFurnitureList: cu.getter,
    setIdFurnitureList: cu.setter,

    getIdLeftBlockBoard: cu.getter,
    setIdLeftBlockBoard: cu.setter,

    getIdRightBlockBoard: cu.getter,
    setIdRightBlockBoard: cu.setter,

    getIdBottomBlockBoard: cu.getter,
    setIdBottomBlockBoard: cu.setter,

    getIdTopBlockBoard: cu.getter,
    setIdTopBlockBoard: cu.setter,

    getHasBackboards: cu.getter,
    setHasBackboards: cu.setter,

    getKnob: cu.getter,
    setKnob(knob) {
        if (knob) {
            this.$data.knob = knob;
        } else {
            this.$data.knob = { ref: null };
        }
        this.knobVisible = Boolean(this.$data.knob.ref);
    },

    getFeet() {
        return this.$data.feet;
    },
    setFeet(feet) {
        if (feet) {
            this.$data.feet = feet;
        } else {
            this.$data.feet = { ref: null, height: 0 };
        }
    },

    getIdBaseboard: cu.getter,
    setIdBaseboard(idBaseboard) {
        this.$data.idBaseboard = idBaseboard || "";
    },

    getFrontMode: cu.getter,
    setFrontMode(frontMode) {
        this.$data.frontMode = frontMode || null;
    },

    getIdsFeet: cu.getter,
    setIdsFeet(idsFeet) {
        this.$data.idsFeet = idsFeet;
    },

    getBaseHeight() {
        return Number(this.$data.baseHeight);
    },
    setBaseHeight: cu.setter,

    getWidth() {
        return this.$data.scaling.x;
    },

    addConstraint(constraint) {
        if (constraint.enabled) {
            this.constraints.push(constraint);
        }
    },

    containFeet() {
        return Boolean(this.$data.idsFeet.length);
    },

    hasBaseboard() {
        return Boolean(this.$data.idBaseboard);
    },

    isFrontModeOutside() {
        return this.$data.frontMode === FRONT_MODE_OUTSIDE;
    },

    isFrontModeInside() {
        return this.$data.frontMode === FRONT_MODE_INSIDE;
    },

});

Entity.$register(FurnitureEntity);
export default FurnitureEntity;
