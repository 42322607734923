import self from "../../index";
import InsetdrawerEntity from "./insetdrawer-entity";

const {
    modules,
} = self.app;

const InsetdrawerEntityBuilder = {
    build(frameEntity, drawerRunnerType) {
        const furnitureEntity = modules.dataStore.getEntity(frameEntity.getFurnitureId());
        return new InsetdrawerEntity({
            furnitureId: furnitureEntity.id,
            idParent: frameEntity.id,
            scaling: frameEntity.getScaling().clone(),
            position: frameEntity.getPosition().clone(),
            drawerRunnerType,
        });
    },
};
export default InsetdrawerEntityBuilder;
