<template>
    <svg id="Double-arrow"
         xmlns="http://www.w3.org/2000/svg"
         width="15.476"
         height="14.176"
         viewBox="0 0 15.476 14.176"
    >
        <title>{{ title }}</title>
        <desc>Une flèche double vers la gauche</desc>
        <path id="Tracé_131" fill="currentColor" data-name="Tracé 131" d="M37.644,32.3a1.416,1.416,0,0,0,.979-2.421l-4.635-4.635,4.635-4.635A1.42,1.42,0,0,0,36.614,18.6L31,24.317a1.4,1.4,0,0,0,0,2.009l5.614,5.614A1.574,1.574,0,0,0,37.644,32.3Z" transform="translate(-23.571 -18.175)" />
        <path id="Tracé_132"
              data-name="Tracé 132"
              d="M37.644,31.948,32.03,26.334a1.4,1.4,0,0,1,0-2.009l5.614-5.614a1.216,1.216,0,0,1,.464-.309,1.356,1.356,0,0,0-1.494.309L31,24.377a1.4,1.4,0,0,0,0,2.009L36.614,32a1.407,1.407,0,0,0,.979.412,1.83,1.83,0,0,0,.515-.1A2.83,2.83,0,0,1,37.644,31.948Z"
              transform="translate(-23.571 -18.236)"
              fill="currentColor"
        />
        <path id="Tracé_133" fill="currentColor" data-name="Tracé 133" d="M23.992,32.3a1.416,1.416,0,0,0,.979-2.421l-4.635-4.635,4.635-4.635A1.42,1.42,0,0,0,22.962,18.6L17.4,24.317a1.4,1.4,0,0,0,0,2.009l5.614,5.614A1.359,1.359,0,0,0,23.992,32.3Z" transform="translate(-16.975 -18.175)" />
        <path id="Tracé_134"
              data-name="Tracé 134"
              d="M24.044,31.948,18.43,26.334a1.4,1.4,0,0,1,0-2.009l5.614-5.614a1.216,1.216,0,0,1,.464-.309,1.356,1.356,0,0,0-1.494.309L17.4,24.377a1.4,1.4,0,0,0,0,2.009L23.014,32a1.407,1.407,0,0,0,.979.412,1.83,1.83,0,0,0,.515-.1A2.83,2.83,0,0,1,24.044,31.948Z"
              transform="translate(-16.975 -18.236)"
              fill="currentColor"
        />
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "chevron-double-left-icon",
    props: {
        title: {
            type: String,
            default: "Chevron double vers la gauche",
        },
    },
});
</script>
