import MovableshelfMesh from "./movableshelf-mesh";

const MovableshelfMeshBuilder = {

    /**
     *
     * @param {BoardEntity} movableshelfEntity
     * @param {TransformNode} parent : furnitureEntity.container
     */
    buildDefault(movableshelfEntity, parent) {
        movableshelfEntity.container.parent = parent;
        const movableshelfMesh = new MovableshelfMesh(movableshelfEntity);
        movableshelfMesh.scaling = movableshelfEntity.getScaling().clone();
        movableshelfMesh.parent = movableshelfEntity.container;
        movableshelfMesh.holesFrontLeft.parent = movableshelfEntity.container;
        movableshelfMesh.holesFrontRight.parent = movableshelfEntity.container;
        movableshelfMesh.holesBackLeft.parent = movableshelfEntity.container;
        movableshelfMesh.holesBackRight.parent = movableshelfEntity.container;
        movableshelfMesh.metadata = Object.assign(movableshelfMesh.metadata || {}, {
            movableshelfEntity: movableshelfEntity,
        });
        movableshelfEntity.mesh = movableshelfMesh;

        movableshelfMesh.linesSystem.parent = movableshelfEntity.container;
        movableshelfMesh.updateLinesSystem();

        return movableshelfMesh;
    },

};

export default MovableshelfMeshBuilder;
