import cu from "vendors/class-utils";
import DrawerAbstractEntity from "../drawer/drawer-abstract-entity";
import { DRAWER_RUNNER_WEIGHT_1 } from "../constants";

const Entity = require("@obsidianjs/data-store/src/entity");

require("helpers/serializers/babylon-vector3");

const MESHPART_FRONT = "front";
const MESHPART_BOTTOM = "bottom";
const MESHPART_BACK = "back";
const MESHPART_LEFT = "left";
const MESHPART_RIGHT = "right";
const MESHPART_FRONT_LINES = "frontLines";


const ACCESSORYDRAWER_MESHPART_NAMES = [MESHPART_FRONT, MESHPART_BOTTOM, MESHPART_BACK, MESHPART_LEFT, MESHPART_RIGHT];

const AccessorydrawerEntity = DrawerAbstractEntity.$extend({

    __name__: "accessorydrawer",

    __init__(params = {}) {
        this.$data.isCoveredByDoor = params.isCoveredByDoor || false;
        this.$data.isCoveredByBackboard = params.isCoveredByBackboard || false;
        this.$data.drawerRunnerType = params.drawerRunnerType || DRAWER_RUNNER_WEIGHT_1;

        this.hingeBoardComposition = [];

        this.$super(params);
    },

    setIsCoveredByBackboard: cu.setter,
    getIsCoveredByBackboard: cu.getter,

    setIsCoveredByDoor: cu.setter,
    getIsCoveredByDoor: cu.getter,

});

Entity.$register(AccessorydrawerEntity);
export default AccessorydrawerEntity;
export {
    ACCESSORYDRAWER_MESHPART_NAMES,
    MESHPART_FRONT,
    MESHPART_BOTTOM,
    MESHPART_BACK,
    MESHPART_LEFT,
    MESHPART_RIGHT,
    MESHPART_FRONT_LINES,
};
