import {
    Vector2, Vector3,
} from "@babylonjs/core/Maths/math.vector";
import cu from "vendors/class-utils";
import { BoundingInfo } from "@babylonjs/core";
import {
    BOARD_THICKNESS,
    DISTANCE_BEHIND_DOOR,
    DRAWER_RUNNER_WEIGHT_1,
    ORIENTATION_HORIZONTAL,
    PULLOUTSHELF_BACK_HEIGHT,
    PULLOUTSHELF_FRONT_HEIGHT,
    PULLOUTSHELF_MARGIN_BOTTOM,
    PULLOUTSHELF_SPACE_ABOVE_BOTTOM_BOARD,
    PULLOUTSHELF_SPACE_BELOW_BOTTOM_BOARD,
    PULLOUTSHELF_SPACE_DEPTH,
} from "../constants";

import BBox2Helper, {
    BBox2,
} from "../../../../helpers/BBox2Helper";
import Object3DEntity from "../entity-mixins/object3D-entity";
import self from "../../index";
import BoardInfo from "../board/board-info";

const {
    modules,
    config,
} = self.app;

const Entity = require("@obsidianjs/data-store/src/entity");

require("helpers/serializers/babylon-vector3");

/**
 * Board Entity in Furnitures module
 */
const PulloutshelfEntity = Object3DEntity.$extend({

    __name__: "pulloutshelf",

    __init__(params = {}) {

        this.$data.isCoveredByDoor = params.isCoveredByDoor || false;
        this.$data.isCoveredByBackboard = params.isCoveredByBackboard || false;
        this.$data.drawerRunnerType = params.drawerRunnerType || DRAWER_RUNNER_WEIGHT_1;

        this._hitbox = null;
        this._mesh = null;
        this._meshDepth = null;
        this._meshFront = null;
        this._bbox2 = null;

        this.unitPulloutshelfBoardComposition = {
            front: new BoardInfo(),
            back: new BoardInfo(),
            bottom: new BoardInfo(),
        };

        this.hingeBoardComposition = [];

        this.$super(params);

        this._debugMeshBoundingBox = false;
    },

    getOrientation() {
        return ORIENTATION_HORIZONTAL;
    },

    getDrawerRunnerType: cu.getter,

    setIsCoveredByBackboard(isCoveredByBackboard) {
        this.$data.isCoveredByBackboard = isCoveredByBackboard;
    },
    getIsCoveredByBackboard: cu.getter,

    setIsCoveredByDoor(isCoveredByDoor) {
        this.$data.isCoveredByDoor = isCoveredByDoor;
    },
    getIsCoveredByDoor: cu.getter,

    setPositionZ(z) {
        if (this.isCoveredByDoor) {
            const furnitureEntity = modules.dataStore.getEntity(this.getFurnitureId());
            this.$data.position.z = furnitureEntity.position.z + DISTANCE_BEHIND_DOOR;
        } else {
            this.$data.position.z = z;
        }
        this.updateContainer();
    },

    setScalingZ(scaleZ, refreshModels = true) {
        this.$data.scaling.z = scaleZ;
        setTimeout(() => {
            const furnitureEntity = modules.dataStore.getEntity(this.getFurnitureId());
            if (this.isCoveredByDoor) {
                this.$data.scaling.z = furnitureEntity.scaling.z - DISTANCE_BEHIND_DOOR;
            }
            if (this.isCoveredByBackboard) {
                const parentEntity = modules.dataStore.getEntity(this.getIdParent());
                this.$data.scaling.z = parentEntity.scaling.z - config.get("backboard_z_shift") - BOARD_THICKNESS;
            }
            if (!refreshModels) return;
            this.updateMeshes();
        });
    },

    getBbox2() {
        "@serializable false";

        if (!this._bbox2 && this._mesh) {
            this.updateBbox2();
        }
        return this._bbox2;
    },

    updateBbox2() {
        const bbox2Mesh = BBox2Helper.getBBox2FromMesh(this._hitbox);
        this._bbox2 = new BBox2(
            new Vector2(this._container.position.x + bbox2Mesh.p1.x, this._container.position.y + bbox2Mesh.p1.y),
            new Vector2(this._container.position.x + bbox2Mesh.p2.x, this._container.position.y + bbox2Mesh.p2.y)
        );

        if (this._debugMeshBoundingBox) {
            this.meshBoundingBox.setBoundingInfo(
                new BoundingInfo(
                    new Vector3(this._bbox2.p1.x, this._bbox2.p1.y, 0),
                    new Vector3(this._bbox2.p2.x, this._bbox2.p2.y, this.scaling.z)
                )
            );
        }
        this.meshBoundingBox.showBoundingBox = this._debugMeshBoundingBox;
    },

    updateMeshes() {
        this.updateMesh();
        this.updateMeshDepth();
        this.updateMeshFront();
        this.updateHitbox();
    },

    getMesh() {
        "@serializable false";

        return this._mesh;
    },

    setMesh(mesh) {
        this._mesh = mesh;
        this.updateMesh();
    },

    updateMesh() {
        if (!this._mesh) return;
        this._mesh.position.x = 0;
        this._mesh.position.y = PULLOUTSHELF_MARGIN_BOTTOM + PULLOUTSHELF_FRONT_HEIGHT - BOARD_THICKNESS;
        this._mesh.position.z = BOARD_THICKNESS + PULLOUTSHELF_SPACE_DEPTH;
        this._mesh.scaling.x = this.$data.scaling.x;
        this._mesh.scaling.y = BOARD_THICKNESS;
        this._mesh.scaling.z = this.$data.scaling.z - BOARD_THICKNESS * 2 - PULLOUTSHELF_SPACE_DEPTH;
        this._mesh.updateLinesSystem();
    },

    getMeshDepth() {
        "@serializable false";

        return this._meshDepth;
    },

    setMeshDepth(meshDepth) {
        this._meshDepth = meshDepth;
        this.updateMeshDepth();
    },

    updateMeshDepth() {
        if (!this._meshDepth) return;
        this._meshDepth.position.y = PULLOUTSHELF_MARGIN_BOTTOM + PULLOUTSHELF_SPACE_BELOW_BOTTOM_BOARD;
        if (this.$data.isCoveredByBackboard) {
            this._meshDepth.position.z = this.$data.scaling.z - BOARD_THICKNESS;
        }
        this._meshDepth.scaling.x = this.$data.scaling.x;
        this._meshDepth.scaling.y = PULLOUTSHELF_BACK_HEIGHT;
        this._meshDepth.scaling.z = BOARD_THICKNESS;

        this._meshDepth.updateLinesSystem();
    },

    getMeshFront() {
        "@serializable false";

        return this._meshFront;
    },

    setMeshFront(meshFront) {
        this._meshFront = meshFront;
        this.updateMeshFront();
    },

    updateMeshFront() {
        if (!this._meshFront) return;
        this._meshFront.position.y = PULLOUTSHELF_MARGIN_BOTTOM;
        this._meshFront.position.z = PULLOUTSHELF_SPACE_DEPTH;
        this._meshFront.scaling.x = this.$data.scaling.x;
        this._meshFront.scaling.y = PULLOUTSHELF_FRONT_HEIGHT;
        this._meshFront.scaling.z = BOARD_THICKNESS;
        this._meshFront.updateLinesSystem();
    },

    getHitbox() {
        "@serializable false";

        return this._hitbox;
    },

    setHitbox(hitbox) {
        this._hitbox = hitbox;
        this.updateHitbox();
    },

    updateHitbox() {
        if (this._hitbox) {
            this._hitbox.position.y = 0;
            this._hitbox.scaling.x = this.$data.scaling.x;
            this._hitbox.scaling.y = PULLOUTSHELF_MARGIN_BOTTOM + PULLOUTSHELF_SPACE_ABOVE_BOTTOM_BOARD + PULLOUTSHELF_BACK_HEIGHT;
            this._hitbox.scaling.z = this.$data.scaling.z;
            this.updateBbox2();
        }
    },

    getFrontDimensions() {
        return [this._meshFront.scaling.x, this._meshFront.scaling.y, this._meshFront.scaling.z].sort((a, b) => a - b);
    },

    getDepthDimensions() {
        return [this._meshDepth.scaling.x, this._meshDepth.scaling.y, this._meshDepth.scaling.z].sort((a, b) => a - b);
    },

    getCenterAtRight() {
        const center = this.$data.position.clone();
        center.x += this.$data.scaling.x;
        center.y += this.$data.scaling.y * 0.5;
        return center;
    },
});

Entity.$register(PulloutshelfEntity);
export default PulloutshelfEntity;
