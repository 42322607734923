import {
    Vector3,
} from "@babylonjs/core";
import self from "../../index";
import {
    ROD_DIAMETER,
    ROD_RADIUS,
} from "../constants";
import RodEntity from "./rod-entity";

const {
    modules,
} = self.app;

const RodEntityBuilder = {

    /**
     *
     * @param {BoardEntity || MovableshelfEntity} parentEntity
     * @returns
     */
    build(parentEntity) {
        const furnitureEntity = modules.dataStore.getEntity(parentEntity.getFurnitureId());

        return new RodEntity({
            furnitureId: furnitureEntity.id,
            idParent: parentEntity.id,
            scaling: new Vector3(
                parentEntity.scaling.x,
                ROD_DIAMETER,
                ROD_DIAMETER,
            ),
            position: new Vector3(
                parentEntity.position.x,
                parentEntity.position.y - ROD_DIAMETER,
                parentEntity.position.z + parentEntity.scaling.z * 0.5 - ROD_RADIUS
            ),
        });
    },
};
export default RodEntityBuilder;
