import BoardMesh from "./board-mesh";

const BoardMeshBuilder = {

    /**
     *
     * @param {BoardEntity} boardEntity
     * @param {TransformNode} parent : furnitureEntity.container
     */
    buildDefault(boardEntity, parent) {
        boardEntity.container.parent = parent;
        const boardMesh = new BoardMesh(boardEntity);
        boardMesh.parent = boardEntity.container;
        boardMesh.metadata = Object.assign(boardMesh.metadata || {}, {
            boardEntity: boardEntity,
        });
        boardEntity.mesh = boardMesh;

        boardMesh.linesSystem.parent = boardEntity.container;
        boardMesh.updateLinesSystem();

        return boardMesh;
    },

};

export default BoardMeshBuilder;
