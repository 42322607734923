import self from "../index";
import { version } from "../../../../package.json";

const {
    modules,
    events,
} = self.app;

/**
 * Api Controller module
 */
class ApiController {

    constructor() {
        modules.iframeApi.addApiMethod("exportAsBlob", () => {
            const projectBlob = modules.dataExporter.exportAsBlob(
                { version }
            );
            return projectBlob;
        });
        modules.iframeApi.addApiMethod("openProjectFromBlob", (blob) => {
            modules.projectManager.helper.openProjectFromBlob(blob);
        });

        // type is an enum info/warning/error
        // message is a string that can contain html
        modules.iframeApi.addApiMethod("showAlert", (status, title, text = "", autoClose = true, closeAfter = 5000) => {
            const message = {
                status,
                title,
                text: typeof text === "string" ? text : null,
                autoClose: typeof autoClose === "boolean" ? autoClose : true,
                closeAfter: typeof closeAfter === "number" ? closeAfter : 5000,
            };
            events.emit("show:alert", message);
        });

        modules.iframeApi.addApiMethod("hideAlert", () => {
            events.emit("hide:alert");
        });

        modules.iframeApi.addApiMethod("showLoader", () => {
            modules.wanadevLoader.controller.show();
        });

        modules.iframeApi.addApiMethod("hideLoader", () => {
            modules.wanadevLoader.controller.hide();
        });

        modules.iframeApi.addApiMethod("getWeight", () => modules.vuejs.getWeight());

        modules.iframeApi.addApiMethod("getPrice", () => modules.vuejs.getPrice());

        modules.iframeApi.addApiMethod("getComposition", () => modules.vuejs.getComposition());

        modules.iframeApi.addApiMethod("exportAsPdf", async () => modules.vuejs.exportAsPdf());

        modules.iframeApi.addApiMethod("getVolume", () => modules.vuejs.getVolume());
    }

}

export default ApiController;
