<template>
    <button
        :title="t('Ouvrir un projet \'.cam\'')"
        :class="[
            'transition-all duration-300',
            'bg-gradient-to-br from-white via-white to-white bg-size-200 bg-pos-0',
            'flex justify-center items-center w-11 h-11 rounded',
            'hover:from-white hover:via-theme-pink-400 hover:to-theme-orange-400 hover:bg-pos-100'
        ]"
        @click="onClickOpenProject()"
        @mouseover="isHovered = true"
        @mouseleave="isHovered = false"
    >
        <FolderIcon :is-colored="!isHovered" />
        <input
            ref="fileInput"
            type="file"
            hidden
            :accept="fileExtension"
            @change="openProject"
        >
    </button>
</template>

<script>
import defaultConfig from "defaultConfig";
import self from "../../..";
import FolderIcon from "../icons/FolderIcon.vue";

const { modules, events } = self.app;

export default {
    name: "open-project-button",
    components: { FolderIcon },
    data() {
        return {
            projectManager: null,
            fileExtension: defaultConfig.fileExtension,
            isHovered: false,
        };
    },
    mounted() {
        this.projectManager = modules.projectManager.helper;
    },
    methods: {
        t(...args) {
            return modules.stonejs.gettext(...args);
        },
        onClickOpenProject() {
            this.$refs.fileInput.click();
        },
        openProject(event) {
            const importedFile = event.target.files[0];
            event.target.value = null;
            this.projectManager.openProjectFromBlob(importedFile);
            events.emit("project:opening");
        },
    },
};
</script>
