import self from "../../index";
import {
    ACCESSORYDRAWER, ACCESSORYDRAWER_HAND_SPACE_HEIGHT, ACCESSORYDRAWER_SPACE_FROM_BOTTOM_OF_FRONT_BOARD, ACCESSORYDRAWER_SPACE_FROM_TOP_OF_FRONT_BOARD, BOARD_HALF_THICKNESS, BOARD_THICKNESS,
    DOOR_DOUBLE, DOOR_SIMPLE_LEFT, DOOR_SIMPLE_RIGHT, DRAWER_BACKSPACE,
    DRAWER_BOTTOM_BOARD_SPACE_INSIDE_FRONT_AND_BACK, DRAWER_RUNNER_LATERAL_SPACE,
    DRAWER_RUNNER_SPACE_BEHIND_BOTTOM_BOARD, INSET_DRAWER, OVERLAY_DRAWER, OVERLAY_PADDING, INSETDRAWER_TOP_SPACE, INSETDRAWER_BOTTOM_SPACE,
    SPACE_MOVEMENT,
} from "../constants";
import OverlaydrawerEntity from "../overlaydrawer/overlaydrawer-entity";
import DrawerRunnerHelper from "./drawer-runner-helper";
import InsetdrawerEntity from "../insetdrawer/insetdrawer-entity";
import AccessorydrawerEntity from "../accessorydrawer/accessorydrawer-entity";
import BBox2Helper from "../../../../helpers/BBox2Helper";
import BoardInfo from "../board/board-info";

const {
    modules,
} = self.app;

function createHingeSpacerBoard(hingeBoardComposition, frameEntity, unitUtilSize) {

    unitUtilSize.x -= BOARD_THICKNESS * 2;

    const spacerBoard = new BoardInfo();
    spacerBoard.scaling.x = BOARD_THICKNESS;
    spacerBoard.scaling.y = frameEntity.scaling.y;
    spacerBoard.scaling.z = unitUtilSize.z + BOARD_THICKNESS * 2 + DRAWER_BACKSPACE;

    hingeBoardComposition.push(spacerBoard);

    const spacerBoardPlus = spacerBoard.clone();
    spacerBoardPlus.scaling.z = 68;

    hingeBoardComposition.push(spacerBoardPlus);
    hingeBoardComposition.push(spacerBoardPlus);
}

const DrawerComposition = {

    /**
     *
     * @param {OverlaydrawerEntity | InsetdrawerEntity | AccessorydrawerEntity} drawerEntity
     */
    updateDrawerComposition(drawerEntity) {
        const frameEntity = modules.dataStore.getEntity(drawerEntity.idParent);

        if (drawerEntity instanceof OverlaydrawerEntity) {
            const drawerRunner = DrawerRunnerHelper.getNearestDrawerRunnerForFrame(frameEntity, OVERLAY_DRAWER, drawerEntity.drawerRunnerType);

            const {
                unitDrawerBoardComposition: {
                    overlay,
                    front,
                    back,
                    left,
                    right,
                    bottom,
                },
                unitUtilSize,
            } = drawerEntity;

            const splitHeight = Math.floor(frameEntity.scaling.y / drawerEntity.quantity);

            unitUtilSize.x = frameEntity.scaling.x - (DRAWER_RUNNER_LATERAL_SPACE * 2) - (BOARD_THICKNESS * 2);
            // unitUtilSize.y = splitHeight - OVERLAYDRAWER_TOP_SPACE - BOARD_THICKNESS - DRAWER_RUNNER_SPACE_BEHIND_BOTTOM_BOARD - OVERLAYDRAWER_BOTTOM_SPACE;
            unitUtilSize.y = splitHeight - SPACE_MOVEMENT - INSETDRAWER_TOP_SPACE - BOARD_THICKNESS - DRAWER_RUNNER_SPACE_BEHIND_BOTTOM_BOARD - INSETDRAWER_BOTTOM_SPACE - SPACE_MOVEMENT;
            unitUtilSize.z = drawerRunner.length - DRAWER_BACKSPACE - (BOARD_THICKNESS * 2);

            overlay.scaling.x = drawerEntity.scaling.x - (OVERLAY_PADDING * 2);
            overlay.scaling.y = splitHeight - (OVERLAY_PADDING * 2);
            overlay.scaling.z = BOARD_THICKNESS;

            front.scaling.x = unitUtilSize.x;
            front.scaling.y = unitUtilSize.y;
            front.scaling.z = BOARD_THICKNESS;

            back.scaling = front.scaling.clone();

            left.scaling.x = BOARD_THICKNESS;
            left.scaling.y = unitUtilSize.y + BOARD_THICKNESS + DRAWER_RUNNER_SPACE_BEHIND_BOTTOM_BOARD;
            left.scaling.z = unitUtilSize.z + BOARD_THICKNESS * 2;

            right.scaling = left.scaling.clone();

            bottom.scaling.x = unitUtilSize.x + (DRAWER_BOTTOM_BOARD_SPACE_INSIDE_FRONT_AND_BACK * 2);
            bottom.scaling.y = BOARD_THICKNESS;
            bottom.scaling.z = unitUtilSize.z + BOARD_THICKNESS * 2;
        }

        if (drawerEntity instanceof InsetdrawerEntity) {
            const drawerRunner = DrawerRunnerHelper.getNearestDrawerRunnerForFrame(frameEntity, INSET_DRAWER, drawerEntity.drawerRunnerType);

            const {
                unitDrawerBoardComposition: {
                    overlay,
                    front,
                    back,
                    left,
                    right,
                    bottom,
                },
                unitUtilSize,
            } = drawerEntity;

            const splitHeight = Math.floor(frameEntity.scaling.y / drawerEntity.quantity);

            unitUtilSize.x = frameEntity.scaling.x - (DRAWER_RUNNER_LATERAL_SPACE * 2) - (BOARD_THICKNESS * 2);
            unitUtilSize.y = splitHeight - SPACE_MOVEMENT - INSETDRAWER_TOP_SPACE - BOARD_THICKNESS - DRAWER_RUNNER_SPACE_BEHIND_BOTTOM_BOARD - INSETDRAWER_BOTTOM_SPACE - SPACE_MOVEMENT;
            unitUtilSize.z = drawerRunner.length - DRAWER_BACKSPACE - (BOARD_THICKNESS * 2);

            overlay.scaling.x = drawerEntity.scaling.x - (OVERLAY_PADDING * 2);
            overlay.scaling.y = splitHeight - (OVERLAY_PADDING * 2);
            overlay.scaling.z = BOARD_THICKNESS;

            front.scaling.x = unitUtilSize.x;
            front.scaling.y = unitUtilSize.y;
            front.scaling.z = BOARD_THICKNESS;

            back.scaling = front.scaling.clone();

            left.scaling.x = BOARD_THICKNESS;
            left.scaling.y = unitUtilSize.y + BOARD_THICKNESS + DRAWER_RUNNER_SPACE_BEHIND_BOTTOM_BOARD;
            left.scaling.z = unitUtilSize.z + BOARD_THICKNESS * 2;

            right.scaling = left.scaling.clone();

            bottom.scaling.x = unitUtilSize.x + (DRAWER_BOTTOM_BOARD_SPACE_INSIDE_FRONT_AND_BACK * 2);
            bottom.scaling.y = BOARD_THICKNESS;
            bottom.scaling.z = unitUtilSize.z + BOARD_THICKNESS * 2;
        }

        if (drawerEntity instanceof AccessorydrawerEntity) {
            const drawerRunner = DrawerRunnerHelper.getNearestDrawerRunnerForFrame(frameEntity, ACCESSORYDRAWER, drawerEntity.drawerRunnerType);

            const {
                unitDrawerBoardComposition: {
                    front,
                    back,
                    left,
                    right,
                    bottom,
                },
                unitUtilSize,
                hingeBoardComposition,
            } = drawerEntity;

            hingeBoardComposition.length = 0;

            const boxHeight = Math.floor(frameEntity.scaling.y / drawerEntity.quantity) - ACCESSORYDRAWER_HAND_SPACE_HEIGHT;
            const isBehindDoor = frameEntity.hasDoor();

            unitUtilSize.x = frameEntity.scaling.x - (DRAWER_RUNNER_LATERAL_SPACE * 2) - (BOARD_THICKNESS * 2);
            unitUtilSize.y = boxHeight - ACCESSORYDRAWER_SPACE_FROM_TOP_OF_FRONT_BOARD - BOARD_THICKNESS - DRAWER_RUNNER_SPACE_BEHIND_BOTTOM_BOARD - ACCESSORYDRAWER_SPACE_FROM_BOTTOM_OF_FRONT_BOARD - 3;
            unitUtilSize.z = drawerRunner.length - DRAWER_BACKSPACE - BOARD_THICKNESS;
            if (isBehindDoor) {
                const doorEntity = modules.dataStore.getEntity(frameEntity.idDoor);
                const bBox2 = BBox2Helper.getBBox2FromObject3dEntity(frameEntity);
                const frameHasHingeTopLeft = bBox2.containVector2(doorEntity.hingeTopLeft);
                const frameHasHingeBottomLeft = bBox2.containVector2(doorEntity.hingeBottomLeft);
                const frameHasHingeTopRight = bBox2.containVector2(doorEntity.hingeTopRight);
                const frameHasHingeBottomRight = bBox2.containVector2(doorEntity.hingeBottomRight);
                const hasHingeAtLeft = frameHasHingeTopLeft || frameHasHingeBottomLeft;
                const hasHingeAtRight = frameHasHingeTopRight || frameHasHingeBottomRight;
                switch (doorEntity.type) {
                    case DOOR_DOUBLE:
                        if (hasHingeAtLeft) {
                            createHingeSpacerBoard(hingeBoardComposition, frameEntity, unitUtilSize);
                        }
                        if (hasHingeAtRight) {
                            createHingeSpacerBoard(hingeBoardComposition, frameEntity, unitUtilSize);
                        }
                        break;

                    case DOOR_SIMPLE_RIGHT:
                        if (hasHingeAtLeft) {
                            createHingeSpacerBoard(hingeBoardComposition, frameEntity, unitUtilSize);
                        }
                        break;
                    case DOOR_SIMPLE_LEFT:
                        if (hasHingeAtRight) {
                            createHingeSpacerBoard(hingeBoardComposition, frameEntity, unitUtilSize);
                        }
                        break;
                    default:
                        break;
                }
            }

            front.scaling.x = unitUtilSize.x + BOARD_THICKNESS * 2;
            front.scaling.y = unitUtilSize.y + BOARD_THICKNESS + DRAWER_RUNNER_SPACE_BEHIND_BOTTOM_BOARD + ACCESSORYDRAWER_SPACE_FROM_BOTTOM_OF_FRONT_BOARD + ACCESSORYDRAWER_SPACE_FROM_TOP_OF_FRONT_BOARD;
            front.scaling.z = BOARD_THICKNESS;

            left.scaling.x = BOARD_THICKNESS;
            left.scaling.y = unitUtilSize.y;
            left.scaling.z = unitUtilSize.z;

            right.scaling = left.scaling.clone();

            bottom.scaling.x = unitUtilSize.x + BOARD_THICKNESS;
            bottom.scaling.y = BOARD_THICKNESS;
            bottom.scaling.z = unitUtilSize.z + BOARD_HALF_THICKNESS;

            back.scaling.x = unitUtilSize.x + BOARD_THICKNESS * 2;
            back.scaling.y = unitUtilSize.y - 2;
            back.scaling.z = BOARD_THICKNESS;
        }
    },
};

export default DrawerComposition;
