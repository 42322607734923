<template>
    <svg id="icon-door-ext-left"
         xmlns="http://www.w3.org/2000/svg"
         width="31.651"
         height="35.628"
         viewBox="0 0 31.651 35.628"
    >
        <g id="Groupe_427" data-name="Groupe 427" transform="translate(0 0)">
            <path id="Tracé_197"
                  data-name="Tracé 197"
                  d="M188.827,481.766l-1.267.318-.017-28.114,1.284-.323Z"
                  transform="translate(-157.176 -451.798)"
                  fill="#fff"
            />
            <path id="Tracé_198"
                  data-name="Tracé 198"
                  d="M206.188,484.186H192.4v-1.2h18.572Z"
                  transform="translate(-184.187 -451.798)"
                  fill="#fff"
            />
            <g id="Groupe_426" data-name="Groupe 426">
                <path id="Tracé_199"
                      data-name="Tracé 199"
                      d="M200.754,481.281a.8.8,0,0,1-.8-.8V455.5a.8.8,0,0,1,.8-.8H216.29L204.78,457.6a2,2,0,0,0-1.513,1.939v21.747Z"
                      transform="translate(-197.05 -451.798)"
                      fill="#fff"
                />
                <path id="Tracé_200"
                      data-name="Tracé 200"
                      d="M188.568,453.332a2,2,0,0,1,1.94-1.534H218.9a2.088,2.088,0,0,1,.3.031l-2.122.532-4.546,1.142H192.212a2,2,0,0,0-2,2v24.978a2,2,0,0,0,2,2h2.513v1.7h-4.217a2.055,2.055,0,0,1-.4-.04,2,2,0,0,1-1.516-1.426,1.966,1.966,0,0,1-.081-.534V453.8A1.99,1.99,0,0,1,188.568,453.332Z"
                      transform="translate(-188.508 -451.798)"
                      fill="#fff"
                />
            </g>
        </g>
        <path id="Tracé_201"
              data-name="Tracé 201"
              d="M188.513,459.291l21.494-5.4a.744.744,0,0,1,.97.756v26.119a1.352,1.352,0,0,1-.97,1.243L188.513,487.4a.744.744,0,0,1-.97-.756V460.534A1.352,1.352,0,0,1,188.513,459.291Zm2.634,15.567a.75.75,0,0,0,1.5,0v-5.114a.75.75,0,0,0-1.5,0Z"
              transform="translate(-179.326 -451.798)"
              fill="#fff"
        />
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "door-left-outside-icon",
});
</script>
