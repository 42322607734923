import {
    MeshBuilder,
} from "@babylonjs/core/Meshes/meshBuilder";
import {
    Vector3,
} from "@babylonjs/core/Maths/math.vector";
import { StandardMaterial } from "@babylonjs/core";
import self from "../../index";
import {
    DOOR_DOUBLE,
    DOOR_SIMPLE_LEFT,
    DOOR_SIMPLE_RIGHT,
} from "../constants";
import CubeFacesMesh from "../common/cube-faces-mesh";

class DoorMesh extends CubeFacesMesh {

    constructor(doorEntity) {
        super(doorEntity);

        this.material = new StandardMaterial("hitBoxMat");
        this.material.alpha = 0;

        this.addCustomWireframe(doorEntity);
    }

    // eslint-disable-next-line class-methods-use-this
    updateUVAngle(frontUvScale, backUvScale, leftRightUvScale, bottomTopUvScale) {
        const rotate90 = Math.PI / 2;
        leftRightUvScale.wAng = 0;
        frontUvScale.wAng = 0;
        bottomTopUvScale.wAng = rotate90;
        backUvScale.wAng = rotate90;
    }

    addCustomWireframe(doorEntity) {
        const lines = [];
        switch (doorEntity.getType()) {

            case DOOR_SIMPLE_RIGHT:
                lines.push(
                    [
                        new Vector3(0, 1, 0),
                        new Vector3(1, 0.5, 0),
                    ],
                    [
                        new Vector3(1, 0.5, 0),
                        new Vector3(0, 0, 0),
                    ],
                );

                break;

            case DOOR_SIMPLE_LEFT:
                lines.push(
                    [
                        new Vector3(1, 0, 0),
                        new Vector3(0, 0.5, 0),
                    ],
                    [
                        new Vector3(0, 0.5, 0),
                        new Vector3(1, 1, 0),
                    ],
                );
                break;


            case DOOR_DOUBLE:
                lines.push(
                    [
                        new Vector3(0, 1, 0),
                        new Vector3(0.5, 0.5, 0),
                    ],
                    [
                        new Vector3(0.5, 0.5, 0),
                        new Vector3(0, 0, 0),
                    ],
                );
                lines.push(
                    [
                        new Vector3(1, 0, 0),
                        new Vector3(0.5, 0.5, 0),
                    ],
                    [
                        new Vector3(0.5, 0.5, 0),
                        new Vector3(1, 1, 0),
                    ],
                );
                lines.push(
                    [
                        new Vector3(0.5, 0, 0),
                        new Vector3(0.5, 1, 0),
                    ],
                );
                break;

            default:
                break;
        }

        lines.push(
            [
                new Vector3(0, 0, 0),
                new Vector3(1, 0, 0),
            ],
            [
                new Vector3(0, 1, 0),
                new Vector3(1, 1, 0),
            ],
            [
                new Vector3(0, 0, 0),
                new Vector3(0, 1, 0),
            ],
            [
                new Vector3(1, 0, 0),
                new Vector3(1, 1, 0),
            ],
        );

        lines.push(
            [
                new Vector3(0, 0, 0),
                new Vector3(0, 0, 1),
            ],
            [
                new Vector3(1, 0, 0),
                new Vector3(1, 0, 1),
            ],
            [
                new Vector3(0, 1, 0),
                new Vector3(0, 1, 1),
            ],
            [
                new Vector3(1, 1, 0),
                new Vector3(1, 1, 1),
            ],
        );

        this.linesSystem = MeshBuilder.CreateLineSystem("lines", {
            lines: lines,
        });
        this.linesSystem.isPickable = false;
        this.linesSystem.color = self.config.doorLinesColor;
    }

    /**
     * Lines scaling and position are independant for having a bigger size than the mesh.
     * Without flickering
     * @param {Number} enlargeValue in mm
     */
    updateLinesSystem(enlargeValue = 1) {
        this.linesSystem.scaling = this.scaling.clone();
        this.linesSystem.position = this.position.clone();
        this.linesSystem.scaling.z += enlargeValue;
        this.linesSystem.position.z -= enlargeValue * 0.5;
    }

}

export default DoorMesh;
