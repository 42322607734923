<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="38"
        height="38"
        viewBox="0 0 38 38"
    >
        <desc>Une icône de séparation horizontale</desc>
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_697"
                      data-name="Rectangle 697"
                      width="38"
                      height="38"
                      transform="translate(211.394 697.394)"
                      fill="#fd7e19"
                />
            </clipPath>
        </defs>
        <g id="icon-sep-horizontal" transform="translate(249.394 735.394) rotate(180)" clip-path="url(#clip-path)">
            <path id="border-center-h-thin" d="M36.16,22.65a.657.657,0,1,1,0,1.315H.657a.657.657,0,1,1,0-1.315ZM2.63,8.844A1.315,1.315,0,1,1,3.945,7.529,1.315,1.315,0,0,1,2.63,8.844Zm0,31.558a1.315,1.315,0,1,1,1.315-1.315A1.317,1.317,0,0,1,2.63,40.4ZM10.519,8.844a1.315,1.315,0,1,1,1.315-1.315A1.317,1.317,0,0,1,10.519,8.844Zm0,31.558a1.315,1.315,0,1,1,1.315-1.315A1.319,1.319,0,0,1,10.519,40.4Zm17.094-1.315A1.315,1.315,0,1,1,26.3,37.772,1.319,1.319,0,0,1,27.613,39.087ZM26.3,8.844a1.315,1.315,0,1,1,1.315-1.315A1.317,1.317,0,0,1,26.3,8.844ZM19.723,39.087a1.315,1.315,0,1,1-1.315-1.315A1.319,1.319,0,0,1,19.723,39.087ZM18.409,8.844a1.315,1.315,0,1,1,1.315-1.315A1.317,1.317,0,0,1,18.409,8.844ZM35.5,39.087a1.315,1.315,0,1,1-1.315-1.315A1.319,1.319,0,0,1,35.5,39.087Zm0-31.558a1.315,1.315,0,1,1-1.315-1.315A1.317,1.317,0,0,1,35.5,7.529ZM19.723,31.2a1.315,1.315,0,1,1-1.315-1.315A1.319,1.319,0,0,1,19.723,31.2Zm14.464,1.315A1.315,1.315,0,1,1,35.5,31.2,1.319,1.319,0,0,1,34.187,32.512ZM3.945,31.2A1.315,1.315,0,1,1,2.63,29.882,1.317,1.317,0,0,1,3.945,31.2ZM34.187,16.733A1.315,1.315,0,1,1,35.5,15.419,1.319,1.319,0,0,1,34.187,16.733ZM3.945,15.419A1.315,1.315,0,1,1,2.63,14.1,1.317,1.317,0,0,1,3.945,15.419Zm14.464,1.315a1.315,1.315,0,1,1,1.315-1.315A1.319,1.319,0,0,1,18.409,16.733Z" transform="translate(211.985 693.086)" fill="#fff" />
        </g>
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "horizontal-split-icon",
});
</script>
