import FrameHelper from "../frame/frame-helper";
import BoardController from "./board-controller";
import self from "../../index";
import {
    TOOL_MODE_SELECT,
} from "../constants";
import BoardHelper from "./board-helper";
import { debounce } from "../../../../helpers/utils";

const {
    modules,
    events,
} = self.app;
const data = {
    listenersAdded: false,
};

function onHistoryGo() {
    if (!BoardController.isActiveTool()) return;
    BoardController.currentBoardEntity = null;
    FrameHelper.hideAllFrames();
}

function onToolBoardDeactivate() {
    modules.toolManager.controller.deactivateTool(BoardController.atool.tool_name);
    BoardController.atool.activateModeAlone("");
}

function onToolBoardUpdate(payload) {
    switch (payload.toolModeName) {
        case TOOL_MODE_SELECT:
            modules.toolManager.controller.activateToolAlone(BoardController.atool.tool_name);
            BoardController.atool.activateModeAlone(payload.toolModeName);
            break;
        default:
            onToolBoardDeactivate();
            break;
    }
}

function onBoardSelect(boardEntity) {
    BoardController.currentBoardEntity = boardEntity;
    events.emit("board:selected", boardEntity);
}

function onBoardRemove() {
    const boardEntity = BoardController.currentBoardEntity;
    if (!boardEntity) return;
    FrameHelper.collapseFrame(boardEntity.getIdParent());
}

function editDistanceFrom(distance, from, fromFrameEntity) {
    BoardHelper.setDistanceFrom(distance, from, fromFrameEntity);
}

const BoardEventsManager = {
    addEventListeners() {
        if (data.listenersAdded) return;

        events.on("@history.history-go", onHistoryGo);
        events.on("@vuejs.tool:board:update", onToolBoardUpdate);
        events.on("tool:board:update", onToolBoardUpdate);
        events.on("board:select", onBoardSelect);
        events.on("board:remove", onBoardRemove);
        events.on("@vuejs.board:remove", onBoardRemove);

        events.on("@vuejs.board:edit:distance", editDistanceFrom);

        data.listenersAdded = true;
    },

    // emit board weakness with debounce
    emitBoardWeakness: debounce(() => {
        events.emit("notification:message", {
            title: "Attention",
            text: "Fragilité d'étagère détectée. Au delà de 780mm, veuillez ajouter une séparation verticale en dessous",
            status: "error",
        });
    }, 200),
};

export default BoardEventsManager;
