import {
    ActionManager,
    ExecuteCodeAction,
} from "@babylonjs/core";
import PulloutshelfEntityBuilder from "./pulloutshelf-entity-builder";
import PulloutshelfMeshBuilder from "./pulloutshelf-mesh-builder";

import self from "../../index";
import PulloutshelfHelper from "./pulloutshelf-helper";
import {
    DRAWER_RUNNER_WEIGHT_1,
    PULLOUTSHELF,
    TOOL_MODE_SELECT,
} from "../constants";
import PulloutshelfATool from "./pulloutshelf-atool";
import PulloutshelfEventsManager from "./pulloutshelf-events-manager";
import PulloutshelfDisplacement from "./pulloutshelf-displacement";
import furnitureUiButtons from "../furniture/furniture-ui-buttons";
import DrawerRunnerHelper from "../drawer/drawer-runner-helper";
import PulloutComposition from "./pullout-composition";
import ConstraintsManager from "../constraints-manager";
import { PULLOUTSHELF_CONSTRAINTS } from "../constraints";

const {
    modules,
    events,
    config,
} = self.app;

const data = {
    atool: new PulloutshelfATool(),
    currentPulloutshelfEntity: null,
};

const PulloutshelfController = {

    get atool() {
        return data.atool;
    },

    get currentPulloutshelfEntity() {
        return data.currentPulloutshelfEntity;
    },
    set currentPulloutshelfEntity(pulloutshelfEntity) {
        PulloutshelfHelper.deselectCurrentPulloutshelfEntityMaterials();
        data.currentPulloutshelfEntity = pulloutshelfEntity;
        events.emit("measures:updated");

        if (!pulloutshelfEntity) return;
        pulloutshelfEntity.mesh.material.emissiveColor = config.get("selectedBoardColor");
        pulloutshelfEntity.mesh.material.alpha = 1;

        pulloutshelfEntity.meshDepth.material.emissiveColor = config.get("selectedBoardColor");
        pulloutshelfEntity.meshDepth.material.alpha = 1;

        pulloutshelfEntity.meshFront.material.emissiveColor = config.get("selectedBoardColor");
        pulloutshelfEntity.meshFront.material.alpha = 1;

        pulloutshelfEntity.mesh.setFacesVisible(false);
        pulloutshelfEntity.meshDepth.setFacesVisible(false);
        pulloutshelfEntity.meshFront.setFacesVisible(false);
    },

    /**
     * Add Mouse actions : over, out, pick
     * @param {PulloutshelfEntity} pulloutshelfEntity
     * @returns
     */
    addMouseActionsToFrameMesh(pulloutshelfEntity) {
        const hitbox = pulloutshelfEntity._hitbox;
        // Actions
        hitbox.actionManager = new ActionManager();

        const OnPickDownTrigger = new ExecuteCodeAction(ActionManager.OnPickDownTrigger, () => {
            if (!PulloutshelfController.isActiveTool()) return;
            events.emit("pulloutshelf:select", pulloutshelfEntity);
            PulloutshelfDisplacement.start();
        });

        const OnPointerOutTrigger = new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, () => {
            hitbox.actionManager.unregisterAction(OnPointerOutTrigger);
            hitbox.actionManager.unregisterAction(OnPickDownTrigger);
        });

        const OnPointerOverTrigger = new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, () => {
            hitbox.actionManager.registerAction(OnPointerOutTrigger);
            hitbox.actionManager.registerAction(OnPickDownTrigger);
        });

        hitbox.actionManager.registerAction(OnPointerOverTrigger);

        return hitbox;
    },

    createPulloutshelf: (frameEntity, position) => {
        if (!DrawerRunnerHelper.canAddToFrame(frameEntity, PULLOUTSHELF, DRAWER_RUNNER_WEIGHT_1)) {
            return;
        }

        const canAddPulloutshelf = ConstraintsManager.canAddPulloutshelf(frameEntity);
        if (!canAddPulloutshelf.isAdapted) {
            events.emit("show:modal", {
                text: PULLOUTSHELF_CONSTRAINTS.message,
                isInfo: true,
            });
            return;
        }

        const pulloutshelfEntity = PulloutshelfEntityBuilder.build(frameEntity);
        pulloutshelfEntity.setPositionY(position.y);

        PulloutshelfHelper.addShelfToFrame(pulloutshelfEntity, frameEntity);
        modules.dataStore.addEntity(pulloutshelfEntity, "pulloutshelf");

        events.emit("pulloutshelf:select", pulloutshelfEntity);
        events.emit("furniture:edited");
        furnitureUiButtons.updateButtons();
    },

    generatePulloutshelfMeshAndMaterial(pulloutshelfEntity) {
        const furnitureEntity = modules.dataStore.getEntity(pulloutshelfEntity.getFurnitureId());
        PulloutshelfMeshBuilder.buildDefault(pulloutshelfEntity, furnitureEntity.container);
        PulloutComposition.updatePulloutComposition(pulloutshelfEntity);
        if (furnitureUiButtons.isUiMainTabFinishing) {
            PulloutshelfHelper.applyFinishMaterial(pulloutshelfEntity);
        } else {
            PulloutshelfHelper.applyConstructionMaterial(pulloutshelfEntity);
        }
        PulloutshelfController.addMouseActionsToFrameMesh(pulloutshelfEntity);
    },

    isSelectActiveToolMode() {
        return PulloutshelfController.atool.isModeActive(TOOL_MODE_SELECT);
    },

    isActiveTool() {
        return PulloutshelfController.atool.tool_isActive;
    },

    clearAllFromFurnitureId(furnitureId) {
        const pulloutshelfEntities = modules.dataStore.listEntities("pulloutshelf");
        pulloutshelfEntities.forEach((pulloutshelfEntity) => {
            if (pulloutshelfEntity.furnitureId === furnitureId) {
                const frameEntity = modules.dataStore.getEntity(pulloutshelfEntity.idParent);
                frameEntity.idPulloutshelfs = [];
                modules.dataStore.removeEntity(pulloutshelfEntity);
            }
        });
    },

    switchMaterialToConstruction() {
        modules.dataStore.listEntities("pulloutshelf").forEach((movableshelfEntity) => {
            PulloutshelfHelper.applyConstructionMaterial(movableshelfEntity);
        });
    },

    switchMaterialsToFinition() {
        modules.dataStore.listEntities("pulloutshelf").forEach((movableshelfEntity) => {
            PulloutshelfHelper.applyFinishMaterial(movableshelfEntity);
        });
    },

    hideAll() {
        const pulloutshelfEntities = modules.dataStore.listEntities("pulloutshelf");
        pulloutshelfEntities.forEach((pulloutshelfEntity) => {
            pulloutshelfEntity.hide();
        });
    },

    showAll() {
        const pulloutshelfEntities = modules.dataStore.listEntities("pulloutshelf");
        pulloutshelfEntities.forEach((pulloutshelfEntity) => {
            pulloutshelfEntity.show();
        });
    },
};

PulloutshelfEventsManager.addEventListeners();
export default PulloutshelfController;
