<template>
    <svg
        v-if="isColored"
        xmlns="http://www.w3.org/2000/svg"
        width="24.998"
        height="30.6"
        viewBox="0 0 24.998 30.6"
    >
        <desc>Une icône de côtes</desc>
        <g id="icon-size-color" transform="translate(-618.2 -619.723)">
            <g id="Groupe_343" data-name="Groupe 343" transform="translate(435.64 1.023)">
                <line id="Ligne_13"
                      data-name="Ligne 13"
                      y2="20.954"
                      transform="translate(203.5 624.5)"
                      fill="none"
                      stroke="#ec910a"
                      stroke-linecap="round"
                      stroke-width="1.5"
                />
                <line id="Ligne_14"
                      data-name="Ligne 14"
                      x2="3"
                      y2="3.78"
                      transform="translate(203.5 623.5)"
                      fill="none"
                      stroke="#ec910a"
                      stroke-linecap="round"
                      stroke-width="1.5"
                />
                <path id="Tracé_149"
                      data-name="Tracé 149"
                      d="M0,0,3.554,3"
                      transform="translate(203.5 623.5) rotate(90)"
                      fill="none"
                      stroke="#ec910a"
                      stroke-linecap="round"
                      stroke-width="1.5"
                />
                <line id="Ligne_16"
                      data-name="Ligne 16"
                      x1="3"
                      y1="3.5"
                      transform="translate(200.5 641.954)"
                      fill="none"
                      stroke="#ec910a"
                      stroke-linecap="round"
                      stroke-width="1.5"
                />
                <line id="Ligne_17"
                      data-name="Ligne 17"
                      y1="3.5"
                      x2="3"
                      transform="translate(203.5 641.954)"
                      fill="none"
                      stroke="#ec910a"
                      stroke-linecap="round"
                      stroke-width="1.5"
                />
            </g>
            <g id="Groupe_417" data-name="Groupe 417" transform="translate(271 59.523)">
                <g id="Groupe_415" data-name="Groupe 415" transform="translate(351.947 564.379)">
                    <path id="Rectangle_468" data-name="Rectangle 468" d="M.563,0H2.526a0,0,0,0,1,0,0V1.125a0,0,0,0,1,0,0H.563A.563.563,0,0,1,0,.563v0A.563.563,0,0,1,.563,0Z" transform="translate(2.947 5.023)" />
                    <path id="Rectangle_469" data-name="Rectangle 469" d="M.563,0H2.526a0,0,0,0,1,0,0V1.125a0,0,0,0,1,0,0H.563A.563.563,0,0,1,0,.563v0A.563.563,0,0,1,.563,0Z" transform="translate(2.947)" />
                    <path id="Rectangle_470" data-name="Rectangle 470" d="M.563,0H2.526a0,0,0,0,1,0,0V1.125a0,0,0,0,1,0,0H.563A.563.563,0,0,1,0,.563v0A.563.563,0,0,1,.563,0Z" transform="translate(2.947 7.535)" />
                    <path id="Rectangle_471" data-name="Rectangle 471" d="M.563,0H2.526a0,0,0,0,1,0,0V1.125a0,0,0,0,1,0,0H.563A.563.563,0,0,1,0,.563v0A.563.563,0,0,1,.563,0Z" transform="translate(2.947 14.442)" />
                    <path id="Rectangle_472" data-name="Rectangle 472" d="M.563,0H2.526a0,0,0,0,1,0,0V1.125a0,0,0,0,1,0,0H.563A.563.563,0,0,1,0,.563v0A.563.563,0,0,1,.563,0Z" transform="translate(2.947 19.465)" />
                    <path id="Rectangle_473" data-name="Rectangle 473" d="M.563,0H2.526a0,0,0,0,1,0,0V1.125a0,0,0,0,1,0,0H.563A.563.563,0,0,1,0,.563v0A.563.563,0,0,1,.563,0Z" transform="translate(2.947 21.977)" />
                    <path id="Rectangle_474" data-name="Rectangle 474" d="M.563,0H2.526a0,0,0,0,1,0,0V1.125a0,0,0,0,1,0,0H.563A.563.563,0,0,1,0,.563v0A.563.563,0,0,1,.563,0Z" transform="translate(2.947 11.93)" />
                    <path id="Rectangle_475"
                          data-name="Rectangle 475"
                          d="M.563,0H5.474a0,0,0,0,1,0,0V1.125a0,0,0,0,1,0,0H.563A.563.563,0,0,1,0,.563v0A.563.563,0,0,1,.563,0Z"
                          transform="translate(0 10.047)"
                          fill="#e83e8c"
                    />
                    <path id="Rectangle_476"
                          data-name="Rectangle 476"
                          d="M.563,0H5.474a0,0,0,0,1,0,0V1.125a0,0,0,0,1,0,0H.563A.563.563,0,0,1,0,.563v0A.563.563,0,0,1,.563,0Z"
                          transform="translate(0 2.512)"
                          fill="#e83e8c"
                    />
                    <path id="Rectangle_477"
                          data-name="Rectangle 477"
                          d="M.563,0H5.474a0,0,0,0,1,0,0V1.125a0,0,0,0,1,0,0H.563A.563.563,0,0,1,0,.563v0A.563.563,0,0,1,.563,0Z"
                          transform="translate(0 16.953)"
                          fill="#e83e8c"
                    />
                </g>
                <g id="Rectangle_478"
                   data-name="Rectangle 478"
                   transform="translate(349 562)"
                   fill="none"
                   stroke="#000"
                   stroke-width="1.8"
                >
                    <rect width="8" height="27" rx="1" stroke="none" />
                    <rect x="-0.9"
                          y="-0.9"
                          width="9.8"
                          height="28.8"
                          rx="1.9"
                          fill="none"
                    />
                </g>
            </g>
        </g>
    </svg>
    <svg v-else
         xmlns="http://www.w3.org/2000/svg"
         width="24.998"
         height="30.6"
         viewBox="0 0 24.998 30.6"
    >
        <g id="icon-size-white" transform="translate(-618.2 -619.723)">
            <g id="Groupe_343" data-name="Groupe 343" transform="translate(435.64 1.023)">
                <line id="Ligne_13"
                      data-name="Ligne 13"
                      y2="20.954"
                      transform="translate(203.5 624.5)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-width="1.5"
                />
                <line id="Ligne_14"
                      data-name="Ligne 14"
                      x2="3"
                      y2="3.78"
                      transform="translate(203.5 623.5)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-width="1.5"
                />
                <path id="Tracé_149"
                      data-name="Tracé 149"
                      d="M0,0,3.554,3"
                      transform="translate(203.5 623.5) rotate(90)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-width="1.5"
                />
                <line id="Ligne_16"
                      data-name="Ligne 16"
                      x1="3"
                      y1="3.5"
                      transform="translate(200.5 641.954)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-width="1.5"
                />
                <line id="Ligne_17"
                      data-name="Ligne 17"
                      y1="3.5"
                      x2="3"
                      transform="translate(203.5 641.954)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-width="1.5"
                />
            </g>
            <g id="Groupe_417" data-name="Groupe 417" transform="translate(271 59.523)">
                <g id="Groupe_415" data-name="Groupe 415" transform="translate(351.947 564.379)">
                    <path id="Rectangle_468"
                          data-name="Rectangle 468"
                          d="M.563,0H2.526a0,0,0,0,1,0,0V1.125a0,0,0,0,1,0,0H.563A.563.563,0,0,1,0,.563v0A.563.563,0,0,1,.563,0Z"
                          transform="translate(2.947 5.023)"
                          fill="#fff"
                    />
                    <path id="Rectangle_469"
                          data-name="Rectangle 469"
                          d="M.563,0H2.526a0,0,0,0,1,0,0V1.125a0,0,0,0,1,0,0H.563A.563.563,0,0,1,0,.563v0A.563.563,0,0,1,.563,0Z"
                          transform="translate(2.947)"
                          fill="#fff"
                    />
                    <path id="Rectangle_470"
                          data-name="Rectangle 470"
                          d="M.563,0H2.526a0,0,0,0,1,0,0V1.125a0,0,0,0,1,0,0H.563A.563.563,0,0,1,0,.563v0A.563.563,0,0,1,.563,0Z"
                          transform="translate(2.947 7.535)"
                          fill="#fff"
                    />
                    <path id="Rectangle_471"
                          data-name="Rectangle 471"
                          d="M.563,0H2.526a0,0,0,0,1,0,0V1.125a0,0,0,0,1,0,0H.563A.563.563,0,0,1,0,.563v0A.563.563,0,0,1,.563,0Z"
                          transform="translate(2.947 14.442)"
                          fill="#fff"
                    />
                    <path id="Rectangle_472"
                          data-name="Rectangle 472"
                          d="M.563,0H2.526a0,0,0,0,1,0,0V1.125a0,0,0,0,1,0,0H.563A.563.563,0,0,1,0,.563v0A.563.563,0,0,1,.563,0Z"
                          transform="translate(2.947 19.465)"
                          fill="#fff"
                    />
                    <path id="Rectangle_473"
                          data-name="Rectangle 473"
                          d="M.563,0H2.526a0,0,0,0,1,0,0V1.125a0,0,0,0,1,0,0H.563A.563.563,0,0,1,0,.563v0A.563.563,0,0,1,.563,0Z"
                          transform="translate(2.947 21.977)"
                          fill="#fff"
                    />
                    <path id="Rectangle_474"
                          data-name="Rectangle 474"
                          d="M.563,0H2.526a0,0,0,0,1,0,0V1.125a0,0,0,0,1,0,0H.563A.563.563,0,0,1,0,.563v0A.563.563,0,0,1,.563,0Z"
                          transform="translate(2.947 11.93)"
                          fill="#fff"
                    />
                    <path id="Rectangle_475"
                          data-name="Rectangle 475"
                          d="M.563,0H5.474a0,0,0,0,1,0,0V1.125a0,0,0,0,1,0,0H.563A.563.563,0,0,1,0,.563v0A.563.563,0,0,1,.563,0Z"
                          transform="translate(0 10.047)"
                          fill="#fff"
                    />
                    <path id="Rectangle_476"
                          data-name="Rectangle 476"
                          d="M.563,0H5.474a0,0,0,0,1,0,0V1.125a0,0,0,0,1,0,0H.563A.563.563,0,0,1,0,.563v0A.563.563,0,0,1,.563,0Z"
                          transform="translate(0 2.512)"
                          fill="#fff"
                    />
                    <path id="Rectangle_477"
                          data-name="Rectangle 477"
                          d="M.563,0H5.474a0,0,0,0,1,0,0V1.125a0,0,0,0,1,0,0H.563A.563.563,0,0,1,0,.563v0A.563.563,0,0,1,.563,0Z"
                          transform="translate(0 16.953)"
                          fill="#fff"
                    />
                </g>
                <g id="Rectangle_478"
                   data-name="Rectangle 478"
                   transform="translate(349 562)"
                   fill="none"
                   stroke="#fff"
                   stroke-width="1.8"
                >
                    <rect width="8" height="27" rx="1" stroke="none" />
                    <rect x="-0.9"
                          y="-0.9"
                          width="9.8"
                          height="28.8"
                          rx="1.9"
                          fill="none"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "measures-icon",
    props: {
        isColored: {
            type: Boolean,
            default: true,
        },
    },
});
</script>
