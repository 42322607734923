<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="33.112" height="38.38" viewBox="0 0 33.112 38.38">
        <g id="icon-door-ext-center" transform="translate(-766.3 -346.3)">
            <path id="Soustraction_53"
                  data-name="Soustraction 53"
                  d="M-699,2357.3h-4.369v-1.5H-699v1.5Zm0-2.7h-4.369v-22.242a1.7,1.7,0,0,0-.523-1.372l-6.007-3.086h17.427l-6.006,3.085a1.7,1.7,0,0,0-.523,1.372V2354.6Zm8.668-27.8h-21.7l-2.922-1.5h27.549l-2.922,1.5Z"
                  transform="translate(1484 -1979)"
                  fill="#fff"
            />
            <path id="Soustraction_54"
                  data-name="Soustraction 54"
                  d="M.016,36.98h0V6.1C.04,6.1.576,5.927,11.624.032A.25.25,0,0,1,11.742,0c.226,0,.383.356.383.866V29.655a1.736,1.736,0,0,1-.5,1.371C.813,36.812.066,36.98.016,36.98Zm9.72-23.556a.751.751,0,0,0-.75.75v4.513a.75.75,0,0,0,1.5,0V14.173A.751.751,0,0,0,9.736,13.424Z"
                  transform="translate(798.926 384.18) rotate(180)"
                  fill="#fff"
                  stroke="rgba(0,0,0,0)"
                  stroke-width="1"
            />
            <path id="Soustraction_55"
                  data-name="Soustraction 55"
                  d="M11.729,36.979a.25.25,0,0,1-.118-.032C.562,31.052.026,30.881,0,30.881V0H0C.053,0,.82.178,11.611,5.954a1.733,1.733,0,0,1,.5,1.371V36.113C12.112,36.623,11.955,36.979,11.729,36.979ZM9.723,17.544a.751.751,0,0,0-.75.75v4.513a.75.75,0,1,0,1.5,0V18.293A.751.751,0,0,0,9.723,17.544Z"
                  transform="translate(766.8 347.199)"
                  fill="#fff"
                  stroke="rgba(0,0,0,0)"
                  stroke-width="1"
            />
        </g>
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "door-center-outside-icon",
});
</script>
