import ATool from "../../../tool-manager/src/atool";
import {
    TOOL_MODE_SELECT,
} from "../constants";

import self from "../../index";

const {
    modules,
    events,
} = self.app;

class InsetdrawerATool extends ATool {

    constructor() {
        super({
            tool_name: "insetdrawer",
        });
        this.tool_modes = [TOOL_MODE_SELECT];
        modules.toolManager.controller.registerTool(this);
    }

    // Atool method
    // eslint-disable-next-line class-methods-use-this
    onActiveCallback() {}

    // Atool method
    // eslint-disable-next-line class-methods-use-this
    onEndActiveCallback() {
        events.emit("insetdrawer:select", null);
    }

}

export default InsetdrawerATool;
