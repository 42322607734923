<template>
    <div v-show="visible" id="tweakpane" />
</template>

<script>
import Mousetrap from "mousetrap";
import { Pane } from "tweakpane";

import self from "../../index";

const { events } = self.app;

export default {
    name: "tweakpane",

    data() {
        return {
            visible: false,
        };
    },
    created() {
        Mousetrap.bind("ctrl+shift+f2", this.toggleVisibility);
    },

    mounted() {
        if (process.env.NODE_ENV === "development") {
            this.pane = new Pane({
                title: "Press ctrl+shift+f2 to hide/show",
                expanded: true,
                width: 500,
                container: document.querySelector("#tweakpane"),
            });
            events.emit("tweakpane:created", this.pane);
        }
    },
    methods: {
        toggleVisibility() {
            this.visible = !this.visible;
        },
    },
};
</script>

<style lang="scss" scoped>
#tweakpane {
    position: fixed;
    right: 7rem;
    top: 1rem;
    z-index: 11;
    user-select: none;
}
#tweakpane * {
    user-select: none;
}
</style>
