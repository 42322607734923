import self from "../../index";
import AccessorydrawerEntity from "./accessorydrawer-entity";

const {
    modules,
} = self.app;

const AccessorydrawerEntityBuilder = {
    build(frameEntity) {
        const furnitureEntity = modules.dataStore.getEntity(frameEntity.getFurnitureId());
        return new AccessorydrawerEntity({
            furnitureId: furnitureEntity.id,
            idParent: frameEntity.id,
            scaling: frameEntity.scaling.clone(),
            position: frameEntity.position.clone(),
            isCoveredByBackboard: frameEntity.hasBackboard(),
            isCoveredByDoor: frameEntity.hasDoor(),
        });
    },
};
export default AccessorydrawerEntityBuilder;
