import {
    StandardMaterial,
} from "@babylonjs/core/Materials/standardMaterial";
import self from "../../index";

const FrameMaterialBuilder = {

    buildDefault() {
        const material = new StandardMaterial("interactMat");
        material.emissiveColor = self.config.selectedFrameColor;
        material.specularColor.set(0, 0, 0);
        material.backFaceCulling = true;
        material.alpha = self.config.frameAlpha;
        return material;
    },

};

export default FrameMaterialBuilder;
