import {
    Vector2,
} from "@babylonjs/core/Maths/math.vector";
import BBox2Helper, {
    BBox2,
} from "../../../../helpers/BBox2Helper";

import self from "../../index";
import {
    BOARD_THICKNESS,
    BOTTOM,
    MOVABLESHELF_BBOX_HALF_HEIGHT,
    ROD_RADIUS_HALF,
    TOP,
} from "../constants";
import MovableshelfController from "./movableshelf-controller";
import MeasureHelper from "../measure/measure-helper";
import { clamp } from "../../../../helpers/utils";
import furnitureUiButtons from "../furniture/furniture-ui-buttons";
import MaterialManager from "../materials/material-manager";

const {
    events,
    modules,
} = self.app;

const MovableshelfHelper = {

    canAddToFrame: (frameEntity, position) => {

        if (frameEntity.idDrawer) return false;

        const bbFrame = BBox2Helper.getBBox2FromObject3dEntity(frameEntity);
        const sideHeight = 32 * 2.5;
        const bbMovableshelf = new BBox2(new Vector2(bbFrame.p1.x, position.y - sideHeight), new Vector2(bbFrame.p2.x, position.y + sideHeight));
        if (!BBox2Helper.BBox2containBBox2(bbFrame, bbMovableshelf)) return false;

        const otherMovableshelfs = frameEntity.getIdMovableshelfs();
        for (let i = 0, il = otherMovableshelfs.length; i < il; i++) {
            const otherMovableshelf = modules.dataStore.getEntity(otherMovableshelfs[i]);
            if (BBox2Helper.BBox2intersectBBox2(bbMovableshelf, otherMovableshelf.bbox2)) return false;
        }
        return true;
    },

    /**
     * ordered by position.y. index 0 is the lowest movableshelf
     * @param {MovableshelfEntity} movableshelfEntity
     * @param {FrameEntity} frameEntity
     * @returns
     */

    addShelfToFrame: (movableshelfEntity, frameEntity) => {
        const otherMovableshelfs = frameEntity.getIdMovableshelfs();
        if (frameEntity.getIdMovableshelfs().indexOf(movableshelfEntity.id) > -1) return;

        if (!frameEntity.hasMovableshelf()) {
            otherMovableshelfs.push(movableshelfEntity.id);
            frameEntity.setIdMovableshelfs(otherMovableshelfs);
            return;
        }
        let added = false;
        for (let i = 0, il = otherMovableshelfs.length; i < il; i++) {
            const otherMovableshelf = modules.dataStore.getEntity(otherMovableshelfs[i]);
            if (movableshelfEntity.getPosition().y < otherMovableshelf.getPosition().y) {
                otherMovableshelfs.splice(i, 0, movableshelfEntity.id);
                added = true;
                break;
            }
        }
        if (!added) otherMovableshelfs.push(movableshelfEntity.id);
        frameEntity.setIdMovableshelfs(otherMovableshelfs);
    },

    updateBbox2: (movableshelfEntity) => {
        movableshelfEntity.bbox2.updateFromMesh(movableshelfEntity.mesh);
        movableshelfEntity.bbox2 = new BBox2(
            new Vector2(movableshelfEntity.bbox2.p1.x, movableshelfEntity.bbox2.p1.y - MOVABLESHELF_BBOX_HALF_HEIGHT),
            new Vector2(movableshelfEntity.bbox2.p2.x, movableshelfEntity.bbox2.p2.y + MOVABLESHELF_BBOX_HALF_HEIGHT)
        );
    },

    setIsCoveredByBackboard: (frameEntity, isCovered) => {
        const movableshelfs = frameEntity.idMovableshelfs;
        const furnitureEntity = modules.dataStore.getEntity(frameEntity.getFurnitureId());
        for (let i = 0, il = movableshelfs.length; i < il; i++) {
            const movableshelf = modules.dataStore.getEntity(movableshelfs[i]);
            movableshelf.isCoveredByBackboard = isCovered;
            movableshelf.scalingZ = furnitureEntity.scaling.z;
        }
    },

    setIsCoveredByDoor: (frameEntity, isCovered) => {
        const movableshelfs = frameEntity.idMovableshelfs;
        const furnitureEntity = modules.dataStore.getEntity(frameEntity.getFurnitureId());
        for (let i = 0, il = movableshelfs.length; i < il; i++) {
            const movableshelf = modules.dataStore.getEntity(movableshelfs[i]);
            movableshelf.isCoveredByDoor = isCovered;
            movableshelf.positionZ = furnitureEntity.position.z;
            movableshelf.scalingZ = furnitureEntity.scaling.z;
        }
    },

    deleteCurrentMovableshelf: () => {
        const movableshelfEntity = MovableshelfController.currentMovableshelfEntity;
        if (!movableshelfEntity) return;
        MovableshelfController.currentMovableshelfEntity = null;
        const frameEntity = modules.dataStore.getEntity(movableshelfEntity.getIdParent());
        const movableshelfs = frameEntity.getIdMovableshelfs();
        movableshelfs.splice(movableshelfs.indexOf(movableshelfEntity.id), 1);
        if (movableshelfEntity.hasRod) {
            modules.dataStore.removeEntity(movableshelfEntity.idRod);
        }
        modules.dataStore.removeEntity(movableshelfEntity.id);
        events.emit("furniture:edited");
        events.emit("measures:updated");
        furnitureUiButtons.updateButtons();
    },

    deselectCurrentMovableshelfEntityMaterial() {
        if (MovableshelfController.currentMovableshelfEntity) {
            const {
                mesh,
            } = MovableshelfController.currentMovableshelfEntity;
            mesh.material.alpha = 0;
            mesh.setFacesVisible(true);
        }
    },

    getMovableshelfDisplacementLimits: (movableshelfEntity) => {
        const frameEntityParent = modules.dataStore.getEntity(movableshelfEntity.getIdParent());
        const allMovableshelfOfframeEntity = frameEntityParent.getIdMovableshelfs();
        const index = allMovableshelfOfframeEntity.indexOf(movableshelfEntity.id);
        let min;
        let max;

        if (index > 0) {
            const previousMovableshelfEntity = modules.dataStore.getEntity(allMovableshelfOfframeEntity[index - 1]);
            min = previousMovableshelfEntity.bbox2.p2.y + movableshelfEntity.bbox2.height * 0.5 + ROD_RADIUS_HALF;
        } else {
            min = frameEntityParent.getPosition().y + movableshelfEntity.bbox2.height * 0.5 + ROD_RADIUS_HALF;
        }
        if (index < allMovableshelfOfframeEntity.length - 1) {
            const nextMovableshelfEntity = modules.dataStore.getEntity(allMovableshelfOfframeEntity[index + 1]);
            max = nextMovableshelfEntity.bbox2.p1.y - movableshelfEntity.bbox2.height * 0.5 + ROD_RADIUS_HALF;
        } else {
            max = frameEntityParent.getPosition().y + frameEntityParent.getScaling().y - movableshelfEntity.bbox2.height * 0.5 + ROD_RADIUS_HALF;
        }

        return {
            min,
            max,
        };
    },

    showMeasures: (movableshelfEntity) => {
        events.emit("measures:updated", MeasureHelper.getMovableshelfMeasures(movableshelfEntity));
    },

    getRelativeDistanceLimitForInput(movableshelfEntity, from) {
        const frameEntityParent = modules.dataStore.getEntity(movableshelfEntity.getIdParent());
        const positionLimits = MovableshelfHelper.getMovableshelfDisplacementLimits(movableshelfEntity);

        let convertGlobalLimitToFrameLocal = {
            min: 0,
            max: 0,
        };
        switch (from) {
            case TOP:
                convertGlobalLimitToFrameLocal = {
                    min: frameEntityParent.position.y + frameEntityParent.scaling.y - positionLimits.max - BOARD_THICKNESS,
                    max: frameEntityParent.position.y + frameEntityParent.scaling.y - positionLimits.min - BOARD_THICKNESS,
                };
                break;
            case BOTTOM:
                convertGlobalLimitToFrameLocal = {
                    min: positionLimits.min - frameEntityParent.position.y,
                    max: positionLimits.max - frameEntityParent.position.y,
                };
                break;
            default:
                break;
        }

        return convertGlobalLimitToFrameLocal;
    },

    setDistanceFrom: (distance, from) => {
        const {
            currentMovableshelfEntity,
        } = MovableshelfController;
        if (!currentMovableshelfEntity) return;

        const frameEntityParent = modules.dataStore.getEntity(currentMovableshelfEntity.getIdParent());

        const positionLimits = MovableshelfHelper.getMovableshelfDisplacementLimits(currentMovableshelfEntity);

        let positionY;
        switch (from) {
            case TOP:
                positionY = frameEntityParent.position.y + frameEntityParent.scaling.y - distance - BOARD_THICKNESS;
                break;
            case BOTTOM:
                positionY = frameEntityParent.position.y + distance;
                break;
            default:
                break;
        }

        const clampedPositionY = clamp(positionY, positionLimits.min, positionLimits.max);
        if (positionY === clampedPositionY) {
            events.emit("movableshelf:edit:distance:edited");
            currentMovableshelfEntity.setPositionY(positionY);
            MovableshelfHelper.showMeasures(currentMovableshelfEntity);
        } else {
            const convertGlobalLimitToFrameLocal = MovableshelfHelper.getRelativeDistanceLimitForInput(currentMovableshelfEntity, from);
            events.emit("movableshelf:edit:distance:rejected");
            events.emit("notification:message", {
                title: "position incorrecte",
                text: `Doit être comprit entre ${convertGlobalLimitToFrameLocal.min} et ${convertGlobalLimitToFrameLocal.max} mm`,
                status: "error",
            });
        }
    },

    applyFinishMaterial(movableShelfEntity) {
        const { mesh } = movableShelfEntity;
        const faces = [mesh.faceBack, mesh.faceLeft, mesh.faceRight, mesh.faceTop, mesh.faceBottom];
        faces.forEach((face) => {
            if (mesh.scaling.x > mesh.scaling.z || face === mesh.faceFront) {
                face.material = MaterialManager.mainFinishMaterial90;
            } else {
                face.material = MaterialManager.mainFinishMaterial;
            }
        });
        mesh.faceFront.material = MaterialManager.borderFinishMaterial90;
        mesh.faceTop.material = MaterialManager.mainFinishMaterial90;
        mesh.faceBottom.material = MaterialManager.mainFinishMaterial90;
        mesh.updateUVScaling();
    },

    applyConstructionMaterial(movableShelfEntity) {
        const { mesh } = movableShelfEntity;
        const faces = [mesh.faceFront, mesh.faceBack, mesh.faceLeft, mesh.faceRight, mesh.faceTop, mesh.faceBottom];
        faces.forEach((face) => {
            face.material = MaterialManager.mainConstructionMaterial;
        });
    },

};
export default MovableshelfHelper;
