import { BOARD_THICKNESS, DRAWER_MAX_HEIGHT_UTIL, DRAWER_MIN_HEIGHT_UTIL, DRAWER_RUNNER_SPACE_BEHIND_BOTTOM_BOARD, DRAWER_SPACE_ABOVE_BOTTOM, DRAWER_SPACE_ABOVE_UTIL, DRAWER_SPACE_BELOW_TOP } from "./constants";

export const RESISTANCE_SOFT = "RESISTANCE_SOFT";
export const RESISTANCE_HARD = "RESISTANCE_HARD";

const constraints = {

    // FURNITURE

    MIN_FURNITURE_WIDTH: {
        key: "MIN_FURNITURE_WIDTH",
        enabled: true,
        min: 238,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.x < this.min;
        },
    },

    MAX_FURNITURE_WIDTH: {
        key: "MAX_FURNITURE_WIDTH",
        enabled: true,
        max: 1226,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.x > this.max;
        },
    },

    MIN_FURNITURE_HEIGHT: {
        key: "MIN_FURNITURE_HEIGHT",
        enabled: true,
        min: 203,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.y < this.min;
        },
    },

    MAX_FURNITURE_HEIGHT: {
        key: "MAX_FURNITURE_HEIGHT",
        enabled: true,
        max: 2753,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.y > this.max;
        },
    },

    MIN_FURNITURE_DEPTH: {
        key: "MIN_FURNITURE_DEPTH",
        enabled: true,
        min: 138,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.z < this.min;
        },
    },

    MAX_FURNITURE_DEPTH: {
        key: "MAX_FURNITURE_DEPTH",
        enabled: true,
        max: 850,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.z > this.max;
        },
    },

    MIN_BASEBOARD_HEIGHT: {
        key: "MIN_BASEBOARD_HEIGHT",
        enabled: true,
        min: 55,
        resistance: RESISTANCE_HARD,
        check(height) {
            return height < this.min;
        },
    },

    MAX_BASEBOARD_HEIGHT: {
        key: "MAX_BASEBOARD_HEIGHT",
        enabled: true,
        max: 220,
        resistance: RESISTANCE_HARD,
        check(height) {
            return height > this.max;
        },
    },

    // FRAME

    MIN_FRAME_WIDTH: {
        key: "MIN_FRAME_WIDTH",
        enabled: true,
        min: 200,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.x < this.min;
        },
    },

    MAX_FRAME_WIDTH: {
        key: "MAX_FRAME_WIDTH",
        enabled: true,
        max: 780,
        resistance: RESISTANCE_SOFT,
        check(scaling) {
            return scaling.x > this.max;
        },
    },

    MAX_FRAME_BOTTOM_VERTICAL_SPLITTED_WIDTH: {
        key: "MAX_FRAME_BOTTOM_VERTICAL_SPLITTED_WIDTH",
        enabled: true,
        max: 1185,
        resistance: RESISTANCE_SOFT,
        check(scaling) {
            return scaling.x > this.max;
        },
    },


    MIN_FRAME_HEIGHT: {
        key: "MIN_FRAME_HEIGHT",
        enabled: true,
        min: 100,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.y < this.min;
        },
    },

    MAX_FRAME_HEIGHT: {
        key: "MAX_FRAME_HEIGHT",
        enabled: true,
        max: 2750,
        resistance: RESISTANCE_SOFT,
        check(scaling) {
            return scaling.y > this.max;
        },
    },

    // DRAWER

    MIN_DRAWER_WIDTH: {
        key: "MIN_DRAWER_WIDTH",
        enabled: true,
        min: 286,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.x < this.min;
        },
    },

    MAX_DRAWER_WIDTH: {
        key: "MAX_DRAWER_WIDTH",
        enabled: true,
        max: 780,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.x > this.max;
        },
    },

    MIN_DRAWER_HEIGHT: {
        key: "MIN_DRAWER_HEIGHT",
        enabled: true,
        min: DRAWER_SPACE_ABOVE_BOTTOM + DRAWER_RUNNER_SPACE_BEHIND_BOTTOM_BOARD + BOARD_THICKNESS + DRAWER_MIN_HEIGHT_UTIL + DRAWER_SPACE_ABOVE_UTIL + DRAWER_SPACE_BELOW_TOP, // 138
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.y < this.min;
        },
    },

    MAX_DRAWER_HEIGHT: {
        key: "MAX_DRAWER_HEIGHT",
        enabled: true,
        max: DRAWER_SPACE_ABOVE_BOTTOM + DRAWER_RUNNER_SPACE_BEHIND_BOTTOM_BOARD + BOARD_THICKNESS + DRAWER_MAX_HEIGHT_UTIL + DRAWER_SPACE_ABOVE_UTIL + DRAWER_SPACE_BELOW_TOP, // 448
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.y > this.max;
        },
    },

    MIN_ACCESSORYDRAWER_HEIGHT: {
        key: "MIN_ACCESSORYDRAWER_HEIGHT",
        enabled: true,
        min: DRAWER_SPACE_ABOVE_BOTTOM + DRAWER_RUNNER_SPACE_BEHIND_BOTTOM_BOARD + BOARD_THICKNESS + DRAWER_MIN_HEIGHT_UTIL + DRAWER_SPACE_ABOVE_UTIL + DRAWER_SPACE_BELOW_TOP, // 138
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.y < this.min;
        },
    },

    MAX_ACCESSORYDRAWER_HEIGHT: {
        key: "MAX_ACCESSORYDRAWER_HEIGHT",
        enabled: true,
        max: DRAWER_SPACE_ABOVE_BOTTOM + DRAWER_RUNNER_SPACE_BEHIND_BOTTOM_BOARD + BOARD_THICKNESS + DRAWER_MAX_HEIGHT_UTIL + DRAWER_SPACE_ABOVE_UTIL + DRAWER_SPACE_BELOW_TOP, // 448
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.y > this.max;
        },
    },

    MIN_DRAWER_DEPTH: {
        key: "MIN_DRAWER_DEPTH",
        enabled: true,
        min: 202,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.z > this.max;
        },
    },

    MAX_DRAWER_DEPTH: {
        key: "MAX_DRAWER_DEPTH",
        enabled: true,
        max: 702,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.z > this.max;
        },
    },

    // DOOR

    MIN_DOOR_WIDTH: {
        key: "MIN_DOOR_WIDTH",
        door: true,
        enabled: true,
        min: 156,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.x < this.min;
        },
    },

    MAX_DOOR_WIDTH: {
        key: "MAX_DOOR_WIDTH",
        door: true,
        enabled: true,
        max: 575,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.x > this.max;
        },
    },

    MIN_DOOR_HEIGHT: {
        key: "MIN_DOOR_HEIGHT",
        door: true,
        enabled: true,
        min: 206,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.y < this.min;
        },
    },

    MAX_DOOR_HEIGHT: {
        key: "MAX_DOOR_HEIGHT",
        door: true,
        enabled: true,
        max: 2750,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.y > this.max;
        },
    },

    // DOOR

    MIN_DOOR_DOUBLE_WIDTH: {
        key: "MIN_DOOR_DOUBLE_WIDTH",
        door: true,
        enabled: true,
        min: 309, // Based on inset door
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.x < this.min;
        },
    },

    MAX_DOOR_DOUBLE_WIDTH: {
        key: "MAX_DOOR_DOUBLE_WIDTH",
        door: true,
        enabled: true,
        max: 1188, // Based on overlay door
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.x > this.max;
        },
    },

    MIN_DOOR_DOUBLE_HEIGHT: {
        key: "MIN_DOOR_DOUBLE_HEIGHT",
        door: true,
        enabled: true,
        min: 206,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.y < this.min;
        },
    },

    MAX_DOOR_DOUBLE_HEIGHT: {
        key: "MAX_DOOR_DOUBLE_HEIGHT",
        door: true,
        enabled: true,
        max: 2750,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.y > this.max;
        },
    },

    // PULLOUTSHELF
    MIN_PULLOUTSHELF_WIDTH: {
        key: "MIN_PULLOUTSHELF_WIDTH",
        enabled: true,
        min: 222,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.x < this.min;
        },
    },

    MAX_PULLOUTSHELF_WIDTH: {
        key: "MAX_PULLOUTSHELF_WIDTH",
        enabled: true,
        max: 758,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.x > this.max;
        },
    },

    // RODE

    MIN_ROD_WIDTH: {
        key: "MIN_ROD_WIDTH",
        enabled: true,
        min: 500,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.x < this.min;
        },
    },

    MAX_ROD_WIDTH: {
        key: "MAX_ROD_WIDTH",
        enabled: true,
        max: 610,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.x > this.max;
        },
    },

    MIN_ROD_DEPTH: {
        key: "MIN_ROD_WIDTH",
        enabled: true,
        min: 500,
        resistance: RESISTANCE_HARD,
        check(scaling) {
            return scaling.x < this.min;
        },
    },
};

export default constraints;

export const FURNITURE_CONSTRAINTS = {
    min: {
        x: constraints.MIN_FURNITURE_WIDTH.min,
        y: constraints.MIN_FURNITURE_HEIGHT.min,
        z: constraints.MIN_FURNITURE_DEPTH.min,
    },
    max: {
        x: constraints.MAX_FURNITURE_WIDTH.max,
        y: constraints.MAX_FURNITURE_HEIGHT.max,
        z: constraints.MAX_FURNITURE_DEPTH.max,
    },
};

export const FRAME_CONSTRAINTS = {
    min: {
        x: constraints.MIN_FRAME_WIDTH.min,
        y: constraints.MIN_FRAME_HEIGHT.min,
        z: constraints.MIN_FURNITURE_DEPTH.min,
    },
    max: {
        x: constraints.MAX_FRAME_WIDTH.max,
        y: constraints.MAX_FRAME_HEIGHT.max,
        z: constraints.MAX_FURNITURE_DEPTH.max,
    },
    message: `
        <p>Les espaces de niches adjacentes doivent être compris entre:</p>
        <p>Largeur : ${constraints.MIN_FRAME_WIDTH.min}mm et ${constraints.MAX_FRAME_WIDTH.max}mm</p>
        <p>Hauteur : ${constraints.MIN_FRAME_HEIGHT.min}mm et ${constraints.MAX_FRAME_HEIGHT.max}mm</p>
        <p>Profondeur : ${constraints.MIN_FURNITURE_DEPTH.min}mm et ${constraints.MAX_FURNITURE_DEPTH.max}mm</p>
    `,
};

export const DOOR_CONSTRAINTS = {
    min: {
        x: constraints.MIN_DOOR_WIDTH.min,
        y: constraints.MIN_DOOR_HEIGHT.min,
        z: constraints.MIN_FURNITURE_DEPTH.min,
    },
    max: {
        x: constraints.MAX_DOOR_WIDTH.max,
        y: constraints.MAX_DOOR_HEIGHT.max,
        z: constraints.MAX_FURNITURE_DEPTH.max,
    },
    message: `
        <p>L'espace recevant votre porte simple doit être compris entre:</p>
        <p>Largeur : ${constraints.MIN_DOOR_WIDTH.min}mm et ${constraints.MAX_DOOR_WIDTH.max}mm</p>
        <p>Hauteur : ${constraints.MIN_DOOR_HEIGHT.min}mm et ${constraints.MAX_DOOR_HEIGHT.max}mm</p>
        <p>Profondeur : ${constraints.MIN_FURNITURE_DEPTH.min}mm et ${constraints.MAX_FURNITURE_DEPTH.max}mm</p>
    `,
};

export const DOOR_DOUBLE_CONSTRAINTS = {
    min: {
        x: constraints.MIN_DOOR_DOUBLE_WIDTH.min,
        y: constraints.MIN_DOOR_DOUBLE_HEIGHT.min,
        z: constraints.MIN_FURNITURE_DEPTH.min,
    },
    max: {
        x: constraints.MAX_DOOR_DOUBLE_WIDTH.max,
        y: constraints.MAX_DOOR_DOUBLE_HEIGHT.max,
        z: constraints.MAX_FURNITURE_DEPTH.max,
    },
    message: `
        <p>L'espace recevant votre porte double doit être compris entre:</p>
        <p>Largeur : ${constraints.MIN_DOOR_DOUBLE_WIDTH.min}mm et ${constraints.MAX_DOOR_DOUBLE_WIDTH.max}mm</p>
        <p>Hauteur : ${constraints.MIN_DOOR_DOUBLE_HEIGHT.min}mm et ${constraints.MAX_DOOR_DOUBLE_HEIGHT.max}mm</p>
        <p>Profondeur : ${constraints.MIN_FURNITURE_DEPTH.min}mm et ${constraints.MAX_FURNITURE_DEPTH.max}mm</p>
    `,
};

export const BASEBOARD_CONSTRAINTS = {
    min: {
        y: constraints.MIN_BASEBOARD_HEIGHT.min,
    },
    max: {
        y: constraints.MAX_BASEBOARD_HEIGHT.max,
    },
};

export const ACCESSORYDRAWER_CONSTRAINTS = {
    min: {
        x: constraints.MIN_DRAWER_WIDTH.min,
        y: constraints.MIN_ACCESSORYDRAWER_HEIGHT.min,
        z: constraints.MIN_DRAWER_DEPTH.min,
    },
    max: {
        x: constraints.MAX_DRAWER_WIDTH.max,
        y: constraints.MAX_ACCESSORYDRAWER_HEIGHT.max,
        z: constraints.MAX_DRAWER_DEPTH.max,
    },
    message: `
        <p>L'espace recevant votre tiroir doit être compris entre:</p>
        <p>Largeur : ${constraints.MIN_DRAWER_WIDTH.min}mm et ${constraints.MAX_DRAWER_WIDTH.max}mm</p>
        <p>Hauteur : ${constraints.MIN_ACCESSORYDRAWER_HEIGHT.min}mm et ${constraints.MAX_ACCESSORYDRAWER_HEIGHT.max}mm</p>
        <p>Profondeur : ${constraints.MIN_DRAWER_DEPTH.min}mm et ${constraints.MAX_DRAWER_DEPTH.max}mm</p>
    `,
};

export const DRAWER_CONSTRAINTS = {
    min: {
        x: constraints.MIN_DRAWER_WIDTH.min,
        y: constraints.MIN_DRAWER_HEIGHT.min,
        z: constraints.MIN_DRAWER_DEPTH.min,
    },
    max: {
        x: constraints.MAX_DRAWER_WIDTH.max,
        y: constraints.MAX_DRAWER_HEIGHT.max,
        z: constraints.MAX_DRAWER_DEPTH.max,
    },
    message: `
        <p>L'espace recevant votre tiroir doit être compris entre:</p>
        <p>Largeur : ${constraints.MIN_DRAWER_WIDTH.min}mm et ${constraints.MAX_DRAWER_WIDTH.max}mm</p>
        <p>Hauteur : ${constraints.MIN_DRAWER_HEIGHT.min}mm et ${constraints.MAX_DRAWER_HEIGHT.max}mm</p>
        <p>Profondeur : ${constraints.MIN_DRAWER_DEPTH.min}mm et ${constraints.MAX_DRAWER_DEPTH.max}mm</p>
    `,
};

export const PULLOUTSHELF_CONSTRAINTS = {
    min: {
        x: constraints.MIN_PULLOUTSHELF_WIDTH.min,
    },
    max: {
        x: constraints.MAX_PULLOUTSHELF_WIDTH.max,
    },
    message: `
        <p>L'espace recevant votre tirette doit être compris entre:</p>
        <p>Largeur : ${constraints.MIN_PULLOUTSHELF_WIDTH.min}mm et ${constraints.MAX_PULLOUTSHELF_WIDTH.max}mm</p>
    `,
};
