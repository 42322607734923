import { DOOR_DOUBLE } from "../constants";
import KnobFactory from "../knob/knob-factory";
import DoorMesh from "./door-mesh";

const DoorMeshBuilder = {

    /**
     *
     * @param {DoorEntity} doorEntity
     * @param {TransformNode} parent : furnitureEntity.container
     */
    buildDefault(doorEntity, parent) {
        doorEntity.container.parent = parent;
        const doorMesh = new DoorMesh(doorEntity);
        doorMesh.parent = doorEntity.container;
        doorMesh.metadata = Object.assign(doorMesh.metadata || {}, {
            doorEntity: doorEntity,
        });
        doorMesh.isPickable = false;
        doorEntity.mesh = doorMesh;

        doorMesh.linesSystem.parent = doorEntity.container;

        doorEntity.addKnob(KnobFactory.createInstance());
        if (doorEntity.type === DOOR_DOUBLE) doorEntity.addKnob(KnobFactory.createInstance());

        return doorMesh;
    },

};

export default DoorMeshBuilder;
