<template>
    <svg v-if="isColored"
         id="Calque_1"
         version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink"
         x="0px"
         y="0px"
         width="28.9px"
         height="28.9px"
         viewBox="0 0 28.9 28.9"
         style="enable-background:new 0 0 28.9 28.9;"
         xml:space="preserve"
    >
        <g>
            <path class="st0"
                  fill="#EC910A"
                  stroke="#EC910A"
                  stroke-width="0.35"
                  stroke-miterlimit="10"
                  d="M4.2,8.3V5.7C4.2,4.9,5,4.2,6,4.2h7.5c0.2,0,0.3,0,0.5,0.1l4.2,2.5C18.6,7,19,7.1,19.4,7.1l4.6,0
		c0.6,0.1,1,0.6,1,1.2c0.5,0.1,1,0.2,1.5,0.5V8.5c0-1.3-0.8-2.6-2.4-2.8h-4.7c-0.2,0-0.3,0-0.5-0.1l-4.2-2.5
		c-0.4-0.2-0.8-0.3-1.2-0.3H6c-1.8,0-3.3,1.3-3.3,2.9v3.1C3.2,8.5,3.7,8.4,4.2,8.3z"
            />
            <path class="st1"
                  stroke="#000000"
                  stroke-width="0.35"
                  stroke-miterlimit="10"
                  d="M22.9,26.4C22.9,26.4,22.9,26.4,22.9,26.4L22.9,26.4l-17-0.1c-2.2,0-4-1.6-4.3-3.6l-1.2-10
		c-0.1-1.1,0.2-2.1,0.9-3c0.8-1,2.1-1.6,3.4-1.6h19.5c1.3,0,2.6,0.6,3.4,1.6c0.7,0.8,1,1.9,0.9,3l-1.2,10.1
		C27,24.9,25.1,26.4,22.9,26.4z M22.9,24.9C22.9,24.9,22.9,24.9,22.9,24.9c1.5,0,2.7-1,2.8-2.2L27,12.5c0.1-0.6-0.1-1.2-0.5-1.7
		c-0.5-0.7-1.4-1.1-2.3-1.1H4.7c-0.9,0-1.8,0.4-2.3,1.1c-0.4,0.5-0.6,1.1-0.5,1.7l1.2,10c0.2,1.3,1.4,2.2,2.8,2.2L22.9,24.9z"
            />
        </g>
    </svg>
    <svg v-else
         id="Calque_1"
         version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink"
         x="0px"
         y="0px"
         width="28.9px"
         height="28.9px"
         viewBox="0 0 28.9 28.9"
         style="enable-background:new 0 0 28.9 28.9;"
         xml:space="preserve"
    >
        <g>
            <path class="st0"
                  fill="#FFFFFF"
                  stroke="#FFFFFF"
                  stroke-width="0.35"
                  stroke-miterlimit="10"
                  d="M4.2,8.3V5.7C4.2,4.9,5,4.2,6,4.2h7.5c0.2,0,0.3,0,0.5,0.1l4.2,2.5C18.6,7,19,7.1,19.4,7.1l4.6,0
		c0.6,0.1,1,0.6,1,1.2c0.5,0.1,1,0.2,1.5,0.5V8.5c0-1.3-0.8-2.6-2.4-2.8h-4.7c-0.2,0-0.3,0-0.5-0.1l-4.2-2.5
		c-0.4-0.2-0.8-0.3-1.2-0.3H6c-1.8,0-3.3,1.3-3.3,2.9v3.1C3.2,8.5,3.7,8.4,4.2,8.3z"
            />
            <path class="st0"
                  fill="#FFFFFF"
                  stroke="#FFFFFF"
                  stroke-width="0.35"
                  stroke-miterlimit="10"
                  d="M22.9,26.4C22.9,26.4,22.9,26.4,22.9,26.4L22.9,26.4l-17-0.1c-2.2,0-4-1.6-4.3-3.6l-1.2-10
		c-0.1-1.1,0.2-2.1,0.9-3c0.8-1,2.1-1.6,3.4-1.6h19.5c1.3,0,2.6,0.6,3.4,1.6c0.7,0.8,1,1.9,0.9,3l-1.2,10.1
		C27,24.9,25.1,26.4,22.9,26.4z M22.9,24.9C22.9,24.9,22.9,24.9,22.9,24.9c1.5,0,2.7-1,2.8-2.2L27,12.5c0.1-0.6-0.1-1.2-0.5-1.7
		c-0.5-0.7-1.4-1.1-2.3-1.1H4.7c-0.9,0-1.8,0.4-2.3,1.1c-0.4,0.5-0.6,1.1-0.5,1.7l1.2,10c0.2,1.3,1.4,2.2,2.8,2.2L22.9,24.9z"
            />
        </g>
    </svg>
</template>
<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "save-icon",
    props: {
        isColored: {
            type: Boolean,
            default: true,
        },
    },
});
</script>
