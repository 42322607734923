import { defineStore } from "pinia";
import { TYPE_DRAWER_INSET,
    TYPE_DRAWER_OVERLAY,
    FIELD_WIDTH,
    FIELD_HEIGHT,
    FIELD_DEPTH,
    FIELD_PLINTH_HEIGHT } from "../services/constants";
import { FRONT_MODE_OUTSIDE } from "../../../furnitures/src/constants";
import useGlobalStore from "./global";

export default defineStore("furniture", {
    state: () => ({
        width: 0,
        height: 0,
        depth: 0,
        overallWidth: 0,
        overallHeight: 0,
        overallDepth: 0,
        plinthHeight: 0,
        frontMode: null,
        baseMode: null,
        overlayDrawers: [],
        insetDrawers: [],
        accessoryDrawers: [],
        currentDrawerId: null,
        currentBoard: null,
        currentMovableshelf: null,
        measures: [],
    }),
    getters: {
        dimensions: (state) => ({
            width: state.width,
            height: state.height,
            depth: state.depth,
        }),
        dimensionsOverall: (state) => {
            const overallDepth = state.frontMode === FRONT_MODE_OUTSIDE ? state.overallDepth + 3 : state.overallDepth;
            return {
                width: state.overallWidth,
                height: state.overallHeight,
                depth: overallDepth,
            };
        },
        currentDrawer: (state) => {
            const currentOverlayDrawer = state.overlayDrawers.find((drawer) => drawer.id === state.currentDrawerId);
            const currentInsetDrawer = state.insetDrawers.find((drawer) => drawer.id === state.currentDrawerId);
            const currentAccessoryDrawer = state.accessoryDrawers.find((drawer) => drawer.id === state.currentDrawerId);
            if (!currentOverlayDrawer && !currentInsetDrawer && !currentAccessoryDrawer) return null;

            const currentDrawer = currentOverlayDrawer || currentInsetDrawer || currentAccessoryDrawer;
            if (!currentAccessoryDrawer) {
                currentDrawer.type = currentOverlayDrawer ? TYPE_DRAWER_OVERLAY : TYPE_DRAWER_INSET;
            }

            return currentDrawer;
        },
        volume: (state) => (state.overallDepth / 1000) * (state.overallHeight / 1000) * (state.overallWidth / 1000),
    },
    actions: {
        setDimensions(dimensions) {
            const { x, y, z } = dimensions;

            this.$patch({
                width: x,
                height: y,
                depth: z,
            });
        },
        setDimensionsOverall(dimensions) {
            const { width, height, depth } = dimensions;

            this.$patch({
                overallWidth: width,
                overallHeight: height,
                overallDepth: depth,
            });
        },
        setPlinthHeight(plinthHeight) {
            this.plinthHeight = plinthHeight;
        },
        setBaseMode(baseMode) {
            this.baseMode = baseMode;
        },
        setFrontMode(frontMode) {
            this.frontMode = frontMode;
        },
        setOverlayDrawers(payload) {
            if (payload.id === null) {
                this.currentDrawerId = null;
                this.overlayDrawers = this.overlayDrawers.filter((overlay) => overlay.id !== payload.id);
                return;
            }
            this.currentDrawerId = payload.id;

            const currentDrawer = this.overlayDrawers.find((overlay) => overlay.id === payload.id);
            if (currentDrawer) {
                currentDrawer.quantity = payload.quantity;
                return;
            }
            this.overlayDrawers.push(payload);
        },
        setInsetDrawers(payload) {
            if (payload.id === null) {
                this.currentDrawerId = null;
                this.insetDrawers = this.insetDrawers.filter((overlay) => overlay.id !== payload.id);
                return;
            }
            this.currentDrawerId = payload.id;

            const currentDrawer = this.insetDrawers.find((overlay) => overlay.id === payload.id);
            if (currentDrawer) {
                currentDrawer.quantity = payload.quantity;
                return;
            }
            this.insetDrawers.push(payload);
        },
        setAccessoryDrawers(payload) {
            if (payload.id === null) {
                this.currentDrawerId = null;
                this.accessoryDrawers = this.accessoryDrawers.filter((overlay) => overlay.id !== payload.id);
                return;
            }
            this.currentDrawerId = payload.id;

            const currentDrawer = this.accessoryDrawers.find((overlay) => overlay.id === payload.id);
            if (currentDrawer) {
                currentDrawer.quantity = payload.quantity;
                return;
            }
            this.accessoryDrawers.push(payload);
        },
        resetCurrentDrawer() {
            this.currentDrawerId = null;
        },
        setCurrentBoard(board) {
            // Toggle back the dimensions button
            if (board && useGlobalStore().shouldDisplayMeasures) {
                useGlobalStore().toggleMeasures();
            }
            if (board && board.id) {
                this.currentBoard = {
                    id: board.id,
                    position: board.position,
                    scaling: board.scaling,
                    vertical: board.vertical,
                };
            }
        },
        setCurrentMovableShelf(movableshelf) {
            if (movableshelf && movableshelf.id) {
                this.currentMovableshelf = {
                    id: movableshelf.id,
                    position: movableshelf.position,
                    scaling: movableshelf.scaling,
                };
            }
        },
        setMeasures(measures) {
            this.measures = measures;
        },
        updateDimension(field, value) {
            switch (field) {
                case FIELD_WIDTH:
                    this.width = value;
                    break;
                case FIELD_HEIGHT:
                    this.height = value;
                    break;
                case FIELD_DEPTH:
                    this.depth = value;
                    break;
                case FIELD_PLINTH_HEIGHT:
                    this.plinthHeight = value;
                    break;
                default:
                    break;
            }
        },
    },
});
