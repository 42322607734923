import {
    StandardMaterial,
} from "@babylonjs/core";

import self from "../../index";

const {
    config,
} = self.app;

let selectedMaterial;

function getSelectedMaterial() {
    if (selectedMaterial) return selectedMaterial;

    selectedMaterial = new StandardMaterial("selectedMaterial");
    selectedMaterial.emissiveColor = config.get("selectedBoardColor");
    return selectedMaterial;
}

const AccessorydrawerMaterialsBuilder = {
    get selectedMaterial() {
        return getSelectedMaterial();
    },
};

export default AccessorydrawerMaterialsBuilder;
