const self = require("../index.js").default;

/**
 * ToolManager Controller module
 * It allows to register and unregister tool and interact the list of them
 * Main idea is to active/inactive tools when you need to switch your type of interaction
 */
class ToolManagerController {

    /**
     * @param  {ToolManagerEntity} entity
     */
    constructor(model) {
        this.model = model;
    }

    /**
     * Register passed tools to the list of current managed tools
     * @param  {Atool} tool tool object which should inherit from abstract tool class
     */
    registerTool(tool) {
        this.model.registerTool(tool);
    }

    /**
     * Unregister passed tool from the list of current managed tools
     * @param  {Atool} tool tool object which should inherit from abstract tool class
     */
    unregisterTool(tool) {
        this.model.unregisterTool(tool);
    }

    /**
     * Function will inactivate non persistent tools and activate current tool
     * @param  {String} toolName Name of the tool to activate
     */
    activateToolAlone(toolName) {
        this.deactivateTools(toolName);
        this.activateTool(toolName);
    }

    /**
     * Active tool from tool name, it won't have any effect to other tools
     * @param  {String} toolName Name of the tool to activate
     */
    activateTool(toolName) {
        const toolFound = this.model.getToolFromName(toolName);
        if (!toolFound) {
            self.app.log.warn("Can't find tool ", toolName, " to activate it");
            return;
        }
        toolFound.setToolActive();
    }

    /**
     * Activate tool list based on name
     * @param  {Array} toolNameList list of tool's name you want to set active
     */
    activateToolList(toolNameList) {
        for (let i = 0; i < toolNameList.length; i++) {
            this.activateTool(toolNameList[i]);
        }
    }

    /**
     * Inactive tool from tool name, it won't have any effect to other tools
     * @param  {String} toolName Name of the tool to inactivate
     */
    deactivateTool(toolName) {
        const toolFound = this.model.getToolFromName(toolName);
        if (!toolFound) {
            self.app.log.warn("Can't find tool ", toolName, " to inactivate it");
            return;
        }
        toolFound.setToolInactive();
    }

    /**
     * Inactive tools, keep persistent tools
     */
    deactivateTools(exceptToolName) {
        for (let i = 0; i < this.model.toolList.length; i++) {
            if (
                this.model.toolList[i].tool_isPersistent === false
                && this.model.toolList[i].tool_name !== exceptToolName
                && this.model.toolList[i].isActive()
            ) {
                this.model.toolList[i].setToolInactive();
            }
        }
    }

    /**
     * Inactive all tools
     * Warning it inactive persistent tools too
     */
    deactivateAllTools() {
        for (let i = 0; i < this.model.toolList.length; i++) {
            this.model.toolList[i].setToolInactive();
        }
    }

    /**
     * Set tool to active if inactive and vice versa
     * Assume tool is to be activated alone if it is not specified
     * @param {String} toolName
     * @param {boolean} toolAlone specifies if the tool is to be activated alone or not
     */
    toggleTool(toolName, toolAlone = true) {
        const tool = this.model.getToolFromName(toolName);
        if (tool.isActive()) {
            this.deactivateTool(toolName);
        } else if (tool.canBeActivated()) {
            if (toolAlone) {
                this.activateToolAlone(toolName);
            } else {
                this.activateTool(toolName);
            }
        }
    }

}

export default ToolManagerController;
