<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="26.244" viewBox="0 0 32 26.244">
        <desc>Une icône représentant une tablette à glissière</desc>
        <g id="icon-tirette" transform="translate(0)">
            <g id="Rectangle_577"
               data-name="Rectangle 577"
               transform="translate(1)"
               fill="none"
               stroke="#fff"
               stroke-width="1.5"
            >
                <rect width="30" height="9" rx="2" stroke="none" />
                <rect x="0.75"
                      y="0.75"
                      width="28.5"
                      height="7.5"
                      rx="1.25"
                      fill="none"
                />
            </g>
            <path id="Soustraction_58"
                  data-name="Soustraction 58"
                  d="M30.782,23.345c-.028-.007-3.548-.011-10.463-.011-8.373,0-19.1.006-19.133.01A1.726,1.726,0,0,1,.005,21.606c-.012-.033,0-1.937,0-1.957L3.71,1.288C3.713,1.275,4,.006,4.922.006c.017.012,1.2.018,3.618.018,2.445,0,5.8-.006,9.06-.012l1.494,0h.04C21.693,0,24.111,0,25.569,0c1.012,0,1.509,0,1.519.007A1.232,1.232,0,0,1,28.283.98a.231.231,0,0,0,.013.036c.059.058,3.556,17.876,3.7,18.635,0,.014,0,1.469,0,1.957a2.016,2.016,0,0,1-.471,1.4,1.033,1.033,0,0,1-.747.336ZM2.5,20.22a.691.691,0,0,0,0,1.382H29.5a.691.691,0,1,0,0-1.382Z"
                  transform="translate(0 2.899)"
                  fill="#fff"
            />
        </g>
    </svg>
</template>

<script>
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "pull-out-shelf-icon",
});
</script>
