import { v4 as uuidv4 } from "uuid";

/**
 * ATool
 * A tool can be seen as an interaction mode
 * Tool which is supposed to be used as a mother class for every tool
 * It gives base tool informations
 */
class ATool {

    constructor(params = {}) {
        this.tool_name = "";

        // indicate whether tool is activate or not
        this.tool_isActive = false;

        // List of availables modes, mode could be for e.g. "add", "edit", "delete" or whatever you need
        this.tool_modes = [];

        // List of actives modes, it's usually one but can be several modes
        this.tool_activeModes = [];

        // a tool can be persistent, it means it will (almost) always stay active
        this.tool_isPersistent = false;

        Object.assign(this, params);

        this.tool_id = `${this.tool_name}_${uuidv4()}`;
    }

    setToolActive() {
        this.tool_isActive = true;
        this.onActiveCallback();
    }

    setToolInactive() {
        if (this.tool_isActive === true) {
            this.onEndActiveCallback();
        }
        this.tool_isActive = false;
    }

    isActive() {
        return this.tool_isActive;
    }

    /**
     * Function will be call when tool is becoming active
     */
    onActiveCallback() {} // eslint-disable-line

    /**
     * Function will be call when tool is switching from state active to inactive
     */
    onEndActiveCallback() {} // eslint-disable-line

    canBeActivated() { return true; } // eslint-disable-line

    activateMode(mode) {
        if (this.tool_modes.includes(mode) && !this.tool_activeModes.includes(mode)) {
            this.tool_activeModes.push(mode);
            this.onActiveModeCallback(mode);
        }
    }

    deactivateMode(mode) {
        if (this.tool_activeModes.includes(mode)) {
            const index = this.tool_activeModes.indexOf(mode);
            this.tool_activeModes.splice(index, 1);
            this.onEndActiveModeCallback(mode);
        }
    }

    activateModeAlone(mode) {
        this.tool_activeModes.forEach((activeMode) => this.deactivateMode(activeMode));
        this.activateMode(mode);
    }

    isModeActive(mode) {
        return this.tool_activeModes.includes(mode);
    }

    isAnyModeActive() {
        return this.tool_activeModes.length > 0;
    }

    onActiveModeCallback(mode) {} // eslint-disable-line

    onEndActiveModeCallback(mode) {} // eslint-disable-line

}

export default ATool;
