<template>
    <div class="relative cursor-grab">
        <div
            class="relative pointer-events-none"
        >
            <slot />
        </div>
        <div
            class="absolute z-0 top-0"
            draggable="true"
            @dragstart="onDragStart"
            @dragend="onDragEnd($event)"
        >
            <slot />
        </div>
    </div>
</template>
<script>
import { defineComponent } from "@vue/runtime-core";
import self from "../../../index";

const { events } = self.app;

export default defineComponent({
    name: "draggable",
    props: {
        targetType: {
            type: String,
            default: "",
        },
        startDragEvents: {
            type: Array,
            default: () => [],
        },
        dropEvents: {
            type: Array,
            default: () => [],
        },
        endDragEvents: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            dragStartEventName: "drag:start",
            dragEndEventName: "drag:end",
        };
    },

    methods: {
        onDragStart() {
            events.emit(this.dragStartEventName, {
                targetType: this.targetType,
                startEvents: [...this.startDragEvents],
                dropEvents: [...this.dropEvents],
                endEvents: [...this.endDragEvents],
            });
        },
        onDragEnd() {
            events.emit(this.dragEndEventName);
        },
    },

});
</script>

<style lang="scss" scoped>
[draggable="true"]{
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>

