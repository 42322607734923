import html2canvas from "html2canvas";
import { toRaw } from "vue";
import self from "../../index";
import useGlobalStore from "../stores/global";
import useFurnitureStore from "../stores/furniture";
import { TAB_STRUCTURE_INT, TAB_FINISHING_TOUCHES, BASE_MODE_BASEBOARD } from "./constants";
import PdfGenerator from "./pdfGenerator";

const {
    modules,
} = self.app;

async function cropFront() {
    const exportSection = document.querySelector("#export");
    modules.camerasManager.controller.lockToFrontWithPerspective();
    await new Promise((r) => setTimeout(r, 100));
    const rect = modules.furnitures.furnituresListController.getViewCropRect("cam_front");
    const canvas = await html2canvas(exportSection, { ...rect, scale: 2 });
    return canvas;
}

async function cropPerspective() {
    const exportSection = document.querySelector("#export");
    modules.camerasManager.controller.perspectiveCameraForSnapshot();
    await new Promise((r) => setTimeout(r, 100));
    const rect = modules.furnitures.furnituresListController.getViewCropRect("cam_perspective");
    const canvas = await html2canvas(exportSection, { ...rect, scale: 2 });
    return canvas;
}

export default async function generatePdf() {
    const pdfGenerator = new PdfGenerator();

    const globalStore = useGlobalStore();
    const furnitureStore = useFurnitureStore();

    modules.wanadevLoader.controller.show();

    globalStore.hideAxes();
    globalStore.setActiveTab(TAB_FINISHING_TOUCHES);
    await new Promise((r) => setTimeout(r, 1000));

    const perspective = await cropPerspective();
    if (perspective.height <= perspective.width) {
        pdfGenerator.addImageSection("Vue en perspective", perspective, 400);
    } else {
        pdfGenerator.addImageSection("Vue en perspective", perspective, 0, 400);
    }

    pdfGenerator.newPage();

    await new Promise((r) => setTimeout(r, 100));

    const frontFinishingTouches = await cropFront();

    if (frontFinishingTouches.height <= frontFinishingTouches.width) {
        pdfGenerator.addImageSection("Vue avec façades", frontFinishingTouches, 400);
    } else {
        pdfGenerator.addImageSection("Vue avec façades", frontFinishingTouches, 0, 400);
    }

    pdfGenerator.newPage();
    globalStore.setActiveTab(TAB_STRUCTURE_INT);
    globalStore.showMeasures();
    const front = await cropFront();
    await new Promise((r) => setTimeout(r, 100));
    if (front.height <= front.width) {
        pdfGenerator.addImageSection("Dimensions structure intérieure", front, 375);
    } else {
        pdfGenerator.addImageSection("Dimensions structure intérieure", front, 0, 375);
    }

    const overallDimensionsHeader = [["Hauteur", "Largeur", "Profondeur"]];
    const overallDimensionsContent = [[`${furnitureStore.dimensionsOverall.height}mm`, `${furnitureStore.dimensionsOverall.width}mm`, `${furnitureStore.dimensionsOverall.depth}mm`]];

    pdfGenerator.addTable("Dimensions hors tout", overallDimensionsHeader, overallDimensionsContent);

    if (furnitureStore.baseMode === BASE_MODE_BASEBOARD) {
        pdfGenerator.addTextSection(null, `Hauteur de la plinthe: ${furnitureStore.plinthHeight}mm`);
    }

    globalStore.toggleMeasures();
    pdfGenerator.newPage();

    const furnitureComposition = toRaw(globalStore.furnitureComposition);
    const boardData = furnitureComposition.boardGroupCollection.map((board) => [board.ref, board.quantity, board.dimensions[1], board.dimensions[2], board.dimensions[0]]);
    const boardHeader = [["Référence", "Quantité", "Largeur (mm)", "Hauteur (mm)", "Epaisseur (mm)"]];

    pdfGenerator.addTable("Panneaux", boardHeader, boardData);

    pdfGenerator.addTextSection(null, `Poids total: ${globalStore.weight.toFixed(2)}kg`);

    const groupedBorders = {};
    furnitureComposition.borderGroupCollection.forEach((borderGroup) => {
        if (!groupedBorders[borderGroup.ref]) {
            groupedBorders[borderGroup.ref] = 0;
        }
        groupedBorders[borderGroup.ref] += borderGroup.dimensions[0] * borderGroup.quantity;
    });
    const borderData = Object.keys(groupedBorders).map((key) => [key, groupedBorders[key]]);
    const borderHeader = [["Référence", "Longueur (mm)"]];

    pdfGenerator.addTable("Chants", borderHeader, borderData);

    if (furnitureComposition.feet.quantity) {
        const feetData = [[globalStore.selectedProducts.feet.product_ref, furnitureComposition.feet.quantity]];
        const feetHeader = [["Référence", "Quantité"]];
        pdfGenerator.addTable("Pieds", feetHeader, feetData);
    }


    if (furnitureComposition.doorKnobs.quantity || furnitureComposition.drawerKnobs.quantity) {
        const knobsData = [];
        if (globalStore.selectedProducts.doorButton.product_ref === globalStore.selectedProducts.drawerButton.product_ref) {
            knobsData.push([furnitureComposition.doorKnobs.ref, furnitureComposition.drawerKnobs.quantity + furnitureComposition.doorKnobs.quantity]);
        } else {
            if (furnitureComposition.doorKnobs.quantity) {
                knobsData.push([globalStore.selectedProducts.doorButton.product_ref, furnitureComposition.doorKnobs.quantity]);
            }
            if (furnitureComposition.drawerKnobs.quantity) {
                knobsData.push([globalStore.selectedProducts.drawerButton.product_ref, furnitureComposition.drawerKnobs.quantity]);
            }
        }
        const knobsHeader = [["Référence", "Quantité"]];
        pdfGenerator.addTable("Boutons de porte", knobsHeader, knobsData);

    }

    if (furnitureComposition.hinges.quantity) {
        const hingesData = [[globalStore.selectedProducts.hinge?.product_ref, furnitureComposition.hinges.quantity]];
        const hingesHeader = [["Référence", "Quantité"]];
        pdfGenerator.addTable("Charnières", hingesHeader, hingesData);
    }

    if (furnitureComposition.rodGroupCollection.length) {
        const rodGroupsData = furnitureComposition.rodGroupCollection.map((rodGroup) => [rodGroup.quantity, rodGroup.dimensions[0]]);
        const rodGroupsHeader = [["Quantité", "Longueur (mm)"]];
        pdfGenerator.addTable("Tringles", rodGroupsHeader, rodGroupsData);
    }

    if (furnitureComposition.drawerRunnersGroupCollection.length) {
        const runnersGroupsData = furnitureComposition.drawerRunnersGroupCollection.map((drawerRunnersGroup) => [drawerRunnersGroup.ref, drawerRunnersGroup.quantity, drawerRunnersGroup.length]);
        const runnersGroupsHeader = [["Référence", "Quantité", "Longueur (mm)"]];
        pdfGenerator.addTable("Glissieres", runnersGroupsHeader, runnersGroupsData);
    }

    pdfGenerator.setWatermark();
    pdfGenerator.generatePageNumbers();

    globalStore.showAxes();

    const blobPdf = new Blob([pdfGenerator.getPdfBlob()], { type: "application/pdf" });
    modules.wanadevLoader.controller.hide();
    return blobPdf;
}
