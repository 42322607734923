export default {

    name: "cameras-manager",
    requires: ["obsidian-babylon-engine", "tweakpane", "data-store"],

    config: {
        target: { x: 0, y: 0, z: 0 }, // {JSON Vector3} camera centered target
        defaultAnimationDuration: 700, // Animation duration while switching view in ms
        views: {
            top: [2000, 0, 0], // Camera view on top (radius, alpha, beta) in meter
            side: [2000, 0, (Math.PI / 2)], // Camera view on side (radius, alpha, beta) in meter
            front: [2000, -(Math.PI / 2), (Math.PI / 2)], // Camera view on front (radius, alpha, beta) in meter
        },
    },

    load() {
        const CamerasManagerController = require("./src/cameras-manager-controller").default;
        this.camerasManagerController = new CamerasManagerController();
        return {
            controller: this.camerasManagerController,
        };
    },

    unload() {},

};
