
import { BOARD_THICKNESS } from "../constants";
import { DRAWER_CONSTRAINTS } from "../constraints";
import { KNOB_DIAMETER, KNOB_INSTANCE_NAME } from "../knob/knob-factory";
import MaterialManager from "../materials/material-manager";
import DrawerComposition from "./drawer-composition";
import self from "../../index";

const {
    modules,
} = self.app;

const DrawerHelper = {

    canAddToFrame(frameEntity) {
        return !frameEntity.idMovableshelfs.length &&
            !frameEntity.idPulloutshelfs.length &&
            !frameEntity.idDrawer &&
            !frameEntity.idDoor &&
            !frameEntity.idRod;
    },

    getMinimumQuantity(drawerEntity, constraints) {
        return Math.ceil(drawerEntity.scaling.y / constraints.max.y);
    },

    adjustQuantityAfterFrameScaled(drawerEntity, constraints) {
        const frameEntity = modules.dataStore.getEntity(drawerEntity.idParent);
        const height = Math.ceil(frameEntity.scaling.y / drawerEntity.quantity);
        if (height < constraints.min.y) {
            drawerEntity.quantity = drawerEntity.quantity - 1 || 1;
        } else if (height > constraints.max.y) {
            drawerEntity.quantity = drawerEntity.quantity + 1 || Math.ceil(drawerEntity.scaling.y / constraints.min.y);
        }
    },

    updateMeshScaleAndPosition(drawerEntity) {
        const frameEntity = modules.dataStore.getEntity(drawerEntity.idParent);
        if (!drawerEntity.mesh || !frameEntity) return;
        drawerEntity.mesh.scaling = drawerEntity.scaling;
        drawerEntity.mesh.scaling.z = BOARD_THICKNESS;
        drawerEntity.mesh.updateLinesSystem();

        DrawerHelper.adjustQuantityAfterFrameScaled(drawerEntity, DRAWER_CONSTRAINTS);

        const height = frameEntity.scaling.y / drawerEntity.quantity;
        for (let i = 0; i < drawerEntity.meshesRef.length; i++) {
            const mesh = drawerEntity.meshesRef[i];
            mesh.scaling.x = drawerEntity.scaling.x;
            mesh.scaling.z = BOARD_THICKNESS;
        }

        for (let i = 0; i < drawerEntity.groupInstances.length; i++) {
            const group = drawerEntity.groupInstances[i];
            for (let j = 0; j < group.getChildMeshes().length; j++) {
                const mesh = group.getChildMeshes()[j];
                mesh.isVisible = i < drawerEntity.quantity && drawerEntity.isVisible;
                if (mesh.name === KNOB_INSTANCE_NAME) {
                    mesh.isVisible = i < drawerEntity.quantity && drawerEntity.furnitureEntity.knobVisible && drawerEntity.isVisible;
                    mesh.position.x = drawerEntity.scaling.x / 2;
                    mesh.position.y = height > 300 ? height - KNOB_DIAMETER : height / 2;
                }
            }
            group.position.y = i * height;
        }
        DrawerComposition.updateDrawerComposition(drawerEntity);
    },

    applySelectedMaterial(drawerEntity) {
        const { mesh } = drawerEntity;
        mesh.material = MaterialManager.mainConstructionMaterial;
        mesh.setVisible(false);
        mesh.isVisible = true;
    },

    applyConstructionMaterial(drawerEntity) {
        const { mesh } = drawerEntity;
        mesh.material = MaterialManager.frontConstructionMaterial;
        mesh.setVisible(false);
        if (drawerEntity.isVisible) {
            mesh.isVisible = true;
            mesh.linesSystem.isVisible = true;
        }
    },

    applyFinishMaterial(drawerEntity) {
        const { mesh } = drawerEntity;
        const faces = [mesh.faceFront, mesh.faceBack, mesh.faceLeft, mesh.faceRight, mesh.faceTop, mesh.faceBottom];
        faces.forEach((face) => {
            if (face === mesh.faceLeft || face === mesh.faceRight) {
                face.material = MaterialManager.frontFinishMaterial;
            } else {
                face.material = MaterialManager.frontFinishMaterial90;
            }
        });
        mesh.setVisible(true);
        mesh.isVisible = false;
        mesh.updateUVScaling();
    },

};

export default DrawerHelper;
